import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
// import axios from "axios";
import {
  // useIsMobile,
  useFormData,
} from "../common/utils/hooks";
import PhoneInput from "react-phone-input-2";
import Form from "react-bootstrap/Form";
// import { media } from "../../../common/libs/media";
import { media } from "../common/libs/media";
import { PostData, FetchData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { Icon } from "@iconify/react";
// import Wrapper from "../../../components/sectionWrapper.component";
import Wrapper from "../components/sectionWrapper.component";
import Messages from "./translations/index.json";
import individual from "./translations/individuel.json";
import InputsText from "./translations/input.json";
import DatePicker from "react-date-picker";
import { trackEvent } from "../common/utils/analytics";
// import { useLangContext } from "../../../components/translateContext";
import { useLangContext } from "../components/translateContext";
import { encryptStorage } from "../common/services/encryptData";
import { Link } from "react-router-dom";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
// import { Spinner } from "../../../components/spinner.component";
import { Spinner } from "../components/spinner.component";
// import { CreateUser } from "../../../common/auth";
import { CreateUser } from "../common/auth";
import { SubscribeTo } from "../common/auth";
import Swal from "sweetalert2";
// import IdDocument from "./ldDocument ";
import IdDocument from "../components/ldDocument ";
import IFuDocument from "../components/lfuDocument";
import { width } from "tailwindcss/defaultTheme";
import AdresseDocument from "../components/adresseDocument";

const categories = {
  Education: ["Schools", "Colleges", "Online Learning"],
  "Financial Services": ["Banking", "Insurance", "Investment"],
  Gaming: ["Mobile Games", "PC Games", "Console Games"],
  Hospitality: ["Hotels", "Restaurants", "Tourism"],
  Healthcare: ["Hospitals", "Clinics", "Pharmaceuticals"],
  "Leisure & Entertainment": ["Theme Parks", "Movie Theaters", "Sports"],
  Logistics: ["Shipping", "Trucking", "Air Freight"],
  "Non-profits": ["Charities", "Foundations", "Community Organizations"],
  Travel: ["Airlines", "Travel Agencies", "Car Rentals"],
  Utilities: ["Electricity", "Water", "Gas"],
  Automobile: ["Cars", "Trucks", "Motorcycles"],
  "Digital goods": ["Software", "E-books", "Streaming Services"],
  "Physical Goods": ["Clothing", "Furniture", "Appliances"],
  "Electronics Hardware": ["Computers", "Smartphones", "Televisions"],
  "Grocery and Supermarket": ["Groceries", "Supermarkets", "Specialty Foods"],
  "Clothing and accessories": ["Clothing", "Jewelry", "Footwear"],
  "Real Estate": ["Residential", "Commercial", "Industrial"],
  "Digital services": [
    "Web Design",
    "Digital Marketing",
    "Software Development",
  ],
  Construction: ["Residential", "Commercial", "Infrastructure"],
  Energy: ["Renewable", "Oil and Gas", "Nuclear"],
  Telecommunications: ["Wireless", "Wired", "Satellite"],
  Media: ["Broadcasting", "Publishing", "Advertising"],
  Consulting: ["Management", "IT", "Financial"],
  Retail: ["Department Stores", "Specialty Retailers", "E-commerce"],
  Manufacturing: ["Consumer Goods", "Industrial Products", "Aerospace"],
  Pharmaceuticals: ["Brand Name", "Generic", "Biotech"],
};

const industries = Object.keys(categories);

export default function IndividualBusness() {
  const [isLoading, setLoading] = useState(false);
  const { Lang } = useLangContext();
  const [data, handleInput] = useFormData();
  const [resize, setResize] = useState(false);
  const Infos = {
    msg: Messages[Lang],
    text: individual[Lang],
    input: InputsText[Lang],
  };
  const history = useHistory();
  const [countr, setCountry] = useState("");
  const [region, setRegion] = useState("");
  //   const [value, onChange] = useState(new Date());
  const [selec, setSelects] = useState();
  const [pays, setPays] = useState("");

  const [loading, setMLoading] = useState(true);
  const [user, setUser] = useState();

  const UserProfile = () => {
    FetchData(Endpoints.User.meNew).then((res) => {
      // console.log("resposnse profile user:", res);
      setUser(res);
      setMLoading(false);
    });
  };

  useEffect(() => {
    UserProfile();
  }, []);

  const infoUser = loading ? "loading" : user?.data?.datas;
  // === "INACTIVE"
  //     ? "loading"
  //     : user?.data?.datas.state;
  // console.log("infoUser infoUser:", infoUser.company_name);
  // console.log("profile data:", data);

  //   }, [history]);

  // console.log("data", data);
  // console.log("country", pays);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelects(e.target.value);
  };

  const handlePays = (e) => {
    e.preventDefault();
    setPays(e.target.value);
  };

  //   useEffect(() => {
  //     if (encryptStorage.getItem("qosToken")) {
  //       history.push("/");
  //     }

  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
    setSelectedCategory("");
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const categoryOptions = categories[selectedIndustry] || [];

  // console.log("selectedIndustry =", selectedIndustry);
  // console.log("selectedCategory =", selectedCategory);

  const category = selectedCategory;
  const industry = selectedIndustry;
  const cal = { category, industry };
  // console.log("cal =", cal);

  const tradingName = infoUser.company_name;
  const lastName = infoUser.last_name;
  const firstName = infoUser.first_name;
  const appName = "";
  const bankAccountName = "";
  const bankCardNumber = "";
  const bankName = "";
  const businessName = tradingName;
  const legalbusinessName = "";
  const registrationNumber = "";
  const taxNumber = "";
  // const phoneNumber = infoUser.phone;

  // const emailAddress = infoUser.email;
  const nationality = infoUser.country;

  const typeIdentification = selec;
  const smaFacebook = "";
  const smaInstagram = "";
  const smaTwitter = "";
  const typeofBusiness = "INDIVIDUALBUSINESS";

  const doc = { typeIdentification };
  const doc1 = { appName };
  const doc2 = { bankAccountName };
  const doc3 = { bankCardNumber };
  const doc4 = { bankName };
  const doc5 = { lastName };
  const doc6 = { firstName };
  const doc7 = { typeofBusiness };
  // const doc7 = { phoneNumber };
  const doc8 = { businessName };
  const doc9 = { legalbusinessName };
  const doc10 = { registrationNumber };
  const doc11 = { taxNumber };

  // console.log("gogog", doc4)
  // console.log("doc5", doc5)
  // console.log("doc6", doc6)
  // console.log("doc7", doc7)
  // console.log("doc8", doc8)

  const momoPayAccountMtn = "";
  const momoPayAccountMtnCi = "";
  const momoPayAccountMv = "";
  const momoPayAccountMvCi = "";
  const momoPayAccountMvtg = "";
  const momoPayAccountOrgCi = "";
  const momoPayAccounttgc = "";

  const mtnb = { momoPayAccountMtn };
  const moovb = { momoPayAccountMv };
  const tgc = { momoPayAccounttgc };
  const moovtg = { momoPayAccountMvtg };
  const orgci = { momoPayAccountOrgCi };
  const moovci = { momoPayAccountMvCi };
  const mtnci = { momoPayAccountMtnCi };

  // console.log("typeIdentification", typeIdentification)
  // console.log("doc", doc)

  // { ...data, ...cal, ...doc, ...doc1, ...doc2, ...doc3 }

  const datas =
    infoUser.country === "BENIN"
      ? {
          ...data,
          ...cal,
          ...doc,
          ...doc1,
          ...doc2,
          ...doc3,
          ...doc4,
          ...doc5,
          ...doc6,
          ...doc7,
          ...doc8,
          ...doc9,
          ...doc10,
          ...doc11,
          ...tgc,
          ...moovtg,
          ...orgci,
          ...moovci,
          ...mtnci,
        }
      : infoUser.country === "IVORY_COAST"
      ? {
          ...data,
          ...cal,
          ...doc,
          ...doc1,
          ...doc2,
          ...doc3,
          ...doc4,
          ...doc5,
          ...doc6,
          ...doc7,
          ...doc8,
          ...doc9,
          ...doc10,
          ...doc11,
          ...tgc,
          ...moovtg,
          ...mtnb,
          ...moovb,
        }
      : infoUser.country === "TOGO"
      ? {
          ...data,
          ...cal,
          ...doc,
          ...doc1,
          ...doc2,
          ...doc3,
          ...doc4,
          ...doc5,
          ...doc6,
          ...doc7,
          ...doc8,
          ...doc9,
          ...doc10,
          ...doc11,
          ...orgci,
          ...moovci,
          ...mtnci,
          ...mtnb,
          ...moovb,
        }
      : "";

  // console.log("datas", datas);
  // console.log("category", datas.category);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screenSize = window.innerWidth;
      setResize(screenSize);
    });
    return () => {
      window.removeEventListener("resize", () => {
        let screenSize = window.innerWidth;
      });
    };
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    // const data = { ...cal}
    // console.log("momoPayAccountMtn", datas.momoPayAccountMtn);
    trackEvent('Document upload successfully', 'Individual business Document ', `Document upload successfully`);
    if  (infoUser.country === "BENIN"  && 
    datas.momoPayAccountMtn === undefined  && 
    datas.momoPayAccountMv === undefined ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        position: "top-end",
        icon: "error",
        title: "Please correctly add your personnal information",
        showConfirmButton: false,
      });

      setLoading(false);
    } else if  (infoUser.country === "TOGO"  && 
    datas.momoPayAccounttgc === undefined  && 
    datas.momoPayAccountMvtg === undefined ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        position: "top-end",
        icon: "error",
        title: "Please correctly add your personnal information",
        showConfirmButton: false,
      });

      setLoading(false);
    } else if  (infoUser.country === "IVORY_COAST"  && 
    datas.momoPayAccountOrgCi === undefined  && 
    datas.momoPayAccountMvCi === undefined ) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        position: "top-end",
        icon: "error",
        title: "Please correctly add your personnal information",
        showConfirmButton: false,
      });

      setLoading(false);
    } else {
      try {
        // console.log("user Data : ", data)
        const { data: user, status } = await SubscribeTo(datas);
        // console.log("un user user indivudual : ", user)
        // console.log("un user individuale user indivudual : ", data)

        // console.log("un user response : ", user.responseMessage)
        // console.log("un user authSatus : ", status)

        if (user.responseMessage === "Successful") {
  
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Congratulations",
            text: "Your activation request is submitted and will be processed, Kindly check your email for feedback and update about your submission. Thank you",
            showConfirmButton: false,
            timer: 6000,
          });

          setLoading(false);
          setTimeout(() => {
            history.push("/getstarted");
          }, 6000);
        } else if (user.responseMessage != "Successful") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            position: "top-end",
            icon: "error",
            title: user.responseMessage,
            showConfirmButton: false,
          });
          setLoading(false);
        }
      } catch (error) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });

        setLoading(false);
      }
    }

    // console.log("un data individuale : ", datas)

    setLoading(false);
  }

  const [stepStatus, setStepStatus] = useState([]);

  function refreshPage() {
    setStepStatus([]);
    window.location.reload(false);
  }

  useEffect(() => {
    let stepForm = document.querySelector("#stepForm");

    let formWidth = stepForm.clientWidth;
    let CurrentScroll = stepForm.scrollLeft;
    let currentPos = 1;

    function ControlHandler() {
      currentPos = +(CurrentScroll / formWidth);

      if (CurrentScroll < formWidth * 4 && this.id === "next") {
        document
          .querySelectorAll(".stepStatus")
          [currentPos].classList.toggle("active");
        document
          .querySelectorAll(".stepStatus")
          [currentPos + 1].classList.add("active");
        document
          .querySelectorAll(".stepStatus")
          [currentPos].classList.add("passed");
        CurrentScroll += formWidth;
        stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
      }

      if (CurrentScroll > 0 && this.id === "prev") {
        document
          .querySelectorAll(".stepStatus")
          [currentPos].classList.toggle("active");
        document
          .querySelectorAll(".stepStatus")
          [currentPos - 1].classList.toggle("passed");
        document
          .querySelectorAll(".stepStatus")
          [currentPos - 1].classList.add("active");

        CurrentScroll -= formWidth;
        stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
      }

      if (CurrentScroll === formWidth * 4) {
        setTimeout(() => {
          document.querySelector("#next").setAttribute("form", "stepForm");
          document
            .querySelector("#next")
            .firstChild.replaceWith(Infos.msg.registerButton);
        });
      } else {
        document.querySelector("#next").removeAttribute("form");
        document
          .querySelector("#next")
          .firstChild.replaceWith(Infos.msg.nextButton);
      }

      if (CurrentScroll > 0) {
        document.querySelector("#prev").hidden = false;
      } else {
        document.querySelector("#prev").hidden = true;
      }

      const buttons = document.querySelectorAll(".stepStatus");
      const statusValues = Array.from(buttons).map((button) => {
        if (button.classList.contains("active")) {
          return "active";
        } else if (button.classList.contains("passed")) {
          return "passed";
        }
        return "";
      });

      setStepStatus(statusValues);
    }

    stepForm.querySelectorAll("input").forEach((input) => {
      input.readOnly = true;
      input.addEventListener("click", function (e) {
        this.readOnly = false;
      });
      input.addEventListener("focus", function (e) {
        this.readOnly = false;
        stepForm.scrollTo({
          left: formWidth * (this.parentElement.parentElement.id - 1),
          behavior: "smooth",
        });
      });
    });

    document.querySelectorAll(".controls").forEach((buttonControl) => {
      buttonControl.addEventListener("click", ControlHandler);
    });
    return () => {
      document.querySelectorAll(".controls").forEach((buttonControl) => {
        buttonControl.removeEventListener("click", ControlHandler);
      });
    };
  }, [Infos.msg.registerButton, Infos.msg.nextButton]);

  // console.log('Valeur de stepStatus :', stepStatus);
  // console.log('Valeur de stepStatus indexOf:', stepStatus.Array.indexOf);

  return (
    <>
      <div className="flex gap-4 mt-1 justify-between py-10 bg-qosbackgray">
        <div
          style={{
            // background: "url('./pictures/authpageBackground.png')",
            // backgroundSize: "100% 1%",
            // display: "flex-",
            // justifyContent: "center",
            // alignItems: "center",
            // alignContent:"center",
            height: "black",
            width: "100%",
            // background: {"red",}
          }}
        >
          <div className="pb-4 w-full ml-4">
            {Lang === "en" ? (
              <span className="text-left mt-6  text-busness20">
                {Infos.msg.IndividualT}
              </span>
            ) : (
              <span className="text-left mt-6">
                <span className="text-qosorange  text-busness20 text-opacity-95">
                  {" "}
                  {Infos.msg.IndividualT}{" "}
                </span>{" "}
              </span>
            )}
          </div>
          <div className="block w-full   bg-qosbackgray py-4  ">
            <form
              id="stepForm"
              onSubmit={handleSubmit}
              className="overflow-x-hidden hideScroll  max-w-full w-5/6 md:w-11/12 mx-auto"
            >
              <span id="0" className="text-left my-4 ">
                {Infos.msg.Contact}
              </span>
              <div className="bg-white mt-2 mb-8 min-w-full py-4 md:px-8 max-w-full">
                <div className="mt-6  text-qosText mx-auto w-full px-2 ">
                  {/* <IFuDocument /> */}
                  <AdresseDocument />
                </div>
              </div>

              <span id="0" className="text-left  ">
                {Infos.msg.Owners}
              </span>
              <div className="bg-white mt-2 mb-8 min-w-full py-4 md:px-8 max-w-full">
                <div className="mt-4 text-qosText mx-auto w-full px-2 ">
                  <IdDocument selects={selec} />
                </div>
              </div>

              <span id="0" className="text-left  ">
                {Lang === "en" ? (
                  <span className="text-center px-1 py-8 ">
                    {Infos.msg.payout}
                  </span>
                ) : (
                  <span className="text-center px-1 py-8 ">
                    {Infos.msg.payout}
                  </span>
                )}
              </span>
              <div className="bg-white mt-2 mb-8 min-w-full py-4 md:px-8 max-w-full">
                <div className="min-w-full md:px-8 max-w-full">
                  <div className="mt-2">
                    {infoUser.country === "BENIN" ? (
                      <>
                        <span className="block pl-2">
                          {" "}
                          {Infos.input.tel.label} MTN
                        </span>
                        <PhoneInput
                          country={"bj"}
                          required={true}
                          placeholder={"MTN"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          // onlyCountries={["bj"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountMtn",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountMtn, country, value) => {
                            handleInput({
                              momoPayAccountMtn: momoPayAccountMtn,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />

                        <span className="block mt-4 pl-2">
                          {" "}
                          {Infos.input.tel.label} MOOV
                        </span>
                        <PhoneInput
                          country={"bj"}
                          placeholder={"MOOV"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["bj"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full ml-12"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountMv",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountMv, country, value) => {
                            handleInput({
                              momoPayAccountMv: momoPayAccountMv,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />
                      </>
                    ) : infoUser.country === "TOGO" ? (
                      <>
                        <span className="block pl-2 mt-4">
                          {" "}
                          {Infos.input.tel.label} TOGOCEL
                        </span>
                        <PhoneInput
                          country={"tg"}
                          placeholder={"TOGOCEL"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["tg"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full"
                          inputProps={{
                            required: true,
                            name: "momoPayAccounttgc",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccounttgc, country, value) => {
                            handleInput({
                              momoPayAccounttgc: momoPayAccounttgc,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />

                        <span className="block mt-2 pl-4 ">
                          {" "}
                          {Infos.input.tel.label} MOOV
                        </span>
                        <PhoneInput
                          country={"tg"}
                          placeholder={"MOOV"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["tg"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full ml-12"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountMvtg",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountMvtg, country, value) => {
                            handleInput({
                              momoPayAccountMvtg: momoPayAccountMvtg,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />
                      </>
                    ) : infoUser.country === "IVORY_COAST" ? (
                      <>
                        <span className="block pl-2">
                          {" "}
                          {Infos.input.tel.label} ORANGE{" "}
                        </span>
                        <PhoneInput
                          country={"ci"}
                          placeholder={"ORANGE"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["ci"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountOrgCi",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountOrgCi, country, value) => {
                            handleInput({
                              momoPayAccountOrgCi: momoPayAccountOrgCi,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />

                        <span className="block mt-4 pl-2">
                          {" "}
                          {Infos.input.tel.label} MTN{" "}
                        </span>
                        <PhoneInput
                          country={"ci"}
                          placeholder={"MTN"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["ci"]}
                          // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full ml-12"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountMtnCi",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountMtnCi, country, value) => {
                            handleInput({
                              momoPayAccountMtnCi: momoPayAccountMtnCi,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />

                        <span className="block mt-4 pl-2">
                          {" "}
                          {Infos.input.tel.label} MOOV{" "}
                        </span>
                        <PhoneInput
                          country={"CI"}
                          placeholder={"MOOV"}
                          inputStyle={{
                            color: "black",
                            height: 50,
                            width: "100px",
                            paddingLeft: 70,
                          }}
                          buttonStyle={{ padding: 8 }}
                          onlyCountries={["ci"]}
                          //  onlyCountries={["bj", "tg", "ng", "bf", "bf", "ci", "cm", "ml", "ne", "sn"]}
                          //  localization={{ci:"cote d'ivoire", }}
                          autoFormat={true}
                          containerClass="mt-2  pl-2 pr-2"
                          inputClass="w-full min-w-full ml-12"
                          inputProps={{
                            required: true,
                            name: "momoPayAccountMvCi",
                          }}
                          disableCountryCode={false}
                          countryCodeEditable={false}
                          onChange={(momoPayAccountMvCi, country, value) => {
                            handleInput({
                              momoPayAccountMvCi: momoPayAccountMvCi,
                              // country: `${country.name}`.toUpperCase(),
                              // value: value,
                              // countr:countr,
                            });
                          }}
                        />
                      </>
                    ) : (
                      " "
                    )}
                  </div>

                  {/* <div className="mt-6 mx-auto w-full px-2">
                      <label className="block">{Infos.input.passwordC.label}</label>
                      <input
                        className=""
                        name="confirmPassword"
                        value={data.confirmPassword}
                        onChange={handleInput}
                        type="password"
                        required
                      />
                    </div> */}

                  {/* <div className="mt-12 mx-auto w-full items-center flex px-2">
                    <input
                      id="privacy"
                      type="checkbox"
                      name="terms_and_conditions"
                      value="true"
                      onChange={handleInput}
                      required
                    />
                    <span
                      htmlFor="privacy"
                      className="text-msm leading-4 font-normal ml-4"
                      dangerouslySetInnerHTML={{
                        __html: Infos.msg.ConfirmInfo,
                      }}
                    ></span>
                  </div> */}

                  {/* <div className="mt-6 mx-auto w-full items-center flex px-2">
                    <input
                      id="privacy"
                      type="checkbox"
                      name="terms_and_conditions"
                      value="true"
                      onChange={handleInput}
                      required
                    />
                    <label
                      htmlFor="privacy"
                      className="text-msm leading-4 font-normal ml-4"
                      dangerouslySetInnerHTML={{
                        __html: Infos.input.checkTerms.label,
                      }}
                    ></label>
                  </div> */}
                </div>
              </div>

              {/* <div
                  className="min-w-full md:px-8 max-w-full bg-qosorange"
                  id="5"
                >
                  <div className="my-4 mx-auto h-8  w-full px-2">
                    <span id="0" className="text-left  ">
                      Step 5.
                      {Lang === "en" ? (
                        <span className="text-center px-1 py-8 ">
                          {Infos.msg.payout}
                        </span>
                      ) : (
                        <span className="text-center px-1 py-8 ">
                          {Infos.msg.payout}
                        </span>
                      )}
                    </span>
                  </div>
                </div> */}

              <div className="w-full font-bold text-white flex justify-around gap-2 mt-4 px-8">
                <Link to="/typeofbusiness">
                  <button
                    style={styles.button1}
                    // hidden
                    // id="prev"
                    className="bg-transparent  text-qosorange   py-2 px-1  btn-sm "
                  >
                    {/* <span className="fi fi-rr-arrow-left mr-2 text-msm"></span> */}
                    {Infos.msg.backButton}
                  </button>
                </Link>
       
                <button
                  style={styles.button}
                  // id="next"
                  type="submit"
                  disabled={isLoading}
                  className={` py-2 px-1 rounded-lg text-mlg flex justify-center items-center ${ isLoading ? "bg-coolGray-300" : "bg-qosorange"
                }  p-3 btn-sm `}
                >
                  {isLoading ? (
                    <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                  ) : (
                    <div className="flex gap-2  justify-center align-center">
                      <span>{Infos.msg.registerButton}</span>
                      <span className="fi inline-block align-bottom ml-2 pt-1 fi-rr-arrow-right text-msm"></span>
                    </div>
                  )}
                </button>

       
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

function Togo(handleInput) {
  return (
    <>
      <label className="block pl-2 mt-2">TOGOCEL</label>
      <PhoneInput
        // country={"tg"}
        placeholder={"TOGOCEL"}
        onlyCountries={["tg"]}
        // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
        autoFormat={true}
        containerClass="mt-2  pl-2 pr-2"
        inputClass="w-full min-w-full"
        inputProps={{ required: true, name: "momoPayAccounttgc" }}
        disableCountryCode={false}
        countryCodeEditable={false}
        onChange={(momoPayAccounttgc, country, value) => {
          handleInput({
            momoPayAccounttgc: momoPayAccounttgc,
            // country: `${country.name}`.toUpperCase(),
            // value: value,
            // countr:countr,
          });
        }}
      />

      <label className="block mt-2 pl-2 ">MOOV</label>
      <PhoneInput
        // country={"tg"}
        placeholder={"MOOV"}
        onlyCountries={["tg"]}
        // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
        autoFormat={true}
        containerClass="mt-2  pl-2 pr-2"
        inputClass="w-full min-w-full ml-12"
        inputProps={{ required: true, name: "momoPayAccountMvtg" }}
        disableCountryCode={false}
        countryCodeEditable={false}
        onChange={(momoPayAccountMvtg, country, value) => {
          handleInput({
            momoPayAccountMvtg: momoPayAccountMvtg,
            // country: `${country.name}`.toUpperCase(),
            // value: value,
            // countr:countr,
          });
        }}
      />
    </>
  );
}

export function FilesUploader({ text, className, selects, Account }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);

  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    e.preventDefault();
    let formData = new FormData();
    let nb = Files.list.files.length;
    formData.append("files", Files.list.files[nb - 1]);
    console.log("fiklkkk", Files.list.files[nb - 1]);

    PostData(
      Endpoints.Documents.uploadDocumentProof + "?document=" + selects,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data; boundary=2000",
          accept: "*/*",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE",
        },
      }
    ).then((res) => {
      // console.log("Lololozzzzzz : ", res);
      // console.log("status de lol: " , res.data)
      // console.log("status de lol23: " , res.data.responseStatus)
      // console.log("status de : " , res.data.datas)

      if (res.data.responseStatus === true) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 6000,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (res.data.responseStatus === false) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 6000,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      let valueselect = select;

      valueselect.push(selects);
      setSelect(valueselect);
    });
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  function handleFileList(e) {
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert("File size exceeds 2 MiB");
      // $(file).val(''); //for clearing with Jquery
    } else {
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e);
    }
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>
      <form onSubmit={UpdateFile}>
        <div
          onClick={() => addFileRef.current.click()}
          className="w-4/12 md:w-4/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
        >
          <img
            src={media.Icons[0].upload}
            className="opacity-70 md:h-11 mx-auto"
            alt="hello"
          />
          <input
            ref={addFileRef}
            onChange={handleFileList}
            className="hidden"
            type="file"
            accept=".jpg,.png,.pdf"
            multiple
          />
          <span className="text-small text-qosdark text-opacity-70">
            {text}
          </span>
        </div>

        {/* <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div>
                  {console.log("interrieur selest: ", select)}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div> */}

        {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}
      </form>
    </div>
  );
}

export function File({ delFunc, name, index, selects }) {
  return (
    <div className="p-1 my-1 bg-qosgray bg-opacity-80 w-auto h-8 shadow-md items-center rounded-md justify-between flex">
      <img
        src={media.Icons[0].file}
        className="opacity-70 h-6 p-1"
        alt="hello"
      />
      <p>{selects}</p>
      <p className="text-msm w-7/12 truncate mx-2">{name}</p>

      <button
        onClick={(e) => delFunc(index, e)}
        type="button"
        className="p-1 h-6 w-6 block"
      >
        <img
          src={media.Icons[0].delete}
          className="h-full w-full opacity-70"
          alt="deleteButton"
        />
      </button>
      {/* <p className="text-msm w-40 truncate text-qosorange gap-2">pending</p> */}
    </div>
  );
}

export function IfuFilesUploader({ text, className, Account }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);

  let selects = "IFU";

  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    e.preventDefault();
    let formData = new FormData();
    let nb = Files.list.files.length;
    formData.append("files", Files.list.files[nb - 1]);
    console.log("fiklkkk", Files.list.files[nb - 1]);

    PostData(
      Endpoints.Documents.uploadDocumentProof + "?document=" + selects,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data; boundary=2000",
          accept: "*/*",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE",
        },
      }
    ).then((res) => {
      // console.log("Lololozzzzzz : ", res);
      // console.log("status de lol: ", res.data);
      // console.log("status de lol23: ", res.data.responseStatus);
      // console.log("status de : ", res.data.datas);

      if (res.data.responseStatus === true) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (res.data.responseStatus === false) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      let valueselect = select;

      valueselect.push(selects);
      setSelect(valueselect);
    });
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  function handleFileList(e) {
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert("File size exceeds 2 MiB");
      // $(file).val(''); //for clearing with Jquery
    } else {
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e);
    }
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>
      <form onSubmit={UpdateFile}>
        <div
          onClick={() => addFileRef.current.click()}
          className="w-4/12 md:w-full cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
        >
          <img
            src={media.Icons[0].upload}
            className="opacity-70 md:h-11 mx-auto"
            alt="hello"
          />
          <input
            ref={addFileRef}
            onChange={handleFileList}
            className="hidden"
            type="file"
            accept=".jpg,.png,.pdf"
            multiple
          />
          <span className="text-small text-qosdark text-opacity-70">
            {text}
          </span>
        </div>

        <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
          {/* {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )} */}
          {Files.array.map((file, index) => (
            <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
              {/* <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div> */}
              {/* {console.log("interrieur selest: ", select)} */}

              <File
                key={file.index}
                selects={select[index] ? select[index] : selects}
                index={index}
                name={file.name}
                delFunc={delFile}
              />
            </div>
          ))}
        </div>

        {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}
      </form>
    </div>
  );
}

export function LogoFilesUploader({ text, className, Account }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);

  let selects = "LOGO";

  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    e.preventDefault();
    let formData = new FormData();
    let nb = Files.list.files.length;
    formData.append("files", Files.list.files[nb - 1]);
    console.log("fiklkkk", Files.list.files[nb - 1]);

    PostData(
      Endpoints.Documents.uploadDocumentProof + "?document=" + selects,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data; boundary=2000",
          accept: "*/*",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE",
        },
      }
    ).then((res) => {
      // console.log("Lololozzzzzz : ", res);
      // console.log("status de lol: ", res.data);
      // console.log("status de lol23: ", res.data.responseStatus);
      // console.log("status de : ", res.data.datas);

      if (res.data.responseStatus === true) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (res.data.responseStatus === false) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      let valueselect = select;

      valueselect.push(selects);
      setSelect(valueselect);
    });
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  function handleFileList(e) {
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert("File size exceeds 2 MiB");
      // $(file).val(''); //for clearing with Jquery
    } else {
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e);
    }
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>
      <form onSubmit={UpdateFile}>
        <div
          onClick={() => addFileRef.current.click()}
          className="w-4/12 md:w-3/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
        >
          <img
            src={media.Icons[0].upload}
            className="bg-transparent opacity-0 md:h-11 mx-auto"
            alt="hello"
          />
          <input
            ref={addFileRef}
            onChange={handleFileList}
            className="hidden"
            type="file"
            accept=".jpg,.png,.pdf"
            multiple
          />
          <span className="text-small text-qosdark text-opacity-70">
            {text}
          </span>
        </div>

        <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
          {/* {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )} */}
          {Files.array.map((file, index) => (
            <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
              {/* <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div> */}
              {/* {console.log("interrieur selest: ", select)} */}

              <File
                key={file.index}
                selects={select[index] ? select[index] : selects}
                index={index}
                name={file.name}
                delFunc={delFile}
              />
            </div>
          ))}
        </div>

        {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}
      </form>
    </div>
  );
}

export function FileLogo({ delFunc, name, index, selects }) {
  return (
    <div className="p-1 my-1 bg-qosgray bg-opacity-80 w-auto h-8 shadow-md items-center rounded-md justify-between flex">
      <img
        src={media.Icons[0].file}
        className="opacity-70 h-6 p-1"
        alt="hello"
      />
      <p>{selects}</p>
      <p className="text-msm w-7/12 truncate mx-2">{name}</p>

      <button
        onClick={(e) => delFunc(index, e)}
        type="button"
        className="p-1 h-6 w-6 block"
      >
        <img
          src={media.Icons[0].delete}
          className="h-full w-full opacity-70"
          alt="deleteButton"
        />
      </button>
      {/* <p className="text-msm w-40 truncate text-qosorange gap-2">pending</p> */}
    </div>
  );
}

// BankInfo: {
//   Getregistration: baseUrl.concat ("/api/v1/bankInfo/Get-registration"),
//   RegisterBankInformation: baseUrl.concat ("/api/v1/bankInfo/registration"),
// },

export function BankFilesUploader({ text, className, selects, Account }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);

  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    e.preventDefault();
    let formData = new FormData();
    let nb = Files.list.files.length;
    formData.append("files", Files.list.files[nb - 1]);
    // console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.BankInfo.RegisterBankInformation + "?document=" + selects,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data; boundary=2000",
          accept: "*/*",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE",
        },
      }
    ).then((res) => {
      // console.log("Lololozzzzzz : ", res);
      // console.log("status de lol: " , res.data)
      // console.log("status de lol23: " , res.data.responseStatus)
      // console.log("status de : " , res.data.datas)

      if (res.data.responseStatus === true) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 6000,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (res.data.responseStatus === false) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 6000,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      let valueselect = select;

      valueselect.push(selects);
      setSelect(valueselect);
    });
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }

  function handleFileList(e) {
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert("File size exceeds 2 MiB");
      // $(file).val(''); //for clearing with Jquery
    } else {
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e);
    }
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>
      <form onSubmit={UpdateFile}>
        <div
          onClick={() => addFileRef.current.click()}
          className="w-4/12 md:w-4/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
        >
          <img
            src={media.Icons[0].upload}
            className="opacity-70 md:h-11 mx-auto"
            alt="hello"
          />
          <input
            ref={addFileRef}
            onChange={handleFileList}
            className="hidden"
            type="file"
            accept=".jpg,.png,.pdf"
            multiple
          />
          <span className="text-small text-qosdark text-opacity-70">
            {text}
          </span>
        </div>

        {/* <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div>
                  {console.log("interrieur selest: ", select)}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div> */}

        {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}
      </form>
    </div>
  );
}

const styles = {
  left: {
    marginTop: 88,
  },
  input: {
    // width:680,
    height: 50,
    borderRadius: "4px",
  },
  button: {
    width: "200px",
    height: "45px",
    borderRadius: 4,
  },
  button1: {
    width: "200px",
    height: "45px",
    borderColor: "#F59E0B",
    borderWidth: 0.5,
    borderRadius: 4,
  },
};
