import { useMemo, useState } from "react";

import Viewer from "react-viewer/dist/index.js";
import { Document, Page ,
  // pdfjs 
} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { FaEye,
  // FaEyeDropper,
   FaEyeSlash } from 'react-icons/fa';

// import { Spinner } from "../spinner.component";

// import { makeCsv, getTableDataForExport } from "../../common/utils/table";
// import DatePicker from "react-flatpickr";
import {
  useTable,
  // useSortBy,
  // useFilters,
  // usePagination,
  // useGlobalFilter,
  // useAsyncDebounce,
} from "react-table";
// import format from "date-fns/format";
// import weeksToDays from "date-fns/weeksToDays";
// import axios from "axios";

// Define a default UI for filtering




export function TabledocumentAdmin({ Operator }) {
  // const [RequestType, setRequestType] = useState(0);
  // const [Data, setData] = useState([]);
  // const [DateRange, setDateRange] = useState(null);
  // const [Loading, setLoading] = useState(false);
  // const [visible, setVisible] = useState("false");

  const getData = () => [
    {
        id: 1,
        document_type: "CNI",
        upload_date: "14-10-2022",
        document_description: "National ID card",
      comment: "Good",
      document_status: "VERIFIED",
      visualize: "Admin",
      imgUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    {
      id: 2,
      document_type: "PASSPORT",
        upload_date: "14-10-2022",
        document_description: "PASSPORT",
      comment: "Not readable",
      document_status: "PENDING",
      visualize: "Owner",
      imgUrl:
        "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60",
    },
    
  ];

 



  const columns = useMemo(
    () => [
      {
        Header: "TYPE",
        accessor: "document_type",
      },
      {
        Header: "DESIGNATION",
        accessor: "document_description",
      },
      {
        Header: "UPLOADED",
        accessor: "upload_date",
      },
      {
        Header: "STATUS",
        accessor: "document_status",
      },
    //   {
    //     Header: "VISUALIZE",
    //     accessor: "visualize",
    //   },
      {
        Header: "COMMENT",
        accessor: "comment",
      },
      
      
      // {
      //   name: "PROCESSING NUMBER",
      //   accessor: "processingnumber",
      // },
    ],
    []
  );
  const data = useMemo(() => getData(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    
  } = useTable(
    {
      columns,
      data,
      
    },
  );

  const [showResults, setShowResults] = useState(false)
  const onClick = () => setShowResults(s => !s)
  
  // const Results = () => (
  //   <div id="results" className="search-results">
  //     Some Results
  //   </div>
  // )


  return (
    <div className="">
      
      <div className=" bg-white overflow-x-auto hideScroll sm:rounded-lg p-2">
       {/*  {Loading && (
          <div className="h-full w-full grid absolute bg-qosblue bg-opacity-10 items-center justify-center">
            <Spinner className="border-qosblue h-8 w-8 mx-1 -mt-20" />
          </div>
        )} */}
        <table
          className="min-w-full divide-y divide-qosorange"
          {...getTableProps()} border='1'
        >
          <thead className="font-extrabold">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left"
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="divide-y divide-gray-200">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                   
                    return (
                      <td
                        className="px-6 py-4 whitespace-nowrap"
                        {...cell.getCellProps()}
                      >
                        <div className="text-msm font-medium text-qosdark">
                          { cell.render("Cell")} 
                        </div>
                      </td>
                      
                    );
                  })}
                  <td key={row.id}>
                  <button
                   
                    icon={`fi-rr-user`}
                    className="px-2 text-center tracking-tighter text-msm border rounded-full bg-qosblue bg-opacity-70 text-qosgray font-bold p-1 mb-3" 
                    onClick={onClick}
                  >
                   { showResults? <FaEye style={{ fontSize: '25px'}}/> : <FaEyeSlash style={{ fontSize: '25px'}} />} 
                   
      
                  </button>
                </td>
                </tr>
                
              );
            })}
          </tbody>
        </table>
      </div>


      <div className="max-w-screen-xl relative overflow-hidden  mx-auto my-2">
   
      { showResults ? <DocumentLoad /> : null }
    
      </div>
     




    </div>
  );
}

// const styles= {
//   btn:{
//     width: 150,
//   },
// }



function DocumentLoad (){


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, 
    
    // setPageNumber
  ] = useState(1);
  
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState("false");
  let images = [
    {
      src: "https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg",
      title: "image title 1"
    },
    {
      src: "https://images.unsplash.com/photo-1534628526458-a8de087b1123",
      title: "image title 2"
    },
    
  ];


  return (
    <section>
    {/* <div className="mt-5 h-full  justify-center">
    <p>Mes documents</p>
    <button
      onClick={() => {
        setVisible(true);
      }}
    >
      show viewer
    </button>
   
    <div>
      {images.map((item, index) => {
        return (
          <div key={index.toString()} className="img-item">
            <img
              alt="aaaaa"
              src={item.src}
              width="200px"
              onClick={() => {
                setVisible("true");
                setActiveIndex(index);
              }}
            />
          </div>
        );
      })}
    </div>
    <Viewer
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      zoomSpeed={0.2}
      images={images}
      activeIndex={activeIndex}
      downloadable
    />


      </div> */}
     

      


      <div>
    <Document file="https://s29.q4cdn.com/816090369/files/doc_downloads/test.pdf" onLoadSuccess={onDocumentLoadSuccess}>
      <Page pageNumber={pageNumber} />
    </Document>
    <p>
      Page {pageNumber} of {numPages}
    </p>
  </div>


  <div className="mt-5 h-full  justify-center">
    <p>Mes documents</p>
    <button
      onClick={() => {
        setVisible(true);
      }}
    >
      show viewer
    </button>
   
    <div>
      {images.map((item, index) => {
        return (
          <div key={index.toString()} className="img-item">
            <img
              alt="aaaaa"
              src={item.src}
              width="200px"
              onClick={() => {
                setVisible("true");
                setActiveIndex(index);
              }}
            />
          </div>
        );
      })}
    </div>
    <Viewer
      visible={visible}
      onClose={() => {
        setVisible(false);
      }}
      zoomSpeed={0.2}
      images={images}
      activeIndex={activeIndex}
      downloadable
    />


      </div> 
      
  </section>

  );

}