import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
// import axios from "axios";
import 
// useIsMobile,
 { useFormData } from "../../../common/utils/hooks";
import PhoneInput from "react-phone-input-2";
import { media } from "../../../common/libs/media";
import Form from 'react-bootstrap/Form';
import {PostData } from "../../../common/services/httpServices";
import { Endpoints } from "../../../common/utils/endpoints";
import Wrapper from "../../../components/sectionWrapper.component";
import Messages from "./translations/index.json";
import InputsText from "../../../translations/input.json";
import buttonText from "../../../translations/button.json";
import { useLangContext } from "../../../components/translateContext";
import { encryptStorage } from "../../../common/services/encryptData";
import { Link } from "react-router-dom";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { Spinner } from "../../../components/spinner.component";
import { CreateUser } from "../../../common/auth";
import { CreateNew } from "../../../common/auth";
import { trackEvent } from "../../../common/utils/analytics";
import Swal from "sweetalert2";
import showPwdImg from './showPwdImg.svg';
import hidePwdImg from './hidePwdImg.svg';

export default function RegisterPage() {
  const [isLoading, setLoading] = useState(false);
  const { Lang } = useLangContext();
  const [data, handleInput] = useFormData();
  const [isCompanyNameValid, setIsCompanyNameValid] = useState(true);
  const [resize, setResize] = useState(false);
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const btnMsg = buttonText[Lang];
  const history = useHistory();
  const [countr, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  console.log("data", data.companyname);
  // console.log("country", countr);


  // const country = { country: "COTE D'IVOIRE"}


  // const  data = { ...country, data}


  useEffect(() => {
    if (encryptStorage.getItem("qosToken")) {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      let screenSize = window.innerWidth;
      setResize(screenSize);
    });
    return () => {
      window.removeEventListener("resize", () => {
        let screenSize = window.innerWidth;
      });
    };
  }, []);

  const validateCompanyName = (name) => {
    const regex = /^[a-zA-Z0-9 ]+$/;
    return regex.test(name);
  };

  const handleCompanyNameChange = (e) => {
    const { value } = e.target;
    handleInput(e);
    setIsCompanyNameValid(validateCompanyName(value));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    trackEvent('Signup', 'Signup dashboard', `Name: ${data.lastname}, LastName: ${data.firstname}, Email: ${data.email}`);
    try {
      // console.log("user Data : ", data)
      const { data: user, status } = await CreateNew(data);
      // console.log("un user status : ", status)
      // console.log("un user authData : ", user)
      // console.log("un user response : ", user.responseMessage)
      // console.log("un user authSatus : ", status)
      
      if (user.responseMessage === "A mail has been sent to your address. Please check" ) {
        // console.log("un usere authUser oks : ", user);
        // console.log("un users authStatus oks : ", status);
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'success',
          //   title: user.responseMessage,
          //   showConfirmButton: false,
          //   timer: 5500
          // });
          setLoading(false)
          Swal.fire({
              position: 'top-end',
              title: 'success!',
              text:  user.responseMessage,
              icon: 'success',
              showConfirmButton: false,
              timer: 9000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
              toast:true,
            })
        history.push("/AccountCreated", { detail: `${user.datas.email}` });
      }else if( user.responseMessage === "Mail already exist "){
       
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      } else if( user.responseMessage === "Company already exist "){
       
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/"); 
      } else if( user.responseMessage === "Country empty "){
     
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      }else if( user.responseMessage === "Invalid activity description "){
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: user.responseMessage,
        });
      // history.push("/");
      } 
    } catch (error) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        timer: 5500,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
     
      Toast.fire({
          icon: "error",
          title: "Une erreur s'est produite. Contactez support",
        });
    }
    setLoading(false);
  }



  // useEffect(() => {
  //   let stepForm = document.querySelector("#stepForm");
  //   let formWidth = stepForm.clientWidth;
  //   let CurrentScroll = stepForm.scrollLeft;
  //   let currentPos = 1;

  //   function ControlHandler() {
  //     currentPos = +(CurrentScroll / formWidth);

  //     if (CurrentScroll < formWidth * 2 && this.id === "next") {
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.toggle("active");
          
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos + 1].classList.add("active");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.add("passed");
  //       CurrentScroll += formWidth;
  //       stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
  //     }

  //     if (CurrentScroll > 0 && this.id === "prev") {
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos].classList.toggle("active");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos - 1].classList.toggle("passed");
  //       document
  //         .querySelectorAll(".stepStatus")
  //         [currentPos - 1].classList.add("active");

  //       CurrentScroll -= formWidth;
  //       stepForm.scrollTo({ left: CurrentScroll, behavior: "smooth" });
  //     }

  //     if (CurrentScroll === formWidth * 2) {
  //       setTimeout(() => {
  //         document.querySelector("#next").setAttribute("form", "stepForm");
  //         document
  //           .querySelector("#next")
  //           .firstChild.replaceWith(Infos.msg.registerButton);
  //       });
  //     } else {
  //       document.querySelector("#next").removeAttribute("form");
  //       document
  //         .querySelector("#next")
  //         .firstChild.replaceWith(Infos.msg.nextButton);
  //     }

  //     if (CurrentScroll > 0) {
  //       document.querySelector("#prev").hidden = false;
  //     } else {
  //       document.querySelector("#prev").hidden = true;
  //     }
  //   }
  //   stepForm.querySelectorAll("input").forEach((input) => {
  //     input.readOnly = true;
  //     input.addEventListener("click", function (e) {
  //       this.readOnly = false;
  //     });
  //     input.addEventListener("focus", function (e) {
  //       this.readOnly = false;
  //       stepForm.scrollTo({
  //         left: formWidth * (this.parentElement.parentElement.id - 1),
  //         behavior: "smooth",
  //       });
  //     });
  //   });

  //   document.querySelectorAll(".controls").forEach((buttonControl) => {
  //     buttonControl.addEventListener("click", ControlHandler);
  //   });
  //   return () => {
  //     document.querySelectorAll(".controls").forEach((buttonControl) => {
  //       buttonControl.removeEventListener("click", ControlHandler);
  //     });
  //   };
  // }, [Infos.msg.registerButton, Infos.msg.nextButton]);

// console("stepStatus", Infos.msg.registerButton)
  return (


    <Wrapper className="">
      <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2 ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          {Lang === "en" ? (
            <h5 className="text-center md:text-mH1">
              {" "}
              {Infos.msg.Title0}{" "}
              <span className="text-qosorange text-opacity-95">
                {" "}
                {Infos.msg.Title1}{" "}
              </span>{" "}
              {Infos.msg.Title2}
            </h5>
          ) : (
            <h5 className="text-center md:text-mH1">
              {" "}
              {Infos.msg.Title0} {Infos.msg.Title1}
              <span className="text-qosorange text-opacity-95">
                {" "}
                {Infos.msg.Title2}{" "}
              </span>{" "}
            </h5>
          )}
        </div>
        <form onSubmit={handleSubmit} className="  w-full ">
          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.contryy.label}</span>

            <select style={styles.input} name="country" onChange={handleInput}>
              <option disabled selected hidden>
                Select country{" "}
              </option>
              <option value="BENIN">BENIN</option>
              <option value="TOGO">TOGO</option>
              <option value="IVORY_COST">COTE D'IVOIRE</option>
            </select>
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.firstname.label} </span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="firstname"
              value={data.firstname}
              onChange={handleInput}
              required
              placeholder={Infos.input.firstname.placeholder}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.lastname.label}</span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="lastname"
              value={data.lastname}
              onChange={handleInput}
              required
              placeholder={Infos.input.lastname.placeholder}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.business.label}</span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="companyname"
              value={data.companyname}
              // onChange={handleInput}
              onChange={handleCompanyNameChange}
              type="text"
              // pattern="[^()/><\][\\\x22,;|]+"
              required
              placeholder={Infos.input.business.placeholder}
            />
          </div>
          {!isCompanyNameValid && (
          <p className="text-red mx-auto  lg:w-8/12 mt-1">{Infos.input.Control}</p>
        )}

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.descriptionB.label}</span>
            <label className="block"></label>
            <textarea
              // className="p-3"
              className="p-3 py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              style={{ borderRadius: 4 }}
              name="activityDescription"
              value={data.activityDescription}
              onChange={handleInput}
              required
              placeholder={Infos.input.descriptionB.placeholder}
            ></textarea>
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block"> {Infos.input.tel.label}</span>
            <PhoneInput
              // style={styles.input}
              inputStyle={{
                color: "black",
                height: 50,
                width: "100px",
                paddingLeft: 70,
              }}
              buttonStyle={{ padding: 8 }}
              country={"bj"}
              placeholder={"229"}
              required
              // onlyCountries={["bj", "tg", "ng", "bf", "bf", "ci", "cm", "ml", "ne", "sn"]}
              // localization={{bj:"Benin", tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"   }}
              autoFormat={true}
              // containerClass="mt-2"\
              // containerStyle={{margin:'20px', height:50}}
              inputClass="w-full min-w-full h-10"
              inputProps={{ required: true, name: "phone" }}
              disableCountryCode={false}
              countryCodeEditable={false}
              onChange={(phone, country, value) => {
                handleInput({
                  phone: phone,
                  // country: `${country.name}`.toUpperCase(),
                  // value: value,
                  // countr:countr,
                });
              }}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            {/* <span className="block">{Infos.input.business.label}</span> */}
            <span className="block">{Infos.input.email.label}</span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="email"
              value={data.email}
              onChange={handleInput}
              type="email"
              required
              placeholder={Infos.input.email.placeholder}
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.password.label}</span>
      
            <div
              style={styles.input}
              className="flex bg-white border my-1 border-qosgraye gap-1 rounded-lg justify-around "
            >
              <input
                style={styles.input}
                onChange={handleInput}
                className="bg-transparent border-none rounded"
                value={data.password}
                name="password"
                type={isRevealPwd ? "text" : "password"}
                required
                placeholder={Infos.input.password.placehol}
              />

              <img
                height={20}
                width={20}
                className="mx-2 bg-white"
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
          </div>

          <div className="mx-auto  lg:w-8/12 mt-4">
            <span className="block">{Infos.input.dev.title}</span>
            <select
              style={styles.input}
              name="developperKnows"
              onChange={handleInput}
            >
              <option disabled selected hidden>
                {Infos.input.dev.placeholder}{" "}
              </option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </select>
          </div>

          <div className="mx-auto flex lg:w-8/12 mt-4">
         
            <Form.Check
              inline
              // label="1"
              // name="group1"
              type="checkbox"
              name="termsandcondition"
              value="true"
              onChange={handleInput}
              required
            />
            <label
              htmlFor="privacy"
              className="text-msm leading-4 font-normal ml-4"
              dangerouslySetInnerHTML={{
                __html: Infos.input.checkTerms.label,
              }}
            ></label>
          </div>

          {!isCompanyNameValid ? ( <>
            <p className="text-red mx-auto  lg:w-8/12 mt-3 mb-3">{Infos.input.Control}</p>
          </>) :( <button
            disabled={isLoading}
            style={styles.button}
            className={`w-full xl:w-8/12 lg:w-8/12  flex justify-center items-center  text-white text-opacity-80  ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            } p-3 btn-sm  mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>{btnMsg.createAccount}</span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>
          )}

          <div className="inline-block mb-10  mt-4 text-center w-full justify-between  align-center lg:mx-auto lg:w-8/12  lg:flex lg:text-center ">
            <p className="text-center flex  pr-2">
              {btnMsg.already}
              <Link
                // className="text-qosorange underline "
                to="/"
              >
                <p className="text-qosorange underline ml-1">
                  {btnMsg.logInButton}
                </p>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const styles = {
  input:{
    // width:680,
    // height:"42px",
    height:50,
    borderRadius:4,
  },
  button:{
    // width:680,
    height:"28",
    borderRadius:8,
  }
}

export function FilesUploader({ text, className, selects, Account, }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);
 


  
  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    
    e.preventDefault()
    let formData = new FormData();
    let nb = Files.list.files.length
    formData.append("files", Files.list.files[nb-1]);
    // console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.Documents.uploadDocumentProof+"?document="+selects ,formData,
      { headers: {
        "Content-Type": "multipart/form-data; boundary=2000","accept": "*/*",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE"
      }},
    ).then( (res) => {console.log("Lololozzzzzz : ",res);
    // console.log("status de lol: " , res.data)
    // console.log("status de lol23: " , res.data.responseStatus)
    // console.log("status de : " , res.data.datas)

    if (res.data.responseStatus === true){
       let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
      
      
  
    } else if(res.data.responseStatus === false){
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: message
      })


    };

   
      let valueselect = select
    
      valueselect.push(selects)
      setSelect(valueselect)

      });
     
      
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }



  function handleFileList(e) {
    
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert('File size exceeds 2 MiB');
      // $(file).val(''); //for clearing with Jquery
    } else {
 
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e)
      
      
    }
    
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>

      <form onSubmit={UpdateFile} >
            <div
              onClick={() => addFileRef.current.click()}
              className="w-4/12 md:w-4/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
            >
              <img
                src={media.Icons[0].upload}
                className="opacity-70 md:h-11 mx-auto"
                alt="hello"
              />
              <input
                ref={addFileRef}
                onChange={handleFileList}
                className="hidden"
                type="file"
                accept=".jpg,.png,.pdf" 
                multiple
              />
              <span className="text-small text-qosdark text-opacity-70">{text}</span>
            </div>

            {/* <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div>
                  {console.log("interrieur selest: ", select)}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div> */}
   
            {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}

        
       

      </form>
      
      
    </div>
  );
}


export function FilesUploaders({ text, className, selects, Account, }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);
 


  
  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    
    e.preventDefault()
    let formData = new FormData();
    let nb = Files.list.files.length
    formData.append("files", Files.list.files[nb-1]);
    // console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.Documents.uploadDocumentProof+"?document="+selects ,formData,
      { headers: {
        "Content-Type": "multipart/form-data; boundary=2000","accept": "*/*",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE"
      }},
    ).then( (res) => {console.log("Lololozzzzzz : ",res);
    // console.log("status de lol: " , res.data)
    // console.log("status de lol23: " , res.data.responseStatus)
    // console.log("status de : " , res.data.datas)

    if (res.data.responseStatus === true){
       let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
      
      
  
    } else if(res.data.responseStatus === false){
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: message
      })


    };

   
      let valueselect = select
    
      valueselect.push(selects)
      setSelect(valueselect)

      });
     
      
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }



  function handleFileList(e) {
    
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert('File size exceeds 2 MiB');
      // $(file).val(''); //for clearing with Jquery
    } else {
 
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e)
      
      
    }
    
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>

      <form onSubmit={UpdateFile} >
            <div
              onClick={() => addFileRef.current.click()}
              className="w-full gap-3 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 py-12 text-center"
            >
              <img
                src={media.Icons[0].upload}
                className="opacity-70 md:h-11 mx-auto mb-4"
                alt="hello"
              />
              <input
                ref={addFileRef}
                onChange={handleFileList}
                className="hidden "
                type="file"
                accept=".jpg,.png,.pdf" 
                multiple
              />
              <span className=" text-qosdark text-opacity-70 ">{text}</span>
            </div>

            {/* <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div>
                  {console.log("interrieur selest: ", select)}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div> */}
   
            {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}

        
       

      </form>
      
      
    </div>
  );
}

export function File({ delFunc, name, index, selects }) {
  return (
    <div className="p-1 my-1 bg-qosgray bg-opacity-80 w-auto h-8 shadow-md items-center rounded-md justify-between flex">
      <img
        src={media.Icons[0].file}
        className="opacity-70 h-6 p-1"
        alt="hello"
      />
      <p >{selects}</p>
      <p className="text-msm w-7/12 truncate mx-2">{name}</p>
      
      <button
        onClick={(e) => delFunc(index, e)}
        type="button"
        className="p-1 h-6 w-6 block"
      >
        <img
          src={media.Icons[0].delete}
          className="h-full w-full opacity-70"
          alt="deleteButton"
        />
      </button>
      {/* <p className="text-msm w-40 truncate text-qosorange gap-2">pending</p> */}
    </div>
  );
}















export function LogoFilesUploader({ text, className, Account, }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);
 

  let selects = "LOGO";


  
  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    
    e.preventDefault()
    let formData = new FormData();
    let nb = Files.list.files.length
    formData.append("files", Files.list.files[nb-1]);
    console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.Documents.uploadDocumentProof+"?document="+selects ,formData,
      { headers: {
        "Content-Type": "multipart/form-data; boundary=2000","accept": "*/*",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE"
      }},
    ).then( (res) => {console.log("Lololozzzzzz : ",res);
    // console.log("status de lol: " , res.data)
    // console.log("status de lol23: " , res.data.responseStatus)
    // console.log("status de : " , res.data.datas)

    if (res.data.responseStatus === true){
       let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
      
      
  
    } else if(res.data.responseStatus === false){
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: message
      })


    };

   
      let valueselect = select
    
      valueselect.push(selects)
      setSelect(valueselect)

      });
     
      
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }



  function handleFileList(e) {
    
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert('File size exceeds 2 MiB');
      // $(file).val(''); //for clearing with Jquery
    } else {
 
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e)
      
      
    }
    
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>

      <form onSubmit={UpdateFile} >
            <div
              onClick={() => addFileRef.current.click()}
              className="w-4/12 md:w-3/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
            >
              <img
                src={media.Icons[0].upload}
                className="opacity-70 md:h-11 mx-auto"
                alt="hello"
              />
              <input
                ref={addFileRef}
                onChange={handleFileList}
                className="hidden"
                type="file"
                accept=".jpg,.png,.pdf" 
                multiple
              />
              <span className="text-small text-qosdark text-opacity-70">{text}</span>
            </div>

            <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {/* {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )} */}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    {/* <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div> */}
                  {/* {console.log("interrieur selest: ", select)} */}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div>
   
            {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}

        
       

      </form>
      
      
    </div>
  );
}

export function FileLogo({ delFunc, name, index, selects }) {
  return (
    <div className="p-1 my-1 bg-qosgray bg-opacity-80 w-auto h-8 shadow-md items-center rounded-md justify-between flex">
    <img
      src={media.Icons[0].file}
      className="opacity-70 h-6 p-1"
      alt="hello"
    />
    <p >{selects}</p>
    <p className="text-msm w-7/12 truncate mx-2">{name}</p>
    
    <button
      onClick={(e) => delFunc(index, e)}
      type="button"
      className="p-1 h-6 w-6 block"
    >
      <img
        src={media.Icons[0].delete}
        className="h-full w-full opacity-70"
        alt="deleteButton"
      />
    </button>
    {/* <p className="text-msm w-40 truncate text-qosorange gap-2">pending</p> */}
  </div>
  );
}





export function IdFilesUploader({ text, className, selec, Account, }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);
 

  let selects = "CNI";


  
  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    
    e.preventDefault()
    let formData = new FormData();
    let nb = Files.list.files.length
    formData.append("files", Files.list.files[nb-1]);
    console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.Documents.uploadDocumentProof+"?document="+selects ,formData,
      { headers: {
        "Content-Type": "multipart/form-data; boundary=2000","accept": "*/*",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE"
      }},
    ).then( (res) => {console.log("Lololozzzzzz : ",res);
    // console.log("status de lol: " , res.data)
    // console.log("status de lol23: " , res.data.responseStatus)
    // console.log("status de : " , res.data.datas)

    if (res.data.responseStatus === true){
       let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
      
      
  
    } else if(res.data.responseStatus === false){
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        // }
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: message
      })


    };

   
      let valueselect = select
    
      valueselect.push(selects)
      setSelect(valueselect)

      });
     
      
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }



  function handleFileList(e) {
    
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert('File size exceeds 2 MiB');
      // $(file).val(''); //for clearing with Jquery
    } else {
 
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e)
      
      
    }
    
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>

      <form onSubmit={UpdateFile} >
            <div
              onClick={() => addFileRef.current.click()}
              className="w-full md:w-full flex justify-center items-center content-center p-3 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 text-center"
            >
                 <span className="text-normal  pr-3 text-qosText text-opacity-70">{text}</span>
              <img
                src={media.Icons[0].upload}
                className="opacity-70  h-5 md:h-5 "
                alt="hello"
              />
              <input
                ref={addFileRef}
                onChange={handleFileList}
                className="hidden"
                type="file"
                accept=".jpg,.png,.pdf" 
                multiple
              />
           
            </div>

            <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {/* {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )} */}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    {/* <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div> */}
                  {/* {console.log("interrieur selest: ", select)} */}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div>
   
            {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}

        
       

      </form>
      
      
    </div>
  );
}











// BankInfo: {
  //   Getregistration: baseUrl.concat ("/api/v1/bankInfo/Get-registration"),
  //   RegisterBankInformation: baseUrl.concat ("/api/v1/bankInfo/registration"),
  // },




export function BankFilesUploader({ text, className, selects, Account, }) {
  const addFileRef = useRef();

  const [Files, setFiles] = useState({
    array: [],
    list: new DataTransfer(),
  });

  const [select, setSelect] = useState([]);
  const [Loading, setLoading] = useState(false);
 


  
  function filesToArray(files) {
    let filesArray = [];
    for (let i = 0; i < files.length; i++) {
      filesArray.push(files.item(i));
    }
    return filesArray;
  }

  function UpdateFile(e) {
    
    e.preventDefault()
    let formData = new FormData();
    let nb = Files.list.files.length
    formData.append("files", Files.list.files[nb-1]);
    // console.log('fiklkkk',Files.list.files[nb-1])

    PostData(
      Endpoints.BankInfo.RegisterBankInformation+"?document="+selects ,formData,
      { headers: {
        "Content-Type": "multipart/form-data; boundary=2000","accept": "*/*",
        "Authorization": "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJmYWJyb3dudGVzdEBnbWFpbC5jb20iLCJpYXQiOjE2NTU4MzAzNDQsImV4cCI6MTY1NTgzMjE0NH0.-pqYsbPbW6erwcjERdVyUO1hdedFl49JL5kZt8uostE"
      }},
    ).then( (res) => {console.log("Lololozzzzzz : ",res);
    // console.log("status de lol: " , res.data)
    // console.log("status de lol23: " , res.data.responseStatus)
    // console.log("status de : " , res.data.datas)

    if (res.data.responseStatus === true){
       let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
      
      
  
    } else if(res.data.responseStatus === false){
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: message
      })


    };

   
      let valueselect = select
    
      valueselect.push(selects)
      setSelect(valueselect)

      });
     
      
  }

  // const UpdateFile = (event) => {
  //   event.preventDefault()
  //   const formData = new FormData();
  //   formData.append("File", File);
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: "/api/upload/file",
  //       data: formData,
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //   } catch(error) {
  //     console.log(error)
  //   }
  // }



  function handleFileList(e) {
    
    let files = e.target.files;
    let array = [];
    let previousFiles = Files.list;
    let fileSize = files[0].size / 1024 / 1024; // in MiB
    if (fileSize > 2) {
      alert('File size exceeds 2 MiB');
      // $(file).val(''); //for clearing with Jquery
    } else {
 
      // Proceed further
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
      UpdateFile(e)
      
      
    }
    
  }

  function delFile(index, e) {
    let dt = new DataTransfer();
    let files = addFileRef.current.files;
    for (let i = 0; i < files.length; i++) {
      if (index !== i) {
        dt.items.add(files.item(i));
      }
    }
    addFileRef.current.files = dt.files;
    setFiles({ array: filesToArray(dt.files), list: dt });
  }

  return (
    <div className={className}>

      <form onSubmit={UpdateFile} >
            <div
              onClick={() => addFileRef.current.click()}
              className="w-4/12 md:w-4/12 cursor-pointer rounded-sm my-2 border-1 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
            >
              <img
                src={media.Icons[0].upload}
                className="opacity-70 md:h-11 mx-auto"
                alt="hello"
              />
              <input
                ref={addFileRef}
                onChange={handleFileList}
                className="hidden"
                type="file"
                accept=".jpg,.png,.pdf" 
                multiple
              />
              <span className="text-small text-qosdark text-opacity-70">{text}</span>
            </div>

            {/* <div className="py-2 w-7/12 md:w-8/12  md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
            
            {console.log("interrieur : ", Files)}
            {console.log("interrieur tableau : ", Files.array)}
            {console.log("interrieur nom : ", )}
              {Files.array.map((file, index) => (
                <div className="flex flex-row  w-full gap-2 justify-items-center justify-start content-center">
                    <div className="flex p-2 top-20 h-8 justify-center items-center content-center" >
                      <span style={{fontSize: "9px", color: "#133339", background:'#e6e6e6', marginTop: 10, }} >{selects} </span>
                    </div>
                  {console.log("interrieur selest: ", select)}
                   
                    
                    <File key={file.index} selects={select[index] ? select[index] : selects } index={index} name={file.name} delFunc={delFile} />
                </div>
                
              ))}
            </div> */}
   
            {/* <div className="flex relative justify-start mb-8 self-start content-start justify-items-start bg-qosorange ">
                  <button  type="submit" className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto  block p-2 text-qosgray rounded-lg font-medium">
                        Upload file
                  </button>

            </div>  */}

        
       

      </form>
      
      
    </div>
  );
}


