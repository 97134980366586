export const isNullOrUndefined = (val) => {
  return val === null || val === undefined;
};
export const isEmptyString = (val) => {
  return val === null || val === undefined || val.length === 0;
};

export const isANumber = (val) => {
  return val !== null && val !== undefined && !Number.isNaN(val);
};

export const valueIsLessThan = (val, operand) => {
  return isANumber(val) && val < +operand;
};

export const valueIsGreaterThan = (val, operand) => {
  return isANumber(val) && val > operand;
};

export const isEmptyArray = (val) => {
  return val === null || val === undefined || val.length === 0;
};

export const toNumber = (val) => {
  return isANumber(+val) ? +val : false;
};

export const isObject = (item) => {
  return typeof item === "object" && !Array.isArray(item) && item !== null;
};

export const newUserFormat = {
  activityDescription: "",
  clientId: "",
  clientIdMoov: "",
  clientfamily: "",
  companyname: "",
  confirmPassword: "",
  country: "",
  documentUrl: "",
  email: "",
  firstname: "",
  lastname: "",
  merchantnumber: "",
  merchantnumbermoov: "",
  mtnphonenumber: "",
  password: "",
  paymentMode: {
    paymentModeId: 0,
    paymentModeName: "",
  },
  roles: [
    {
      roleId: 0,
      type: "",
    },
  ],
  state: "",
  termsandcondition: true,
  userId: 0,
};
