import React from "react";
import Flatpickr from "react-flatpickr";

function Datepicker({ option, placeholder }) {
  const options = {
    mode: "range",
    static: true,
    monthSelectorType: "static",
    dateFormat: "M j, Y",
    prevArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow:
      '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace("to", "-");
    },
    onChange: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace("to", "-");
    },
    ...option,
  };

  return (
    <div className="relative">
      <Flatpickr
        style={{ paddingLeft: "32px" }}
        className="bg-qosgray bg-opacity-50 border border-qosdark border-opacity-20"
        options={options}
        placeholder={placeholder}
      />
      <span className="absolute top-4 left-px fi fi-rr-box-alt h-4 w-4 inline-block mx-2"></span>
    </div>
  );
}

export default Datepicker;
