import React, { useEffect, useMemo, useState } from "react";
import { isNull, isUndefined } from "lodash";
import { Endpoints } from "../../common/utils/endpoints";
import { useLangContext } from "../../components/translateContext";
import overview from "../../components/translation/overview.json"
// import { getUser } from "../../common/utils/tools";
// import { PostData } from "../../common/services/httpServices";
// import parseISO from "date-fns/parseISO";
// import numeral from "numeral";
// import { AleatoireNumber } from "../../common/utils/tools";
// import { percent } from "../../common/utils/tools";
import { FetchData } from "../../common/services/httpServices";
import BarChart from "../../charts/BarChart02";
import BarChart1 from "../../charts/BarChart021";

import eachDayOfInterval from "date-fns/eachDayOfInterval";
import { tailwindConfig } from "../../utils/Utils";
import { 
  // isSameDay, 
  toDate } from "date-fns";
import format from "date-fns/format";

import axios from "axios";

const DaysMilliseconds = 86400 * 1000;
const Past7Days = toDate(new Date().getTime() - 6 * DaysMilliseconds);
const toDay = new Date();
const chartWeek = eachDayOfInterval({ start: Past7Days, end: toDay }).map(
  (date) => format(date, "M-dd-Y")
);


// let j1 = '400.000';
// let j2 = '100.000';
// let j3 = '800.00';
// let j4 = '400.81';
// let j5 = '590.36';
// let j6 = '890.35';
// let j7 = '0';

// let Inflow = [100000, 50009, 8000, 100000, 59036, 89035, 93300],
let Inflow = [],
Outflow = [],
//  Outflow = [j1, j2,j3 , j4,j5 , j6, j7],
 TinFlow = 20000,
 ToutFlow = 0;

 

 const mtndata = {};
 const moovdata = {};
 const carddata = {};
 const typedata = {};
 
 mtndata['inflow']= 'REQUESTPAYMENT';
 mtndata['outflow']= 'MAKEDEPOSIT';
 moovdata['inflow'] ='REQUESTPAYMENTMV';
 moovdata['outflow'] ='MAKEDEPOSITMV';
 carddata['inflow']= 'REQUESTPAYMENT';
 carddata['outflow']= 'MAKEDEPOSIT';
 
 typedata['mtn'] = mtndata ;
 typedata['moov'] = moovdata; 
 typedata['card'] = carddata; 

let innflow = {'mtn':[], 'moov':[], 'card':[]}
let ouutflow = {'mtn':[], 'moov':[], 'card':[]}




function DashboardCard09({ Operators, network, country }) {

  // const [innflow, setInflow] = useState([]);
  // const [ouutflow, setOutflow] = useState([]);
 

  // const Loperator = Operators[0];
  // const Loperator1 = Operators[1];

  // console.log("mon operateur :::", Loperator)
  // console.log("mon payss ", country)

  const [Transaction, setTransaction] = useState(null);
  const [seven, setSeven] = useState();
  const [sevenLoading, setSevenLoading] = useState(true);



  let MTN = "MTN";

  const last7 = () => {
    FetchData(
      Endpoints.Transactions.getTodayAmount+'?country='+country+'&operator='+MTN+'&type='+"REQUESTPAYMENT"
       
      ).then((res) =>
         { setSeven(res); setSevenLoading(false)}
         
      );
  }
  
  useEffect(() => {
    last7()
  }, [] )







  // console.log("valeur de transactio :" , Transaction)

  useEffect(() => {
    axios.get(
      // Endpoints.Transactions.getUserSuccessfullDeposit,
      // Endpoints.Transactions.getUserSuccessfullDeposit+'?operator='+Loperator,
      // {},
      // {
      //   params: {
      //     operator: operator,
      //   },
      // }
    ).then((res1) => {
      axios.get(
        // Endpoints.Transactions.getUserSuccessfullRefund,
        // {},
        // {
        //   params: {
        //     clientId: getUser(`ID${operator}`),
        //   },
        // }
      ).then((res2) => {
        axios.get(
          // Endpoints.Transactions.getLastTransaction,
          // {},
          // {
          //   params: {
          //     operator: operator,
          //   },
          // }
        ).then((res3) => {
          setTransaction((prev) => ({
            ...prev,
            // setData(res?.data?.datas?.items?.filter((trx) => !isNull(trx)));
            deposit: res1?.data?.datas?.items?.filter((x) => !isNull(x)),
            refund: res2?.data?.datas?.items?.filter((x) => !isNull(x)),
            payment: res3?.data?.datas?.items?.filter((x) => !isNull(x)),
          }));
        });
      });
    });
  }, [Operators]);

  // useEffect(() => {
  //   if (Transaction) {
  //     const { payment, deposit, refund } = Transaction;
  //     Inflow = [];
  //     Outflow = [];
  //     chartWeek.forEach((day) => {
  //       let dayInflow = payment.reduce((value, current, index) => {
  //         if (
  //           isSameDay(toDate(new Date(day)), toDate(parseISO(current.trxndate)))
  //         ) {
  //           return value + +current.amount;
  //         }
  //         return value;
  //       }, 0);
  //       Inflow.push(dayInflow);
  //     });

  //     chartWeek.forEach((day) => {
  //       let dayOutflow =
  //         refund.reduce((value, current, index) => {
  //           if (
  //             isSameDay(
  //               toDate(new Date(day)),
  //               toDate(parseISO(current.trxndate))
  //             )
  //           ) {
  //             return value + +current.amount;
  //           }
  //           return value;
  //         }, 0) +
  //         deposit.reduce((value, current, index) => {
  //           if (
  //             isSameDay(
  //               toDate(new Date(day)),
  //               toDate(parseISO(current.trxndate))
  //             )
  //           ) {
  //             return value + +current.amount;
  //           }
  //           return value;
  //         }, 0);
  //       Outflow.push(dayOutflow);
  //     });
  //     TinFlow = Inflow.reduce((t, c) => t + c, 0);
  //     ToutFlow = Outflow.reduce((t, c) => t + c, 0);
  //   }
  // }, [Transaction, chartWeek]);

  const chartData = useMemo(
    () => (
      {
      labels: chartWeek,
      datasets: [
        // Light blue bars
        {
          label: "Inflow",
          data: Inflow.reverse(),
          backgroundColor: tailwindConfig().theme.colors.blue[400],
          hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
          barPercentage: 0.4,
          categoryPercentage: 0.4,
        },
        // Blue bars
        // {
        //   label: "Outflow",
        //   data: Outflow.map((amount) => -1 * amount),
        //   backgroundColor: tailwindConfig().theme.colors.red[400],
        //   hoverBackgroundColor: tailwindConfig().theme.colors.red[800],
        //   barPercentage: 0.4,
        //   categoryPercentage: 0.4,
        // },
      ],
    }),
    [Inflow, Outflow, Transaction, Operators, chartWeek]
  );

  const chartData1 = useMemo(
    () => ({
      labels: chartWeek,
      datasets: [
        // Light blue bars
        // {
        //   label: "Inflow",
        //   data: Inflow,
        //   backgroundColor: tailwindConfig().theme.colors.blue[400],
        //   hoverBackgroundColor: tailwindConfig().theme.colors.blue[500],
        //   barPercentage: 0.4,
        //   categoryPercentage: 0.4,
        // },
        // Blue bars
        {
          label: "Outflow",
          data: Outflow.reverse(),
          backgroundColor: tailwindConfig().theme.colors.red[400],
          hoverBackgroundColor: tailwindConfig().theme.colors.red[800],
          barPercentage: 0.4,
          categoryPercentage: 0.4,
        },
      ],
    }),
    [Inflow, Outflow, Transaction, Operators, chartWeek]
  );

  function Togglee() {
    const [state, setstate] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const { Lang } = useLangContext();
    const Infos = { msg: overview[Lang] };
    // const btnMsg = Button;
  
  return (
    <section>
    <header className="flex justify-between">
      <nav className="bg-white  overflow-hidden  flex justify-between max-w-max relative">
        <div
          className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
            state === 0
              ? "translate-x-0"
              : state === 1
              ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
              : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
          } w-24 sm:w-32 md:w-24 text-black h-full   p-2 md:px-4`}
        ></div>
        <button
          onClick={() => setstate(0)}
          className={`transition-all duration-500 ${
            state === 0 && "text-qosorange"
          } z-10 w-24 sm:w-32 md:w-24 font-semibold text-msm md:text-mlg p-2 md:px-4`}
        >
          {Infos.msg.Inflow}
        </button>
        <button
          onClick={() => setstate(1)}
          className={`transition-all duration-500 ${
            state === 1 && "text-qosorange"
          } z-10 w-24 sm:w-32 md:w-24 font-semibold text-msm md:text-mlg p-2 md:px-4`}
        >
           {Infos.msg.Outflow}
        </button>
        
        
       
      </nav>
      <div className="flex" >
        <span className="text-mmd text-opacity-70 p-4 text-qosdark">
            last 7 days
          </span>
       
        </div>
    </header>
    <div>
      <section>
        {state === 0 && <Infloww  />}
        {state === 1 && <Outfloww />}
      </section>
    </div>
  </section>
    
  );
  }
  






  function LoadData (typeRequest) {
    

    
  }
  
  function Infloww (){
    // console.log('mon reseau inf',typedata[network.toLowerCase()]['inflow'])
    let type_of_req = typedata[network.toLowerCase()]['inflow']

    FetchData(
      Endpoints.Transactions.getTodayAmount+'?country='+country+'&operator='+network+'&type='+type_of_req
      
      ).then((res) =>
        { 
        let temp = res.data.datas.inflow;
        // console.log('test',temp)
        if (innflow[network.toLowerCase()].length === 0){ 
        for (let v in temp){
          innflow[network.toLowerCase()].push(temp[v]['amount'])
        }
        Inflow = innflow[network.toLowerCase()]
      }
      
      }
        
      );
    return charter
  }
  
  
  
  function Outfloww (){
    let type_of_req = typedata[network.toLowerCase()]['outflow']

    FetchData(
      Endpoints.Transactions.getTodayAmount+'?country='+country+'&operator='+network+'&type='+type_of_req
      
      ).then((res) =>
        { 
        let temp = res.data.datas.outflow;
        // console.log('aaa',temp)
        if (ouutflow[network.toLowerCase()].length === 0){ 
        for (let v in temp){
          ouutflow[network.toLowerCase()].push(temp[v]['amount'])
        }
        Outflow = ouutflow[network.toLowerCase()]
      }
      
      }
        
      );    return charter1
  }


  const charter = useMemo(
    () => (
      <div className="bg-white rounded w-full p-4">
        



        <div className="font-bold flex items-center justify-between border-b border-qosdark border-opacity-20  tracking-tighter text-mH3 text-qosdark text-opacity-90">
          {/* <span></span>
          <span className="text-mmd text-opacity-70 text-qosdark">
            last 7 days
          </span> */}
        </div>
        <div className="px-5 py-3">
          <div className="flex items-start">
            <div className="text-3xl font-bold text-qosdark mr-2">
              {/* {numeral(TinFlow).format()}{" "}
              <span className="uppercase font-bold text-small">XOF</span> */}
            </div>
            {/* <div className="text-sm font-semibold text-white px-1.5 bg-qosblue opacity-60 rounded-full">
              - {percent(ToutFlow, TinFlow)}%
            </div> */}
          </div>
        </div>
        {/* Chart built with Chart.js 3 */}
        <div className="flex-grow">
          {/* Change the height attribute to adjust the chart height */}
          <BarChart data={chartData} width={100} height={150} />
        </div>
      </div>
    ),
    [Inflow, Outflow, Transaction, chartData, TinFlow, ToutFlow, chartWeek]
  );

  const charter1 = useMemo(
    () => (
      <div className="bg-white rounded w-full p-4">
        



        <div className="font-bold flex items-center justify-between border-b border-qosdark border-opacity-20  tracking-tighter text-mH3 text-qosdark text-opacity-90">
          {/* <span></span>
          <span className="text-mmd text-opacity-70 text-qosdark">
            last 7 days
          </span> */}
        </div>
        <div className="px-5 ">
          <div className="flex items-start">
            <div className="text-3xl font-bold text-qosdark mr-2">
              {/* {numeral(TinFlow).format()}{" "}
              <span className="uppercase font-bold text-small">XOF</span> */}
            </div>
            {/* <div className="text-sm font-semibold text-white px-1.5 bg-qosblue opacity-60 rounded-full">
              - {percent(ToutFlow, TinFlow)}%
            </div> */}
          </div>
        </div>
        {/* Chart built with Chart.js 3 */}
        <div className="flex-grow">
          {/* Change the height attribute to adjust the chart height */}
          <BarChart1 data={chartData1} width={100} height={150} />
        </div>
      </div>
    ),
    [Inflow, Outflow, Transaction, chartData1, TinFlow, ToutFlow, chartWeek]
  );
  return( 
  <div className="bg-white rounded w-full p-4">
    <Togglee/>
    </div>
  
  );
}

export default DashboardCard09;
