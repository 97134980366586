import { GiConsoleController } from "react-icons/gi";
import { IdFilesUploader } from "../pages/public/register/index";
import { IdBFilesUploader, IdFilesUploaderB } from "../pages/registerBusness";

function IdDocumentB() {
  return (
    <div className="mt-5">
      <div className="flex w-auto border-black ">
        <p className="text-qosdark">Upload copy of your identification card</p>
      </div>
      <div className="flex w-auto">
        {/* <IdFilesUploader selec={selec.selects} className="w-full" text="uploads your Id " /> */}
        {/* <IdDoc */}
        {/* <IdFilesUploaderB/> */}
        <IdBFilesUploader className="w-full" text="Drag and drop or browse to choose a file"/>
      </div>
    </div>
  );
}

export default IdDocumentB;
