import { encryptStorage } from "../services/encryptData";
import { FetchData, PostData } from "../services/httpServices";
import { Endpoints } from "./endpoints";

export function AleatoireNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getUser(attr) {
  let User = encryptStorage.getItem("qosUser"),
  
    result;
  switch (attr) {
    case "ID":
      result = User.clientId;
      break;
    case "IDMTN":
      result = User.clientId;
      break;
    case "IDMOOV":
      result = User.clientIdMoov;
      break;
    case "userId":
      result = User.userId;
      break;
    case "username":
      result = User.email;
        // User.firstname && User.lastname
        //   ? ` ${User.firstname} ${User.lastname}`
        //   : `Customer ${User.clientId}`;
      break;
    case "pwd":
      result = User.password;
      break;
    case "mtn_merchant_number":
      result = User.mtn_merchant_number;
      break;
    case "moov_merchant_number":
      result = User.moov_merchant_number;
      break;
    case "phone":
      result = User.phone;
      break;
    case "mail":
      result = User.email;
      break;
    case "company_name":
      result = User.company_name;
      break;
    case "activity_description":
      result = User.activity_description;
      break;
    case "last_name":
      result = User.last_name;
      break;
    case "first_name":
      result = User.first_name;
      break;
    case "paymentMode":
      result = User.paymentMode;
      break;
    case "country":
      result = User.country;
      break;
  }
  // console.log('user get user :',User)
  return result;
}

export function getUserTransCredentials(attr, operator) {
  let cred = encryptStorage.getItem(`credTrans${operator}`),
    result;
  switch (attr) {
    case "username":
      result = cred?.username;
      break;
    case "pwd":
      result = cred?.password;
      break;
  }
  return result;
}

export async function getTransfertCredentialsFromServer() {
  try {
    let { data, status } = await FetchData(Endpoints.Credentials.get, {}, {})
    console.log("la data : ", data)

    if (status === 200 && data) {
      console.log("la data encrypt Moov dscs : ",  data.datas[0])
      encryptStorage.setItem("credTransMOOV", data.datas[0].operatorMOOV);
      encryptStorage.setItem("credTransMTN", data.datas[0].operatorMTN);
      
    } else {
      throw new Error("request failed please retry");
    }
  } catch (e) {
    alert(e);
  }
}

export async function generateCredentials(operator) {
  try {
    const { status } = await PostData(
      Endpoints.Credentials.generate(operator),
      {},
      {
        params: {
          operator,
        },
      }
    );
    if (status === 201) {
      getTransfertCredentialsFromServer();
      return true;
    }
    return false;
  } catch (e) {
    e.message = "not here";
  }
}

export function percent(value, total) {
  if (total > 0) {
    return Math.ceil((+value / +total) * 99);
  }
  return 0;
}
