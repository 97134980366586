import React, { useState } from "react";
import Wrapper from "../components/sectionWrapper.component";
import { useParams } from "react-router-dom";
// import { media } from "../common/libs/media";
import { media } from "../common/libs/media";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import base32 from "hi-base32";

export default function ResponsePage() {
  const { qosLogoBlack } = media.Logos.qos;
  // const { id } = useParams();

  // let regard = id;

  //  const idEE = regardc;
  //  url = url.replace(/[?#]$/,'')

  const URL = window.location.search;
  // const { id } = useParams();
  // const deco = handle;
  // const decoded = base32.decode(handle);
  // const id = decoded.split('/')[0].replace('%20',' ');
  // const comp = decoded.split('/')[0];

  const searchParams = new URLSearchParams(URL);
  const status = searchParams.get("status");
  const transref = searchParams.get("transref");

  // console.log("idEE", URL)
  // console.log("idEE", URL.replace(/[?]$/,''))
  // console.log("searchParams", searchParams)
  // console.log("status", status)
  // console.log("transref", transref)

  // console.log("regar", regard)

  return (
    <div
      style={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Wrapper
        style={{
          background: "transparent",
          backgroundSize: "80% .12%",
        }}
      >
        <div className="w-12/12 max-w-md bg-white shadow-sm  px-4 py-4 mx-auto my-20 rounded-lg">
          <div className="p-1">
            <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
          </div>
          {/* { mail === "Your account has been successfully verified"?<h3 className="text-qosgreen" >{mail}</h3>:<h3 className="text-qosred" >{mail}</h3>  } */}
          {/* { mail === "Your account has been successfully verified"?  */}

          {status === "SUCCESS" ? (
            <>
              <div className="flex justify-center mt-6 p-4">
                <Icon
                  // icon="icon-park:mail-unpacking"
                  icon="icon-park-solid:check-one"
                  height={80}
                  style={{
                    color: "#1faf38",
                    position: "relative",
                  }}
                />
              </div>
              <div className=" m-1 py-2 text-center">
                {/* <h4 className="text-center text-green pb-2 md:text-mH2 ">Su</h4> */}
                <p className="pt-4 text-black md:text-mH2">
                  Merci d'avoir utiliser notre service voici votre réference de
                  transfer: {transref}
                </p>
              </div>

              {/* <div className="w-full  justify-center text-center mt-4">
          <button className="w-full h-16 bg-qosorange  text-white border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange">
            <Link to="/">visite our website</Link>
          </button>
        </div>  */}
            </>
          ) : (
            <>
              <div className="flex justify-center mt-6 p-4">
                <Icon
                  // icon="icon-park:mail-unpacking"
                  icon="tabler:xbox-x"
                  height={80}
                  style={{
                    color: "red",
                    // marginLeft: "60px",
                  }}
                />
              </div>
              <div className="m-1 py-1 text-center">
                <h4 className="text-center text-red md:text-dH2 ">
                  L'opération à échouer
                </h4>

                {/*         
          <h4 className="pt-2 gap-4 text-black">
          Voici votre réference de transfer:
          
          </h4>
          <h4 className="text-red mt-4"> {transref} </h4> */}
              </div>
            </>
          )}

          <div className="flex justify-center mt-4 mb-24">
            {/* <Link to="/">
            <p className="underline">Close</p>
          </Link> */}
          </div>
        </div>
      </Wrapper>
    </div>
  );
}
