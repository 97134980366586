import React, { useState, useEffect } from "react";

import { useFormData } from "../common/utils/hooks";
import { FetchData, PostData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { SinglePay } from "../common/auth";
import { Spinner } from "./spinner.component";
import { encryptStorage } from "../common/services/encryptData";
import { useLangContext } from "../components/translateContext";
import Wrapper from "../components/sectionWrapper.component";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { ActivateTwoAuth } from "../common/auth";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Messages from "./translations/index.json";
import InputsText from "../translations/input.json";
import CommingSoon from "./commingSoon";

export default function SingleTwo(network, listOperators) {
  const [data, handleInput] = useFormData();
  const [loading, setMLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [myCode, setMyCode] = useState(null);
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [userMessage, setUserMessage] = useState(null);

  const [listCredential, setListCredential] = useState([]);
  const [loadingCredential, setLoadingCredential] = useState(true);
  const [newCountry, setNewCountry] = useState("");

  const [info, setInfo] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [transferType, setTransferType] = useState("");

  const [dataz, setDataz] = useState({
    client_id: "",
  });

  const handleClose = () => setShow(false);
  const handleClose1 = () => {
    setShow(false);
    setShow1(false);
    setUserMessage(null);
  };

  const handleMyCode = (e) => {
    setMyCode(e.target.value);
  };

  const resetHandle = () => {
    handleInput({});
  };

  const inf = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfo(res);
      setIsLoading(false);
      ListOfCredential({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    inf();
  }, []);

  function ListOfCredential(pays) {
    setLoadingCredential(true);

    setNewCountry(pays.target.value);

    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;

    // console.log("pays :",newCountry)
    if (pays.target) {
      FetchData(
        // Endpoints.Credentials.get + "?country=" + pays.target.value
        Endpoints.Credentials.get + "?country=" + lopetateur
      ).then((res) => {
        setListCredential(res);
        setLoadingCredential(false);
      });
    }
  }

  const username = encryptStorage.getItem("qosUser")?.email;
  const twofa = encryptStorage.getItem("qosUser")?.isUsing2Fa;
  // console.log("twofa", twofa)

  const cara = { username };
  let code = { code: myCode };
  let verificationCode = myCode;

  const datas = { ...code, ...cara };

  // console.log("network", network);
  // console.log("listCredential", listCredential);

  const reseau = network?.pays;

  let first3 = data.accountNumber
  // console.log("first3", first3);

  let aro = first3 == undefined ? "undefine" : first3.slice(0,3)
  // console.log("aro", aro);

  let newValue = aro != 229 && aro != 228 ? "Please add contry prefixe Ex: 229 " : "Prefixe ok"  ;
  // console.log("newValue", newValue);
 




  // console.log("userMessage", userMessage);

  const pays = `${
    reseau === "MTN" || reseau === "MOOV" || reseau === "MTN"
      ? "BENIN"
      : reseau === "TOGOCEL" || reseau === "MOOVTG"
      ? "TOGO"
      : reseau === "CI_OPERATORS"
      ? "IVORY_COST"
      : ""
  }`;

  const trueCountry = `${
    newCountry === "🇹🇬"
      ? "TOGO"
      : newCountry === "🇧🇯"
      ? "BENIN"
      : newCountry === "🇨🇮"
      ? "IVORY_COST"
      : newCountry
  }`;

  // console.log("trueCountry", trueCountry);
  // if (reseau === "MTN" || reseau === "MOOV" || reseau === "MTN" ){
  //   setPays("BENIN")
  // }else if( reseau === "TOGOCEL" || reseau === "MOOVTG"){
  //   setPays("TOGO")
  // }else if( reseau === "CI_OPERATORS"){
  //   setPays("IVORY_COST")
  // }

  // const UserProfile = () => {
  //   FetchData(Endpoints.User.meNew).then((res) => {
  //     // console.log("resposnse :", res);
  //     setUser(res);
  //     setMLoading(false);
  //   });
  // };

  // useEffect(() => {
  //   UserProfile();
  // }, []);

  const ref = Math.random()
    .toString(36)
    .substr(2, 9)
    .padEnd(9, "0")
    .substr(0, 9)
    .toUpperCase();

  let account = { account: dataz.client_id };
  let accountMail = { accountMail: encryptStorage.getItem("qosUser").email };
  let accountName = {
    accountName: encryptStorage.getItem("qosUser").last_name,
  };
  let accountNumber = { accountNumber: data.accountNumber };
  let amount = { amount: data.amount };
  let country = { country: trueCountry };
  let operator = { operator: dataz.operator };
  let typeTransfert = { typeTransfert: transferType };
  let transfertReference = { transfertReference: ref };
  let transfertRemark = { transfertRemark: data.description };

  // console.log("transfertReference", transfertReference)
  // console.log("country", country)
  // console.log("operateur type :", account);

  // console.log("transfertReference", transfertReference)
  // console.log("data", data)

  // const infoUser = loading ? "loading" : user?.data?.datas;

  //  console.log("infoUser :", infoUser);

  //  console.log("encript qosUser email :", encryptStorage.getItem("qosUser").email)
  //  console.log("encript qosUser  last_name:", encryptStorage.getItem("qosUser").last_name)
  let newoperateur = { operator: reseau };
  //  {
  //   "account": "qoskey",
  //   "accountMail": "email de customers",
  //   "accountName": "nom entreprise ou nom ",
  //   "accountNumber": "mobile de destinattion 22893455129",
  //   "amount": "200000",
  //   "country": "TOGO",
  //   "operator": "TOGOCEL",
  //   "transfertReference": "SED4513545",
  //   "transfertRemark": "PAIEMENT SALAIRE",
  //   "typeTransfert": "TOGOCEL-TOGOCEL"
  // }
  let Newdata = {
    // ...newoperateur,
    ...account,
    ...accountMail,
    ...accountName,
    ...accountNumber,
    ...country,
    ...amount,
    ...operator,
    ...transfertRemark,
    ...transfertReference,
    ...typeTransfert,
  };

  // console.log("Newdata ",Newdata)
  // console.log("envode myCode:", myCode)

  const handleOperatorChange = (event) => {
    // const selectedOperator = event.target.value;
    setTransferType(event.target.value);
    const selectedOperator = `${
      event.target.value === "CARD to BANK"
        ? "CARD"
        : event.target.value === "MTN to MTN"
        ? "MTN"
        : event.target.value === "MOOV to MOOV"
        ? "MOOV"
        : event.target.value === "MOOVTG to MOOVTG"
        ? "MOOVTG"
        : event.target.value === "TOGOCEL to TOGOCEL"
        ? "TOGOCEL"
        : event.target.value === "CI_OPERATORS to CI_OPERATORS"
        ? "CI_OPERATORS"
        : ""
    }`;

    // console.log("event.target.value:", event.target.value)
    setSelectedOperator(selectedOperator);

    // Find the data object for the selected operator
    const selectedData = listCredential?.data?.datas?.items?.find(
      (operatorData) => operatorData.operator === selectedOperator
    );

    // Update the client_id in the state
    setDataz((prevData) => ({
      ...prevData,
      operator: selectedData.operator,
      client_id: selectedData.client_id,
    }));
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // let newoperateur = { operator: reseau };
    // console.log("valeur code ==>", code)
    // let Newdata = {
    //   ...data,
    //   ...newoperateur,
    // };
    try {
      // console.log("SinglePay SinglePay : ", Newdata);
      const { data: user } = await SinglePay(Newdata, verificationCode);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if(  user.responseCode === "00" && user.datas.responseMessage === "Failed" ){
        setIsLoading(false);
        let message = user.datas.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });

      setTimeout(() => {
        handleClose1()
        setShow1(false)
      }, 5000);


      }else if (user.responseCode === "00") {
        setIsLoading(false);
        let message = user.datas.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
        setTimeout(() => {
          handleClose1();
        }, 5000);
        
       
      } else if (user.responseCode === "01") {
        setIsLoading(false);
        let message = "2FA confirmation code incorrect";
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });

      setTimeout(() => {
        handleClose1()
        setShow1(false)
      }, 5000);
        
      } else if (user.responseCode != "00") {
        setIsLoading(false);
        let message = user.datas.responseMessage;
        return setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
          handleClose1();
        }, 5000);
      }
    } catch (error) {
      // console.log("erreur :", error);
      setIsLoading(false);
      return setTimeout(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        handleClose();
        Toast.fire({
          icon: "error",
          title: "Verify the enterd information / Contant QOS support",
        });
      }, 5000);
    }
  }

  const handleShow = () => {
    // setIsLoading(true)
    if (
      data.account === "" ||
      data.account === undefined ||
      data.amount === "" ||
      data.amount === undefined ||
      data.account_number == "" ||
      data.account_number === undefined ||
      data.bankName === ""
    ) {
      // alert("Please first select a file");
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! completly  fill the form",
      });
      return;
    } else if (twofa === false || twofa === null) {
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! activate 2AF authenticator first",
      });
      return;
    }

    setShow(true);
  };

  const handleShow1 = () => {
    // setIsLoading(true)
    if (
      transferType === "" ||
      transferType === undefined ||
      data.amount === "" ||
      data.amount === undefined ||
      data.accountNumber == "" ||
      data.accountNumber === undefined
    ) {
      // alert("Please first select a file");
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! completly  fill the form",
      });
      return;
    } else if (twofa === false || twofa === null) {
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! activate 2AF authenticator first",
      });
      return;
    }

    setShow1(true);
  };

  async function handleCode(e) {
    e.preventDefault();
    setIsLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ActivateTwoAuth(datas);
      // console.log("status status : ", status)

      // console.log("datas.dat  : ", data)
      // console.log("user user: ", user.responseCode)
      // console.log("encript data token :", encryptStorage.getItem("qosToken"))
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript data  :", encryptStorage.getItem("qosUser"))

      if (user.responseMessage === "Successful") {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });

        setIsLoading(false);
        setUserMessage(user.responseMessage);
      } else {
        setIsLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setIsLoading(false);
  }

  async function handleCode1(e) {
    e.preventDefault();
    setIsLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ActivateTwoAuth(datas);
      // console.log("status status : ", status)

      // console.log("datas.dat  : ", data)
      // console.log("user user: ", user.responseCode)
      // console.log("encript data token :", encryptStorage.getItem("qosToken"))
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript data  :", encryptStorage.getItem("qosUser"))

      if (user.responseMessage === "Successful") {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });

        setIsLoading(false);
        setUserMessage(user.responseMessage);
      } else {
        setIsLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setIsLoading(false);
  }

  return (
    <div className="">
      {/* <form onSubmit={handleSubmit} className="px-4">
        <div className="mx-auto w-full md:max-w-sm mt-10">
          <label htmlFor="operator" className="">
            Operator:
          </label>
          <input
            onChange={handleInput}
            id="operator"
            name="operator"
            placeholder="-"
            required="true"
            defaultValue={reseau === "null" || reseau === "CARD" ? "-" : reseau}
            disabled={true}
          />
        </div>

        <div className="mx-auto w-full md:max-w-sm mt-6">
          <label htmlFor="amount">{reseau === "null" || reseau === "CARD" ? "-" : reseau} QosKey</label>
          <input
            onChange={handleInput}
            required="true"
            type="tel"
            placeholder="Your Qoskey"
            maxlength = "11"
            name="account"
            id="account"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <div className="mx-auto w-full md:max-w-sm mt-6">
          <label htmlFor="amount">Receiver Number:</label>
          <input
            onChange={handleInput}
            required="true"
            type="tel"
            placeholder={reseau === "MOOVTG" || reseau === "TOGOCEL"  ? "228 ********" : reseau === "CI_OPERATORS" ? "225 ********": "229 ********"}
            maxlength = "11"
            name="accountNumber"
            id="accountNumber"
            autoComplete="accountNumber"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

        <div className="mx-auto w-full md:max-w-sm mt-6">
          <label htmlFor="amount">Amount:</label>
          <input
            onChange={handleInput}
            required="true"
            type="tel"
            placeholder="Amount"
            name="amount"
            id="amount"
            autoComplete="amount"
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>

    
      </form> */}

      <Wrapper className="mt-10">
        <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-2  my-1  pb-16 lg:px-0 md:w-3/6 lg:w-3/6 sm:w-full  ">
          <div className=" my-10">
            <p
              className="pt-2 pl-10 text-semibold "
              style={{ fontSize: 20, color: "#45464E" }}
            >
              New transfer
            </p>
          </div>
          <div className="mx-auto  lg:w-8/12 mt-4  flex justify-end ">
            <div>
              {isLoading ? (
                <select
                  id="country"
                  name="country"
                  autoComplete="country"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  <option className="text-white m-1">Country</option>
                </select>
              ) : (
                <select
                  onChange={(e) => ListOfCredential(e)}
                  id="country"
                  name="country"
                  autoComplete="country"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  {/* <option value="" 
               disabled
               selected
               hidden
               className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
              >
              Country
              </option> */}
                  {info?.data?.datas?.items?.map((item) => (
                    <option key={item.index}>
                      {item === "TOGO"
                        ? "🇹🇬"
                        : item === "BENIN"
                        ? "🇧🇯"
                        : item === "IVORY_COST" || item === "IVORY_COAST"
                        ? "🇨🇮"
                        : ""}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          {/* <pre>{JSON.stringify(dataz, null, 2)}</pre> */}

          <form onSubmit={handleSubmit} className="  w-full ">
            <div className="mx-auto  lg:w-8/12 mt-4">
              <span className="block"> Type of transfer*</span>

              {/* <select
                style={styles.input}
                name="type_of_transaction"
                onChange={handleInput}
              >
                <option disabled selected hidden>
                  Select type of transfer{" "}
                </option>
                <option value="CARD to BANK">CARD to BANK</option>
                <option value="MTN to MTN">MTN to MTN</option>
                <option value="MTN to MOOV">MTN to MOOV</option>
                <option value="MOOV to MOOV">MOOV to MOOV</option>
                <option value="MOOV to MTN">MOOV to MTN</option>
                <option value="BANK to BANK">BANK to BANK</option>
              </select> */}

              <div>
                <select
                  onChange={handleOperatorChange}
                  id="account"
                  name="account"
                  autoComplete="account"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    className="h-8 text-qosText  px-4 md:w-40 min-w-max bg-qosorange"
                  >
                    Select type of transfer
                  </option>
                  {listCredential?.data?.datas?.items?.map((item) => (
                    // console.log("",)
                    <option key={item.index}>
                      {item.operator === "CARD"
                        ? "CARD to BANK"
                        : item.operator === "MTN"
                        ? "MTN to MTN"
                        : item.operator === "MOOV"
                        ? "MOOV to MOOV"
                        : item.operator === "MOOVTG"
                        ? "MOOVTG to MOOVTG"
                        : item.operator === "TOGOCEL"
                        ? "TOGOCEL to TOGOCEL"
                        : item.operator === "CI_OPERATORS"
                        ? "CI_OPERATORS to CI_OPERATORS"
                        : ""}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div>
                 <select value={selectedOperator} onChange={handleOperatorChange}>
                <option value="">{Infos.msg.selectOp}</option>
                {listCredential?.data?.datas?.items?.map((operatorData) => (
                  <option
                    key={operatorData.operator}
                    value={operatorData.operator}
                  >
                    {operatorData.operator}
                  </option>
                ))}
              </select>
            </div> */}

            {transferType === "CARD to BANK" ||
            transferType === "BANK to BANK" ? (
              <>
              <CommingSoon/>
              </>
              // <>
              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <label className="block">Bank name</label>

              //     <select
              //       style={styles.input}
              //       className="py-2 border text-qosText mt-2 rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="bankName"
              //       id="bankName"
              //       value={data.bankName}
              //       onChange={handleInput}
              //     >
              //       <option disabled selected hidden>
              //         Select bank
              //       </option>
              //       <option value="UBA">UBA</option>
              //       <option value="NSIA_BANQUE">NSIA BANQUE</option>
              //       <option value="ALIDe">ALIDe</option>
              //       <option value="Ecobank">Ecobank</option>
              //       <option value="PADME">PADME</option>
              //       <option value="BGFIBANK">BGFIBANK</option>
              //       <option value="Coris_Bank">Coris Bank</option>
              //       <option value="ORABANK">ORABANK</option>
              //       <option value="Banque Atlanque">Banque Atlanque</option>
              //     </select>
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block">Account number*</span>
              //     <input
              //       style={styles.input}
              //       className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="accountNumber"
              //       value={data.accountNumber}
              //       onChange={handleInput}
              //       required
              //       placeholder="Enter account number"
              //     />
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block"> Account name*</span>
              //     <input
              //       style={styles.input}
              //       className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="account_name"
              //       value={data.account_name}
              //       onChange={handleInput}
              //       required
              //       placeholder="Enter account name"
              //     />
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block">Amount to send*</span>
              //     <input
              //       style={styles.input}
              //       className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="amount"
              //       value={data.amount}
              //       onChange={handleInput}
              //       type="text"
              //       required
              //       placeholder="Enter amount"
              //     />
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block">Description (optional)</span>
              //     <label className="block"></label>
              //     <textarea
              //       className="p-3 py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       style={{ borderRadius: 4 }}
              //       name="description"
              //       value={data.description}
              //       onChange={handleInput}
              //       placeholder="Enter transaction description"
              //     ></textarea>
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block">Transfer reference (optional)</span>
              //     <input
              //       style={styles.input}
              //       className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="transref"
              //       value={data.transref}
              //       onChange={handleInput}
              //       required
              //       placeholder="Enter transaction reference"
              //     />
              //   </div>

              //   <div className="mx-auto  lg:w-8/12 mt-4">
              //     <span className="block">
              //       {Infos.input.email.label} address (optional)
              //     </span>
              //     <input
              //       style={styles.input}
              //       className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              //       name="email"
              //       value={data.email}
              //       onChange={handleInput}
              //       type="email"
              //       required
              //       placeholder="Enter email address"
              //     />
              //   </div>
              // </>
            ) : (
              <>
                <div className="mx-auto  lg:w-8/12 mt-4">
                  <span className="block"> Mobile money number* </span>
                  <input
                    style={styles.input}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="accountNumber"
                    value={data.accountNumber}
                    onChange={handleInput}
                    required
                    placeholder="ex: 22967000000"
                  />
                  <p className={newValue === "Prefixe ok" ? 'text-qosorange' : 'text-red' } > {newValue} </p>
                </div>

                <div className="mx-auto  lg:w-8/12 mt-4">
                  <span className="block"> Recipient name*</span>
                  <input
                    style={styles.input}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="recipient_name"
                    value={data.recipient_name}
                    onChange={handleInput}
                    required
                    placeholder="Enter recipient name"
                  />
                </div>

                <div className="mx-auto  lg:w-8/12 mt-4">
                  <span className="block">Amount to send*</span>
                  <input
                    style={styles.input}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="amount"
                    value={data.amount}
                    onChange={handleInput}
                    type="number"
                    required
                    placeholder="Enter amount"
                  />
                </div>

                <div className="mx-auto  lg:w-8/12 mt-4">
                  <span className="block">Description (optional)</span>
                  <label className="block"></label>
                  <textarea
                    // className="p-3"
                    className="p-3 py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    style={{ borderRadius: 4 }}
                    name="description"
                    value={data.transfertRemark}
                    onChange={handleInput}
                    placeholder="Enter transaction description"
                  ></textarea>
                </div>

                <div className="mx-auto  lg:w-8/12 mt-4">
                  {/* <span className="block">{Infos.input.business.label}</span> */}
                  <span className="block">Transfer reference (optional)</span>
                  <input
                    style={styles.input}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="transref"
                    value={data.transfertReference}
                    onChange={handleInput}
                    required
                    placeholder="Enter transaction reference"
                  />
                </div>

                <div className="mx-auto  lg:w-8/12 mt-4">
                  {/* <span className="block">{Infos.input.business.label}</span> */}
                  <span className="block">
                    {Infos.input.email.label} address (optional)
                  </span>
                  <input
                    style={styles.input}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="email"
                    value={data.email}
                    onChange={handleInput}
                    type="email"
                    required
                    placeholder="Enter email address"
                  />
                </div>
              </>
            )}
          </form>

          {transferType === "CARD to BANK" ||
          transferType === "BANK to BANK" ? (
            <div className="mb-10">
              {/* <button
                onClick={handleShow}
                variant="warning"
                className={`w-8/12 h-12 flex justify-center items-center  ${
                  isLoading ? "bg-qosorange" : "bg-qosorange "
                }  mx-auto mt-4 my-4`}
              >
                <span className="text-white" style={{ fontSize: "16px" }}>
                  Continue
                </span>
              </button> */}
            </div>
          ) : (
            <div className="mb-10 ">
              <button
                onClick={handleShow1}
                // disabled={isLoading}
                variant="warning"
                className={`w-full  lg:w-8/12 h-12 rounded flex mt-4 justify-center items-center  ${
                  isLoading ? "bg-qosorange" : "bg-qosorange "
                }  mx-auto mt-4 my-4`}
              >
                <span className="text-white" style={{ fontSize: "16px" }}>
                  Continue
                </span>
              </button>
            </div>
          )}
        </div>
      </Wrapper>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            {userMessage == null ? (
              <form
                id="stepForm"
                onSubmit={handleCode1}
                className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto"
              >
                <p className="pt-2">Confirm transfer</p>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full">
                    <div className=" p-1">
                      <span className="text-qosText ">Mobile money number</span>
                      <p className="my-2">{Newdata?.accountNumber}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Operator</span>
                      <p className="my-2">{transferType}</p>
                    </div>
                  </div>

                  <div className=" w-full text-left  gap-2">
                    <div className="p-1 ">
                      <span className="text-qosText">Recipient name</span>
                      <p className="my-2">{data?.recipient_name}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Amount</span>
                      <p className="my-2">{data?.amount}</p>
                    </div>
                  </div>
                </div>

                <p className="pt-1">
                  Enter the code generated by the app to complete 2FA
                  verification.
                </p>
                <div className="mx-auto  lg:w-full mt-8">
                  <span className="block text-left">Authentication code</span>
                  <input
                    style={styles.input}
                    // onChange={handleInput}
                    className="py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    // value={data.username}
                    // name="username"
                    // type="email"
                    // required
                    name="code"
                    // value={data.code}
                    onChange={handleMyCode}
                    required
                    placeholder="Enter code here"
                  />
                </div>
                {/* <label className="block">Authentication code </label>
            <div className="flex-column bg-qoswhite border my-2 border-qosgraye gap-2 rounded-lg justify-around">
              
              <input
                className="bg-qoswhite border-none rounded"
                name="code"
                value={data.code}
                onChange={handleMyCode}
                required
                placeholder="Enter code here"
              />
            </div> */}

                <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-4 mb-10">
                  <button
                    id="next"
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-sm text-white  font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    } btn-sm md:max-w-sm mx-auto mt-8`}
                  >
                    {isLoading ? (
                      <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                    ) : (
                      <span className="text-msm" style={{ fontSize: "16px" }}>
                        Confirm transfer
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <p className="p-2 text-center">
                  Click below to complete your transaction
                </p>
                <p className=" text-center">👇</p>

                <Button
                  disabled={isLoading}
                  type="submit"
                  className={`w-full h-12 flex justify-center items-center  rounded-md text-qosgray text-opacity-80 font-bold ${
                    isLoading ? "bg-coolGray-300" : "bg-qosorange"
                  } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-8`}
                  variant="primary"
                  size="lg"
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Spinner className="border-qosorange h-4 w-4 mx-1" />
                  ) : (
                    "Envoyé"
                  )}
                </Button>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header
          closeButton
          className="border-0 border-white"
        ></Modal.Header> */}
          <button onClick={() =>{
          handleClose()
        }}>CLOSE</button>
        <Modal.Body>
          <div>
            {userMessage == null ? (
              <form
                id="stepForm"
                onSubmit={handleCode1}
                className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto"
              >
                <p className="pt-2">Confirm transfer</p>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full">
                    <div className=" p-1">
                      <span className="text-qosText ">Recipient number</span>
                      <p className="my-2">{data?.accountNumber}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Recipient name</span>
                      <p className="my-2">{data?.recipient_name}</p>
                    </div>
                  </div>

                  <div className=" w-full text-left  gap-2">
                    <div className="p-1 ">
                      <span className="text-qosText">Transfer type</span>
                      <p className="my-2">{transferType}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Amount</span>
                      <p className="my-2">{data?.amount}</p>
                    </div>
                  </div>
                </div>

                <p className="pt-1">
                  Enter the code generated by the app to complete 2FA
                  verification.
                </p>
                <div className="mx-auto  lg:w-full mt-8">
                  <span className="block text-left">Authentication code</span>
                  <input
                    style={styles.input}
                    // onChange={handleInput}
                    className="py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    // value={data.username}
                    // name="username"
                    // type="email"
                    // required
                    name="code"
                    // value={data.code}
                    onChange={handleMyCode}
                    required
                    placeholder="Enter code here"
                  />
                </div>
                {/* <label className="block">Authentication code </label>
                <div className="flex-column bg-qoswhite border my-2 border-qosgraye gap-2 rounded-lg justify-around">
                  
                  <input
                    className="bg-qoswhite border-none rounded"
                    name="code"
                    value={data.code}
                    onChange={handleMyCode}
                    required
                    placeholder="Enter code here"
                  />
                </div> */}

                <div className="w-full font-bold text-qosblue  flex justify-around items-center mt-4 mb-10">
                  <button
                    id="next"
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-sm text-white  font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    } btn-sm lg:w-full  mt-8`}
                  >
                    {isLoading ? (
                      <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                    ) : (
                      <span className="text-msm" style={{ fontSize: "16px" }}>
                        Confirm authentication code
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <div className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto">
                  <p className="pt-2">Confirm transfer</p>
                  <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                    <div className="text-left pl-4 w-full">
                      <div className=" p-1">
                        <span className="text-qosText ">Recipient number</span>
                        <p className="my-2">{data?.accountNumber}</p>
                      </div>
                      <div className="p-1">
                        <span className="text-qosText">Recipient name</span>
                        <p className="my-2">{data?.recipient_name}</p>
                      </div>
                    </div>

                    <div className=" w-full text-left  gap-2">
                      <div className="p-1 ">
                        <span className="text-qosText">Transfer type</span>
                        <p className="my-2">{transferType}</p>
                      </div>
                      <div className="p-1">
                        <span className="text-qosText">Amount</span>
                        <p className="my-2">{data?.amount}</p>
                      </div>
                    </div>
                  </div>

                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  mb-14 rounded-md text-qosgray text-opacity-80 font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  btn-sm lg:w-full mx-auto mt-8`}
                    // variant="primary"
                    size="lg"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      "Confirm transfer"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    height: 50,
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
