import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../components/sectionWrapper.component";
import { useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { media } from "../common/libs/media";
import { ResentVerify } from "../common/auth";
import { Spinner } from "../components/spinner.component";
import { useLocation } from "react-router-dom";
import Messages from "./translations/index.json";
import { useLangContext } from "../components/translateContext";
import Swal from "sweetalert2";
import base32 from "hi-base32";

export default function ResendVerificationEmail() {
  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(false);
  const { qosLogoBlack } = media.Logos.qos;
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang] };
  // const { id } = useParams();

  // let regard = id;

  //  const idEE = regardc;
  //  url = url.replace(/[?#]$/,'')

  const location = useLocation();

  useEffect(() => {
    //  console.log("location.pathname",location.pathname);
    //  console.log("location.search",location.search);
    //  console.log("location.state.detail",location.state.detail);
    setData(location.state.detail); // result: 'some_value'
  }, [location]);

  const URL = window.location.search;
  // const { id } = useParams();
  // const deco = handle;
  // const decoded = base32.decode(handle);
  // const id = decoded.split('/')[0].replace('%20',' ');
  // const comp = decoded.split('/')[0];

  // const searchParams = new URLSearchParams(URL);
  // const status = searchParams.get("status");
  // const transref = searchParams.get("transref");

  // console.log("location", location)
  // console.log("idEE", URL.replace(/[?]$/,''))
  // console.log("searchParams", searchParams)
  // console.log("status", status)
  // console.log("transref", transref)

  console.log("data resendemailverify", data);

  const datas = { username: data };
  const remenber = { rememberMe: true };
  const password = { password: "" };

  const resenddata = { ...datas, ...remenber, ...password };

  console.log("resentdata", resenddata);
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ResentVerify(resenddata);
      console.log("login data : ", user);
      console.log("status  : ", status);
      // console.log("login status : ", status)

      if (user.responseCode === "00") {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
      // else if (!encryptStorage.getItem("qosToken")){
      //   setLoading(false)
      //   Swal.fire({
      //       position: 'top-end',
      //       title: 'Error!',
      //       text: 'Bad credentials check your e-mail & password',
      //       icon: 'error',
      //       showConfirmButton: false,
      //       timer: 3000,
      //       timerProgressBar: true,
      //       didOpen: (toast) => {
      //         toast.addEventListener('mouseenter', Swal.stopTimer)
      //         toast.addEventListener('mouseleave', Swal.resumeTimer)
      //       },
      //       toast:true,
      //     })

      // }else{
      //   alert("error");
      // }
    } catch (error) {
      alert("error");
    }
    setLoading(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      {/* <div
        className=" bg-coolGray-50  rounded-xl"
        style={{
          background:
            "url('https://firebasestorage.googleapis.com/v0/b/image-cb355.appspot.com/o/Success.jpg?alt=media&token=0ee004c9-34bf-46fe-8727-5a31326b6778')",
          // backgroundSize: "90% .12%",

          // backgroundSize: "cover",
          height: "100vh",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          // backgroundPosition: center ,
          backgroundSize: "cover",
          // borderWidth:"5px",
          borderRadius: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="">
          <h2 className="text-center  md:text-dH2">
            Account successfully created <br />
            check your email for account verification
          </h2>
        </div>

        <div className="grid w-full   justify-center align-center">
          <button className="bg-qosorange p-2 ml-4 w-80 justify-center items-center rounded-md text-black  font-bold  bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-4">
            <Link className="text-qosblue underline inline-block " to="/">
              Go to login page
            </Link>
          </button>
        </div>
      </div> */}
      <Wrapper
        style={{
          background: "transparent",
          backgroundSize: "80% .12%",
        }}
      >
        <div className="w-12/12 max-w-md bg-white shadow-sm  px-4 py-4 mx-auto my-20 rounded-lg">
          <div className="p-1">
            <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
          </div>
          {/* { mail === "Your account has been successfully verified"?<h3 className="text-qosgreen" >{mail}</h3>:<h3 className="text-qosred" >{mail}</h3>  } */}
          {/* { mail === "Your account has been successfully verified"?  */}

          {/* <div className=" m-1 py-2 text-start">
          <span className="pt-2 text-qosText">
          If you don’t see it in your inbox, you may need to 
          check your spam folder  
          </span>
        </div> */}

          <div className="mt-3 m-1 px-2 py-4 text-center">
            <p className="pt-2 ">{Infos.msg.cant}</p>
          </div>

          <form onSubmit={handleSubmit} className="  w-full ">
            <button
              disabled={isLoading}
              style={styles.button}
              className={` w-full  flex justify-center items-center  text-white text-opacity-80  ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              }  p-3 btn-sm mx-auto mt-4`}
            >
              {isLoading ? (
                <Spinner className="border-qosorange h-4 w-4 mx-1" />
              ) : (
                <div className="flex gap-2  justify-center align-center">
                  <span>Resend verification email</span>
                  {/* <span className="fi-rr-arrow-right mt-1"></span> */}
                </div>
              )}
            </button>

            {/* <div className="w-full  justify-center text-center mt-2 mb-20">
          <button className="w-full h-14 bg-qosorange  text-white border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange">
            <Link to="/">Login to your account</Link>
          </button>
        </div>  */}
          </form>

          {/* <div className="flex justify-center mt-4 mb-36">
          <Link to="/">
            <p className="underline">Close</p>
          </Link>
        </div> */}
        </div>
      </Wrapper>
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
