import React from "react";
import { columns } from "../common/utils/table";
// import Sidebar from "../partials/Sidebar";
// import Header from "../partials/Header";
// import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
// import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
// import FilterButton from "../partials/actions/FilterButton";
import Datepicker from "../partials/actions/Datepicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
// import DashboardCard02 from "../partials/dashboard/DashboardCard02";
// import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
// import DashboardCard07 from "../partials/dashboard/DashboardCard07";
// import DashboardCard08 from "../partials/dashboard/DashboardCard08";
// import DashboardCard09 from "../partials/dashboard/DashboardCard09";
// import DashboardCard10 from "../partials/dashboard/DashboardCard10";
// import DashboardCard11 from "../partials/dashboard/DashboardCard11";
// import DashboardCard12 from "../partials/dashboard/DashboardCard12";
// import DashboardCard13 from "../partials/dashboard/DashboardCard13";
// import Banner from "../partials/Banner";
// import { Table } from "../components/table.component";
import { Tablecompo } from "../components/table.component";



export function Analytics() {
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div>
      <header className="pricingCard rounded flex items-center justify-between my-4 px-4 py-1 mx-auto max-w-screen-md-lg pr-14">
        <p className="justify-self-start text-qosdark text-opacity-90 text-dmd md:text-mH2 font-semibold tracking-tighter">
          Select the period interval
        </p>
        <Datepicker />
      </header>
      <div className="grid grid-flow-row md:grid-cols-2 lg:grid-cols-3 gap-2 mb-10 max-w-full">
        <DashboardCard06 />
        <DashboardCard05 />
        <DashboardCard01 />
        <DashboardCard04 />
        <div className="pricingCard md:col-span-2 overflow-auto p-2">
          <Tablecompo
            name="name"
            dataColumns={columns}
            content={[
              {
                id: "97478992",
                name: "ORTB",
                amount: "99000",
                count: "9900",
                none: "view",
              },
              {
                id: "67678992",
                name: "Easycom",
                amount: "8000",
                count: "1100",
                none: "view",
              },
              {
                id: "63383804",
                name: "MAdev",
                amount: "99999000",
                count: "99999900",
                none: "view",
              },
              {
                id: "63383804",
                name: "Abdel",
                amount: "99999000",
                count: "99999900",
                none: "view",
              },
              {
                id: "63383804",
                name: "Zabir",
                amount: "99999000",
                count: "99999900",
                none: "view",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
