import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkAuthentication, checkAuthorization } from "../common/auth/index";

export const PrivateRoutes = (props) => (
  <Route
    {...props.rest}
    render={({ location }) =>
      checkAuthentication(props) === true ? (
        checkAuthorization(location.pathname) === true ? (
          props.children
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorised",
              state: { from: location },
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )
    }
  />
);

export const PrivateRoute = (props) => (
  <Route
    {...props.rest}
    render={({ location }) =>
      checkAuthentication(props) === true ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: location },
          }}
        />
      )
    }
  />
);
