import { useState, useEffect } from "react";
import { VerifyUser, ActivateTwoAuth, ValidateTwoAuth } from "../common/auth";
import { media } from "../common/libs/media";
import ReactTooltip from "react-tooltip";
import { useSideBarContext } from "../common/utils/context";
import { Spinner } from "./spinner.component";
import Wrapper from "./sectionWrapper.component";
import Swal from "sweetalert2";
import { FetchData, PostData } from "../common/services/httpServices";
import { useFormData } from "../common/utils/hooks";
import { Endpoints } from "../common/utils/endpoints";
import { getUser } from "../common/utils/tools";
import { useHistory } from "react-router";
import { LogoutUser } from "../common/auth";
import { AddNewProfile } from "../common/auth";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { paths } from "../common/utils/path";
import { useMemo } from "react";
import DropdownCustom from "./dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLangContext } from "./translateContext";
import { encryptStorage } from "../common/services/encryptData";
import { GiShoppingBag } from "react-icons/gi";
import nav from "./translation/nav.json";
import head from "./translation/header.json";
import Dropdown from "react-bootstrap/Dropdown";
import "./styles.css";
import "./table.css";
import DropdownButton from "react-bootstrap/DropdownButton";
// import { useHistory } from "react-router";

const { qosLogoBlack } = media.Logos.qos;

export function Header() {
  const history = useHistory();
  const { Active, setActive } = useSideBarContext();
  const { Lang } = useLangContext();
  const Infos = { msg: nav[Lang], header: head[Lang] };

  const [data, handleInput] = useFormData();
  const [accountloading, setAccountLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const [loading, setMLoading] = useState(true);
  const [user, setUser] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [qoCode, setQrCode] = useState();
  const [Qloading, setQLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [show3, setShow3] = useState(false);


  const [ listContries, setListContries ] = useState([])

  // console.log("data ==>", data)

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setListContries(res.data.datas.items);
      // console.log("__Doctype", res.data.datas.items)
     
    });
  };


  var set1 =  listContries.map(item => {return item})


  var set2 =  ['BENIN', 'TOGO', 'IVORY_COST']


  const uniqueSet = new Set()

  // Add elements from set1 to uniqueSet
  set1.forEach(item => {
    uniqueSet.add(item);
  });

  // Remove elements from uniqueSet if they exist in set2
  set2.forEach(item => {
    if (uniqueSet.has(item)) {
      uniqueSet.delete(item);
    } else {
      // If the element doesn't exist in set1, add it to uniqueSet
      uniqueSet.add(item);
    }})


    const resultList = Array.from(uniqueSet);

  // console.log("set1", set1)
  // console.log("set2", set2)
  // console.log("resultList", resultList)
 

  const handleCclose1 = ({}) => {
    setIsActive(!isActive);
    setShow1(false);
    setShow(false);
  };
  const handleShow1 = () => {
    // setIsActive(!isActive);
    setShow1(true);
  };
  const handleShow2 = () => {
    // setIsActive(!isActive);
    setShow1(true);
  };

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleCclose = () => {
    setIsActive(!isActive);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const UserProfile = () => {
    FetchData(Endpoints.User.meNew).then((res) => {
      // console.log("resposnse :", res);
      setUser(res);
      setMLoading(false);
    });
  };

  const infoUser = loading ? "loading" : user?.data?.datas;
  // === "INACTIVE"
  //     ? "loading"
  //     : user?.data?.datas.state;
  // console.log(" infoUser email:", infoUser.email);
  // console.log(" infoUser:", infoUser);

  useEffect(() => {
    UserProfile();
    countries();
  }, []);

  // if (isActive) {
  //   PostData(
  //     Endpoints.User.enableTwoFactor+infoUser.email,

  //   ).then((res) =>
  //    {console.log("your reste:", res); setQrCode(res);setQLoading(false); }

  //   );

  // }

  const base64Image = isLoading ? "loading" : qoCode.data.qrCode;
  // encryptStorage.getItem("qosUser")

  // console.log("encryptStorage. :", encryptStorage.getItem("qosUser")?.state);

  const handleToggle = () => {
    setShow(true);
    setIsActive(!isActive);
  };

  const handleClose = () => {
    setIsActive(!isActive);
    // console.log("rererer");
  };

  const handleOpen = () => {
    // setIsOn(!isOn);
    handleShow1();
    // console.log("handleOpen");

    if (isActive) {
      // setIsLoading(true);
      PostData(Endpoints.User.enableTwoFactor + infoUser.email)
        .then((res) => {
          // console.log("Your response:", res);
          setQrCode(res);
          setIsLoading(false);
        })
        .catch((error) => {
          // console.error("Error:", error);
          setIsLoading(false);
        });
    }
  };

  const description = { description: "string" }


  // console.log("Form Data : ", data)

  const datas = {...data, ...description }
  // console.log("datas datas : ", datas)

  async function handleSubmit(e) {
    e.preventDefault();
    setAccountLoading(true);
    try {
      // console.log("user Data : ", data)
      const { data: user, status } = await AddNewProfile(datas);
      // console.log("un user status : ", status)
      // console.log("un user authData : ", user)
      // console.log("un user response : ", user.responseMessage)
      // console.log("un user authSatus : ", status)
      
      if (user.responseMessage === "Your Adding account has been done successfully" ) {
        // console.log("un usere authUser oks : ", user);
        // console.log("un users authStatus oks : ", status);
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'success',
          //   title: user.responseMessage,
          //   showConfirmButton: false,
          //   timer: 5500
          // });
          setAccountLoading(false)
          Swal.fire({
              position: 'top-end',
              title: 'success!',
              text:  user.responseMessage,
              icon: 'success',
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              },
              toast:true,
            })
            setTimeout(() => {
              handleClose3()
            }, 7000);
      } else if( user.responseMessage === "Country empty "){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: user.responseMessage,
          showConfirmButton: false,
          timer: 5500
        });

        
      // history.push("/");
      }else if( user.responseMessage === "Company already exist "){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: user.responseMessage,
          showConfirmButton: false,
          timer: 5500
        });
      // history.push("/");
      } else if( user.responseMessage === "Invalid activity description "){
        Swal.fire({
          position: 'top-end',
          icon: 'warning',
          title: user.responseMessage,
          showConfirmButton: false,
          timer: 5500
        });
      // history.push("/");
      } 
    } catch (error) {
      // console.log("error server",error)
     setTimeout( () => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Internal Server Error",
          showConfirmButton: false,
          timer: 5500
        });
      }, "100") 
     
      
 
    }
    setAccountLoading(false);
  }


  function signout() {
    LogoutUser();
    history.replace("/");
  }
  const username = useMemo(() => getUser("last_name"), []);



  return (
    <header className="w-full absolute top-0 bg-white rounded-none items-center h-16 z-1">
      <div
        className={`relative  z-20 flex items-center   h-full  px-4 mx-auto  md:${
          Active ? "md:container md:mx-auto" : "mx-auto "
        }`}
        style={styles.section1}
      >
        <div
          onClick={() => setActive((prev) => !prev)}
          className="relative h-8 mr-2 cursor-pointer w-1/4  gap-4 p-1 flex "
          style={styles.icon}
        >
          <span
          // className={`${
          //   !Active ? ("fi fi-rr-indent") : "fi-rr-arrow-left"
          // } text-mH2`}
          >
            {!Active ? (
              <Icon
                icon="cil:hamburger-menu"
                height={24}
                style={{ color: "black", fontSize: "20px" }}
              />
            ) : (
              <Icon
                icon="oi:arrow-left"
                height={24}
                style={{ color: "black", fontSize: "20px", paddingTop: 5 }}
              />
            )}
          </span>
        </div>
        <div className="w-4/12 flex ">
          {encryptStorage.getItem("qosUser")?.isUsing2Fa !== true ? (
            <label className="flex h-full justify-start items-start cursor-pointer ">
              <div
                className="flex  justify-start cursor-pointer "
                style={{ marginLeft: 10, marginTop: -1 }}
              >
                <span className="p-1 pt-2 mr-2 font-semibold text-qosTab ">
                  Enable 2FA
                </span>

                <Form>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="customs"
                    // label="Disable 2FA authentication"
                    style={{ borderColor: "black" }}
                    className=" pr-2 border-qosred  pt-1"
                    checked={isActive}
                    size={50}
                    onChange={handleToggle}
                  />
                  {/*                   
                  <ReactTooltip
                    id="customs"
                    place="bottom"
                    effect="solid"
                    backgroundColor="gray"
                    textColor="white"
                  >
                    2FA Authentication
                  </ReactTooltip> */}
                </Form>
              </div>
            </label>
          ) : (
            ""
          )}
          {/* <div className="flex  "></div> */}
        </div>

        <div
          className={`relative text-center align-items-center content-center  flex w-4/6  mx-auto  ${
            Active
              ? `justify-end  mr-48 hidden md:flex`
              : `justify-start justify-items-start align-start`
          }`}
          style={styles.section2}
        >
          {/* <div
            className={`h-8 mr-8 text-center top-5 content-center  justify-center align-center ${
              Active ? "hidden" : "hidden"
            }`}
            style={styles.ImageWrapper}
          >
            <div style={styles.ImageSection}>
              <Link to={paths.dashboard}>
                <img
                  src={qosLogoBlack}
                  alt=""
                  className="h-full w-auto text-center"
                  style={styles.imageInner}
                />
              </Link>
            </div>
          </div> */}

          {/* {encryptStorage.getItem("qosUser")?.state === "INACTIVE" ? ( */}
          <div
            className={`flex w-full items-center justify-end text-center    py-1  ${
              Active
                ? " "
                : "flex justify-start justify-items-start   md:justify-end"
            }`}
          >
             <p className="hidden md:flex font-semibold text-qosTab">Account status :</p>
            <div
              className={`px-2 py-2  text-center hidden md:block ${
                Active
                  ? "flex justify-center justify-items-center "
                  : "flex justify-center justify-items-center md:justify-end"
              }`}
            >
             
              {infoUser.state === "INACTIVE" ? (
                <div
                  data-for="customs"
                  data-tip
                  id="testmode"
                  className=" bg-qosgrain p-2 rounded-full cursor-pointer text-center "
                >
                  <span className="text-qosred  p-1 truncate md:truncate-none text-xs md:text-sm">
                    Test Mode
                  </span>

                  <ReactTooltip
                    id="customs"
                    place="bottom"
                    effect="solid"
                    backgroundColor="gray"
                    textColor="white"
                  >
                    <span className="text-white">
                      Your account is in test mode. <br />
                    </span>

                    <span className="text-white">
                      Submit all compliance form to go Live
                    </span>
                  </ReactTooltip>
                </div>
              ) : (
                <div className="flex align-center justify-between gap-1 ">
                 <label class="switch">
                  <input type="checkbox" checked/>
                  <span class="slider round"></span>
                </label>

                  <span
                    className=" pt-1 pl-2 text-semibold"
                    style={{ color: "#11D124", fontWeight: "bold" }}
                  >
                    Live
                  </span>
                </div>
              )}
            </div>
          </div>
          {/* ) : (
            ""
          )} */}

          <div
            className={`bg-white flex items-center justify-end  pr-5 mr-5 ${
              Active ? "justify-end mr-48" : "justify-center"
            }`}
            style={styles.section3}
          >
            {/* <div className="text-msm text-center font-bold sm:mr-8 "> */}
            {/* <div className="font-bold hidden sm:block text-mmd mb-0.5">
                {Infos.msg.Welcome}
              </div> */}
            {/* <div className="text-qosdark text-opacity-60">{username}</div>
            </div> */}

            {/* <button
              onClick={signout}
              data-for="signout"
              data-tip
              className="mx-111 p-2"
            >
              <span className="fi fi-rr-sign-out font-bold text-dlg text-qosred"></span>
            </button> */}
            {/* <ReactTooltip
              id="signout"
              place="left"
              effect="solid"
              backgroundColor="gray"
              textColor="white"
            >
              {Infos.msg.Signout}
            </ReactTooltip> */}

            <div className="px-1">
              {/* <Dropdown
                class="shadow-none "
                className="flex bg-qosgrain rounded-xl justify-center align-center px-1 "
                variant="light"
              >
                <div className="h-7 w-8 mt-2  rounded-full overflow-hidden">
                  <img
                    className="h-full w-full block mx-auto"
                    alt="Profile"
                    src="https://www.seekpng.com/png/detail/966-9665493_my-profile-icon-blank-profile-image-circle.png"
                  />
                </div>

                <Dropdown.Toggle
                  variant="light"
                  class="btn"
                  className="shadow-none rounded-full bg-qosgrain"
                ></Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    class="shadow-none "
                    variant="light"
                    onClick={signout}
                  >
                    <GiShoppingBag style={{width:22, height:22, }} />
                    <p className="text-qosdark">Logout</p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className="flex w-full  bg-qosgrain justify-between align-center items-center"
                >
                  <Icon
                    icon="healthicons:ui-user-profile"
                    height={24}
                    style={{ color: "black", fontSize: "20px" }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-2" className="flex gap-1">
                    <Icon
                      icon="healthicons:ui-user-profile"
                      height={24}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                    My account
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item
                    href="#/action-3"
                    className="flex "
                    onClick={handleShow3}
                  >
                    <Icon
                      icon="carbon:add"
                      height={24}
                      style={{ color: "black", fontSize: "20px" }}
                    />
                    {Infos.header.Add}
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item
                    class="shadow-none "
                    className="bg-white"
                    variant="light"
                    onClick={signout}
                  >
                    <p className="text-qosdark text-center">
                      {Infos.header.Signout}
                    </p>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="hidden lg:flex bg-white h-10 mr-8 relative">
              <DropdownCustom Short={true} />
            </div>
          </div>
        </div>
        <div>
          {/* <DropdownButton
            variant="secondary"
            id="dropdown-basic-button"
            title="Options"
            className="ml-4"
          >
            <Dropdown.Item onClick={signout}>Sign Out</Dropdown.Item>
          
          </DropdownButton> */}
        </div>
      </div>
      {/* {isOn && (
        <Modalq
          onClose={() => (setIsOn(false), setIsActive(false))}
          base64Image={base64Image}
          username={infoUser.email}
        />
      )} */}

      <Modal
        show={show}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pr-3"
          onClick={handleCclose1}
        >
          <button>X</button>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="w-full   p-2">
              <div className="p-1">
                <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
              </div>

              <div className=" mt-4 mb-3">
                <span
                  className="text-dark text-left pl-2 "
                  style={{ fontSize: 18 }}
                >
                  Protect your account with just two easy steps
                </span>
              </div>

              <div className=" py-1">
                <ol className="m-2 gap-2">
                  <li>
                    <p className="text-dark font-semibold">
                      1. Link an authentication app to your Qospay account
                    </p>
                    <span className="text-qosText">
                      Use a compatible authentication app like Google
                      Authenticator, Authy, Duo Mobile, 1Password etc. We will
                      generate a QR code for you to scan
                    </span>
                  </li>
                  <li className="pt-4">
                    <p className="text-dark font-semibold">
                      2. Enter confirmation code
                    </p>
                    <span className="text-qosText">
                      Two factor authentication will be turned on for
                      authentication app, which you can turn off any time.
                    </span>
                  </li>
                </ol>
              </div>

              <div className="w-full flex   justify-center items-center p-1">
                {/* <div className="w-1/2 p-1">
                  <button
                    onClick={handleCclose}
                    className="w-full h-12   rounded-md text-qosgray text-opacity-80 font-bold bg-qosred"
                  >
                    Cancel
                  </button>
                </div> */}

                <div className="w-full p-1 mt-4 mb-2 ">
                  <button
                    style={styles.button}
                    onClick={handleOpen}
                    className="h-14  w-full   rounded-md font-bold bg-qosorange"
                  >
                    <p className=" p-1 text-white ">Get started</p>
                  </button>
                </div>
              </div>

              <div className="flex justify-center mt-4 mb-8">
                <button onClick={handleCclose1}>
                  <p className="underline">Close</p>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleCclose1}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Title
          // className="flex justify-end items-end align-end pr-3"
          className="flex justify-end items-end align-end pt-3 pl-3 pb-3 pr-8"
          onClick={handleCclose1}
        >
          <button>X</button>
        </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <Modalq
            base64Image={base64Image}
            username={infoUser.email}
            handleCclose1={handleCclose1}
          />
        </Modal.Body>
      </Modal>

      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton className="border-1 border-white">
          {/* <Modal.Title>Modal addAccount</Modal.Title> */}
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body> */}
        {/* <Wrapper className="w-full" > */}
        <div className="px-4 border-t shadow-sm border-qosgrain mb-14 md:px-0  w-full">
          <form onSubmit={handleSubmit} className="  w-full ">
            <div className="mx-auto  lg:w-11/12 mt-4">
              <span className="block"> {Infos.header.Country}</span>

              {/* <select
                style={styles.input}
                name="country"
                onChange={handleInput}
              >
                <option disabled selected hidden>
                  {Infos.header.Select}
                </option>
                <option value="BENIN">BENIN</option>
                <option value="TOGO">TOGO</option>
                <option value="IVORY_COST">COTE D'IVOIRE</option>
              </select> */}

              <select
                style={styles.input}
                name="country"
                onChange={handleInput}
              >
                 <option disabled selected hidden>
                  {Infos.header.Select}
                </option>
                {resultList.map((items) => {
                  return <option value={items}>{items}</option>;
                })}
              </select>
            </div>

            <div className="mx-auto   lg:w-11/12 mt-4">
              <span className="block">{Infos.header.Business}</span>
              <input
                style={styles.input}
                className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                name="company"
                value={data.company}
                onChange={handleInput}
                type="text"
                pattern="[^()/><\][\\\x22,;|]+"
                required
                placeholder={Infos.header.BusinessText}
              />
            </div>

            <div className=" mx-auto  lg:w-11/12 mt-4 ">
              <span className="block">{Infos.header.What}</span>

              <div className="flex  w-full gap-2">
                <div className="w-full flex justify-start align-center items-center gap-2">
                  <div>
                    <input
                      type="radio"
                      id="INDIVIDUALBUSINESS"
                      name="typeOfBusiness"
                      value="INDIVIDUALBUSINESS"
                      onChange={handleInput}
                      // checked
                      className="bg-qosorange"
                    />
                  </div>
                  <div>
                    <span for="INDIVIDUALBUSINESS" className="text-qosText ">
                      {Infos.header.Individual}
                    </span>
                  </div>
                </div>

                <div className="w-full  flex justify-start align-center items-center gap-2">
                  <div>
                    <input
                      className="bg-qosred"
                      type="radio"
                      id="REGISTREDBUSINESS"
                      onChange={handleInput}
                      name="typeOfBusiness"
                      value="REGISTREDBUSINESS"
                    />
                  </div>
                  <div>
                    <span for="REGISTREDBUSINESS" className="text-qosText ">
                      {Infos.header.Registered}
                    </span>
                  </div>
                </div>

                {/* <div className="w-full rounded rounded-full">
                  <input type="radio" id="dewey" name="drone" value="dewey" />
                  <label for="dewey">{Infos.header.Registered}</label>
                </div> */}
              </div>
            </div>

            <button
              disabled={accountloading}
              style={styles.button}
              className={`w-full xl:w-11/12 lg:w-11/12  flex justify-center items-center  text-white text-opacity-80  ${
                accountloading ? "bg-coolGray-300" : "bg-qosorange"
              } p-3 btn-sm  mx-auto mt-14`}
            >
              {accountloading ? (
                <Spinner className="border-qosorange h-4 w-4 mx-1" />
              ) : (
                <div className="flex gap-2  justify-center align-center">
                  <span>{Infos.header.new}</span>
                  <span className="fi-rr-arrow-right mt-1"></span>
                </div>
              )}
            </button>
          </form>
        </div>
        {/* </Wrapper> */}
      </Modal>
    </header>
  );
}

const styles = {
  section1: {
    width: "100%",
    color: "#292b2c",
    padding: "0 2em",
  },
  icon: {
    justifyItems: "start",
    alignItems: "start",
    width: "6%",
    padding: "0 1em",
  },
  // section3:{
  //   width: "100%",
  //   height:'100%',
  //   color: "#292b2c",
  //   backgroundColor:"#292b2c",
  //   padding: "0 2em",
  //   flexDirection:"row"

  // },
  section2: {
    width: "100%",
    height: "100%",
    color: "#292b2c",
    // padding: "0 2em",
  },
  ImageWrapper: {
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  ImageSection: {
    width: "90%",
    height: "80%",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  section3: {
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    paddingLeft: "100px",
  },
  imageInner: {
    paddingTop: "15px",
    width: "50px",
    height: "50px",
    alignSelf: "center",
  },
  button:{
    // width:680,
    height:"28",
    borderRadius:4,
  }
};

function Modalq({ onClose, children, base64Image, username , handleCclose1}) {
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);
  const [show2, setShow2] = useState(false);


  const handleShow2 = () => {
    // setIsActive(!isActive);
    setShow2(true);
  };

  const handleCclose = () => {
    // setIsActive(!isActive);
    setShow2(false);
  };

  const history = useHistory();

  // console.log("data username :", username);
  // console.log("data code :", data);

  const cara = { username };

  const datas = { ...data, ...cara };

  // console.log("data data  data:", datas);

  const closeIt = (onClose) => {
    onClose = { onClose };
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ActivateTwoAuth(datas);
      // console.log("status status : ", status);

      // console.log("datas.dat  : ", data);
      // console.log("user user: ", user.responseCode);
      // console.log("encript data token :", encryptStorage.getItem("qosToken"))
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript data  :", encryptStorage.getItem("qosUser"))

      if (user.responseMessage === "Successful") {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          history.push("/");
        }, 4000);
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setLoading(false);
  }

  return (
    <div className="w-full ">
      {/* {children} */}
      <div className="p-1">
        <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
      </div>

      {/* <div className=" text-center  p-2 m-3 mt-3"> 
        <h5 className="text-2xl font-semibold mb-1">
          Link the App to your Qospay account
        </h5>
      </div> */}
      <div className=" mt-8 mb-4">
                <span className="text-dark text-left pl-2 " style={{fontSize:18}}>
                Link the App to your Qospay account
                </span>
              </div>

      <div className="">
        <p className="text-qosText p-2">
          Use your authentication app to scan this QR code. If you do not have
          an authentication app on your device. You will need to install one
          now.{" "}
        </p>
      </div>

      <div className="flex justify-center align-center mt-2">
        <img
          src={base64Image}
          alt="Base64 Image"
          style={{ width: "190px", height: "190px" }}
        />
      </div>
      {/* <form
          id="stepForm"
          onSubmit={handleSubmit}
          className="overflow-x-hidden hideScroll  max-w-full w-5/6 md:w-11/12 mx-auto"
        >
          <p className="p-4">
            &#8226; Enter the code generated by the app to complete the setup.
          </p>
          <div className="mx-auto  px-2">
            <input
              className="w-1/2"
              name="code"
              value={data.code}
              onChange={handleInput}
              required
              placeholder="code"
            />
          </div>

          <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-4 ">
            <Button
              id="next"
              disabled={isLoading}
              type="submit"
              className={`w-full h-12 flex justify-center items-center  rounded-md text-qosgray text-opacity-80 font-bold ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-1`}
              variant="warning"
              size="lg"
            >
              {isLoading ? (
                <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
              ) : (
                <span className="text-msm">Activate</span>
              )}
            </Button>
          
          </div>
        </form> */}

      <div>
        <div className="w-full p-1 mt-3 mb-2 ">
          <button
            style={styles.button}
            onClick={handleShow2}
            className="h-14 mt-4  w-full   rounded-md font-bold bg-qosorange"
          >
            <span>Next</span>
          </button>
        </div>
        <div className="flex justify-center mt-4 mb-8">
          <button  onClick={handleCclose1}>
            <p className="underline">Close</p>
          </button>
        </div>
      </div>
      
      <Modal
        show={show2}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal title</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <ModalRequest base64Image={base64Image} username={username} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function ModalRequest({ onClose, children, base64Image, username }) {
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);

  // console.log("data username :", username);

  // const cara = { username };

  // const datas = { ...data, ...cara };

  // console.log("data data  data:", datas);

  function signout() {
    LogoutUser();
    history.replace("/");
  }

  const history = useHistory();

  // console.log("data username 3 :", username);
  // console.log("data code 3:", data);

  const cara = { username };

  const datas = { ...data, ...cara };

  // console.log("data data  data 3:", datas);

  const closeIt = (onClose) => {
    onClose = { onClose };
  };


  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ValidateTwoAuth(datas);
      // console.log("status status : ", status);
      // console.log("status data : ", data);

      // console.log("datas user   : ", user);
      // console.log("user user: ", user.responseCode);
      // console.log("encript data token :", encryptStorage.getItem("qosToken"))
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript data  :", encryptStorage.getItem("qosUser"))

      if (status === 200) {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: "Successful",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          signout()
        }, 4000);
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setLoading(false);
  }

  return (
    <div className="w-full">
      <div className="w-full mt-4">
        <div className="p-1">
          <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
        </div>

        <div className=" text-center  p-2 m-3 mt-4">
          <p className=" text-dark mb-2" style={{ fontSize: 18 }}>
            Enter the confirmation code
          </p>
        </div>

        <div className="p-2 ">
          <p className="mb-2 text-qosText">
            Follow the instruction on the authentication app to link your Qospay
            account. Once the authentication app generates confirmation code,
            enter it Healthcare
          </p>
          <span className="text-qosText">
            If the authentication process fails, restart the process
          </span>
        </div>

        <form
          id="stepForm"
          onSubmit={handleSubmit}
          className="overflow-x-hidden hideScroll text-center p-1 w-full"
        >
          <div className="mt-10">
            {/* <label className="block">Code </label> */}
            <input
              style={styles.input}
              className="py-4  h-14 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="code"
              value={data.code}
              onChange={handleInput}
              required
              placeholder="Enter confirmation code"
            />
          </div>

          <div className="font-bold w-full text-white  h-32   flex justify-around items-center mt-14  mb-14">
            <button
              style={styles.input}
              id="next"
              disabled={isLoading}
              type="submit"
              className={`w-full h-14 flex justify-center items-center  rounded-sm text-white font-bold ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              }  mt-1`}
              variant="warning"
              size="lg"
            >
              {isLoading ? (
                <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
              ) : (
                <span className="text-white">Confirm</span>
              )}
            </button>
            {/* <Button
             style={styles.button}
               onClick={handleShow2}
               className="h-14  w-full   rounded-md font-bold bg-qosorange"
             >
              <span>Next</span>
            </Button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
