import { Route, Switch } from "react-router";
import { PrivateRoute } from "./Private.routes";
import { paths } from "../common/utils/path";
import MainLayout from "../layouts/mainLayout";
import RegisterPage from "../pages/public/register";
import LoginPage from "../pages/public/login";
import { Dashboard } from "../pages/public/dashboard";
import Home from "../pages/Home";
import { Credentials } from "../pages/credentials";
import { TransactionsByPeriod } from "../pages/transactionsByPeriod";
import { Statements } from "../pages/statements";
import { Transfer } from "../pages/transfer";
import { Transferts } from "../pages/transferts";
import { Settings } from "../pages/settings";
import { Analytics } from "../pages/analytics";
import { Mailing } from "../pages/mailing";
import Customers from "../pages/Customers";
import Offer from "../pages/Offer";
import { PaymentLinks } from "../pages/paymentLink";
import Paycomponent from "../components/paycomponent";
import PayLayout from "../layouts/payLayout";
import PassLayout from "../layouts/PassLayout";
import TermsAndCondition from "../components/termsAndConditions";
import PayLayoutSimple from "../layouts/payLayoutSimple";
import Paycomponentsimple from "../components/paycomponentsimple";
import MailComfirm from "../components/mailComfirm";
import MailLayout from "../layouts/mailLayout";
import PasswordReset from "../pages/passwordReset";
import Passreset from "../components/passReset";
import ResponsePage from "../pages/responsePage";
import SuccessLayout from "../layouts/successLayout";
import AccountCreated from "../pages/accountCreated";
import { GetStarted } from "../pages/getStarted";
import { Status } from "../pages/status";
import { TypeBusiness } from "../pages/typeBusiness";
import IndividualBusness from "../pages/individualBusness";
import RegisterBusness from "../pages/registerBusness";
import AuthenticatorPage from "../pages/public/login/authenticator";
import PublicLayout from "../layouts/publicLayout";
import { Singletransfer } from "../pages/singletransfer";
import { BulkTransfer } from "../pages/bulktransfer";
import Store from "../pages/Store";
import ResendVerificationEmail from "../pages/resendVerificationEmail";
import { TopUp } from "../pages/TopUp";
import { TopUpPage } from "../pages/TopUpPage";
import { PaymentLinkDetails } from "../pages/paymentLinkDetails";

export const Routes = () => {
  return (
    <Switch>
      <PrivateRoute exact={true} path={paths.dashboard}>
        <MainLayout>
          <Dashboard />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.home}>
        <MainLayout>
          <Home />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.Offer}>
        <MainLayout>
          <Offer />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.paymentlink}>
        <MainLayout>
          <PaymentLinks />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.Customers}>
        <MainLayout>
          <Customers />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.transaction.byPeriod.path}>
        <MainLayout>
          <TransactionsByPeriod />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.transaction.statment.path}>
        <MainLayout>
          <Statements />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.analytics.path}>
        <MainLayout>
          <Analytics />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.transfer.process.path}>
        <MainLayout>
          <Transfer />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.transfers}>
        <MainLayout>
          <Transferts />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.MyStore}>
        <MainLayout>
          <Store />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.Singletransfer}>
        <MainLayout>
          <Singletransfer />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.TopUp}>
        <MainLayout>
          <TopUp />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.TopUpPage}>
        <MainLayout>
          <TopUpPage />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.Bulktransfer}>
        <MainLayout>
          <BulkTransfer />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.transfer.credentials.path}>
        <MainLayout>
          <Credentials />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.setting.general}>
        <MainLayout>
          <Settings />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.paymentlink}>
        <MainLayout>
          <PaymentLinks />
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.paymentlinkdetails}>
        <MainLayout>
       <PaymentLinkDetails/>
        </MainLayout>
      </PrivateRoute>
      <PrivateRoute exact={true} path={paths.mailing}>
        <MainLayout>
          <Mailing />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.getstarted}>
        <MainLayout>
          <GetStarted />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.typeofbusiness}>
        <MainLayout>
          <TypeBusiness />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.status}>
        <MainLayout>
          <Status />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.individual}>
        <MainLayout>
          <IndividualBusness />
        </MainLayout>
      </PrivateRoute>

      <PrivateRoute exact={true} path={paths.register}>
        <MainLayout>
          <RegisterBusness />
        </MainLayout>
      </PrivateRoute>

      <Route
        exact={true}
        path="/"
        render={() => (
          <PublicLayout>
            <LoginPage />
          </PublicLayout>
        )}
      />
      <Route
        exact={true}
        path="/signup"
        render={() => (
          <PublicLayout>
            <RegisterPage />
          </PublicLayout>
        )}
      />
      <Route
        exact={true}
        path="/authenticator"
        render={() => (
          <PublicLayout>
            <AuthenticatorPage />
          </PublicLayout>
        )}
      />

      {/* <Route exact={true} path="/getstarted" render={() => <GetStarted/> }  /> */}
      <Route
        exact={true}
        path="/PasswordReset"
        render={() => (
          <PublicLayout>
            <PasswordReset />
          </PublicLayout>
        )}
      />

      <Route
        exact={true}
        path="/ResponsePage/:id"
        render={() => (
          <SuccessLayout>
            <ResponsePage />
          </SuccessLayout>
        )}
      />

      <Route
        exact={true}
        path="/qos/payment"
        render={() => (
          <PayLayoutSimple>
            <Paycomponentsimple />
          </PayLayoutSimple>
        )}
      />

      <Route
        exact={true}
        path="/AccountCreated"
        render={() => (
          <SuccessLayout>
            <AccountCreated />
          </SuccessLayout>
        )}
      />

      <Route
        exact={true}
        path="/resendemailverify"
        render={() => (
          <SuccessLayout>
            <ResendVerificationEmail />
          </SuccessLayout>
        )}
      />

      <Route
        exact={true}
        path="/register/:id"
        render={() => (
          <PublicLayout>
            <MailComfirm />
          </PublicLayout>
        )}
      />

      <Route
        exact={true}
        path="/verify/:id"
        render={() => (
          <PublicLayout>
            <Passreset />
          </PublicLayout>
        )}
      />

      <Route
        exact={true}
        path="/termse"
        render={() => (
          <PayLayoutSimple>
            <TermsAndCondition />
          </PayLayoutSimple>
        )}
      />

      <Route
        exact={true}
        path="/terms"
        render={() => (
          <PayLayout>
            <TermsAndCondition />
          </PayLayout>
        )}
      />

      <Route
        path="/:handle"
        render={() => (
          <PayLayout>
            <Paycomponent />
          </PayLayout>
        )}
      />
    </Switch>
  );
};
