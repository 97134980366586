import React, { useEffect, useState } from "react";
import Wrapper from "./sectionWrapper.component";
import { FetchData } from "../common/services/httpServices";
import { Link } from "react-router-dom";
import { Endpoints } from "../common/utils/endpoints";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import { Spinner } from "../components/spinner.component";
import { useFormData } from "../common/utils/hooks";
import { PasswordReset } from "../common/auth";
import { useHistory } from "react-router";

export default function Passreset() {
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const [mail, setMail] = useState();

  const location = useLocation();

  const code = location?.search.split("=")[1].split(".")[0];

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      // console.log("user Data : ", data)
      const { data: user, status } = await PasswordReset(data, code);
      // console.log("un user authData : ", user)
      // console.log("un user authSatus : ", status)
      // console.log("status de lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseStatus)

      if (user.responseStatus === true) {
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
        history.push("/");
      } else if (user.responseStatus === false) {
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      history.push("/");
    } catch (error) {
      // console.log("erreur :", error)
      // return setTimeout( () => {
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'error',
      //     title: 'Mail invalid',
      //     showConfirmButton: false,
      //     timer: 5500
      //   });
      // }, "100")
      return history.push(
        "/",
        setTimeout(() => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "error",
            showConfirmButton: false,
            timer: 5500,
          });
        }, "100")
      );
    }
    setLoading(false);

    // setTimeout(window.location.reload(), 5000);
    // setTimeout(function(){window.location.reload()} , 5500);
  }
  // <Wrapper className="">
  //     <div className="bg-white border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2  ">
  //       <div className="w-full p-4  flex bg-white justify-center items-center ">
  //         <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
  //           <h2
  //             className="text-center md:text-mH2"
  //             dangerouslySetInnerHTML={{ __html: Infos.msg.reset }}
  //           ></h2>
  //         </span>
  //       </div>
  //       <form onSubmit={handleSubmit} className="  w-full ">
  //         <div className="mx-auto  lg:w-8/12 mt-8">
  //           <span className="block">{Infos.input.email.label}</span>
  //           <input
  //             style={styles.input}
  //             onChange={handleInput}
  //             className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
  //             value={data.mail}
  //             name="mail"
  //             type="email"
  //             required
  //             placeholder={Infos.input.email.emaile}
  //           />
  //         </div>

  //         <button
  //           disabled={isLoading}
  //           style={styles.button}
  //           className={`w-full xl:w-8/12 lg:w-8/12   flex justify-center items-center  text-white text-opacity-80  ${
  //             isLoading ? "bg-coolGray-300" : "bg-qosorange"
  //           } bg-opacity-80 p-3 btn-sm  mx-auto mt-14`}
  //         >
  //           {isLoading ? (
  //             <Spinner className="border-qosorange h-4 w-4 mx-1" />
  //           ) : (
  //             <div className="flex gap-2  justify-center align-center">
  //               <span>{btnMsg.request}</span>
  //               <span className="fi-rr-arrow-right mt-1"></span>
  //             </div>
  //           )}
  //         </button>

  //         <div className="inline-block mb-10  mt-4 text-center w-full justify-between justify-center align-center lg:mx-auto lg:w-8/12  lg:flex lg:text-center ">
  //           <p className="text-center flex  pr-2">
  //             Remember your password?
  //             <Link
  //               to="/"
  //             >
  //               <p className="text-qosorange underline ml-1">Log in</p>
  //             </Link>
  //           </p>
  //         </div>
  //       </form>
  //     </div>
  //   </Wrapper>

  return (
    <Wrapper>
      <div className="bg-white border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2  ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
            <h2
              className="text-center md:text-mH2"
              // dangerouslySetInnerHTML={{ __html: Infos.msg.reset }}
            >
              {" "}
              Password reset
            </h2>
          </span>
        </div>

        <form method="POST" onSubmit={handleSubmit} className="px-4">
          <div className="mx-auto  lg:w-8/12 mt-8">
            <span className="block">New password</span>
            <input
              style={styles.input}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              placeholder="Enter new password"
              type="text"
              id="name"
              autoComplete="name"
              required
            />
          </div>

          <div className="mx-auto  lg:w-8/12 mt-8">
            <span className="block">Confirm new password</span>
            <input
              onChange={handleInput}
              placeholder="Enter new password"
              type="text"
              name="password"
              id="name"
              autoComplete="name"
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
            />
          </div>

          <button
            style={styles.button}
            disabled={isLoading}
            className={`w-full  xl:w-8/12 lg:w-8/12   flex justify-center items-center  text-white   ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            } p-3 btn-sm  mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>Request</span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>
          {/* <p className="mt-4 text-center">
            You don't have an account{" "}
            <Link
              className="text-qosblue underline inline-block ml-2"
              to="/signup"
            >
              Create an account
            </Link>
          </p> */}
        </form>
      </div>
    </Wrapper>
  );
}
const styles = {
  input: {
    // width:680,
    // height:"42px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: 54,
    borderRadius: 8,
  },
};
