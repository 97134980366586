import { media } from "../common/libs/media";
import { useSideBarContext } from "../common/utils/context";
import { Link } from "react-router-dom";
import { paths } from "../common/utils/path";

const { qosLogoBlack } = media.Logos.qos;

export function Header() {
  const { Active, setActive } = useSideBarContext();


  
//   const username = useMemo(() => getUser("username"), []);
  return (
    <header className="w-full absolute top-0 shadow-md bg-white rounded-none items-center h-16 z-50" >
      <div
        className={`relative z-20 flex items-center   h-full  px-6 mx-auto  md:${
          Active ? "md:container md:mx-auto" : "mx-auto"
        }`}

        style={styles.section1}
      >
        <div
          onClick={() => setActive((prev) => !prev)}
          className="relative h-8 mr-2 cursor-pointer p-1 flex "
          style={styles.icon}
        >
          <span
            className={`${
              !Active ? "fi fi-rr-indent hover:text-qosorange" : "fi-rr-arrow-left hover:text-qosorange"
            } text-mH2`}
          ></span>
        </div>


        <div
          className={`relative justify-items-center text-center align-items-center content-center  pl-1 flex w-full lg:max-w-screen-lg mx-auto  ${
            Active ? "justify-end, " : "justify-between"
          }`} style={styles.section2}
        >
          
          <div className={`h-8 mr-8 text-center top-5 content-center  justify-center align-center ${Active ? "hidden" : ""}`} 
              style={styles.ImageWrapper}>
                    <div style={styles.ImageSection}>
                    <Link to={paths.dashboard}>
                        <img src={qosLogoBlack} alt="" className="h-full w-auto text-center" style={styles.imageInner} />
                      </Link>
                    </div>
            
          </div>

          <div className={`flex items-center justify-end mr-5 pr-5 ${Active ? "justify-end lg:container lg:mx-auto right-9" : ""}`}   
              style={styles.section3}>
           
            {/* <button
              onClick={signup}
              data-for="signup"
              data-tip
              className="mx-2 p-2 text-gray-900 hover:text-qosorange"
            >
              <span className="fi-rr-user"></span>
              <span className=" text-lg text-gray-900 "> Business signup</span>
            </button> */}
            {/* <ReactTooltip
              id="signup"
              place="center"
              effect="solid"
              backgroundColor="gray"
              textColor="white"
            >
              signup
            </ReactTooltip> */}


            {/* <button
              onClick={signin}
              data-for="signin"
              data-tip
              className="mx-2 relative text-black hover:text-qosorange"
            >
                <span className="fi-rr-sign-in"></span>
              <span className=" text-lg inset-x-0 top-0 p-2 text-gray-900">Signin</span>
            </button> */}
            {/* <ReactTooltip
              id="signin"
              place="left"
              effect="solid"
              backgroundColor="gray"
              textColor="white"
            >
              Login
            </ReactTooltip> */}
          </div>
        </div>
      </div>
    </header>
  );
}

const styles = {
  section1:{
    width: "100%",
    color: "#292b2c",
    padding: "0 2em",
  },
  icon:{
    justifyItems:'start',
    alignItems:'start',
    width: "50%",
    padding: "0 2em"
  },
  section2:{
    width: "100%",
    height:'100%',
    color: "#292b2c",
    padding: "0 2em"
  },
  ImageWrapper:{
    width: "30%",
    height: '100%',
    justifyContent:'center',
    alignItems:'center',
    alignContent:'center',
  },
  ImageSection:{
    width: "90%",
    height: '80%',
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
  },
  section3:{
    width: "50%",
  },
  imageInner:{
    paddingTop:"15px",
    alignSelf:'center',
  },


}
