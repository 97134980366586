import React from "react";
import ReactDOM from "react-dom";
import "react-phone-input-2/lib/style.css";
import "flatpickr/dist/themes/material_green.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

import App from "./App";
import { Provider } from "react-redux";
import { SideBarStateProvider, ThemeProvider } from "./common/utils/context";
import LangsProvider from "./components/translateContext";
import store from "./store";
import { BrowserRouter as Router } from "react-router-dom";

const Root = document.getElementById("root");

if (Root.hasChildNodes()) {
  ReactDOM.hydrate(
    <React.StrictMode>
      <LangsProvider>
        <ThemeProvider>
          <Provider store={store}>
            <Router>
              <SideBarStateProvider>
                <App />
              </SideBarStateProvider>
            </Router>
          </Provider>
        </ThemeProvider>
      </LangsProvider>
    </React.StrictMode>,
    Root
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <LangsProvider>
        <ThemeProvider>
          <Provider store={store}>
            <Router>
              <SideBarStateProvider>
                <App />
              </SideBarStateProvider>
            </Router>
          </Provider>
        </ThemeProvider>
      </LangsProvider>
    </React.StrictMode>,
    Root
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
