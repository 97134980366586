import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { media } from "../common/libs/media";
import { FetchData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  useParams,
} from "react-router-dom";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { BiMoney, BiTransferAlt } from "react-icons/bi";
import clsx from "clsx";
import { IconLink } from "./iconLink.component";
import { paths } from "../common/utils/path";
import sidebar from "./translation/sidebar.json";
import { useSideBarContext } from "../common/utils/context";
import { encryptStorage } from "../common/services/encryptData";
import { useLangContext } from "./translateContext";
const { qosLogoBlack } = media.Logos.qos;
const { Qlogo } = media.Logos.qos;

export function Sidebar() {
  const { Active } = useSideBarContext();
  const [toggle, setToggle] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [toggl, setToggl] = useState(toggle);
  let location = useLocation();
  let { slug } = useParams();
  // console.log("My location:", location.pathname);
  // console.log("My slug:", slug);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { Lang } = useLangContext();
  const Infos = { msg: sidebar[Lang] };

  const UserProfile = () => {
    FetchData(Endpoints.User.meNew).then((res) => {
      // console.log("resposnse profile:", res);
      setData(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    UserProfile();
  }, []);

  const macc =
    loading && data?.data?.datas.state === "INACTIVE"
      ? "loading"
      : data?.data?.datas.state;
  // console.log("macc data:", macc);
  // console.log("profile data:", macc);
  // encryptStorage.getItem("qosUserLog");

  // console.log("encryptStorage. :", encryptStorage.getItem("qosUserLog"));
  const links = [
    {
      name: `${Infos.msg.Overview}`,
      href: `${paths.dashboard}`,
      icon: "bx:home-alt",
    },
    {
      name: `${Infos.msg.Transactions}`,
      href: `${paths.transaction.statment.path}`,
      icon: "ic:outline-payment",
    },
    {
      name: `${Infos.msg.PaymentLink}`,
      href: `${paths.paymentlink}`,
      icon: "mdi:link",
    },
    {
      name: `${Infos.msg.Transfer}`,
      href: `${paths.transfers}`,
      icon: "tabler:send",
    },
    {
      name: `${Infos.msg.TopUp}`,
      href: `${paths.TopUpPage}`,
      icon: "mdi:wallet-plus",
    },
    {
      name: `${Infos.msg.Payout}`,
      href: `${paths.transfer.process.path}`,
      icon: "icons8:down-round",
    },
    {
      name: `${Infos.msg.Store}`,
      href: `${paths.MyStore}`,
      icon: "ic:outline-local-grocery-store",
    },
    {
      name: `${Infos.msg.Settings}`,
      href: `${paths.setting.general}`,
      icon: "uil:setting",
    },
  ];

  const Secondlinks = [
    {
      name: `${Infos.msg.getstarted}`,
      href: `${paths.getstarted}`,
      icon: "tabler:world",
    },
    {
      name: `${Infos.msg.Overview}`,
      href: `${paths.dashboard}`,
      icon: "bx:home-alt",
    },
    {
      name: `${Infos.msg.Transactions}`,
      href: `${paths.transaction.statment.path}`,
      icon: "ic:outline-payment",
    },
    {
      name: `${Infos.msg.PaymentLink}`,
      href: `${paths.paymentlink}`,
      icon: "mdi:link",
    },
    {
      name: `${Infos.msg.Transfer}`,
      href: `${paths.transfers}`,
      icon: "tabler:send",
    },
    {
      name: `${Infos.msg.TopUp}`,
      href: `${paths.TopUpPage}`,
      icon: "mdi:wallet-plus",
    },
    {
      name: `${Infos.msg.Payout}`,
      href: `${paths.transfer.process.path}`,
      icon: "icons8:down-round",
    },
    {
      name: `${Infos.msg.Store}`,
      href: `${paths.MyStore}`,
      icon: "ic:outline-local-grocery-store",
    },
    {
      name: `${Infos.msg.Settings}`,
      href: `${paths.setting.general}`,
      icon: "uil:setting",
    },
  ];

  const filteredLinks =
    encryptStorage.getItem("qosUserLog").typeOfBusiness === "REGISTREDBUSINESS"
      ? links
      : links.filter(
          (link) =>
            link.name !== `${Infos.msg.Transfer}` &&
            link.name !== `${Infos.msg.TopUp}`
        );

  const filteredSecLinks =
    encryptStorage.getItem("qosUserLog").typeOfBusiness === "REGISTREDBUSINESS"
      ? Secondlinks
      : Secondlinks.filter(
          (link) =>
            link.name !== `${Infos.msg.Transfer}` &&
            link.name !== `${Infos.msg.TopUp}`
        );
  return (
    <div className="bg-white max-h-screen z-50">
      <div
        className={`flex sidebar relative ${
          Active ? "active" : ""
        } flex-col min-h-screen  sm:flex-row`}
      >
        <div className="bottom-px mt-3.5 w-full pl-8">
          <div className="flex items-center max-w-max">
            <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
              <Link to={paths.dashboard}>
                {Active ? (
                  <img src={qosLogoBlack} alt="" className="h-full w-auto" />
                ) : (
                  <img src={Qlogo} alt="" className="h-full w-auto" />
                )}
              </Link>
            </span>
          </div>
          <div className="bg-qosOrageopacity ">
            {Active ? (
              <>
                <p style={{fontSize:20}} className="mt-3 p-1 font-bold  text-[100] text-qosorange">
                  {encryptStorage.getItem("qosUserLog").user?.company_name.charAt(0).toUpperCase()+ encryptStorage.getItem("qosUserLog").user?.company_name.slice(1) }
                </p>

                <p style={{fontSize:14}} className=" p-1 font-bold text-qosorange">
                  {encryptStorage.getItem("qosUserLog").user?.merchandId ===
                  null
                    ? ""
                    : encryptStorage.getItem("qosUserLog").user?.merchandId}
                </p>
                <p style={{fontSize:15}} className=" p-1 text-qosText">
                  {encryptStorage.getItem("qosUserLog")?.typeOfBusiness ===
                  null ? "" : encryptStorage.getItem("qosUserLog")?.typeOfBusiness}
                </p>
               
              </>
            ) : (
              ""
            )}
          </div>

          <nav className="mt-2 w-full h-fit">
            {macc === "INACTIVE" ? (
              <ul
                className="w-full grid grid-flow-row gap-y-1"
                style={{ width: "100%" }}
              >
                {filteredSecLinks.map((link) => {
                  const LinkIcon = link.icon;
                  return (
                    <div key={link.name}>
                      <Link
                        to={link.href}
                        className={clsx(
                          "flex h-[48px] cursor-pointer items-center gap-2 rounded-md bg-gray-50 p-3 text-sm font-medium",
                          {
                            "bg-qosorange": location.pathname === link.href,
                          }
                        )}
                      >
                        <Icon
                          icon={LinkIcon}
                          height={24}
                          style={{ color: "black", fontSize: "20px" }}
                        />
                        <p
                          className={`text-dsm transition-all duration-100 inline-block overflow-hidden ${
                            !Active ? "lg:w-0" : "lg:w-full"
                          } text-black text-opacity-70`}
                        >
                          {link.name}
                        </p>
                      </Link>
                    </div>
                  );
                })}
              </ul>
            ) : macc === "ACTIVE" ? (
              <ul
                className="w-full grid grid-flow-row gap-y-1"
                style={{ width: "100%" }}
              >
                {filteredLinks.map((link) => {
                  const LinkIcon = link.icon;
                  return (
                    <div key={link.name}>
                      <Link
                        to={link.href}
                        className={clsx(
                          "flex h-[48px] cursor-pointer items-center gap-2 rounded-md bg-gray-50 p-3 text-sm font-medium transition-all duration-100",
                          {
                            "bg-qosorange": location.pathname === link.href,
                          },
                          Active && "hover:translate-x-2"
                        )}
                      >
                        <Icon
                          icon={LinkIcon}
                          height={24}
                          style={{ color: "black", fontSize: "20px" }}
                        />
                        <p
                          className={`text-dsm transition-all duration-100 inline-block overflow-hidden ${
                            !Active ? "lg:w-0" : "lg:w-full"
                          } text-black text-opacity-70 group-hover:text-opacity-100`}
                        >
                          {link.name}
                        </p>
                      </Link>
                    </div>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </nav>
          <div className="absolute bottom-px">
            <a
              href="https://qosic.com/terms-and-conditions/"
              className="flex items-center group py-2 hover:shadow-md rounded-r-full w-full"
            >
              <span className="fi-rr-info text-mH3 opacity-90 font-light inline-block mr-4 mt-2"></span>
              <span
                className={`text-msm block overflow-hidden ${
                  !Active ? "lg:w-0" : "lg:w-full"
                } text-qosdark text-opacity-70 group-hover:text-opacity-100`}
              >
                {Infos.msg.Termsandconditions}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  border: {
    borderRadius: "5px",
    width: "100%",
  },
};

const Dropdown = ({ name, toggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [toggl, setToggl] = useState(toggle);

  // console.log("toggl", toggl);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full pl-3 py-3 font-medium text-left text-gray-600 hover:bg-gray-100 hover:text-gray-800 focus:outline-none"
      >
        <BiTransferAlt size={25} />
        {/* <IconLink
                    icon={`fi-rr-shopping-bag`}
                    title={Infos.msg.Offer}
                    to={paths.Offer}
                  /> */}
        <span className="pl-4 font-semibold">{name}</span>
        <span className="ml-auto pl-2">
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 transition-transform transform rotate-0"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 9l-7 7-7-7" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 transition-transform transform "
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 15l7-7 7 7" />
            </svg>
          )}
        </span>
      </button>
      {isOpen && (
        <div className="relative right-0 mt-1 py-1 pl-3 w-48 bg-white rounded-md ">
          {/* Dropdown content goes here */}
          <ul className="space-y-2">
            <li className="focus:ring-qosorange focus:border-qosorange">
              <IconLink
                icon="fi-rr-money"
                title="Withdrawal"
                // to={paths.payout}
                to={paths.transfer.process.path}
              />
            </li>
            <li
              onClick={() => setToggl(1)}
              className={`${
                toggl === 1 ? "bg-qoswhite text-white" : "bg-white"
              } `}
              style={styles.border}
            >
              <IconLink
                icon="fi-rr-money"
                title="Payout"
                // to={paths.caliName}
                to={paths.transfers}
              />
              {/* <BiMoney size={25}  to={paths.caliName} /> */}
            </li>
          </ul>
        </div>
      )}
    </li>
  );
};
