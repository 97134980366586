
// import { useMemo } from "react";
import { useState,  
  // useRef, 
} from "react";
// import { FetchData, PutData, PostData } from "../common/services/httpServices";
// import { http } from "../common/services/httpServices";
// import { Endpoints } from "../common/utils/endpoints";
// import { useFormData } from "../common/utils/hooks";
// import { getUser } from "../common/utils/tools";
// import { Credentials } from "../pages/credentials";
// import { encryptStorage } from "../common/services/encryptData";
// import { TabledocumentUser } from "../components/tables/tabledocumentUser";
import { TabledocumentAdmin } from "../components/tables/tabledocumentAdmin";




export default function Customers() {
  const [state, setstate] = useState(0);
  return (
    <section>
      <header className="">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-24 sm:w-32 md:w-44  text-white h-full bg-qosblue opacity-70 rounded-full p-2 md:px-4`}
          ></div>
          <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            Users
          </button>
          <button
            onClick={() => setstate(1)}
            className={`transition-all duration-500 ${
              state === 1 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            Documents
          </button>
         
         
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <Users />}
          {state === 1 && <Document />}
        </section>
      </div>
    </section>
  );
}


function Users() {
  



  return (
    <div>
    <section className="lg:flex flex-row  bg-white my-8 p-4 gap-3 w-100 bg-qosgray sm:block justify-center w-100 md:block w-100 justify-center">
        <div className="w-1/2 sm:w-full min-w-100 md:w-full  lg:w-full">
          <h2>Qos</h2>
        
        </div>

      
    </section>



    </div>
  );
}

function Document() {
  return (
    <div className="max-w-screen-xl relative overflow-hidden bg-white mx-auto my-2">
      <TabledocumentAdmin/>
    </div>
  );
}











