import React, { useState } from "react";
import { useHistory } from "react-router";
import Wrapper from "../components/sectionWrapper.component";
import Messages from "./public/login/translations/index.json";
import { useLangContext } from "../components/translateContext";
import buttonText from "../translations/button.json";
import { Link } from "react-router-dom";
import InputsText from "../translations/input.json";
import { ResetPassword } from "../common/auth";
import { useFormData } from "../common/utils/hooks";
import { Spinner } from "../components/spinner.component";
import Swal from "sweetalert2";

export default function PasswordReset() {
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const btnMsg = buttonText[Lang];


  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      // console.log("user Data : ", data);
      const { data: user, status } = await ResetPassword(data);
      // console.log("un user authData : ", user);
      // console.log("un user authSatus : ", status)
      // console.log("status de lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseStatus)

      if (user.responseStatus === true) {
        setLoading(false);
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (user.responseStatus === false) {
        setLoading(false);
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // console.log("erreur :", error);
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Mail invalid",
          showConfirmButton: false,
          timer: 5500,
        });
      }, "100");
    }
    setLoading(false);

    // setTimeout(window.location.reload(), 5000);
    setTimeout(function () {
      window.location.reload();
    }, 5500);
  }

  return (
    // <Wrapper
    // style={{
    //     background: "url('./pictures/authpageBackground.png')",
    //     backgroundSize: "80% .12%",
    //   }}>

    // <div className="w-12/12 max-w-md bg-coolGray-50 shadow-xl py-4 mx-auto my-20 rounded-xl">
    // <h2
    //   className="text-center md:text-mH2"
    //   dangerouslySetInnerHTML={{ __html: Infos.msg.reset }}
    // ></h2>
    // <form className="px-4" onSubmit={handleSubmit} >
    //   <div className="mx-auto  w-full md:max-w-sm mt-8">
    //     <input
    //     name="mail"
    //     type="email"
    //     onChange={handleInput}
    //     placeholder="Email" />

    //     <button type="submit"  className="bg-qosblue p-1 m-2 text-coolGray-100 rounded mt-4 text-center" >
    //     {isLoading ? (
    //       <Spinner className="border-qosorange h-4 w-4 mx-1" />
    //     ) :
    //       "Send"
    //     }
    //       </button>
    //     or
    //     <button className="bg-qosred m-2 p-1 text-coolGray-100 rounded" >
    //     <Link
    //       className="inline-block "
    //       to="/"
    //     >
    //       Cancel
    //     </Link>
    //     </button>
    //   </div>
    //   </form>

    // </div>
    // </Wrapper>

    <Wrapper className="">
      <div className="bg-white border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2  ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
            <h2
              className="text-center md:text-mH2"
              dangerouslySetInnerHTML={{ __html: Infos.msg.reset }}
            ></h2>
          </span>
        </div>
        <form onSubmit={handleSubmit} className="  w-full ">
          <div className="mx-auto  lg:w-8/12 mt-8">
            <span className="block">{Infos.input.email.label}</span>
            <input
              style={styles.input}
              onChange={handleInput}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              value={data.mail}
              name="mail"
              type="email"
              required
              placeholder={Infos.input.email.emaile}
            />
          </div>

          <button
            disabled={isLoading}
            style={styles.button}
            className={`w-full xl:w-8/12 lg:w-8/12   flex justify-center items-center  text-white text-opacity-80  ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            } bg-opacity-80 p-3 btn-sm  mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>{btnMsg.request}</span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>

          <div className="inline-block mb-10  mt-4 text-center w-full justify-between justify-center align-center lg:mx-auto lg:w-8/12  lg:flex lg:text-center ">
            <p className="text-center flex  pr-2">
              {Infos.msg.Go}
              <Link
                // className="text-qosorange underline "
                to="/"
              >
                <p className="text-qosorange underline ml-1">{btnMsg.logInButton}</p>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
