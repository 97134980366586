import { AdresseFilesUploader,  } from "../pages/registerBusness";

function AdresseDocument() {
  return (
    <div className="">
      <div className="flex text-left w-auto border-black ">
        <p className="text-qosdark">Upload proof of address</p>
      </div>
      <div className="flex w-auto">
        {/* <IdFilesUploader selec={selec.selects} className="w-full" text="uploads your Id " /> */}
        {/* <RccmFilesUploader  className="w-full" text="Registration Document"/> */}
        {/* <TaxFilesUploader className="w-full" text="Tax Registration"/> */}
        <AdresseFilesUploader className="w-full text-qosText" text="Upload document" />
      </div>
    </div>
  );
}

export default AdresseDocument;
