import { useState, useMemo, useEffect } from "react";
import {
  BsFillFlagFill,
  BsAppIndicator,
  BsFillDiscFill,
  BsFillFileTextFill,
  BsWhatsapp,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { TabledocumentUser } from "../components/tables/tabledocumentUser";
import Documents from "../partials/dashboard/Documents";
import { Icon } from "@iconify/react";
import { Line, Circle } from "rc-progress";
import Messages from "./translations/status.json";
import { useLangContext } from "../components/translateContext";

export function Status() {

  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang] };

  return (
    <section className="bg gap-4 m-10">
      <div className="flex text-left gap-1">
        <h4 className="pl-10 ">{Infos.msg.Title0} </h4>
      </div>
      <div className="pl-10 ">
        <p className="pt-2">{Infos.msg.Title1}</p>
      </div>
      {/* <div className="grid  justify-center gap-2  mb-16 text-center">
        <h4 className="text-qosorange">Welcome to Qos</h4>
        <p className="text-black">Check status</p>
      </div> */}

      {/* <div className=" mt-5 rounded-lg flex justify-around ">
        <div className="bg-WhiteG  filter drop-shadow-md  rounded flex flex-col p-8 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillFlagFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Activate your account Information about your company</p>
          </div>
          <Link to="/typeofbusiness">
            <button className="bg-qosgreen min-w-max w-36 font-bold mx-auto block p-2 text-white rounded font-medium">
              Go live
            </button>
          </Link>
        </div>

        <div className="bg-WhiteG  filter drop-shadow-md  rounded flex flex-col p-8 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillDiscFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Choose your business type</p>
          </div>
        </div>

        <div className="bg-WhiteG  filter drop-shadow-md  rounded flex flex-col p-8 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillHandThumbsUpFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>
              After submitting compliance details to accept live payment check
              status
            </p>
          </div>
          <Link to="/typeofbusiness">
            <button className="bg-qosorange  min-w-max w-36 font-bold mx-auto block p-2 text-white rounded font-medium">
              status
            </button>
          </Link>
        </div>
      </div> */}

      {/* <div className="bg-white  filter drop-shadow-md rounded-lg mb-14 mt-14 flex gap-4 justify-around ">
      <div 
        style={{ borderRadius: "22px" }}
        className="bg-white mt-8  mb-8 filter drop-shadow-lg  rounded-lg flex flex-col w-2/5 p-8 justify-center items-center justify-items-center content-center ">
        <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillFileTextFill color="white" width={40} height={40} />
          </div>
          <div className="grid mt-4 justify-center gap-1 mb-2 text-center">
            <h4 className="text-black pb-1">API Documentation</h4>
            <p className="text-black ">
              Our documentation contains the libraries APIs.
            </p>
            <p className="text-black">
              you need to integrate QosPay in your website or app.
            </p>
          </div>

          <a
           style={{ borderRadius: "10px" }}
            href="https://qosic.com/docs/"
            className="bg-qosorange min-w-max w-36 mt-3 font-bold mx-auto block py-2 px-7 text-white rounded font-medium"
          >
           View Docs
          </a>
        </div>

        <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-8 w-2/5   gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsAppIndicator color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Your Status</p>
          </div>
      

          <div className="flex">
              <label
                disabled
                style={{ borderRadius: "10px" }}
                className="bg-qosred min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
              >
                In_CHECKING
              </label>

            
            </div>
        </div>

      
      </div> */}

      <div className="rounded m-10 gap-4 rounded-lg flex justify-between ">
        <div
          // style={styles.box}
          className="bg-white p-10  border rounded-lg flex flex-col  w-1/2   gap-4 "
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              <Icon
                icon="fluent-mdl2:sync-status"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Account}</h5>
            </div>
          </div>
          <div className="">
            <span className="text-qosText">
            {Infos.msg.AccountText}
            </span>
          </div>

          <div
            style={{ marginTop: -10 }}
            className="h-full  w-full flex justify-center align-center text-center "
          >
            <div className="text-center flex flex-col justify-center align-center ">
              <div>
                <Circle
                  percent={50}
                  strokeWidth={6}
                  trailWidth={6}
                  strokeColor="#1FAF38"
                  className="  transform rotate-1140"
                />
              </div>
              <div className="mt-2 ">
                <span className="text-black text-semibold ">{Infos.msg.In}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          // style={styles.box}
          className="bg-white p-10   border rounded-lg  flex flex-col  w-1/2   gap-4 "
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              <Icon
                icon="bx:support"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Contact}</h5>
            </div>
          </div>
          <div>
            <span className="text-qosText">
            {Infos.msg.ContactText}
            </span>
          </div>

          <div className=" text-center">
            <div className="flex gap-3 justify-center items-center text-center">
              {/* <BsFillEnvelopeFill color="black" width={25} height={70} />  */}
              <Icon
                icon="mdi:envelope-outline"
                height={24}
                style={{ color: "black", fontSize: "20px" }}
              />
              <p className=" ">support@qosic.com</p>
            </div>

            <div className="flex pt-3 gap-3  justify-center items-center ">
              <div
                style={styles.support}
                className="flex gap-3  justify-center items-center"
              >
                <a className=" text-qosorange" href="https://wa.me/22994285672">
                  Chat with us
                </a>
                <BsWhatsapp color="green" width={25} height={70} />
              </div>
            </div>
          </div>
        </div>

        {/* <div
          style={{
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "#E0E0E0",
          }}
          className="bg-white mt-8  mb-8 filter drop-shadow-sm  rounded-lg flex flex-col  w-1/2  gap-4 justify-center items-center justify-items-center content-center "
        >
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)}
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-8 p-8 w-2/5  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillChatSquareDotsFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Contact support</p>
            <div className="flex pt-3 gap-3 justify-center items-center text-center">
              <BsFillEnvelopeFill color="black" width={25} height={70} />
              <p className="text-qosorange font-bold ">support@qosic.com</p>
            </div>

            <div className="flex pt-3 gap-3 justify-left items-center ">
              <BsWhatsapp color="black" width={25} height={70} />
              <a className="bg-green" href="https://wa.me/22994285672">
                Whatsapp
              </a>
            </div>
          </div>
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-3 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillHandThumbsUpFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Check your activation status or Add additional document</p>
          </div>
          { documentList !=0 ? (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>): (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>)  }
        </div> */}
      </div>

      <div className="rounded m-10 p-10 rounded-lg flex justify-center align-center items-center border-sm shadow-sm  bg-white">
        <Documents />
      </div>

      {/* <div className="md:grid relative overflow-hidden mx-auto my-2">
       
      
      </div> */}
      <div className="rounded m-10 p-1 rounded-lg  border-sm shadow-sm  bg-white relative overflow-hidden ">
        <TabledocumentUser />
      </div>
    </section>
  );
}

const styles = {
  button: {
    height: 48,
    width: 200,
    borderRadius: 4,
  },
  support: {
    height: 48,
    width: 200,
    borderRadius: 4,
    borderColor: "orange",
    borderWidth: 1,
  },

  box: {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    borderRadius: 5,
    borderWidth: "1px",
    borderColor: "#E0E0E0",
  },
};
