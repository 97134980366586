import React from "react";
import { Icon } from "@iconify/react";
import Wrapper from "./sectionWrapper.component";

function CommingSoon() {
  return (
    <div className=" w-full">
      <Wrapper className="mt-10">
        <div className="bg-white gap-4 flex-col text-center justify-center content-center justify-items-center items-center border-t shadow-sm border-qosgrain  w-full  mx-auto px-2  my-1  pb-16 lg:px-0  sm:w-full  ">

          <div className="p-4 flex mt-44 text-center justify-center">
            <Icon
              icon="mdi:progress-wrench"
              height={80}
              style={{ color: "green", fontSize: "20px" }}
            />
          </div>

          <div className="p-4 mb-40">
            <span>Coming soon</span>
            <p className="text-qosText">Stay tuned we are working on something amazing</p>
          </div>

         

        </div>
      </Wrapper>
    </div>
  );
}

export default CommingSoon;
