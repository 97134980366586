import React from 'react'
import CommingSoons from '../components/commingSoon'

function Store() {
  return (
    <div className='p-4'>
      {/* <div className="mt-14 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
        Store
        </p>
      </div> */}
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
        Store
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        Sell and manage products
        </span>
      </div>
      <CommingSoons/>
    </div>
  )
}

export default Store