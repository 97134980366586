// import { getUser } from "./tools";
export const columnsTable = [
  {
    name: "SMSISDN",
    accessor: "sendersmsisdn",
  },
  {
    name: "OPERATION TYPE",
    accessor: "operationtype",
  },
  {
    name: "AMOUNT",
    accessor: "amount",
  },
  {
    name: "DATE",
    accessor: "trxndate",
  },
  {
    name: "MESSAGE",
    accessor: "responsemessage",
  },
  {
    name: "CLIENT ID",
    accessor: "clientid",
  },
  {
    name: "CLIENT REF N°",
    accessor: "clientrefno",
  },
  {
    name: "PROCESSING NUMBER",
    accessor: "processingnumber",
  },
];
export const columns = [
  {
    name: "ID",
    accessor: "id",
  },
  {
    name: "NAME",
    accessor: "name",
  },
  {
    name: "AMOUNT",
    accessor: "amount",
  },
  {
    name: "COUNT",
    accessor: "count",
  },
  {
    name: "ACTION",
    accessor: "none",
  },
];

export const paymentLinksHeader = [
  {
    name: "Page Name",
    accessor: "pagename",
  },
  {
    name: "Type",
    accessor: "type",
  },
  {
    name: "Product Name",
    accessor: "productname",
  },
  {
    name: "Quantity",
    accessor: "quantity",
  },
  {
    name: "Amount",
    accessor: "amount",
  },
  {
    name: "Create on",
    accessor: "createOn",
  },
  // {
  //   name: "Links",
  //   accessor: "links",
  // },
  // {
  //   name: "QR code",
  //   accessor: "qrcode",
  // },
];

export const credentialsHeader = [
  {
    name: "KEY",
    accessor: "key",
  },
  {
    name: "VALUE",
    accessor: "value",
  },
];

export const statisticsHeader = [
  {
    name: "Company",
    accessor: "company",
  },
  {
    name: "Contact",
    accessor: "contact",
  },
  {
    name: "Email",
    accessor: "email",
  },
  {
    name: "MNO",
    accessor: "mno",
  },
  {
    name: "Total Transaction",
    accessor: "totaltransaction",
  },
];

/**
 * @desc get table data as json
 * @param data
 * @param columns
 */

export const getTableDataForExport = (data, columns) =>
  data?.map((record) =>
    columns.reduce(
      (recordToDownload, column) => ({
        ...recordToDownload,
        [column.name]: record[column.accessor],
      }),
      {}
    )
  );

/**
 * @desc make csv from given data
 * @param rows
 * @param filename
 */
export const makeCsv = async (rows, filename) => {
  const separator = ";";
  const keys = Object.keys(rows[0]);

  const csvContent = `${keys.join(separator)}\n${rows
    .map((row) =>
      keys
        .map((k) => {
          let cell = row[k] === null || row[k] === undefined ? "" : row[k];

          cell =
            cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator)
    )
    .join("\n")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // In case of IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
