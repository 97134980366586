import { Sidebar } from "../components/sidebar.component";
import { Header } from "../components/header.component";
import { useSideBarContext } from "../common/utils/context";
import { BreadCrumb } from "../components/breadcrumb.component";
export default function MainLayout({ children }) {
  const { Active, setActive } = useSideBarContext();
  return (
    <main className="min-h-screen bg-white  relative flex overflow-hidden">
      <Sidebar />
      <section
        className={`${
          Active ? "w-full" : "w-full"
        } max-h-screen overflow-x-hidden overflow-y-auto pt-16`}
      >
        {/* tioiot */}
        <Header />
        <section
          className={`px-4 ${
            Active ? "mx-0" : "mx-auto"
          } overflow-hidden`}
        >
          {/* <BreadCrumb /> */}
          {children}
        </section>
      </section>
    </main>
  );
}
