// import { Sidebar } from "../components/sidebar.paycomponent";
// import { Header } from "../components/header.paycomponent";
import { backgroundImage } from "tailwindcss/defaultTheme";
import { useSideBarContext } from "../common/utils/context";
// import { BreadCrumb } from "../components/breadcrumb.component";
export default function SuccessLayout({ children }) {
  const { Active, setActive } = useSideBarContext();
  return (
    <main className="min-h-screen relative flex overflow-hidden" 
    style={{  
     
    // background: "url('https://firebasestorage.googleapis.com/v0/b/image-cb355.appspot.com/o/Success.jpg?alt=media&token=0ee004c9-34bf-46fe-8727-5a31326b6778')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor:"#FFF",
    // right: 0,
    // left: 0,
    // position:"absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: 'center',
    
    
  }}
    
    >
  
      {/* <Sidebar /> */}
      
   
      <section
        className={`${
          Active ? "w-full" : "w-full"
        } max-h-screen overflow-x-hidden overflow-y-auto pt-16`}
      >
        {/* <Header /> */}
      
        <section
          className={`px-4 ${
            Active ? "mx-0" : "mx-auto"
          } overflow-hidden`}
        >
      
          {/* koie */}
          {children}
          {/* okaa */}
        </section>
      </section> 
    </main>
  );
}
