
import React, { useState, useEffect, useRef } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useFormData } from "../common/utils/hooks";
import { FetchData, PostData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { SinglePay } from "../common/auth";
import { Spinner } from "./spinner.component";
import { encryptStorage } from "../common/services/encryptData";
import { useLangContext } from "./translateContext";

import Wrapper from "./sectionWrapper.component";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import { TopUp } from "../common/auth";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Messages from "./translations/index.json";
import InputsText from "../translations/input.json";
import CommingSoon from "./commingSoon";
import { useHistory } from "react-router";

export default function TopUpTwo(network) {
  const history = useHistory();
  const [data, handleInput] = useFormData();
  const [loading, setMLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [myCode, setMyCode] = useState(null);
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [response, setResponse] = useState([]);

  const [userMessage, setUserMessage] = useState(null);

  const [listCredential, setListCredential] = useState([]);
  const [listOperators, setlistOperators] = useState([]);
  const [loadingCredential, setLoadingCredential] = useState(true);
  const [newCountry, setNewCountry] = useState("");
  const [autoriseOperator, setAutoriseOperator ] = useState("");

  const [info, setInfo] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [transferType, setTransferType] = useState("");

  const [dataz, setDataz] = useState({
    client_id: "",
  });

  const handleClose = () => setShow(false);
  const handleClose1 = () => {
    setShow(false);
    setShow1(false);
    setUserMessage(null);
  };

  const handleMyCode = (e) => {
    setMyCode(e.target.value);
  };

  const resetHandle = () => {
    handleInput({});
  };

  const inf = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfo(res);
      ListOfCredential({ target: { value: res.data.datas.items[0] } });
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
      setIsLoading(false);
    });
  };

  useEffect(() => {
    inf();
  }, []);

  function ListOfCredential(pays) {
    // console.log("newCountry :", pays.target.value);
    setLoadingCredential(true);

    setNewCountry(pays.target.value);

    if (pays.target) {
      FetchData(
        Endpoints.Credentials.get + "?country=" + pays.target.value
        // Endpoints.Credentials.get + "?country=" + setNewCountry
      ).then((res) => {
        // console.log("res res :",res)
        setListCredential(res);
        setLoadingCredential(false);
      });
    }
  }

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays", pays);
    if (pays.target) {
      FetchData(
        Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
      ).then((res) => {
        // setchosenPays(pays.target.value);
        setlistOperators(res.data.datas.items);
      });
    }
  }

  const username = encryptStorage.getItem("qosUser")?.email;
  const twofa = encryptStorage.getItem("qosUser")?.isUsing2Fa;
  // console.log("twofa", twofa)
  // console.log("listCredential :", listCredential);
  // console.log("dataz :", dataz);
  // console.log("listOperators: ", listOperators);
  // // console.log("data:", data);

  const transfertReference = new Date().getTime();

  let account = { account: dataz.client_id };
  let amount = { amount: data.amount };
  // let channel = { channel: dataz.operator };
  let channel = { channel: data.channel };
  let transRef = { transRef: transfertReference };

  // console.log("transfertReference", transfertReference)
  // console.log("country", country)
  // console.log("operateur type :", account);

  // console.log("transfertReference", transfertReference)
  // console.log("data", data)

  // const infoUser = loading ? "loading" : user?.data?.datas;

  //  console.log("infoUser :", infoUser);

  let Newdata = {
    // ...newoperateur,
    ...account,
    ...amount,
    ...channel,
    ...transRef,
  };

  // console.log("Newdata ", Newdata);
  // console.log("envode myData:", data)

  const handleOperatorChange = (event) => {
    const selectedOperator = event.target.value;
    // console.log("event:", event.target.value);
    // setTransferType(event.target.value);

    let autoriseOperator =  setAutoriseOperator(selectedOperator) ;

    // console.log("event.target.value:", event.target.value)
    setSelectedOperator(selectedOperator);

    // Find the data object for the selected operator
    const selectedData = listCredential?.data?.datas?.items?.find(
      (operatorData) => operatorData.operator === selectedOperator
    );

    // Update the client_id in the state
    setDataz((prevData) => ({
      ...prevData,
      operator: selectedData.operator,
      client_id: selectedData.client_id,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // let newoperateur = { operator: reseau };
    // let Newdata = {
    //   ...data,
    //   ...newoperateur,
    // };
    try {
      // console.log("SinglePay SinglePay : ", Newdata);
      const { data: user } = await TopUp(Newdata);
      // console.log("un user authData : ", user);
      // console.log("mon data : ", data);
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseCode === "00") {
        setIsLoading(false);
       
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
        setShow(true);
        setResponse(user);

        // handleClose();
        // setLoading(false);
        // setTimeout(() => {
        //   history.push("/TopUpPage");
        // }, 6500);
        
      } else if (user.datas.responseCode != "00") {
        setIsLoading(false);
        let message = user.responseMessage;
        return setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
          handleClose();
        }, "100");
      }
    } catch (error) {
      // console.log("erreur :", error);
      setIsLoading(false);
      return setTimeout(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        handleClose();
        Toast.fire({
          icon: "error",
          title: error,
        });
      }, "100");
    }
  }

  const handleShow1 = () => {
    // setIsLoading(true)
    if (
      transferType === "" ||
      transferType === undefined ||
      data.amount === "" ||
      data.amount === undefined ||
      data.accountNumber == "" ||
      data.accountNumber === undefined
    ) {
      // alert("Please first select a file");
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "No available",
      });
      return;
    } else if (twofa === false || twofa === null) {
      setIsLoading(false);
      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 7000,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please! activate 2AF authenticator first",
      });
      return;
    }

    setShow1(true);
  };


//   const getTargetElement = () => document.getElementById("container");

// const downloadPdf = () => generatePDF(getTargetElement, options);
const handleDownloadPDF = () => {
  const input = document.getElementById('pdf-content'); 
  // Specify the id of the element you want to convert to PDF
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 30, 30, 150, 150);
    pdf.save('topUp.pdf'); 
    // Specify the name of the downloaded PDF file
  });
};

  return (
    <div className="">
      <Wrapper className="mt-10">
        <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-2  my-1  pb-16 lg:px-0 md:w-4/6 lg:w-4/6 sm:w-full  ">
          <div className=" my-10">
            <p
              className="pt-2 pl-10 text-semibold "
              style={{ fontSize: 20, color: "#45464E" }}
            >
              New TopUp
            </p>
          </div>

          {/* <pre>{JSON.stringify(dataz, null, 2)}</pre> */}

          <form onSubmit={handleSubmit} className="mt-14 w-full">
            <div className="mx-auto  lg:w-7/12 mt-4">
              <span className="block text-qosText"> Select account</span>

              <div>
                <select
                  onChange={handleOperatorChange}
                  id="account"
                  name="account"
                  autoComplete="account"
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd"
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    className="h-8 text-qosText  px-4 md:w-40 min-w-max bg-qosorange"
                  >
                    Select account
                  </option>

                  {/* <option className="text-qosText">
                      {info?.data?.datas?.items?.[0]}
                    </option> */}
                  {listOperators?.map((item) => (
                    // console.log("",)
                    <option key={item.index} className="text-qosText">
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {autoriseOperator == "MTN"? (<div>

              <div className="mx-auto  lg:w-7/12 mt-4">
              <span className="block">Enter amount</span>
              <input
                style={styles.input}
                className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                name="amount"
                value={data.amount}
                onChange={handleInput}
                type="number"
                min={100}
                required
                placeholder="Enter amount"
              />
            </div>

            <div className="mx-auto  lg:w-7/12 mt-4">
              <span className="block">Select channel </span>
              <select
                style={styles.input}
                className="text-qosText"
                name="channel"
                required
                onChange={handleInput}
              >
                <option disabled selected hidden>
                  Select channel
                </option>

                <option>Momo</option>
                <option>Bank</option>
              </select>
            </div>
            <div className="mb-10 ">
              <button
                // onClick={handleShow1}
                disabled={isLoading}
                type="submit"
                variant="warning"
                className={`w-full md:w-12/12 rounded lg:w-7/12  h-12 flex mt-4 justify-center items-center  ${
                  isLoading ? "bg-qosorange" : "bg-qosorange "
                }  mx-auto mt-4 my-4`}
              >
                {isLoading ? (
                  <Spinner className="border-qosorange bg-white h-4 w-4 mx-1" />
                ) : (
                  <div className="flex gap-2  justify-center align-center">
                    <span className="text-white" style={{ fontSize: "16px" }}>
                      Submit
                    </span>
                    {/* <span className="fi-rr-arrow-right mt-1"></span> */}
                  </div>
                )}
              </button>
            </div>
            </div> ) :(<div className="mt-10 w-full">
                <p className="text-center text-qosorange">{autoriseOperator == "" ? "Select account" : "Not allowed"}</p>
            </div> ) }

          
          </form>
        </div>
      </Wrapper>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
     
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div    id="pdf-content">

       
          <h4 className="p-4 text-center text-black">TopUp summary</h4>

 
          <div className="bg-qosWhiteg p-4 text-center border ">
              <p className="p-2 text-black ">QOS Integrated Center</p> 
              <p className="p-1 text-black ">{response?.datas?.channel}</p>

            </div>
          <div className="w-full flex justify-center border  align-center  mb-2 gap-4 py-4 ">
           
            <div className="text-left pl-4 w-full">
              <div className=" p-1">
                <span className="text-qosText ">Selected account</span>
                <p className="my-2">{response?.datas?.channel}</p>
              </div>
              <div className="p-1">
                <span className="text-qosText">Amount</span>
                <p className="my-2">{response?.datas?.amount}</p>
              </div>
            </div>

            <div className=" w-full text-left  gap-2">
              <div className="p-1 ">
                <span className="text-qosText">Transaction ID</span>
                <p className="my-2">{response?.datas?.transRef}</p>
              </div>
              <div className="p-1">
                <span className="text-qosText">Transaction type</span>
                <p className="my-2">TopUp</p>
              </div>
            </div>
          </div>

          </div>

          <div>
            <p className="pt-2 text-qosText text-center">
              Please include this transaction ID{" "}
            </p>
            <p className="text-qosText text-center">
              in transaction description or bank deposit slip
            </p>
          </div>

          <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-1 mb-10">
            <button
              id="next"
              onClick={handleClose}
            
              className={`w-full md:w-12/12 lg:w-12/12 h-12 flex justify-center items-center  rounded-sm text-white  font-bold ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              } btn-sm md:max-w-sm mx-auto mt-8`}
            >
              <span className="text-msm" style={{ fontSize: "16px" }}>
                Make a new topUp
              </span>
            </button>
          </div>
          
          <p className="underline cursor-pointer text-center" onClick={handleDownloadPDF}>Download PDF</p>
        </Modal.Body>

        {/* <Modal.Body>
          <div>
            {userMessage == null ? (
              <form
                id="stepForm"
                onSubmit={handleCode1}
                className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto"
              >
                <p className="pt-2">TopUp summary</p>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full">
                    <div className=" p-1">
                      <span className="text-qosText ">Mobile money number</span>
                      <p className="my-2">{Newdata?.accountNumber}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Operator</span>
                      <p className="my-2">{transferType}</p>
                    </div>
                  </div>

                  <div className=" w-full text-left  gap-2">
                    <div className="p-1 ">
                      <span className="text-qosText">Recipient name</span>
                      <p className="my-2">{data?.recipient_name}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Amount</span>
                      <p className="my-2">{data?.amount}</p>
                    </div>
                  </div>
                </div>

                <p className="pt-1">
                  Enter the code generated by the app to complete 2FA
                  verification.
                </p>
                <div className="mx-auto  lg:w-full mt-8">
                  <span className="block text-left">Authentication code</span>
                  <input
                    style={styles.input}
                    className="py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    
                    name="code"
                    onChange={handleMyCode}
                    required
                    placeholder="Enter code here"
                  />
                </div>
         

                <div className="font-bold text-qosblue  mx-auto flex justify-around items-center mt-4 mb-10">
                  <button
                    id="next"
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-sm text-white  font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    } btn-sm md:max-w-sm mx-auto mt-8`}
                  >
                    {isLoading ? (
                      <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                    ) : (
                      <span className="text-msm" style={{ fontSize: "16px" }}>
                        Confirm transfer
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <p className="p-2 text-center">
                  Click below to complete your transaction
                </p>
                <p className=" text-center">👇</p>

                <Button
                  disabled={isLoading}
                  type="submit"
                  className={`w-full h-12 flex justify-center items-center  rounded-md text-qosgray text-opacity-80 font-bold ${
                    isLoading ? "bg-coolGray-300" : "bg-qosorange"
                  } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-8`}
                  variant="primary"
                  size="lg"
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Spinner className="border-qosorange h-4 w-4 mx-1" />
                  ) : (
                    "Envoyé"
                  )}
                </Button>
              </>
            )}
          </div>
        </Modal.Body> */}
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 border-white"
        ></Modal.Header>
        {/* <Modal.Body>
          <div>
            {userMessage == null ? (
              <form
                id="stepForm"
                onSubmit={handleCode1}
                className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto"
              >
                <p className="pt-2">Confirm transfer</p>

                <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                  <div className="text-left pl-4 w-full">
                    <div className=" p-1">
                      <span className="text-qosText ">Recipient number</span>
                      <p className="my-2">{data?.accountNumber}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Recipient name</span>
                      <p className="my-2">{data?.recipient_name}</p>
                    </div>
                  </div>

                  <div className=" w-full text-left  gap-2">
                    <div className="p-1 ">
                      <span className="text-qosText">Transfer type</span>
                      <p className="my-2">{transferType}</p>
                    </div>
                    <div className="p-1">
                      <span className="text-qosText">Amount</span>
                      <p className="my-2">{data?.amount}</p>
                    </div>
                  </div>
                </div>

                <p className="pt-1">
                  Enter the code generated by the app to complete 2FA
                  verification.
                </p>
                <div className="mx-auto  lg:w-full mt-8">
                  <span className="block text-left">Authentication code</span>
                  <input
                    style={styles.input}
                    className="py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                 
                    name="code"
                    onChange={handleMyCode}
                    required
                    placeholder="Enter code here"
                  />
                </div>
         

                <div className="w-full font-bold text-qosblue  flex justify-around items-center mt-4 mb-10">
                  <button
                    id="next"
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  rounded-sm text-white  font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    } btn-sm lg:w-full  mt-8`}
                  >
                    {isLoading ? (
                      <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
                    ) : (
                      <span className="text-msm" style={{ fontSize: "16px" }}>
                        Confirm authentication code
                      </span>
                    )}
                  </button>
                </div>
              </form>
            ) : (
              <>
                <div className="overflow-x-hidden hideScroll text-center max-w-full w-5/6 md:w-11/12 mx-auto">
                  <p className="pt-2">Confirm transfer</p>
                  <div className="w-full flex justify-center border rounded-sm align-center bg-qosWhiteg mt-5 mb-2 gap-4 py-4 ">
                    <div className="text-left pl-4 w-full">
                      <div className=" p-1">
                        <span className="text-qosText ">Recipient number</span>
                        <p className="my-2">{data?.accountNumber}</p>
                      </div>
                      <div className="p-1">
                        <span className="text-qosText">Recipient name</span>
                        <p className="my-2">{data?.recipient_name}</p>
                      </div>
                    </div>

                    <div className=" w-full text-left  gap-2">
                      <div className="p-1 ">
                        <span className="text-qosText">Transfer type</span>
                        <p className="my-2">{transferType}</p>
                      </div>
                      <div className="p-1">
                        <span className="text-qosText">Amount</span>
                        <p className="my-2">{data?.amount}</p>
                      </div>
                    </div>
                  </div>

                  <button
                    disabled={isLoading}
                    type="submit"
                    className={`w-full h-12 flex justify-center items-center  mb-14 rounded-md text-qosgray text-opacity-80 font-bold ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  btn-sm lg:w-full mx-auto mt-8`}
                    // variant="primary"
                    size="lg"
                    onClick={handleSubmit}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      "Confirm transfer"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body> */}
      </Modal>
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    height: 50,
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
