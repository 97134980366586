import {  useState, } from "react";
import { Link } from "react-router-dom";
import { media } from "../common/libs/media";
// import Dropdown from "../components/dropdown.component";
// import { FetchData, PutData, PostData } from "../common/services/httpServices";
// import { Endpoints } from "../common/utils/endpoints";
import { IconLink } from "./iconLink.component";
import { paths } from "../common/utils/path";
// import { LinkList } from "./linkList.component";
import { useSideBarContext } from "../common/utils/context";
const { qosLogoBlack } = media.Logos.qos;

export function Sidebar() {
  const { Active } = useSideBarContext();
  const [toggle, setToggle] = useState(0);




  return (
    <div className="bg-white max-h-screen z-50">
     

    
      <div
        className={`flex sidebar relative ${
          Active ? "active" : ""
        } flex-col min-h-screen  sm:flex-row`}
      >
        <div className="bottom-px mt-5 bg-opacity-80 w-full pl-8">
          
          <div className="flex items-center max-w-max">
            <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
              <Link to={paths.dashboard}>
                <img
                  src={qosLogoBlack}
                  alt=""
                  className={`${Active ? "" : "hidden"} h-full w-auto`}
                />
              </Link>
            </span>
          </div>
      

          <nav className="mt-8 w-full">
             <ul className="w-full grid grid-flow-row gap-y-4 " style={{width: '100%',}}>
            

              <li onClick={() => setToggle(0)} 
                className={`${
                  toggle === 0 ? "bg-qosorange" : 'bg-white'
                } `}
                style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-home`}
                  title={`Home`}
                  to={paths.pay}
                  
                />
              </li>
              

             

            <li onClick={() => setToggle(1)} 
                className={`${
                  toggle === 1 ? "bg-qosorange" : 'bg-white'
                } `}
                style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-info`}
                  title={`Terms and conditions`}
                  to={paths.terms}
                  
                />
              </li> 

             {/* <li>
                <IconLink
                  icon={`fi-rr-stats`} 
                  title={`${paths.analytics.label}`}
                  to={paths.analytics.path}
                />
              </li> 
              <li onClick={() => setToggle(2)}
                  className={`${
                    toggle === 2 ? "bg-qosorange" : 'bg-white'
                  } `}
                  style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-link`}
                  title={`Payment Link`}
                  to={paths.paymentlink}
                />
              </li>

              <li onClick={() => setToggle(3)}
                  className={`${
                    toggle === 3 ? "bg-qosorange" : 'bg-white'
                  } `}
                  style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-shopping-cart-check`}
                  title={paths.transaction.statment.label}
                  to={paths.transaction.statment.path}
                />
              </li>
              <li onClick={() => setToggle(4)}
                  className={`${
                    toggle === 4 ? "bg-qosorange" : 'bg-white'
                  } `}
                  style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-shuffle`}
                  title={`Transfer`}
                  to={paths.transfer.process.path}
                />
              </li>
              <li onClick={() => setToggle(5)}
                  className={`${
                    toggle === 5 ? "bg-qosorange" : 'bg-white'
                  } `}
                  style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-user`}
                  title={`Customers`}
                  to={paths.Customers}
                />
              </li> 
              <li>
                <IconLink
                  icon={`fi-rr-shopping-cart-check`}
                  title={`Payment links`}
                  to={paths.paymentlink}
                />
              </li>
              <li>
                <IconLink
                  icon={`fi-rr-settings`}
                  title={`Mailing`}
                  to={paths.mailing}
                />
              </li>
              
              <li onClick={() => setToggle(6)}
                  className={`${
                    toggle === 6 ? "bg-qosorange" : 'bg-white'
                  } `} 
                  style={styles.border}
                >
                <IconLink
                  icon={`fi-rr-settings`}
                  title={`Settings`}
                  to={paths.setting.general}
                />
              </li>
              <li onClick={() => setToggle(7)} 
                className={`${
                  toggle === 7 ? "bg-qosorange" : 'bg-white'
                } `}
                style={styles.border}
              >
                <IconLink
                  icon={`fi-rr-bell`}
                  title={`Get latest update`}
                  to={paths.home}
                  
                />
              </li>
              */}
            </ul> 
          </nav>
          {/* <div className="absolute bottom-px">
            <a
              href="https://qosic.com/terms-and-conditions/"
              className="flex items-center group py-2 hover:shadow-md transition-all duration-300 hover:translate-x-5 hover:scale-120 rounded-r-full w-full"
            >
              <span className="fi-rr-info text-mH3 opacity-90 font-light inline-block mr-4 mt-2"></span>
              <span
                className={`text-msm block overflow-hidden ${
                  !Active ? "lg:w-0" : "lg:w-full"
                } font-bold text-qosdark text-opacity-70 group-hover:text-opacity-100`}
              >
                Terms and conditions
              </span>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const styles = {
  border: { 
    borderRadius: '5px',
    width: '100%',
  }
}
