import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { getUser } from "../common/utils/tools";
import { FetchData } from "../common/services/httpServices";
import { PostData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import { VerifyUser, ActivateTwoAuth } from "../common/auth";
import { encryptStorage } from "../common/services/encryptData";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import axios from "axios";
// import { Switch } from "../components/switch.component";
// import { getUserTransCredentials } from "../common/utils/tools";
// import axios from "axios";
// import { Button } from 'react-bootstrap';
// import { isEmpty, isNull, isUndefined } from "lodash";
// import { reduceHooks } from "react-table";
// import { SinglePay } from "../common/auth";
// import Swal from "sweetalert2";
import { useMemo } from "react";

import { Spinner } from "../components/spinner.component";

import Single from "./single";
import SingleTwo from "./singletwo";

export default function SingletransfertComponent() {
  const [toggle, setToggle] = useState(0);

  const [myAmount, setMyAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);
  // const [failed, setFailed] = useState(false);

  const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);

  const [Infocountries, setInfocountries] = useState([]);
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState([]);
  const [chosenPays, setchosenPays] = useState("BENIN");

  const [network, setNetwork] = useState();

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    countries();
  }, []);

  function ListOfOperator(pays) {
    // console.log('yoo')
    // console.log("pays",pays)
    if (pays.target) {
      FetchData(
        Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
      ).then((res) => {
        setchosenPays(pays.target.value);
        setlistOperators(res.data.datas.items);
      });
    }
  }

  function getAmout(operateur) {
    FetchData(
      Endpoints.User.getAvailableAmount +
        "?country=" +
        chosenPays +
        "&operator=" +
        operateur.target.value
    ).then((res) => {
      setMyAmount(res.data.datas.amount);
      setLoading(true);
    });
  }

  function handleChange(event) {
    // console.log("voila toi nous maj",event.target.value);
    getAmout(event);
    setNetwork(event.target.value);
  }

  // onChange={(e) => getAmout(e)}

  return (
    <section>
      <SingleTwo pays={network} listOperators={listOperators} />
    </section>
  );
}