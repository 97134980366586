import React, { useEffect, useState } from "react";
import { useLangContext } from "../components/translateContext";
import offer from "../components/translation/offer.json";
import { FetchData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { Tableoffers } from "../components/tables/tableoffers";
import { TableMySubscription } from "../components/tables/tableMySubscription";
import {
  //  useIsMobile,
  useFormData,
} from "../common/utils/hooks";
// import { Button } from 'react-bootstrap';
import Wrapper from "../components/sectionWrapper.component";
import { CreateOffer } from "../common/auth";
import Swal from "sweetalert2";

export default function Offer() {
  const [state, setstate] = useState(0);
  // const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: offer[Lang] };

  return (
    <section>
      <header className="">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-24 sm:w-32 md:w-44  text-white h-full bg-qosorange opacity-70 rounded-full p-2 md:px-4`}
          ></div>
          <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.My}
          </button>
          <button
            onClick={() => setstate(1)}
            className={`transition-all duration-500 ${
              state === 1 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.Offers}
          </button>
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <MySubscription />}
          {state === 1 && <Offers />}
        </section>
      </div>
    </section>
  );
}

function Offers() {
  const [modalOn, setModalOn] = useState(false);
  const [choice, setChoice] = useState(false);

  const clicked = () => {
    setModalOn(true);
  };

  return (
    <div classNameName="">
      <div className="overscroll-auto">
        <div className="flex overscroll-auto justify-end">
          {/* <div className="flex  cursor-pointer justify-center  bg-qosblue p-2  m-1 rounded-md text-white"

    onClick={clicked}
  >
    New
  </div> */}
        </div>

        {/* conditionally display the result of the action if user confirms  */}
        {choice && (
          <div className="flex justify-center">
            <div className="flex  justify-center w-1/3 bg-red-400 m-4 p-6 text-lg text-white ">
              {" "}
              You have been bitten !!!
            </div>
          </div>
        )}

        {modalOn && <Modal setModalOn={setModalOn} setChoice={setChoice} />}
      </div>

      <section className="flex-row  bg-white my-8 p-4 gap-3 w-full  sm:block justify-center w-100 md:block w-100 justify-center sm:rounded-lg ">
        <div className="max-w-screen-xl overflow-x-auto relative overflow-hidden bg-white mx-auto my-2 sm:rounded-lg ">
          <Tableoffers />
        </div>
      </section>

      
    </div>
  );
}

function MySubscription() {
  return (
    <div>
       <section className="flex-row  bg-white my-8 p-4 gap-3 w-full   sm:block justify-center w-100 md:block w-100 justify-center sm:rounded-lg ">
        <div className="max-w-screen-xl overflow-x-auto relative overflow-hidden bg-white mx-auto my-2 sm:rounded-lg ">
        <TableMySubscription />
        </div>
      </section>
  
    </div>
  );
}

function Modal({ setModalOn, setChoice }) {
  const [info, setInfo] = useState([]);
  const [selects, setSelects] = useState();
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [loadingUnit, setLoadingUnit] = useState(true);
  const [listOperators, setlistOperators] = useState([]);
  const [loadingOperator, setLoadingOperator] = useState(true);
  const [data, handleInput] = useFormData();

  const inf = () => {
    FetchData(Endpoints.Offers.OffersCountriesList).then((res) => {
      setInfo(res);
      setLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    inf();
  }, []);

  const Unit = () => {
    FetchData(Endpoints.Utilities.getCalculationUnits).then((res) => {
      setUnits(res);
      setLoadingUnit(false);
    });
  };
  useEffect(() => {
    Unit();
  }, []);

  function ListOfOperator(pays) {
    setLoadingOperator(true);
    // console.log('yoo')
    // console.log("pays",pays)
    if (pays.target) {
      FetchData(
        Endpoints.Utilities.getOperators + "?country=" + pays.target.value
      ).then((res) => {
        setlistOperators(res);
        setLoadingOperator(false);
        handleInput(pays);
      });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("user Data : ", data);
      const { data: user, status } = await CreateOffer(data);
      // console.log("un user authData : ", user)
      // console.log("un user authSatus : ", status)
      // console.log("status de lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseStatus)

      if (user.responseStatus === true) {
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });
      }
    } catch (error) {
      // console.log("erreur :", error)
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Vérifier  incorrectes",
          showConfirmButton: false,
          timer: 5500,
        });
      }, "100");
    }
    setLoading(false);
  }

  // const handleSelect = (e) => {
  //   e.preventDefault();
  //   setSelects(e.target.value)
  // }

  const handleOKClick = () => {
    setChoice(true);
    setModalOn(false);
  };
  const handleCancelClick = () => {
    setChoice(false);
    setModalOn(false);
  };

  return (
    <div className=" overscroll-auto md:overscroll-contain  bg-zinc-200  fixed inset-0 z-50   ">
      <Wrapper
        className=" overscroll-auto md:overscroll-contain "
        style={{
          backgroundSize: "40% .12%",
        }}
      >
        <div className="flex overscroll-auto  h-screen justify-center items-center ">
          <div className="mt-10 sm:mt-0  ">
            <form action="#" method="POST" onSubmit={handleSubmit}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div class=" py-5 grid grid-cols-1 divide-y">
                    <h4 class="mt-2 text-center text-3xl font-extrabold text-gray-900">
                      Create new Offer
                    </h4>
                    <div class="grid grid-cols-1 mt-6 divide-y"></div>
                  </div>

                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label
                        for="street_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <input
                        onChange={handleInput}
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>

                      {loading ? (
                        <select
                          id="country"
                          name="country"
                          autoComplete="country"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option className="text-white m-1">BENIN</option>
                        </select>
                      ) : (
                        <select
                          onChange={(e) => ListOfOperator(e)}
                          id="country"
                          name="country"
                          autoComplete="country"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled selected hidden>
                            Select a COUNTRY
                          </option>
                          {info.data.datas.items.map((item) => (
                            <option key={item.index}>{item}</option>
                          ))}
                        </select>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="operators"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Operator
                      </label>

                      {loadingOperator ? (
                        <select
                          onChange={handleInput}
                          id="operators"
                          name="operators"
                          autoComplete="operators"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option className="text-white m-1">Yes</option>
                        </select>
                      ) : (
                        <select
                          id="operators"
                          onChange={handleInput}
                          name="operators"
                          autoComplete="operators"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled selected hidden>
                            Select an OPERATOR
                          </option>
                          {listOperators.data.datas.items.map((item) => (
                            <option key={item.index}>{item}</option>
                          ))}
                        </select>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="is_free"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Is Free
                      </label>
                      <select
                        onChange={handleInput}
                        id="is_free"
                        name="is_free"
                        autoComplete="is_free"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Select a...
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="validity_period"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Validity Period (days){" "}
                      </label>
                      <input
                        onChange={handleInput}
                        type="number"
                        name="validity_period"
                        id="validity_period"
                        autoComplete="validity_period"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="base_fee"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fee
                      </label>
                      <input
                        onChange={handleInput}
                        type="number"
                        name="base_fee"
                        id="base_fee"
                        autoComplete="base_fee"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        step="any"
                      />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="calculation_unit"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Unit
                      </label>

                      {loadingUnit ? (
                        <select
                          onChange={handleInput}
                          id="calculation_unit"
                          name="calculation_unit"
                          autoComplete="calculation_unit"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option className="text-white m-1">Yes</option>
                        </select>
                      ) : (
                        <select
                          onChange={handleInput}
                          id="calculation_unit"
                          name="calculation_unit"
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled selected hidden>
                            Select a UNIT
                          </option>
                          {units.data.datas.items.map((item) => (
                            <option key={item.index}>{item}</option>
                          ))}
                        </select>
                      )}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="has_evaluation_period"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Has Evaluation
                      </label>
                      <select
                        onChange={handleInput}
                        id="has_evaluation_period"
                        name="has_evaluation_period"
                        autoComplete="has_evaluation_period"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="" disabled selected hidden>
                          Select an evaluation
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        for="evaluation_period"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Period (days)
                      </label>
                      <input
                        onChange={handleInput}
                        type="number"
                        name="evaluation_period"
                        id="evaluation_period"
                        autoComplete="evaluation_period"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-white text-center sm:px-6">
                  {/* <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Save
            </button> */}
                  <div classNameName="flex justify-around ">
                    <button
                      type="submit"
                      className=" rounded px-4 py-2 text-white  bg-qosgreen "
                    >
                      Submit
                    </button>
                    <button
                      onClick={handleCancelClick}
                      className="rounded px-4 py-2 ml-4 text-white bg-qosblue"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="flex">
          <button
            onClick={handleOKClick}
            className=" rounded px-4 py-2 text-white  bg-qosgreen "
          >
            Submit
          </button>
          <button
            onClick={handleCancelClick}
            className="rounded px-4 py-2 ml-4 text-white bg-qosblue"
          >
            Cancel
          </button>
        </div>
      </Wrapper>
    </div>
  );
}
