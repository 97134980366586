import React, { useEffect } from "react";
import { useDarkTheme } from "./common/utils/context";
import { Routes } from "./routes/index.routes";


function App() {
  const {  setDark } = useDarkTheme();
  useEffect(() => {
    setDark(true);
  }, [setDark]);
  return <Routes />;
}
export default App;
