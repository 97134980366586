import React from "react";

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <span>
     
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        min={2}
        placeholder={"Search transaction"}
        className="h-18 border-none md:w-40 text-mmd px-10 min-w-max"
        style={{
          // height: "32px",
          height: 50,
          borderRadius:50,
          width: "40px",
          border: "1px solid rgba(0,0,0,.2)",
        }}
      />
    </span>
  );
};

export default GlobalFilter;
