import React, { useEffect, useState } from "react";
import { useFormData } from "../common/utils/hooks";
import Wrapper from "./sectionWrapper.component";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { useParams, useLocation } from "react-router";
import { Icon } from "@iconify/react";
import { Spinner } from "./spinner.component";
import base32 from "hi-base32";
import { useLangContext } from "./translateContext";
import payform from "./translation/payform.json";

export default function Paycomponent() {

  const [isLoading, setIsLoading] = useState(false);
  const [datas, handleInput] = useFormData();

 
  const { handle } = useParams();
  const decoded = base32.decode(handle);
  const comp = decoded.split("/")[0];



  const searchParams = new URLSearchParams(
    "?" + decoded.split("/")[1].split("?")[1]
  );

  // console.log("decoded", decoded)

  const titre = searchParams.get("titre");
  const qte = searchParams.get("qte");
  const amount = searchParams.get("amount");
  const qoskey = searchParams.get("qoskey");
  const description  = searchParams.get("description");


  let tokoss = amount === null ? datas.Amount : amount?.split(".")[0];


  const id = decodeURI(comp);
  const name = decodeURI(titre);

  const ema = datas.email;
  const firs = datas.prenom;
  const nam = datas.name;
  const num = datas.phone;


  let company_nam = id;
  const valeur = name.toString();

  const { Lang } = useLangContext();
  const Infos = { msg: payform[Lang] };


  let isLauch = false;
  let myTimeLoop = "";

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });


  function sendRequest() {
    setIsLoading(true);
    // initiale body for request  ==> m = mandatory = obligatoire
    var data = {
      type: "all", // in checkout value is mobile/card/all ==> m
      transref: new Date().getTime(), // transref ===> m et unique
      qosKey: qoskey, // is provided by qos platform  ==> m
      returnUrl: "https://dashboard.qosic.com/ResponsePage/reponse", // is callback redirection with parameter transref ans status ==> m
      amountDetails: {
        totalAmount: Number(tokoss), // amount wil be pay by customer //  ==> m
        currency: "XOF",
      },
      saleDetails: {
        firstName: firs, // m
        lastName: nam, // m
        middleName: "",
        nameSuffix: "",
        title: "Mr", // m
        address1: "Cotonou", // m
        address2: "Cococodji",
        address4: "string",
        locality: "Litoral", // m
        administrativeArea: "",
        postalCode: "229", // m
        country: "Benin", // m
        district: "Bj", // m
        buildingNumber: "string",
        email: ema, // m
        emailDomain: "string",
        phoneNumber: "67222918", // m
        phoneType: "cel", // m
      },
    };

    fetch("https://b-card.qosic.net/public/v1/initTransaction", {
      // fetch("http://74.208.84.251:9014/public/v1/initTransaction", {
      //fetch("https://b-checkout-api.qosic.net/public/v1/initTransaction", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log("response",res)

        if (res.responseCode === "00") {
          setIsLoading(false);
          window.location.href = res.url;
        } else {
          setIsLoading(false);
          let message = res.message;
          Toast.fire({
            icon: "error",
            title: message,
          });

          // console.log("Request response:", res);
        }

        // console.log("Request complete! response:", res);
      });

    /*  var xhr = new XMLHttpRequest();
     xhr.open("POST", 'https://b-checkout-api.qosic.net/public/v1/initTransaction', true);
     xhr.setRequestHeader('Content-Type', 'application/json');
     xhr.onreadystatechange = function () {
         if (this.readyState != 4) return;

         if (this.status == 200) {
             var data = JSON.parse(this.responseText);
             console.log(data)

             // we get the returned data
         }

         // end of state change: it can be after some time (async)
     };
     xhr.send(JSON.stringify({
         value: data
     })); */
  }



  return (
    <Wrapper className="mb-4 mx-2 lg:mx-0">
      <div className="bg-white  rounded-xl border-t shadow-sm border-qosgrain  w-full  mx-auto px-1  my-15 py-10 mb-4  md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2   ">
        <h2
          className="text-center md:mb-4 md:my-5 md:text-dH3"
        >
      
          {/* {Infos.msg.Title} */}
          {" " + id.charAt(0).toUpperCase()}
          {id.toLowerCase().slice(1)}
        </h2>
        <div className="flex justify-center items-center">

        <p className="mt-1 mb-2 text-qosText">
          {description}
        </p>
        </div>

        <form className="mb-4 px-1">
          <div className=" mx-auto  md:w-full md:flex lg:w-9/12 lg:flex gap-3">
            <div className="mx-auto  md:w-full lg:w-9/12 mt-3">
              <label
                class="block text-qosTab  font-normal mb-2"
                for="password"
              >
                {Infos.msg.Your}
              </label>
              <input
                style={styles.input}
                onChange={handleInput}
                className=" py-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                placeholder={Infos.msg.Name}
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                required
                step="any"
              />
            </div>
            <div className="mx-auto  md:w-full lg:w-9/12 mt-3">
              <label
                class="block text-qosTab  font-normal mb-2"
                for="password"
              >
                {Infos.msg.Last}
              </label>
              <input
                onChange={handleInput}
                style={styles.input}
                className=" py-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                placeholder={Infos.msg.First}
                type="text"
                name="prenom"
                id="prenom"
                autoComplete="prenom"
                required
                step="any"
              />
            </div>
          </div>

          <div className="mx-auto  md:w-full lg:w-9/12 mt-4">
            <label
              class="block text-qosTab text-sm font-normal mb-2"
              for="password"
            >
              {Infos.msg.Email}
            </label>
            <input
              onChange={handleInput}
              style={styles.input}
              className=" py-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              placeholder={Infos.msg.Email}
              type="email"
              name="email"
              id="email"
              required
              autoComplete="email"
              step="any"
            />
          </div>
          <div class="mx-auto  md:w-full lg:w-9/12 mt-4">
            <label
              class="block text-qosTab text-sm font-normal mb-2"
              for="password"
            >
              {Infos.msg.Amount}
            </label>
            <div className="flex w-full gap-2">
              <div className="w-4/12">
                <input
                  disabled
                  // onChange={handleInput}
                  style={styles.input}
                  className="w-4/12 py-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  value="XOF"
                  type="text"
                  placeholder="XOF"
                  name="XOF"
                  step="any"
                />
              </div>
              <div className="w-full">
                <input
                  disabled={amount === null ? false : true}
                  value={tokoss}
                  onChange={handleInput}
                  style={styles.input}
                  className=" py-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  id="Amount"
                  name="Amount"
                  type="number"
                  placeholder={Infos.msg.Amount}
                />
              </div>
            </div>
          </div>
       
        </form>

        {datas.email === undefined ||
        datas.prenom === undefined ||
        datas.name === undefined ? (
          <div
            className={`mx-auto  md:w-full lg:w-9/12 mt-10 mb-4 h-12 flex justify-center items-center rounded-md text-qosgray text-opacity-80 font-bold bg-qosdark bg-opacity-80 btn-sm  `}
          >
            <h5>{Infos.msg.Fill}</h5>
          </div>
        ) : (
       
          <button
            onClick={sendRequest}
            disabled={isLoading}
            style={styles.button}
            className={`mx-auto w-full  md:w-full lg:w-9/12 mt-10 mb-4 flex justify-center items-center  rounded-md text-lg text-white  font-normal ${
              isLoading ? "" : "bg-qosorange"
            }  btn-sm mt-10`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1 " />
            ) : (
              <div className="flex gap-2">
              {Infos.msg.Pay}
              
              <Icon
                icon="teenyicons:arrow-right-solid"
                height={24}
                style={{ color: "white", fontSize: "20px" }}
              />
            </div>
            )}
          </button>
        )}

        {/* <div className="mt-4 mb-2 flex justify-center align-center">
          <p>
            {Infos.msg.power} {""}
            <a href="https://www.qosic.com/" className="text-qosorange ">
              Qospay
            </a>
          </p>
        </div> */}
      </div>
    </Wrapper>
  );
}

const styles = {
  input: {
    borderRadius: 4,
  },
  button: {
    height: "50px",
    borderRadius: 4,
  },
};
