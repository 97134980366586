// const baseUrl = "http://50.21.186.201:8080/qos-settlement-api";
// const baseUrl = "http://127.0.0.1:9005";
// const baseUrl = "http://74.208.84.251:9004" 
 const baseUrl = "https://api-stlement.qosic.net"

export const Endpoints = {
  Credentials: {

    get: baseUrl.concat("/api/v1/credentials"),
  },

  BankInfo: {
    Getregistration: baseUrl.concat ("/api/v1/bankInfo/Get-registration"),
    RegisterBankInformation: baseUrl.concat ("/api/v1/bankInfo/registration"),
  },

  Documents: {
    documentsUploadedList: baseUrl.concat("/api/v1/documents/list"),
    documentsUploadedListv: baseUrl.concat("/api/v2/documents/list"),
    uploadDocumentProof: baseUrl.concat("/api/v1/documents/upload"),
    uploadDocumentProofv: baseUrl.concat("/api/v2/documents/upload"),
    getaFile: baseUrl.concat("/api/v1/documents/view/"),
  },

  Offers: {
    saveAnOffer: baseUrl.concat("/api/v1/offers"),
    OffersCountriesList: baseUrl.concat("/api/v1/offers/countries/list"),
    offersList: baseUrl.concat("/api/v1/offers/list"),
    addOperatorsToOfferCountry: baseUrl.concat("/api/v1/offers/operators/_add"),
    removeOperatorsFromOfferCountry: baseUrl.concat("/api/v1/offers/operators/_remove"),
    offersOperatorsList: baseUrl.concat("/api/v1/offers/operators/list"),
    
  },
  Paymentlinks: {
    getuserPaymentLinks: baseUrl.concat("/api/v1/paymentlinks"),
    createUserPaymentLinks: baseUrl.concat("/api/v1/paymentlinks"),
    payByPaymentLinks: baseUrl.concat("/api/v1/paymentlinks/pay"),
    paymentStatus: baseUrl.concat("/api/v1/paymentlinks/status"),
    GetpaymentLinks: baseUrl.concat("/api/v2/paymentlinks"),
    CreateUserpaymentLinks: baseUrl.concat("/api/v2/paymentlinks"),
    CheckExistCustompaymentLinks: baseUrl.concat("/api/v2/paymentlinks/check-custom-link"),
    PayByPaymentLinks: baseUrl.concat("/api/v2/paymentlinks/pay"),
    GetpaymentStatus: baseUrl.concat("/api/v2/paymentlinks/status"),
    UploadDocumentProof: baseUrl.concat("/api/v2/paymentlinks/UploadImage"),
    AddProfile: baseUrl.concat("/api/v3/users/addprofile"),
    paymentLinkDelete: baseUrl.concat("/api/v2/paymentlinks/delete"),
    tAFile: baseUrl.concat("/api/vte2/paymentlinks/view/"),

  },


  Roles: {
    rolesList: baseUrl.concat("/api/v1/roles/list"),
  },

  Transactions: {
    getLastTransaction: baseUrl.concat("/api/v1/transactions/user/last/10"),
    getLastSuccessfulTransactions: baseUrl.concat(
      "/api/v1/transactions/last/successful/10"
    ),
    getTransactionsByPeriod: baseUrl.concat(`/api/v1/transactions/period`),

    getUserLastTransaction: baseUrl.concat("/api/v1/transactions/user/last/10"),
    getUserLastSuccessfulTransactions: baseUrl.concat(
      "/api/v1/transactions/user/last/successful/10"
    ),

    getUsertransactionsByPeriod: baseUrl.concat(
      `/api/v1/transactions/user/period`
    ),

    getTodayAmount: baseUrl.concat(
      `/api/v1/transactions/stats/last_7_days`
    ),
    getUsertransactionsByClientAndByPeriod: baseUrl.concat(
      `/api/v1/transactions/period`
    ),
    getUserLastTenSuccessfullPayment: baseUrl.concat(
      `/api/v1/transactions/user/successfull/requestpayment`
    ),
    getUserLastTenSuccessfullTransaction: baseUrl.concat(
      `/api/v1/transactions/user/successfull/requestpayment`
    ),

    getUserSuccessfullDeposit: baseUrl.concat(
      `/api/v1/transactions/user/successfull/deposit`
    ),
    getUserSuccessfullRefund: baseUrl.concat(
      `/api/v1/transactions/user/successfull/refund`
    ),
    getUserSuccessfullRequestPayment: baseUrl.concat(
      `/api/v1/transactions/user/successfull/requestpayment`
    ),

    getLast: baseUrl.concat(
      `/api/v1/transactions/user/last/10`
    ),
    getLastBycountry: baseUrl.concat(
      `/api/v1/transactions/last/10`
    ),
    getOperationStatus: baseUrl.concat(
      `/api/v1/transactions/operation/status`
    ),
  },

  Transfer: {

    TransfertoSingleMultiplerReceiver: baseUrl.concat(`/api/v1/transfer`),
    Allowdepositforuser: baseUrl.concat(`/api/v1/transfer/deposit/activate`),
    WithdrawMoney: baseUrl.concat(`/api/v1/transfer/withdrawal`),
    PayoutCard: baseUrl.concat(`/api/v1/transfer/payout_card`),
    PayoutCardAll: baseUrl.concat(`/api/v1/transfer/payout_status_all`),
    TransferMomo: baseUrl.concat(`/api/v1/transfer/transfert_momo`),
    bulkTransferMomo: baseUrl.concat(`/api/v1/transfer/bulk_transfert_momo`),
    bulk_transfert_momo_Excel: baseUrl.concat(`/api/v1/transfer/bulk_transfert_momo_Excel`),
    getAllAccount: baseUrl.concat(`/api/v1/transfer/get_all_account`),
    TopUp: baseUrl.concat(`/api/v1/transfer/top_up`),
    TopupTransaction: baseUrl.concat(`/api/v1/transfer/topupTransaction`),
    TransfertTransaction: baseUrl.concat(`/api/v1/transfer/transfert_momo_Transaction`),
    
  },

  Subscriptions: {

    UserIssubscriptionsactivation: baseUrl.concat(`/api/v1/subscriptions/_activate`),
    UserIsSubscriptionsClosed: baseUrl.concat(`/api/v1/subscriptions/_closed`),
    getCountries: baseUrl.concat(`/api/v1/subscriptions/countries/list`),
    getOperators: baseUrl.concat(`/api/v1/subscriptions/countries/operators/list`),
    UserIsSubscriptionsList: baseUrl.concat(`/api/v1/subscriptions/list`),
    UnsubscribeToService: baseUrl.concat(`/api/v1/subscriptions/unsubscribe/`),
    SubscribeToService: baseUrl.concat(`/api/v1/subscriptions/subscribe/business`),
    IndividualBusness: baseUrl.concat(`/api/v1/subscriptions/subscribe/individual/business`),
    RegisteredBusness: baseUrl.concat(`/api/v1/subscriptions/subscribe/registered/business`),
    
    getAllInfo: baseUrl.concat(`/api/v1/subscriptions/resume/all/data`),
  },

  User: {
    signin: baseUrl.concat("/api/v1/auth/signin"),
    signinNew: baseUrl.concat("/api/v2/auth/signin"),
    signinNewNew: baseUrl.concat("/api/v3/auth/signin"),
    logout: baseUrl.concat("/api/v1/auth/logout"),
    signup: baseUrl.concat("/api/v1/users/signup"),
    signupNew: baseUrl.concat("/api/v2/users/signup"),
    signupNewNew: baseUrl.concat("/api/v3/users/signup"),
    enableTwoFactor: baseUrl.concat("/api/v1/auth/enabled-2fa/"),
    validateTwoFactor: baseUrl.concat("/api/v1/auth/valid-2fa/"),
    disableTwoFactor: baseUrl.concat("/api/v1/auth/disabled-2fa/"),
    verifyTwoFactor: baseUrl.concat("/api/v1/auth/enabled-2fa/verify-2fa"),
    getUser: baseUrl.concat("/api/v1/users/me"),
    getByUserName: (username) => baseUrl.concat(`/api/v1/users${username}`),
    updateUser: baseUrl.concat("/api/v1/users"),
    updatePassword: baseUrl.concat("/api/v1/users/update-password"),
    uploadsFiles: baseUrl.concat("/api/v1/users/uploads"),
    refresh: baseUrl.concat("/users/refresh"),
    updateActivity: baseUrl.concat("/api/v1/users/activity"),
    getAvailableAmount: baseUrl.concat("/api/v1/users/available-amount"),
    CurrentUserData: baseUrl.concat("/api/v1/users/me"),
    getUserMerchantNumbers: baseUrl.concat("/api/v1/users/merchant-numbers"),
    updateUserMerchantNumbers: baseUrl.concat("/api/v1/users/merchant-numbers"),
    updateCurrentUserInformations: baseUrl.concat("/api/v1/users"),
    subscribeToService: baseUrl.concat("/api/v1/users/services/subscribe"),
    userSubscriptionsList: baseUrl.concat("/api/v1/users/services/subscriptions/list"),
    unSubscribeToService: baseUrl.concat(`/api/v1/users/services/unsubscribe/{service_name}`),
    upload: baseUrl.concat("/api/v1/users/uploads"),
    userDocumentsUploadList: baseUrl.concat(`/api/v1/users/uploads/list`),
    profile: baseUrl.concat(`/api/v1/users/profile`),
    me: baseUrl.concat(`/api/v1/users/me`),
    meNew: baseUrl.concat(`/api/v2/users/me`),
    meNewNew: baseUrl.concat(`/api/v3/users/me`),
    verifyMail: baseUrl.concat(`/api/v1/users/verify`),
    verifyMailNew: baseUrl.concat(`/api/v2/users/verify`),
    resetUserPassword: baseUrl.concat(`/api/v1/users/reset-password`),
    verificationForPassword: baseUrl.concat(`/api/v1/users/verify/reset-password`),
    resentVerification: baseUrl.concat("/api/v3/users/resentVerification"),
    AddNewAccount: baseUrl.concat("/api/v3/users/addaccount"),
    AddProfile: baseUrl.concat("/api/v3/users/addprofile"),

    
  },

  UsersProfile:{
    resetApiPassword: baseUrl.concat(`/api/v1/usersProfile/reset-api-password`),
    whitelisteApi: baseUrl.concat(`/api/v1/usersProfile/add-api-ip`),
    whitelisteApiAll: baseUrl.concat(`/api/v1/usersProfile/all-api-ip`),
    whitelisteApiDelete: baseUrl.concat(`/api/v1/usersProfile/delete-api-ip`),
    getListOfAllIp: baseUrl.concat(`/api/v1/usersProfile/all-user-api-ip`),
    AddcallBack: baseUrl.concat(`/api/v1/usersProfile/add-callback`),
    AllcallBack: baseUrl.concat(`/api/v1/usersProfile/all-user-callback-api`),

  },

  Utilities: {

    getCalculationUnits: baseUrl.concat(`/api/v1/utils/calculation-units/list`),
    getCountries: baseUrl.concat(`/api/v1/utils/countries/list`),
    getOperators: baseUrl.concat(`/api/v1/utils/countries/operators/list`),
    getDocumentType: baseUrl.concat(`/api/v1/utils/documents/list`),
    getPaymentLinkDevices: baseUrl.concat(`/api/v1/utils/payment-links/devices/list`),
    getPaymentLinkTypes: baseUrl.concat(`/api/v1/utils/payment-links/types/list`),
    getPaymentMode: baseUrl.concat(`/api/v1/utils/payment-modes/list`),
  },
  Rapport:{
    generateRapport: baseUrl.concat(`/api/v1/repport/monthly/`),
  
  },



};


  // MTN: {
  //   getAvailableAmount: baseUrl.concat("/mtn-api/mtnavailableamount"),
  //   getAccountHolderInfo:
  //     "https://www.qosic.net:8443/QosicBridge/user/getaccountholderinfo",
  // },
  // MOOV: {
  //   getAvailableAmount: baseUrl.concat("/moov-api/moovavailableamount"),
  //   getAccountHolderInfo:
  //     "https://www.qosic.net:8443/QosicBridge/user/getaccountholderinfomv",
  // },
