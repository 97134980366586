import Wrapper from "../../../components/sectionWrapper.component";
import Messages from "./translations/index.json";
import InputsText from "../../../translations/input.json";
import { useLangContext } from "../../../components/translateContext";
import buttonText from "../../../translations/button.json";
import { useFormData } from "../../../common/utils/hooks";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link } from "react-router-dom";
import axios from "axios";
import { VerifyUser, ActivateTwoAuth } from "../../../common/auth";
import { useHistory } from "react-router";
import { encryptStorage } from "../../../common/services/encryptData";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/spinner.component";
import Swal from "sweetalert2";
import showPwdImg from "./showPwdImg.svg";
import hidePwdImg from "./hidePwdImg.svg";

export default function AuthenticatorPage() {
  const { Lang } = useLangContext();
  const history = useHistory();
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const btnMsg = buttonText[Lang];
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);


  const username = encryptStorage.getItem("qosUser")?.email;

  // console.log("data username :", username);

  const cara = { username };

  const datas = { ...data, ...cara };

  // console.log("data data  data:", datas);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // console.log("data :", datas)
    try {
      // data.isRememberMe = true;

      const { data: user, status } = await ActivateTwoAuth(datas);
   

      if (
        encryptStorage.getItem("qosToken") &&
        encryptStorage.getItem("qosUser")?.appVersion === "1.0" &&
        user.responseMessage === "Successful"
      ) {
    

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
      } else if (
        encryptStorage.getItem("qosToken") &&
        encryptStorage.getItem("qosUser")?.appVersion === "2.0" &&
        encryptStorage.getItem("qosUser")?.state === "ACTIVE" &&
        user.responseMessage === "Successful"
      ) {
        // history.push("/dashboard");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          history.push("/dashboard");
        }, 2000);
      } else if (
        encryptStorage.getItem("qosToken") &&
        encryptStorage.getItem("qosUser")?.appVersion === "2.0" &&
        encryptStorage.getItem("qosUser")?.state === "INACTIVE" &&
        user.responseMessage === "Successful"
      ) {
        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: user.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          history.push("/getstarted");
        }, 2000);
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Please use real generated code number",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    } catch (error) {
      // alert("error");
      Swal.fire({
        position: "top-end",
        // title: error,
        text: "Please use real generated code number",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        toast: true,
      });
    }
    setLoading(false);
  }

  const [pwd, setPwd] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (

    <Wrapper className="">
      <div className="bg-white border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2   ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
            <h2
              className="text-center md:text-mH2"
              dangerouslySetInnerHTML={{ __html: Infos.msg.Title }}
            ></h2>
          </span>
        </div>
        <form onSubmit={handleSubmit} className="  w-full ">
          <div className="mx-auto  lg:w-8/12 mt-8">
            <span className="block">{Infos.msg.auth}</span>
            <input
              style={styles.input}
            
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
           
              name="code"
              value={data.code}
              onChange={handleInput}
              required
              placeholder="Enter code here"
            />
          </div>

          <button
            disabled={isLoading}
            style={styles.button}
            className={`w-full xl:w-8/12 lg:w-8/12   flex justify-center items-center  text-white text-opacity-80  ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            }  p-3 btn-sm  mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>Verify</span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>

          <div className="inline-block mb-10  mt-4 text-center w-full justify-between justify-center align-center lg:mx-auto lg:w-8/12  lg:flex lg:text-center ">
            <p className="text-center flex  pr-2">
              {Infos.msg.Go}
              <Link
           
                to="/"
              >
                <p className="text-qosorange underline ml-1">{btnMsg.logInButton}</p>
              </Link>
            </p>
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const styles = {
  input: {
    
    borderRadius: 4,
  },
  button: {
 
    height: "28",
    borderRadius: 8,
  },
};
