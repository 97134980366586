import { useEffect, useState } from "react";
import { FetchData } from "../../common/services/httpServices";
import { Endpoints } from "../../common/utils/endpoints";
import offer from "../translation/offer.json";
import { useLangContext } from "../translateContext";
import Table from "react-bootstrap/Table";

// Define a default UI for filtering

// import "./table.css";

export function TableMySubscription() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { Lang } = useLangContext();
  const Infos = { msg: offer[Lang] };

  const List = () => {
    FetchData(Endpoints.Subscriptions.UserIsSubscriptionsList).then((res) => {
      setList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    List();
  }, []);

  // const data = [
  //     { name: "MTN BJ API", rate: 1.7, period: "-" , validady: "-", status: "FREE",},
  //     { name: "MOOV TOGO API", rate: 3000, period: 5, validady: 30},

  //   ]

  // function UnsubscribeTo (name) {
  //   setLoading(true)
  //   console.log('yoo')
  //   console.log("pays",name)
  //   if (name)
  //   {
  //     PutData(
  //       Endpoints.Subscriptions.UnsubscribeToService+''+name,

  //     ).then((res) =>
  //       {console.log("la res : ",res); setLoading(res);setLoading(false)}

  //     );

  //   }

  // }

  return (
    <div>
      <div className="h-20  flex justify-center text-center">
        <span className="mt-10" style={{ fontSize: 30 }}>
          {Infos.msg.Subscription}
        </span>
      </div>
      <div className="overflow-x-auto hideScroll ">
      <div className="bg-white lg:px-20 sm:rounded-lg  mt-12">
        <table
        className="no-vertical-lines border"
        >
          <thead>
            <tr className=" text-center ">
              <th className="px-4 py-3 relative text-dlg text-qosdark   ">
                {Infos.msg.Product}
              </th>
              <th className="px-4 py-3 relative text-dlg text-qosdark   ">
                {Infos.msg.Rate}
              </th>
              <th className="px-4 py-3 relative text-dlg text-qosdark  ">
                {" "}
                {Infos.msg.Status}
              </th>
              {/* <th className="px-4 py-3 relative text-dlg text-qosdark   ">
                {" "}
                {Infos.msg.Expiration}
              </th> */}
            </tr>
          </thead>
          <tbody>
            {loading
              ? "loading..."
              : list.data.datas.items.map((val, key) => {
                  return (
                    <tr className="text-center  bg-white" key={key}>
                      <td className="px-6 bg-white">
                        <div className="flex  ">
                          {val.service_name}
                          {/* { val.is_free === true ? <p style={styles}>FREE</p> : null} */}
                        </div>
                      </td>
                      <td className=" px-6 py-4  bg-white">
                        {val.fee}{" "}
                        {val.calculation_unit === "PERCENTAGE"
                          ? "%"
                          : val.calculation_unit === "AMOUNT"
                          ? "XOF"
                          : "%"}
                      </td>
                      <td className="px-6 py-4  bg-white">
                        {val.status}
                      </td>
                      <td className="px-6 py-4 bg-white">
                        {val.expiration_date}
                        {/* { val.expiration_date.length === 0 ? "-" : val.expiration_date}  */}
                        {/* <button className="bg-qosred p-2 ml-4 rounded-md text-white" onClick={(e) => UnsubscribeTo(val.service_name)}>Unsubscribe</button> */}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <style jsx>{`
              .no-vertical-lines td,
              .no-vertical-lines th {
                border-left: none !important;
                border-right: none !important;
              }
            `}</style>
      </div>
      </div>
    </div>
  );
}

const styles = {
  backgroundColor: "#0e730f",
  borderRadius: 3,
  padding: 2,
  marginLeft: 10,
  width: 60,
  borderColor: "#0e730f",
  alignContent: "center",
  borderWidth: 1,
  color: "white",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
};
