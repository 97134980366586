// import { Sidebar } from "../components/sidebar.paycomponentsimple";
// import { Header } from "../components/header.paycomponent";
import { useSideBarContext } from "../common/utils/context";
// import { BreadCrumb } from "../components/breadcrumb.component";

export default function PayLayoutSimple ({ children }) {
  const { Active, setActive } = useSideBarContext();
  return (
    <main className="min-h-screen relative flex overflow-hidden" style={{  background: "linear-gradient(to right, #DECBA4, #3E5151)" }}>
{/*   
      <Sidebar /> */}
      
   
      <section
        className={`${
          Active ? "w-full" : "w-full"
        } max-h-screen overflow-x-hidden overflow-y-auto pt-16`}
      >
        {/* <Header /> */}
      
        <section
          className={`px-4 ${
            Active ? "mx-0" : "mx-auto"
          } overflow-hidden`}
        >
      
          {/* koie */}
          {children}
          {/* okaa */}
        </section>
      </section> 
    </main>
  );
}
