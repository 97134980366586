import React, { useContext, useState } from "react";
import { useEffect } from "react";

let LangContext = React.createContext();
const SupportedLanguages = ["en", "fr"];

export default function LangsProvider({ children }) {
  const userLanguage = navigator.language.split("-")[0];

  function checkqosLangCookie() {
    let tab = document.cookie.split(";");
    for (let i = 0; i < tab.length; i++) {
      let smalltab = tab[i].split("=");
      for (let a = 0; a < smalltab.length; a++) {
        return tab[i] === "qosLangPrefer" ? tab[i + 1] : false;
      }
    }
  }
  const [Lang, setLang] = useState(
    localStorage.getItem("qosLangPrefer")
      ? localStorage.getItem("qosLangPrefer")
      : checkqosLangCookie()
      ? checkqosLangCookie()
      : SupportedLanguages.includes(userLanguage)
      ? userLanguage
      : "en"
  );

  useEffect(() => {
    localStorage.setItem("qosLangPrefer", Lang);
    document.cookie = `qosLangPrefer=${Lang}`;
  });

  return (
    <LangContext.Provider value={{ Lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
}

export function useLangContext() {
  return useContext(LangContext);
}
