
import { FetchData,PutData, PostData } from "../../common/services/httpServices";
import { Endpoints } from "../../common/utils/endpoints";
import { useMemo } from "react";
import { useState,  useRef,useEffect } from "react";
import Swal from "sweetalert2";
import {BankFilesUploader} from "../../pages/public/register/index"

import { useFormData } from "../../common/utils/hooks";




function DocumentsBank( ) {
    
 const [info, setInfo] = useState([]);
 const [loading, setLoading] = useState(true);

 const [data, handleInput] = useFormData();


 const modal = useRef();
 const modalLink = useRef();


//  console.log("mya data input", data)

  const inf = () => {
    FetchData(
        Endpoints.BankInfo.Getregistration,
       
      ).then((res) =>
         { setInfo(res);setLoading(false)}


      );
  }


  // BankInfo: {
  //   Getregistration: baseUrl.concat ("/api/v1/bankInfo/Get-registration"),
  //   RegisterBankInformation: baseUrl.concat ("/api/v1/bankInfo/registration"),
  // },

  useEffect(() => {
      inf()
  }, [] )


  const [doc, setDoc] = useState([]);
  const [selects, setSelects] = useState();
 

  const RIB = "RIB";
 
   const docu = () => {
     FetchData(
         Endpoints.Utilities.getDocumentType,
        
       ).then((res) =>
         { setDoc(res);setLoading(false)}
 
 
       );
   }
 
   useEffect(() => {
       docu()
   }, [] )
 
   const handleSelect = (e) => {
     e.preventDefault();
     setSelects(e.target.value)
   }
 
 

   function Submit(e) {
    
    e.preventDefault();
    // console.log("ici la data", data)
    
    PostData(
      Endpoints.BankInfo.RegisterBankInformation+"?document="+RIB,
      { ...data},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(
      (res) => {
        console.log("my res", res)
       if( res.data.responseMessage  === "Successful"  ){
          
        let message = res.data.responseMessage ;
        const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: message
      })
       }
      }
      
      
      ).catch((error) => {
        alert(error);
       })
     
      
      ;
  }



  return (
      <div className="block w-auto mt-5 rounded-lg" >

{/* <section
        id="modal"
        ref={modal}
        className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modal"
            ? modal.current.classList.add("-translate-y-full")
            : ""
        }
      >
        <form
         
          className="max-w-screen-sm duration-500 bg-white relative p-4 my-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
  
            <div className="flex  ">



            <div className="w-6/12">
            <select onChange={handleSelect} className="h-8 text-mmd px-4 md:w-40 min-w-max ">
                    
                    <option>Select document type</option>
                        
                        { loading ?  <option  className="text-white m-1" >IFU</option>:
                        
                        doc.data.datas.items.map(item => (
                    
                          <option  className="text-white m-1" value={item} key={item.index} >{item} </option>
                      
                  ))
                      }
                    
    
            </select>
            <div className="w-full   p-5">
            <BankFilesUploader  selects={selects} className="w-full  md:w-full" text="uploads your bussiness files" />

            </div>

            </div>


        <div className="w-6/12 justify-between ">
             <div className="flex w-full   p-5">
            
               <ul className="pt-5">
                   <li>1. Select the type of document you want to upload</li>
                   <li>2. Click on upload document icon and the you select your document from laptop</li>
               
                 </ul>
               </div>
           
         



 
            </div>
 

          </div>
        
        </form>
      </section> */}


{/* 

      <section
        id="modalLink"
        ref={modalLink}
        className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modalLink"
            ? modalLink.current.classList.add("-translate-y-full")
            : ""
        }
      >
   
      </section> */}



   






      <div className="md:flex flex-row  bg-white my-8 p-4 gap-3 w-100 bg-qosgray sm:block justify-center w-100 md:block w-100 justify-center sm:rounded-lg ">
      <div>
        <h4 className="text-dlg text-center text-qosdark text-opacity-70 my-2">
               BANK INFORMATION
        </h4>
      <div className="w-100  mt-5">

        
        <div className="w-1/2 w-full min-w-100 md:w-full  lg:w-full">
        <form className=" w-100" onSubmit={Submit}>
        <div className="block">
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
              bankId:
            </label>
            <input
              name="bankId"
              onChange={handleInput}
              type="number"
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
            Cle Rib:
            </label>
            <input
              name="cleRib"
              onChange={handleInput}
              type="text"
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
            Code Bank:
            </label>
            <input
             onChange={handleInput}
              name="codeBank"
              type="number"
              
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
            Code Guichet:
            </label>
            <input
             onChange={handleInput}
              name="codeGuichet"
            
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
            Code Pays:
            </label>
            <input
             onChange={handleInput}
              name="codePays"
              type="number"
            
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
            Num Account:
            </label>
            <input
             onChange={handleInput}
              name="numAccount"
              type="number"
            
            />
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <label className="mb-2 inline-block" htmlFor="amount">
             urlRib:
            </label>
            <input
             onChange={handleInput}
              name="urlRib"
              type="text"
            
            />
          </div>
        </div>
        <div className="items-left">
        <button className="bg-qosorange bg-opacity-70 mt-8 min-w-max w-44 mx-auto block p-2 text-black rounded-lg font-medium">
          Add 
        </button>

        </div>
        
        
      </form>

        </div>
      

      {/* <div className="flex-inline justify-start  content-start justify-items-start  w-1/2 w-full min-w-100 md:w-full lg:w-full">
         
      

              <div className="flex relative justify-start self-start content-start justify-items-start bg-qosorange ">
                  <button className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto block p-2 text-qosgray rounded-lg font-medium">
                    Upload file
                </button>
              </div> 
            

          
      </div>  */}
       {/* <div className=" max-w-screen-lg bg-white p-4 rounded-md flex justify-center items-center  mx-auto">
       
        <button
          onClick={() => modal.current.classList.remove("-translate-y-full")}
          className="bg-qosorange opacity-70 min-w-max w-44 block p-2 text-qosgray rounded-xl font-medium"
        >
          Add bank information
        </button>
      </div> */}
      


       

       </div>

           
       </div>

       

       </div>
       </div>
            



          

        

    
  )
}

export default DocumentsBank

