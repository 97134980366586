import { useEffect, useState } from "react";
import "./styles.css";
import { FetchData } from "../../common/services/httpServices";
import { Endpoints } from "../../common/utils/endpoints";
import { FilesUploader, FilesUploaders } from "../../pages/public/register/index";
import settings from "../../components/translation/settings.json";
import { useLangContext } from "../../components/translateContext";

function Documents(Account) {
  const [info, setInfo] = useState([]);
  const [selects, setSelects] = useState();
  const [loading, setLoading] = useState(true);
  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  const inf = () => {
    FetchData(Endpoints.Utilities.getDocumentType).then((res) => {
      setInfo(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    inf();
  }, []);

  const handleSelect = (e) => {
    e.preventDefault();
    setSelects(e.target.value);
  };

  //  const test = JSON.stringify(info.data.datas.items)

  return (
    <div className="block w-full mt-5 gap-4 bg-white rounded-lg">
      <div className="w-full ">
        <select
          onChange={handleSelect}
          style={styles.input}
          className="h-8 w-full text-mmd px-4  lg-w-full border bg-qoswhite"
        >
          <option>Select document type</option>

          {loading ? (
            <option className="text-white m-1">IFU</option>
          ) : (
            info.data.datas.items.map((item) => (
              <option className="text-white m-1" value={item} key={item.index}>
                {item}{" "}
              </option>
            ))
          )}
        </select>
      </div>

      {/* <select onChange={handleSelect} className="h-8 text-mmd px-4 md:w-40 min-w-max bg-qosorange">
                 {}
            </select> */}

      {/* lovw{selects} */}

      <div className="flex flex-col pl-14 justify-center align-center item-center mt-10  border w-auto  ">

        <div className="mt-5">
        <span className="text-black text-semibold">
          How to upload file
          </span>
        <ul className="pt-2 text-qosText">
            <li>1. {Infos.msg.Un}</li>
            <li>2. {Infos.msg.Two}</li>
          </ul>

        </div>
        <div className=" w-full mt-5 ">
        <span className="text-black text-semibold">
        Upload file here
        </span>

        <div className="pr-14 mb-14">
        <FilesUploaders
            Account={Account}
            selects={selects}
            className="w-full"
            text="Drag and drop or browse to choose a file"
          />
        </div>

          
          
        </div>
        
      </div>
    </div>
  );
}


const styles = {
  left: {
    
    marginTop:88
  },
  input:{
    // width:680,
    height:50,
    borderRadius:"4px",
  },
  button:{
    width: "200px",
    height: "45px",
    borderRadius:4,
  },
  button1:{
    width: "200px",
    height: "45px",
    borderColor:"#F59E0B",
    borderWidth:0.5,
    borderRadius:4,
  }
  
};

export default Documents;

/* export function FilesUploader({ text, className, handleSelect, selects }) {
    const addFileRef = useRef();
    const [Files, setFiles] = useState({
      array: [],
      list: new DataTransfer(),
    });
    function filesToArray(files) {
      let filesArray = [];
      for (let i = 0; i < files.length; i++) {
        filesArray.push(files.item(i));
      }
      return filesArray;
    }
  
    function handleFileList(e) {
      let files = e.target.files;
      let array = [];
      let previousFiles = Files.list;
      for (let i = 0; i < files.length; i++) {
        previousFiles.items.add(files.item(i));
      }
      e.target.files = previousFiles.files;
      array = filesToArray(previousFiles.files);
      setFiles({ array: array, list: previousFiles });
    }
  
    function delFile(index, e) {
      let dt = new DataTransfer();
      let files = addFileRef.current.files;
      for (let i = 0; i < files.length; i++) {
        if (index !== i) {
          dt.items.add(files.item(i));
        }
      }
      addFileRef.current.files = dt.files;
      setFiles({ array: filesToArray(dt.files), list: dt });
    }
  
    return (
      <div className={className}>
        <div
          onClick={() => addFileRef.current.click()}
          className="w-4/12 md:w-3/12 cursor-pointer rounded-xl my-2 border-2 overflow-hidden border-dashed border-qosdark border-opacity-40 p-2 text-center"
        >
          <img
            src={media.Icons[0].upload}
            className="opacity-70 md:h-11 mx-auto"
            alt="hello"
          />
          <input
            ref={addFileRef}
            onChange={ handleFileList}
            className="hidden"
            type="file"
            multiple
          />
          <span className="text-small text-qosdark text-opacity-70">{text}</span>
        </div>
        <div className="py-2 w-7/12 md:w-8/12 md:grid grid-cols gap-x-4 max-h-full overflow-auto hideScroll">
        
          {Files.array.map((file, index,) => (
              <div>
                <File key={index} index={index} name={file.name} delFunc={delFile}   />
                </div>
           
          ))}
        </div>
      </div>
    );
  }
  
  export function File({ delFunc, name, index, }) {
    return (
      <div className="p-1 my-1 bg-qosgray bg-opacity-80 h-8 shadow-md items-center rounded-md justify-between flex">
        
        <img
          src={media.Icons[0].file}
          className="opacity-70 h-6 p-1"
          alt="hello"
        />
        <p className="text-msm w-7/12 truncate mx-2">{name}</p>
        <p className="text-msm  truncate text-qosorange">pending</p>
        <button
          onClick={(e) => delFunc(index, e)}
          type="button"
          className="p-1 h-6 w-6 block"
        >
          <img
            src={media.Icons[0].delete}
            className="h-full w-full opacity-70"
            alt="deleteButton"
          />
        </button>
      </div>
    );
  } */
