import { useMemo, useRef, useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FetchData } from "../common/services/httpServices";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { Endpoints } from "../common/utils/endpoints";
import { useLangContext } from "../components/translateContext";
import link from "../components/translation/link.json";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import Form from "react-bootstrap/Form";
import "./table.css";
import {
  // useIsMobile,
  useFormData,
} from "../common/utils/hooks";
import { Icon } from "@iconify/react";
import { Spinner } from "../components/spinner.component";
import { DeletePamentLink2 } from "../common/auth";
import Swal from "sweetalert2";
import CommingSoon from "../components/commingSoon";

export function PaymentLinkDetails() {
  const [data, handleInput] = useFormData();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const location = useLocation();
  const { val } = location.state;

  const currentURL = useMemo(() => window.location.host);
  console.log("val", val);
  // console.log("currentURL", currentURL);
  //   console.log("props:--> state",state)
  //   console.log("props:--> props",props)
  const mot = currentURL.toString();
  let nevalue = mot.concat("/", val.url);

  console.log("nevalue", nevalue);
  const handleOpen = () => {
    setShow(true);
    setIsActive(!isActive);
  };
  const handleCclose = () => {
    setIsActive(!isActive);
    setShow(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(nevalue);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  async function handleDelete(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      // console.log("paymentlink Data : ", val.url);
      const { data: user, status } = await DeletePamentLink2(val.url);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        let message = user.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: message,
        });

        setIsLoading(false);
        setTimeout(() => {
          history.push("/paymentlink");
        }, 2000);
      } else if (user.responseStatus === false) {
        let message = user.responseMessage;

        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("erreur :", error);
      setIsLoading(false);
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Erreur serveur contactez support",
          showConfirmButton: false,
        });
      }, "100");
    }
    setIsLoading(false);
  }

  const linkBox = useRef();
  return (
<div className="mt-4">
  <div className="grid pr-0 pl-0 md:pr-20 md:pl-20 md:flex w-full h-full md:h-screen">
    <div className="w-full md:w-1/2 bg-coolGray-50 md:px-5">
      <div className="w-full h-20 mt-2 bg-coolGray-50 flex items-center">
        <span className="py-4 text-xl md:text-2xl">{val.page_name}</span>
      </div>
      <div className="w-full h-10">
        <span className="py-4 text-lg md:text-xl">
          Page description: {val.description}
        </span>
      </div>
      <div className="w-full h-12 pt-4 mb-2">
        <span className="text-lg md:text-xl">Page link</span>
      </div>
      <div className="flex w-full gap-2 pr-5 justify-center items-center">
        <div className="w-full">
          <input
            type="text"
            value={nevalue}
            readOnly
            style={styles.input}
            className="border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
          />
        </div>
        <div className="w-3/12">
          <button
            onClick={handleCopy}
            style={styles.input}
            className={`w-full border ${
              isCopied ? "bg-qosgreen" : "bg-qosgraylinkButton"
            } rounded-lg focus:outline-none focus:ring-1 focus:ring-qosgreen focus:border-qosgreen`}
          >
            {isCopied ? "Copied!" : "Copy"}
          </button>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex h-16 items-center justify-between px-4 border-qosgraye border-t">
          <span className="text-black text-sm md:text-base">Amount</span>
          <span className="text-sm md:text-base">{val?.product_price} {val?.productPrice} XOF</span>
        </div>
        <div className="flex h-16 items-center justify-between px-4 border-qosgraye border-t">
          <span className="text-black text-sm md:text-base">Status</span>
          <span className="text-sm md:text-base">Enabled</span>
        </div>
        <div className="flex h-16 items-center justify-between px-4 border-qosgraye border-t">
          <span className="text-black text-sm md:text-base">Page type</span>
          <span className="text-sm md:text-base">{val.type === "SINGLE_CHARGE" ? "One-time payment" : "Multiple payment"}</span>
        </div>
        <div className="flex h-16 items-center justify-between px-4 border-qosgraye border-t">
          <span className="text-black text-sm md:text-base">Date created</span>
          <span className="text-sm md:text-base">{val.createAt}</span>
        </div>
      </div>
      <div className="flex my-1 w-full mx-auto gap-4 lg:w-full">
        <button
          style={styles.button1}
          className="w-full border-sm rounded-sm border-qosorange flex justify-center items-center text-qosorange p-2 mt-4 md:mt-14"
        >
          Edit page
        </button>
        <button
          style={styles.button}
          disabled={isLoading}
          onClick={handleOpen}
          className="w-full flex justify-center items-center text-white bg-qosred p-2 mt-4 md:mt-14"
        >
          <div className="flex gap-2">Delete page</div>
        </button>
      </div>
    </div>
    <div className="w-full md:w-1/2 bg-white">
      <div className="mt-10">
        <div className="flex h-14 items-center px-5">
          <div className="w-1/2">
            <span className="text-black">0</span>
          </div>
          <div className="w-1/2 text-left md:pl-10">
            <span className="text-center">00</span>
          </div>
        </div>
        <div className="flex items-center justify-between px-5 border-qosgraye">
          <div className="w-1/2">
            <span className="text-black">No of views on link</span>
          </div>
          <div className="w-1/2 text-left md:pl-10">
            <span className="text-center">Revenue via link</span>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="text-center w-full h-20">
          <span className="text-xl md:text-2xl font-semibold">Payment history</span>
        </div>
        <div className="flex bg-qosgraylink h-20 items-center justify-between px-5">
          <span className="text-black">Payment</span>
          <span className="">Date</span>
        </div>
        <div className="flex w-full items-center  justify-center px-5 border-qosgraye">
          <div className="mt-10 text-center">
            <span className="text-black  text-xl md:text-2xl font-medium">No payment history</span>
            <p className="text-qosgraye text-lg p-3">
              Once you start receiving payment, <br />
              your payment history will be displayed here
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

    {/* modal delete paymentLink */}

    <Modal
        show={show}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modale"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <Modal.Body>
          <div className="">
            <div className="w-full gap-2 justify-center items-center md:mt-10 ">
              <div className="h-10 justify-center  text-center md:mb-4">
                <p className="text-semibold text-xl text-qosTab">
                  Delete page
                </p>
              </div>

              <div className="w-full px-5 md:px-26 justify-center text-qosText items-center text-center">
                <p className="text-semibold text-qosText">
                  Are you sure you want to delete this payment link?
                </p>
                <p className="text-semibold text-qosText">
                  Please note that you can’t undo this action.
                </p>
              </div>

              <div className="w-full md:flex mt-10 md:px-10 px-4 md:gap-4 mb-20">
                <button
                  style={styles.button2}
                  disabled={isLoading}
                  onClick={handleCclose}
                  className={` w-full xl:w-12/12 lg:w-12/12  border-sm rounded-sm border-qosorange  flex justify-center items-center  text-qosorange  p-2  mx-auto mt-4`}
                >
                  Cancel
                </button>

                <button
                  style={styles.button2}
                  disabled={isLoading}
                  onClick={handleDelete}
                  className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80  ${
                    isLoading ? "bg-coolGray-300" : "bg-qosorange"
                  }  p-3 btn-sm mx-auto mt-4 md:mt-14`}
                >
                  {isLoading ? (
                    <Spinner className="border-qosorange h-4 w-4 mx-1" />
                  ) : (
                    <div className="flex gap-2  justify-center align-center">
                      <span>Delete payment link</span>
                      <span className="fi-rr-arrow-right mt-1"></span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
</div>

  );
}

const styles = {
  input: {
    // width:680,
    height: "52px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 4,
  },

  button1: {
    borderWidth: 1,
    borderRadius: 4,
  },
  button2: {
    height: "45px",
    borderWidth: 1,
    borderRadius: 4,
  },
};
