import { isString } from "lodash";
import { useState } from "react";
import { isNullOrUndefined, isObject } from "../services/tools";


export const useFormData = (defaultState) => {
  const [data, setData] = useState({ ...defaultState });

  function handleInput(e) {
    if (!isNullOrUndefined(e.target)) {
      if (e.target.name === "paymentMode") {
        setData({ ...data, [e.target.name]: JSON.parse(e.target.value) });
      } else {
        setData({ ...data, [e.target.name]: e.target.value });
      }
    } else if (isObject(e) && !isString(e)) {
      setData({ ...data, ...e });
    } else if (isString(e) && isObject(JSON.parse(e))) {
      console.log(e, "not take");
      let payloadToObj = JSON.parse(e);
      setData(...data, ...payloadToObj);
    }
  }
  return [data, handleInput];
};