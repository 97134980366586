import { GiConsoleController } from "react-icons/gi";
import { IdFilesUploader } from "../pages/public/register/index";
import { RccmFilesUploader } from "../pages/registerBusness";

function RccmDocument() {
  return (
    <div className="mt-5 text-left ">
      <div className="flex w-auto border-black ">
        <p className="text-qosdark">Upload copy of business registration document or certificate</p>
      </div>
      <div className="flex w-auto">
        {/* <IdFilesUploader selec={selec.selects} className="w-full" text="uploads your Id " /> */}
        <RccmFilesUploader
          className="w-full"
          text="Upload document"
        />
      </div>
    </div>
  );
}

export default RccmDocument;
