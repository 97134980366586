// import { useHistory } from "react-router";
import { encryptStorage } from "./encryptData";
import axios from "axios";
import { isEmptyString, isNullOrUndefined } from "./tools";

import { browserName, browserVersion } from "react-device-detect";
import {useReactIpLocation} from "react-ip-details";




// // localStorage.setItem('items', JSON.stringify(ip));
// const ip = JSON.parse(localStorage.getItem('items'));
// // console.log("mon ipeppe:", ip)

// const contry = JSON.parse(localStorage.getItem('pays'));
// // console.log("mon contryy:", contry)

// const city = JSON.parse(localStorage.getItem('cite'));
// // console.log("mon cityy:", city)





axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";


  // axios.defaults.headers.post["q-device"] = [
  //   ip,
  //   contry,
  //   city,
  //   browserName,
  // ]
  // ;



function getUserToken() {
  let token = encryptStorage.getItem("qosToken");
  if (!isNullOrUndefined(token) && !isEmptyString(token))
    return `Bearer ${token}`;
  return false;
}

export function setUserToken(token) {
  if (!isNullOrUndefined(token) && !isEmptyString(token)) {
    encryptStorage.setItem("qosToken");
    return true;
  }
  return false;
}



axios.interceptors.request.use(
  (request) => {
    let token = getUserToken();
    if (token) {
      request.headers.Authorization = token;
    }
    // request.headers['q-device']=[
    //   ip,
    //   contry,
    //   city,
    //   browserName,
    // ];
    return request;
  },


  function (error) {
    return Promise.reject(error);
  }
);


// function review (){
//   retrun 
// }

axios.interceptors.response.use(
  (request) => {
    // console.log(" response deux ::", request )
    return request;
  },

  function (error) {
    // console.log("vouos :", error.response)
    if(error.response )
    {
      if (error.response.status === 403 ) {
        encryptStorage.clear();
        window.location.reload();
        // window.location.href = window.location.origin+'/'
        
    } 

    }
    
  //   //window.location.href = 'http://www.google.com';
    return Promise.reject(error);
  }
);

export const http = {
  post: axios.post,
  get: axios.get,
  put: axios.put,
  delete: axios.delete,
};
export async function FetchData(url = "", config = {}) {
  try {
    const res = await http.get(url, config);
    return res || {};
  } catch (error) {}
}

export async function PutData(url = "", data = {}, config = {}) {
  try {
    const res = await http.put(url, data, config);
    return res || {};
  } catch (e) {
    // console.log('tokoss :', e)
  }
}

export async function PostData(url = "", data = {}, config = {}) {
  try {
    const res = await http.post(url, data, config);
    
    return res || {};
  } catch (error) {
    // console.log('tokoss :', error)
  }
}
