import Popover from "react-bootstrap/Popover";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useLangContext } from "../components/translateContext";
import transfer from "../components/translation/transfer.json";
import { encryptStorage } from "../common/services/encryptData";
import {
  FetchData,
  //  PostData
} from "../common/services/httpServices";
import Table from "react-bootstrap/Table";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import { Icon } from "@iconify/react";

import TransferComponent from "../components/transfert.component";
import { Withdrawal, CardPayout } from "../common/auth";
import { Spinner } from "../components/spinner.component";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import "./table.css";

export function Transferts() {
  const [state, setstate] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  // console.log("state", state)

  return (
    <section className="p-4">
       { state === 0 ? ( <> 
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
        {Infos.msg.Transferh} (0)
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        {Infos.msg.See} 
        </span>
      </div> 
      </>  ) : (
        <> <div className="mt-12 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
          {Infos.msg.Transfer}
        </p>
      </div>
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className=" ">
        {Infos.msg.ChooseTop} (0)
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        {Infos.msg.Seeal} 
        </span>
      </div> 
      </> 
      ) }
      <header className="mt-10">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-32 sm:w-32 md:w-44  text-white h-full bg-qosorange rounded-full p-2 md:px-4`}
          ></div>
          {/* <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-32 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-12`}
          >
            {Infos.msg.Transfer}
          </button> */}
          {/* <button
          onClick={() => setstate(1)}
          className={`transition-all duration-500 ${
            state === 1 && "text-white"
          } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
        >
          TopUp
        </button> */}
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <Transfers />}
          {/* {state === 1 && <TopUpState />} */}
        </section>
      </div>
    </section>
  );
}

function Withdraw() {
  const { pathname } = useLocation();

  const [myAmount, setMyAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  // const [toggle, setToggle] = useState(!true);

  const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);

  const [Infocountries, setInfocountries] = useState([]);
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState([]);
  const [chosenPays, setchosenPays] = useState("Benin");

  const [number, setNumber] = useState([]);

  const [numberLoading, setNumberLoading] = useState(true);

  const [network, setNetwork] = useState("");
  const [val, setNewval] = useState("");

  const modal = useRef();
  const modalLink = useRef();

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    countries();
  }, []);

  function ListOfOperator(pays) {
    // console.log("pays",pays)
    if (pays.target) {
      FetchData(
        Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
      ).then((res) => {
        setchosenPays(pays.target.value);
        setlistOperators(res.data.datas.items);
      });
    }
  }

  function getAmout(operateur) {
    FetchData(
      Endpoints.User.getAvailableAmount +
        "?country=" +
        chosenPays +
        "&operator=" +
        operateur.target.value
    ).then((res) => {
      setMyAmount(res.data.datas.amount);
      setLoading(true);
    });
  }

  const [monNumero, setMonNumero] = useState("");

  function handleChange(event) {
    // console.log("voila toi nous maj",event.target.value);
    getAmout(event);
    setNetwork(event.target.value);
    setNewval(event.target.value);

    FetchData(Endpoints.User.getUserMerchantNumbers).then((res) => {
      setNumber(res);
      setNumberLoading(false);
      // console.log("moo po", network );

      if (res.data.datas.items.length !== 0) {
        // console.log("voila toi nous maj",event.target.value);
        for (let x in res.data.datas.items) {
          let elem = res.data.datas.items;
          if (network !== undefined) {
            if (
              event.target.value.toLowerCase() ===
              elem[x].operator.toLowerCase()
            ) {
              // console.log("voila toi nous", elem[x].operator.toLowerCase());
              setMonNumero(elem[x].merchantNumber);
            }
          }
        }
      }
    });
  }

  // console.log("mon numero :", numberLoading ?  "tokoss" :number.data.datas.items[0].merchantNumber );

  // let monNumero = numberLoading ?  "" : number.data.datas.items[0].merchantNumber;
  // let monNumero1 = numberLoading ?  "" :number.data.datas.items[1].merchantNumber;

  //  console.log("tu vois ça", network)

  //  let nethuiwork = numberLoading ?  "" : network;
  //  console.log("tu vois pas nonn", nethuiwork)

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // let ope = {net :network};

    let newoperateur = { operator: network };
    let Newdata = {
      ...data,
      ...newoperateur,
    };
    // console.log("widraw Data : ", Newdata)
    // console.log("myAmount : ", myAmount)
    // console.log("montant", Number(Newdata.amount))

    // let val = myAmount - Number(Newdata.amount);
    // console.log("valeur", val)
    // if ( myAmount - Number(Newdata.amount) <= 100 ){
    //   let message = "The remain in your account must at least be 100 XOF";
    //   const Toast = Swal.mixin({
    //     toast: true,
    //     position: "top",
    //     timer: 5500,
    //     showConfirmButton: false,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", Swal.stopTimer);
    //       toast.addEventListener("mouseleave", Swal.resumeTimer);
    //     },
    //   });
    //   setTimeout(() => {
    //     setIsLoading(false);
    //   }, 20000);

    //   Toast.fire({
    //     icon: "error",
    //     title: message,
    //   });
    //   setIsLoading(false);
    // }else{
    // }
    try {
      // console.log("widraw Data : ", Newdata)
      // console.log("montant", Newdata.amount)

      const { data: user } = await Withdrawal(Newdata);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        let message = user.datas.responsemsg;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 20000);

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (user.responseStatus === false) {
        // console.log("rerere", user.datas.responsemsg)
        // console.log("error", user.responseMessage)

        // let message = user.datas.responsemsg || user.datas.responseMessage;
        if (
          user.datas.responsemsg === undefined ||
          user.datas.responsemsg === ""
        ) {
          let message = user.responseMessage;
          return setTimeout(() => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 20000);
            Toast.fire({
              icon: "error",
              title: message,
            });
          }, "100");
        } else {
          let message = user.datas.responsemsg;
          return setTimeout(() => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 20000);
            Toast.fire({
              icon: "error",
              title: message,
            });
          }, "100");
        }
      }
    } catch (error) {
      // console.log("erreur :", error)

      setTimeout(() => {
        setIsLoading(false);
      }, 10000);
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Verify the information entered",
          showConfirmButton: false,
        });
      }, "100");
    }

    // setLoading(false);
  }

  async function cardSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // let ope = {net :network};

    let Newdata = {
      ...data,
    };

    Newdata.mode =
      Newdata.mode === "MOOV 0.5%"
        ? "MOOV"
        : Newdata.mode === "MTN 0.5%"
        ? "MTN"
        : Newdata.mode === "BANK 0.0%"
        ? "Bank"
        : Newdata.amount;

    try {
      // console.log("Card Data : ", Newdata)
      const { data: user } = await CardPayout(Newdata);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        let message = user.datas.responsemsg;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 20000);

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (user.responseStatus === false) {
        let message = user.datas.responsemsg;
        return setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 20000);
          Toast.fire({
            icon: "error",
            title: message,
          });
        }, "100");
      }
    } catch (error) {
      // console.log("erreur :", error)

      setTimeout(() => {
        setIsLoading(false);
      }, 20000);
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Verify the information entered",
          showConfirmButton: false,
        });
      }, "100");
    }
    // setLoading(false);
  }

  return (
    <div className="max-w-screen-xl relative overflow-hidden mx-auto my-2">
      <section
        id="modal"
        ref={modal}
        className="absolute transform -translate-y-full  duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-full h-full left-0"
        onClick={(e) =>
          e.target.id === "modal"
            ? modal.current.classList.add("-translate-y-full")
            : ""
        }
      >
        <div className="max-w-screen-sm duration-500 bg-white rounded relative p-4 my-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="col-start-1 col-end-7 justify-center text-center">
            <div className=" p-5">
              <h4>For card payout send a request to our email </h4>

              <h3 className="mt-10">
                {" "}
                <a href="mailto:support@qosic.com" className="text-qosorange">
                  support@qosic.com
                </a>{" "}
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="modalLink"
        ref={modalLink}
        className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modalLink"
            ? modalLink.current.classList.add("-translate-y-full")
            : ""
        }
      ></section>

      <div className="">
        <div className="flex  justify-between ">
          <div>
            <select
              onChange={(e) => ListOfOperator(e)}
              className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
            >
              {/* <option value="" disabled selected hidden >BENIN</option> */}
              {doloading
                ? "BENIN"
                : Infocountries.data.datas.items.map((item) => (
                    <option className="text-black m-1" key={item.index}>
                      {item}
                    </option>
                  ))}
            </select>
          </div>

          <div>
            {doloading ? (
              <select
                onChange={handleInput}
                id="operators"
                name="operators"
                autoComplete="operators"
                className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
              >
                <option className="text-black m-1">loading...</option>
              </select>
            ) : (
              <select
                id="operators"
                onChange={handleChange}
                name="operators"
                autoComplete="operators"
                className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
                >
                  Select an operator
                </option>
                {listOperators.map((item) => (
                  <option key={item.index}>{item}</option>
                ))}
              </select>
            )}
          </div>

          {/* {loading ? " oksy" :number.data.datas.items[0].operator } */}
        </div>
      </div>

      {/* <Switch setOperator={setOperator} /> */}
      <div className="max-w-screen-xl bg-white border-none  rounded-t-lg overflow-x-hidden my-4 mx-auto">
        <div className="flex bg-qosblue bg-opacity-10 p-2 px-4 rounded-t-lg items-center mb-4 justify-between">
          <h3 className="md:text-mH2 text-black text-center">
            {Infos.msg.Avalaible}
          </h3>
          {/* <h3 className="md:text-mH2 text-black text-center">Account Balance</h3> */}
          <h3 className="md:text-mH2 text-black text-center font-sans">
            {myAmount}
            <span className="text"> XOF</span>
          </h3>
        </div>
        <header className="my-4 mx-auto max-w-max"></header>

        {val === "CARD" ? (
          // <section className="h-full  md:h-100 overflow-hidden">
          //   <div
          //     className={`transform transition-transform flex duration-500 ${
          //       success && "translate-y-full"
          //     } h-56 md:h-44`}
          //   >
          //     {/* <div class="flex space-x-4 ..."> */}
          //       {/* <div class="flex w-full  justify-self-center ...">
          //         <button
          //           disabled
          //           // disabled={loading}
          //           className="bg-qosorange bg-opacity-70 flex justify-between  items-center p-2 text-qosgray rounded-lg font-medium mx-auto"
          //           style={{
          //             width: 340,
          //             justifyContent: "center",
          //             color: "black",
          //           }}
          //         >
          //           {isLoading ? (
          //                     <Spinner className="border-qosorange h-4 w-4 mx-1" />
          //                   ) : "Send"}
          //           NOT AVAILABLE NOW
          //         </button>
          //       </div> */}

          //       <div className=" max-w-screen-lg bg-white p-4 rounded-md flex justify-self-center justify-center items-center  mx-auto">
          //         <button
          //           onClick={() =>
          //             modal.current.classList.remove("-translate-y-full")
          //           }
          //           className="bg-qosorange bg-opacity-70 flex justify-between  items-center p-2 text-qosgray rounded-lg font-medium mx-auto"
          //           style={{
          //             width: 300,
          //             justifyContent: "center",
          //             color: "black",
          //           }}
          //         >
          //           Request payment
          //         </button>
          //       </div>
          //     </div>
          //   {/* </div> */}
          // </section>
          <section className="h-101 justify-center  md:h-101 overflow-hidden">
            <div
              className={`transform transition-transform flex duration-500 ${
                success && "translate-y-full"
              } h-72 md:h-56`}
            >
              <form
                onSubmit={cardSubmit}
                className={`min-w-full transform transition-transform duration-500 ${
                  failed ? "translate-x-full" : "translate-x-0"
                }`}
              >
                <div className="justify-center justify-items-center content-center self-center items-center mt-10 md:grid  px-4 mx-auto max-w-max">
                  {/* <div>{network}</div> */}
                  <div className="sm:flex">
                    <div className="mx-auto md:w-96 w-full md:max-w-sm  md:mb-0 relative">
                      <label htmlFor="merchantNumberTrans" className="">
                        {Infos.msg.Payment}:
                      </label>
                      <div className="relative  ">
                        <select
                          onChange={handleInput}
                          id="mode"
                          name="mode"
                          autoComplete="mode"
                          className="h-8 text-black text-mmd px-4 md:w-full min-w-max bg-qosorange  bg-opacity-70"
                        >
                          <option
                            className="text-black m-1"
                            disabled
                            selected
                            hidden
                          >
                            {Infos.msg.Choose}
                          </option>
                          <option className="text-black m-1">MTN 0.5%</option>
                          <option className="text-black m-1">MOOV 0.5%</option>
                          <option className="text-black m-1">BANK 0.0%</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="sm:flex  mx-auto md:w-96 w-full md:max-w-sm mt-2 mb-2">
                    <div className="mx-auto  w-full md:max-w-sm mt-2 md:mb-0 relative">
                      <label htmlFor="Description" className="">
                        {Infos.msg.Description}:
                      </label>
                      <div className="relative  ">
                        <textarea
                          id="w3review"
                          name="w3review"
                          rows="4"
                          cols="50"
                          onChange={handleInput}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="mx-auto md:w-96 w-full md:max-w-sm mt-2 mb-2">
                    <label htmlFor="amount">{Infos.msg.Amount}:</label>
                    <div className="relative">
                      <input
                        id="amount"
                        type="tel"
                        name="amount"
                        onChange={handleInput}
                        required="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex space-x-4 ...">
                  <div class="flex w-full  mb-5 justify-center items-center align-center text-center  justify-self-center ...">
                    <button
                      disabled={isLoading}
                      className={`w-8/12 h-12 flex justify-center  md:w-96 mb-5 items-center justify-center items-center rounded-md justify-center items-center text-qosgray text-opacity-80 font-bold ${
                        isLoading ? "" : "bg-qosorange"
                      } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-4 mb-4`}
                    >
                      {isLoading ? (
                        <Spinner className="border-qosorange h-4 w-4 mx-1" />
                      ) : (
                        "Request payment"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        ) : (
          <section className="h-72 md:h-56 overflow-hidden">
            <div
              className={`transform transition-transform flex duration-500 ${
                success && "translate-y-full"
              } h-72 md:h-56`}
            >
              <form
                onSubmit={handleSubmit}
                className={`min-w-full transform transition-transform duration-500 ${
                  failed ? "translate-x-full" : "translate-x-0"
                }`}
              >
                <div className="md:flex px-4 mx-auto max-w-max">
                  {/* <div>{network}</div> */}
                  <div className="sm:flex">
                    <div className="mx-auto sm:mx-1 w-full md:max-w-sm mt-2 md:mb-0 relative">
                      <label htmlFor="merchantNumberTrans" className="">
                        {Infos.msg.Receiver}:
                      </label>
                      <input
                        onChange={handleInput}
                        id="merchantNumberTrans"
                        name="phoneNumber"
                        type="tel"
                        placeholder="-"
                        required="true"
                        defaultValue={
                          monNumero === "null" || monNumero === "CARD"
                            ? "-"
                            : monNumero
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="mx-auto md:w-96 w-full md:max-w-sm mt-2 mb-2">
                    <label htmlFor="amount">{Infos.msg.Amount}:</label>
                    <div className="relative">
                      <input
                        id="amount"
                        type="tel"
                        name="amount"
                        onChange={handleInput}
                        required="true"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex space-x-4 ...">
                  <div class="flex w-full  mb-5 justify-center items-center align-center text-center  justify-self-center ...">
                    <button
                      disabled={isLoading}
                      className={`w-8/12 h-12 flex justify-center mb-5 items-center justify-center items-center rounded-md justify-center items-center text-qosgray text-opacity-80 font-bold ${
                        isLoading ? "" : "bg-qosorange"
                      } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-4 mb-4`}
                    >
                      {isLoading ? (
                        <Spinner className="border-qosorange h-4 w-4 mx-1" />
                      ) : (
                        `${Infos.msg.Button}`
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
    </div>
  );
}

function Transfers() {


  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  const [loading, setLoading] = useState(false)
  const [ transaction , SetTransaction ] = useState([])


  const TopuGetTransaction = () => {
    FetchData(Endpoints?.Transfer?.TransfertTransaction)?.then((res) => {
      // console.log("Top Up tarnscation ==>", res)
      SetTransaction(res);
      setLoading(false);
    });
  };

  useEffect(()=>{
    TopuGetTransaction()
  },[])

  const popover = (
    <Popover id="popover-basic" className="rounded-0 right-10">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        <div className="flex-column gap-2 mx-2.5">
          <Link to="/Singletransfer">
            <div className="flex text-black gap-2 ">
              <Icon
                icon="solar:send-square-outline"
                height={20}
                style={{ color: "black", fontSize: "20px" }}
              />
              Single transfer
            </div>
          </Link>

          <Link to="/Bulktransfer" className="">
            <div className="flex  mt-2 text-vlack gap-2">
              <Icon
                icon="solar:send-twice-square-outline"
                height={20}
                style={{ color: "black", fontSize: "20px" }}
              />
              Bulk transfer
            </div>
          </Link>
        </div>
      </Popover.Body>
    </Popover>
  );

  function handleEditToast  () {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "error",
      title: `${Infos.msg.ena}`,
    });
  }

  return (
    <div className=" relative overflow-hidden  mx-auto my-2">
  
  {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
            encryptStorage.getItem("qosUser")?.isUsing2Fa == null ?(
              <div className="mt-10 max-w-full lg border bg-white p-4 rounded-md flex justify-between items-center mx-auto">
              <p className="font-semibold text-qosdark text-opacity-90">
                {/* {`${loadingLink ?  "loading...": Links.data.datas.items.length} ${Infos.msg.links}`}{" "} */}
              </p>
      
              
                <button
                  onClick={handleEditToast}
                  className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
                >
                  {Infos.msg.Newtr}
                  <Icon
                    icon="ri:add-line"
                    height={24}
                    style={{ color: "white", fontSize: "20px" }}
                  />
                </button>
            
            </div>
            ):(
              <div className="mt-10 max-w-full lg border bg-white p-4 rounded-md flex justify-between items-center mx-auto">
              <p className="font-semibold text-qosdark text-opacity-90">
                {/* {`${loadingLink ?  "loading...": Links.data.datas.items.length} ${Infos.msg.links}`}{" "} */}
              </p>
      
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <button
                  // onClick={() => modal.current.classList.remove("-translate-y-full")}
                  className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
                >
                  {Infos.msg.Newtr}
                  <Icon
                    icon="ri:add-line"
                    height={24}
                    style={{ color: "white", fontSize: "20px" }}
                  />
                </button>
              </OverlayTrigger>
            </div>
            )

            }


      <div className="w-full max-w-full h-screen  xl overflow-x-auto hideScroll  mt-8">
        <table id="customers">
          <thead className="bg-qosred">
            <tr className="min-w-full text-center ">
              <th className="px-4 py-3 relative  text-qosTab tracking-tighter leading-3 truncate ">
              Type
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate">
              Amount
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate ">
              Account number
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate ">
              Account name
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate ">
              Date
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate ">
              Reference
              </th>
              <th className="px-4 py-3  relative  text-qosTab tracking-tighter leading-3 truncate ">
              Status
              </th>
            </tr>
          </thead>
          <tbody>
          {transaction?.data?.datas?.map(( items) =>{
            return(
              <tr className=" text-center w-full" key={items.id}>
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.typeTransfert}
              </td>
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.amount}
              </td>
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.accountNumber}
              </td> 
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.accountName}
              </td>
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.transfertDate}
              </td> 
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.transfertReference}
              </td>
              <td className="px-6 text-center divide-qosorange py-4 whitespace-nowrap">
                {items.status}
              </td>
              </tr>
            )
          } ).reverse()}
          </tbody>
        </table>
      </div>

      {/* <TransferComponent /> */}
    </div>
  );
}

function TopUpState() {

  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  const [loading, setLoading] = useState(false)
  const [ transaction , SetTransaction ] = useState([])

  const TopuGetTransaction = () => {
    FetchData(Endpoints.Transfer.TopupTransaction).then((res) => {
      // console.log("Top Up tarnscation ==>", res)
      SetTransaction(res);
      setLoading(false);
    });
  };

  useEffect(()=>{
    TopuGetTransaction()
  },[])
 
  return (
    <div className=" relative overflow-hidden  mx-auto my-2">
  

      <div className="mt-10 max-w-full lg border bg-white p-4 rounded-md flex justify-between items-center mx-auto">
        <p className="font-semibold text-qosdark text-opacity-90">
          {/* {`${loadingLink ?  "loading...": Links.data.datas.items.length} ${Infos.msg.links}`}{" "} */}
        </p>

        <Link to="/topup" className="">
          <button
            // onClick={() => modal.current.classList.remove("-translate-y-full")}
            className="flex justify-between gap-2 bg-qosorange  min-w-max px-8 block py-2 text-white rounded-sm font-medium"
          >
            {Infos.msg.TopUp}
            <Icon
              icon="ri:add-line"
              height={24}
              style={{ color: "white", fontSize: "20px" }}
            />
          </button>
          </Link>
    
      </div>

      <div className="w-full  mt-8 border">
        <table id="customers">
          <thead>
            <tr className="min-w-full text-center ">
              <th className="px-4 py-3 relative  text-qosTab  tracking-tighter leading-3 truncate ">
              Channel
              </th>
              <th className="px-4 py-3  relative  text-qosTab  tracking-tighter leading-3 truncate">
              Amount
              </th>
              <th className="px-4 py-3  relative  text-qosTab  tracking-tighter leading-3 truncate ">
              Account 
              </th>
              <th className="px-4 py-3  relative  text-qosTab  tracking-tighter leading-3 truncate ">
              Date
              </th>
              <th className="px-4 py-3  relative  text-qosTab  tracking-tighter leading-3 truncate ">
              Reference
              </th>
              <th className="px-4 py-3  relative  text-qosTab  tracking-tighter leading-3 truncate ">
              Status
              </th>
            </tr>
          </thead>
          <tbody>
          {transaction?.data?.datas?.map(( items) =>{
            return(
              <tr className=" text-center w-full" key={items.id}>
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.channel}
              </td>
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.amount}
              </td>
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.account}
              </td> 
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.dateRequest}
              </td>
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.transactionId}
              </td> 
              <td className="px-6  divide-qosorange py-4 whitespace-nowrap">
                {items.status}
              </td>
              </tr>
            )
          } ).reverse()}
          </tbody>
        </table>
      </div>

      {/* <TransferComponent /> */}
    </div>
  );
}
