import { useState, useMemo, useEffect } from "react";
import {
  BsFillFlagFill,
  BsAppIndicator,
  BsFillChatSquareDotsFill,
  BsFillHandThumbsUpFill,
  BsFillEnvelopeFill,
  BsWhatsapp,
  BsFillFileTextFill,
} from "react-icons/bs";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { FetchData } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { encryptStorage } from "../common/services/encryptData";
// import { IconLink } from "./iconLink.component";
import { IconLink } from "../components/iconLink.component";
import Messages from "./translations/getstrated.json";
import { useLangContext } from "../components/translateContext";


export function GetStarted() {
  const [list, setList] = useState([]);
  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang] };
  // const [selects, setSelects] = useState();
  const [loading, setLoading] = useState(true);

  const List = () => {
    FetchData(Endpoints.Documents.documentsUploadedList).then((res) => {
      // console.log("res", res);
      setList(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    List();
  }, []);

  const documentList = loading ? "" : list?.data?.datas?.items?.length;

  encryptStorage.getItem("qosUserLog")

  // console.log("encryptStorage. :", encryptStorage.getItem("qosUserLog"));
  // console.log("documentList", documentList);

  return (
    <section className="mt-14">
      <div className=" m-10 flex text-left gap-1">
        <p
          className="pt-2 text-semibold "
          style={{ fontSize: 20, color: "#45464E" }}
        >
          {Infos.msg.Title0}
        </p>
      </div>
      <div className="flex text-left gap-1">
        <h4 style={{ color: "#45464E" }} className="pl-10 ">
        {Infos.msg.Title1}{" "}
        </h4>
        <h4 style={{ color: "#45464E" }} className=" ">
          QOSPAY
        </h4>
      </div>
      <div className="pl-10 ">
        <p className="pt-2">
        {Infos.msg.Title2}
        </p>
      </div>

      <div className="rounded  m-10 rounded-lg flex  ">
        <div
          style={styles.box}
          className="bg-white   p-10 mb-8 filter drop-shadow-sm  flex flex-col  w-1/2  gap-4  "
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              {/* <BsFillFileTextFill color="orange" width={40} height={40} /> */}
              <Icon
                icon="carbon:document"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2"> {Infos.msg.Documents}</h5>
            </div>
          </div>
          <div>
            <span className="text-qosText">
            {Infos.msg.DocumentText}
            </span>
          </div>

          <div>
            {/* <Link to="/typeofbusiness">
              <button
                style={styles.button}
                className="bg-qosorange text-white "
              >
                Activate account
              </button>
            </Link> */}
            {encryptStorage.getItem("qosUserLog").submitDoc === null || encryptStorage.getItem("qosUserLog").submitDoc === false ? (
             <Link to="/typeofbusiness">
             <button
               style={styles.button}
               className="bg-qosorange text-white"
             >
               {Infos.msg.BtnActi}
             </button>
           </Link>
            ) : (
              
               <Link to="/status">
               <button
                 style={styles.button}
                 className="bg-qosorange text-white"
               >
                 {Infos.msg.BtnStat}
               </button>
             </Link>
            )}
          </div>

          {/* <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)} */}
        </div>
        <div
          style={styles.box}
          className="bg-white p-10 mb-8 filter drop-shadow-sm  flex flex-col  w-1/2  gap-4  "
        >
          <div className="flex w-full gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              {/* <BsFillFileTextFill color="orange" width={40} height={40} /> */}
              <Icon
                icon="fluent:plug-disconnected-28-filled"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Api}</h5>
            </div>
          </div>
          <div>
            <span className="text-qosText">
            {Infos.msg.ApiText}
            </span>
          </div>

          <div>
            <a
              href="https://www.qosic.com/docs"
              target="_blank"
            >
              <button
                style={styles.button}
                className="bg-qosorange text-white "
              >
               {Infos.msg.BtnView}
              </button>
            </a>
          </div>

          {/* <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)} */}
        </div>

        {/* <div
          style={{
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "#E0E0E0",
          }}
          className="bg-white mt-8  mb-8 filter drop-shadow-sm  rounded-lg flex flex-col  w-1/2  gap-4 justify-center items-center justify-items-center content-center "
        >
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)}
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-8 p-8 w-2/5  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillChatSquareDotsFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Contact support</p>
            <div className="flex pt-3 gap-3 justify-center items-center text-center">
              <BsFillEnvelopeFill color="black" width={25} height={70} />
              <p className="text-qosorange font-bold ">support@qosic.com</p>
            </div>

            <div className="flex pt-3 gap-3 justify-left items-center ">
              <BsWhatsapp color="black" width={25} height={70} />
              <a className="bg-green" href="https://wa.me/22994285672">
                Whatsapp
              </a>
            </div>
          </div>
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-3 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillHandThumbsUpFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Check your activation status or Add additional document</p>
          </div>
          { documentList !=0 ? (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>): (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>)  }
        </div> */}
      </div>

      <div className="rounded m-10 rounded-lg flex  ">
        <div
          style={styles.box}
          className="bg-white p-10 mb-8 filter drop-shadow-sm  flex flex-col  w-1/2"
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              <Icon
                icon="carbon:security"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Test}</h5>
            </div>
          </div>
          <div className="pt-2">
            <span className="text-qosText">
            {Infos.msg.TestText}
            </span>
          </div>

          <div className="flex mt-2">
            <div className="gap-1 flex flex-column">
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2"
              >
                Username
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2"
              >
                Password
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2"
              >
                BaseUrl
              </span>
            </div>

            <div className="gap-1 flex flex-column">
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-white p-2 "
              >
                USR01
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-white p-2"
              >
                YG739G5XFVPYYV4ADJVW
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-white p-2"
              >
                http://staging.qosic.net:9010
              </span>
            </div>

            <div className="gap-1 ml-2 flex flex-column">
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2 "
              >
                Copy
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2"
              >
                Copy
              </span>
              <span
                style={{ borderWidth: 0.7, borderColor: "#E0E0E0" }}
                className="bg-qosT p-2"
              >
                Copy
              </span>
            </div>
          </div>

          {/* <div className="pt-4">
            <div className="flex w-full mb-1 mt-1" style={{ height: 32 }}>
              <div className="flex w-96 mr-3 bg-qosgraye border text-center ">
                <div className="w-24 h-fit text-center  pt-1 pl-1 pr-2 ">Username</div>
                <div className="bg-white w-full p-1 flex text-left ">
                  <span className="text-left pl-3 ">USR01</span>
                </div>
              </div>
              <label
                disabled
                style={{
                  borderRadius: 0,
                  borderWidth: 0.5,
                }}
                className="bg-qosgraye h-fit border  mx-auto block p-1 "
              >
                Copy
              </label>
            </div>

            <div className="flex w-full mb-1 mt-1" style={{ height: 32 }}>
              <div className="flex w-96 mr-3 bg-qosgraye border text-center ">
                <div className=" w-24 h-fit text-center  p-1 mr-2">
                  Password
                </div>
                <div className="bg-white w-full p-1 flex text-left ">
                  <span className="text-left pl-3">YG739G5XFVPYYV4ADJVW</span>
                </div>
              </div>
              <label
                disabled
                style={{
                  borderRadius: 0,
                  borderWidth: 0.5,
                }}
                className="bg-qosgraye h-fit border  mx-auto block p-1 "
              >
                Copy
              </label>
            </div>

            <div className="flex w-full mb-1 mt-1" style={{ height: 32 }}>
              <div className="flex w-96 mr-3 bg-qosgraye border text-center ">
                <div className="bg-qosgraye w-24 h-fit text-center  pl-1 pt-1 pr-2 mr-3">
                  Base url
                </div>
                <div className="bg-white w-full p-1 flex text-left ">
                  <span className="text-left pl-3">
                    http://staging.qosic.net:9010
                  </span>
                </div>
              </div>
              <label
                disabled
                style={{
                  borderRadius: 0,
                  borderWidth: 0.5,
                }}
                className="bg-qosgraye h-fit border  mx-auto block p-1 "
              >
                Copy
              </label>
            </div>
          </div> */}

          {/* <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)} */}
        </div>
        <div
          style={styles.box}
          className="bg-white p-10  filter drop-shadow-sm  flex flex-col  w-1/2   gap-2 "
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-lg bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              <Icon
                icon="bx:support"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1  pl-2">{Infos.msg.Contact}</h5>
            </div>
          </div>
          <div style={{ top: -4 }}>
            <span className="text-qosText ">
            {Infos.msg.ContactText}
            </span>
          </div>

          <div className=" text-center">
            <div className="flex gap-3 justify-center items-center text-center">
              {/* <BsFillEnvelopeFill color="black" width={25} height={70} />  */}
              <Icon
                icon="mdi:envelope-outline"
                height={24}
                style={{ color: "black", fontSize: "20px" }}
              />
              <p className=" ">support@qosic.com</p>
            </div>

            <div className="flex pt-3 gap-3  justify-center items-center ">
              <div
                style={styles.support}
                className="flex gap-3  justify-center items-center"
              >
                <a className="text-qosorange" href="https://wa.me/22941179651">
                  {Infos.msg.Chat}
                </a>
                <BsWhatsapp color="green" width={25} height={70} />
              </div>
            </div>
          </div>

          {/* <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)} */}
        </div>

        {/* <div
          style={{
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "#E0E0E0",
          }}
          className="bg-white mt-8  mb-8 filter drop-shadow-sm  rounded-lg flex flex-col  w-1/2  gap-4 justify-center items-center justify-items-center content-center "
        >
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
          { documentList != 0 ? ( <BsFillHandThumbsUpFill color="white" width={40} height={40} />):
          ( <BsFillFileTextFill color="white" width={40} height={40} />)}
           
          </div>
          <div className="justify-center items-center text-center">
          { documentList != 0 ? ( <p>Check your activation status or Add additional document </p>):
          ( <p>Submit compliance details to accept <br/> live payments</p>)}
          </div>
         { documentList != 0 ? (
        
            <Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Go check your Status
            </button>
          </Link>
          ):(<Link to="/typeofbusiness">
            <button
              style={{ borderRadius: "10px" }}
              className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium"
            >
              Activate Account
            </button>
          </Link>)}
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-8 p-8 w-2/5  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillChatSquareDotsFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Contact support</p>
            <div className="flex pt-3 gap-3 justify-center items-center text-center">
              <BsFillEnvelopeFill color="black" width={25} height={70} />
              <p className="text-qosorange font-bold ">support@qosic.com</p>
            </div>

            <div className="flex pt-3 gap-3 justify-left items-center ">
              <BsWhatsapp color="black" width={25} height={70} />
              <a className="bg-green" href="https://wa.me/22994285672">
                Whatsapp
              </a>
            </div>
          </div>
        </div> */}

        {/* <div 
          style={{ borderRadius: "22px" }}
        className="bg-white mt-5  mb-8 filter drop-shadow-lg  rounded-lg  flex flex-col p-3 w-72  gap-4 justify-center items-center justify-items-center content-center ">
          <div className="rounded-full flex justify-center items-center bg-qosorange w-12 h-12">
            <BsFillHandThumbsUpFill color="white" width={40} height={40} />
          </div>
          <div className="justify-center items-center text-center">
            <p>Check your activation status or Add additional document</p>
          </div>
          { documentList !=0 ? (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosgreen min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>): (<Link to="/status">
            <button 
              style={{ borderRadius: "10px" }}
            className="bg-qosorange min-w-max w-36 font-bold mx-auto block py-3 px-7 text-white rounded font-medium">
              Activation Status
            </button>
          </Link>)  }
        </div> */}
      </div>
    </section>
  );
}

const styles = {
  button: {
    height: 48,
    width: 200,
    borderRadius: 4,
  },
  support: {
    height: 48,
    width: 200,
    borderRadius: 4,
    borderColor: "orange",
    borderWidth: 1,
  },
  box: {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    borderRadius: 5,
    borderWidth: "1px",
    borderColor: "#E0E0E0",
  },
};
