import React from "react";

import { useState, useMemo, useRef, useEffect } from "react";
import {
  BsFillPersonFill,
  BsFillCalendar3RangeFill,
  BsFillEnvelopeFill,
  BsWhatsapp,
  BsFillFileTextFill,
} from "react-icons/bs";
import { Icon } from "@iconify/react";
import IndividualBusness from "../components/individualBusness";
import RegisterBusness from "../components/registerBusness";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import Messages from "./translations/typeofbusness.json";
import { useLangContext } from "../components/translateContext";

export function TypeBusiness() {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);

  const { Lang } = useLangContext();
  const Infos = { msg: Messages[Lang] };

  const [showModal, setShowModal] = React.useState(false);

  const modal = useRef();

   const [isBox1Hovered, setIsBox1Hovered] = React.useState(false);
   const [isBox1Hovered1, setIsBox1Hovered1] = React.useState(false);

  const handleBox1MouseEnter = () => {
    setIsBox1Hovered(true);
  };
 

  const handleBox1MouseLeave = () => {
    setIsBox1Hovered(false);
  };
  const handleBox1MouseEnter1 = () => {
    setIsBox1Hovered1(true);
  };
  const handleBox1MouseLeave1 = () => {
    setIsBox1Hovered1(false);
  };
  const box1Style = {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    border: isBox1Hovered ? "2px solid orange" : "1px solid #E0E0E0",
    borderRadius: 5,
    backgroundColor: "#qosblue",
    color: "black",
    cursor: "pointer",
    transition: "border-color 0.3s ease-in-out",
    justifyContent:"center",
    alignItems:"center"
  };

  const box1Style1 = {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    border: isBox1Hovered1 ? "2px solid orange" : "1px solid #E0E0E0",
    borderRadius: 5,
    backgroundColor: "#qosblue",
    color: "black",
    cursor: "pointer",
    transition: "border-color 0.3s ease-in-out",
    justifyContent:"center",
    alignItems:"center"
  };
  return (
    <section className="mt-14 ">
         <div className=" m-10 flex text-left gap-1">
        <p className="pt-2 text-semibold " style={{fontSize:20, color:"#45464E" }}>{Infos.msg.Title0}</p>
      </div>
    
      <div className="flex text-left gap-1">
        <h4 style={{color:"#45464E" }} className="pl-10 ">{Infos.msg.Title1}</h4>
      </div>
      <div className="pl-10 ">
        <p className="pt-2 text-qosText">
        {Infos.msg.Title2}
        </p>
      </div>

      <div className="rounded  m-10 rounded-lg grid w-fit lg:flex  ">
        <div
          className="bg-white focus  p-10 mb-8 filter drop-shadow-sm  flex flex-col  w-1/2  gap-4  "
          style={box1Style}
          onMouseEnter={handleBox1MouseEnter}
          onMouseLeave={handleBox1MouseLeave}
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-sm bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              {/* <BsFillFileTextFill color="orange" width={40} height={40} />   */}
              <Icon
                icon="bi:person"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Individual}</h5>
            </div>
          </div>
          <div className="text-left">
            <span className="text-left text-qosText ">
            {Infos.msg.IndividualText}
            </span>
          </div>
          <div className=" mt-2 mb-4 w-full ">
            <div className=" w-full flex justify-center items-center">
              <Link to="/individual">
                <button
                  style={styles.button1}
                  id="next"
                  type="submit"
                  className="controls seft-center bg-qosorange  py-2 px-1 rounded-lg text-mlg"
                >
                  {" "}
                  <span className="text-center text-white">{Infos.msg.Select}</span>
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div
          style={box1Style1}
          onMouseEnter={handleBox1MouseEnter1}
          onMouseLeave={handleBox1MouseLeave1}
          className="bg-white focus  p-10 mb-8 filter drop-shadow-sm  flex flex-col  w-1/2  gap-4  "
        >
          <div className="flex w-full  gap-1 items-center">
            <div
              className="flex border border-sm rounded-sm bg-white justify-center items-center"
              style={{ height: 40, width: 40 }}
            >
              {/* <BsFillFileTextFill color="orange" width={40} height={40} /> */}
              <Icon
                icon="ic:outline-business"
                height={24}
                style={{ color: "orange", fontSize: "20px" }}
              />
            </div>
            <div className="grid  justify-center  text-center">
              <h5 className="text-black pb-1 pl-2">{Infos.msg.Registered}</h5>
            </div>
          </div>
          <div className="text-left">
            <span className="text-left text-qosText">
            {Infos.msg.RegisteredText}
            </span>
          </div>
          <div className="mt-2 mb-4 w-full flex justify-center items-center">
            <Link to="/register">
              <button
                style={styles.button1}
                id="next"
                type="submit"
                className="controls seft-center bg-qosorange  py-2 px-1 rounded-lg text-mlg"
              >
                {" "}
                <span className="text-center text-white">{Infos.msg.Select}</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
const styles = {
  button: {
    height: 48,
    width: 200,
    borderRadius: 4,
  },
  support: {
    height: 48,
    width: 200,
    borderRadius: 4,
    borderColor: "orange",
    borderWidth: 1,
  }, 
  box1: {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    borderRadius: 5,
    borderWidth: "1px",
    borderColor: "#F59E0B",
  },
  box: {
    position: "sticky",
    width: "472px",
    height: "318px",
    marginRight: 32,
    borderRadius: 5,
    borderWidth: "1px",
    borderColor: "#E0E0E0",
  },
  button1: {
    width: "180px",
    height: "50px",
    borderRadius: 4,
  },
};
