import { useEffect, useState } from "react";
// import { Table } from "../components/table.component";
import { getUser } from "../common/utils/tools";
import { Endpoints } from "../common/utils/endpoints";
import { PostData } from "../common/services/httpServices";
import { columnsTable } from "../common/utils/table";
import { Tablecompo } from "../components/table.component";


export function TransactionsByPeriod() {
  const [Data, setData] = useState([]);
  const [Begin, setBegin] = useState("");
  const [End, setEnd] = useState("");

  useEffect(() => {
    PostData(
      Endpoints.Transactions.getUsertransactinsByPeriod(getUser("ID")),
      {},
      {
        params: {
          clientId: getUser("ID"),
          begin: Begin,
          end: End,
        },
      }
    ).then((res) => {
      setData(res?.data);
    });
  }, [Begin, End]);

  return (
    <div className="pricingCard">
      <div className="flex max-w-screen-sm my-4">
        <div className="mx-auto w-60 md:max-w-sm flex flex-row ">
          <label className="py-4 text-msm px-3 ">From:</label>
          <input type="date" onChange={(e) => setBegin(e.target.value)} />
        </div>
        <div className="mx-auto w-60 md:max-w-sm flex flex-row ">
          <label className="py-4 px-3 "> To: </label>
          <input type="date" onChange={(e) => setEnd(e.target.value)} />
        </div>
      </div>
      <Tablecompo content={Data || []} dataColumns={columnsTable} />
    </div>
  );
}
