import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { transactionsReducer } from "./state/reducers/transactions";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  transactionsReducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
