import React from "react";

export default function Home() {
  //   function Welcome(props) {
  //     return <h3>Hello, Qos</h3>;
  //   }

  return (
    <div>
      <div class="w-100 h-96  overflow-auto md:overflow-scroll">
        <div class=" mt-2">
          {/* <h4>Hello Qos</h4> */}
          <div class="flex bg-qosgray mt-2 rounded justify-between p-4  border border-qosgray">
            <div class="w-full justify-between">
              <div class="rounded-full  ">
                <p class="text-mH3 text-center text-qosblue">No news</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="mt-6 w-100 h-1/2">
            <Service />
        </div> */}
    </div>
  );
}

function Service(props) {
  return <h3>Nos, Services</h3>;
}
