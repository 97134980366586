import { useMemo, useRef, useState, useEffect, useContext } from "react";
// import { paymentLinksHeader } from "../common/utils/table";
// import { Table } from "../components/table.component";
import { FetchData } from "../common/services/httpServices";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import { Endpoints } from "../common/utils/endpoints";
import { trackEvent } from "../common/utils/analytics";
import { useLangContext } from "../components/translateContext";
import link from "../components/translation/link.json";
import Modal from "react-bootstrap/Modal";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";
import Form from "react-bootstrap/Form";
// import Table from "react-bootstrap/Table";
import "./table.css";
import {
  // useIsMobile,
  useFormData,
} from "../common/utils/hooks";
import { Icon } from "@iconify/react";

// import { Button } from 'react-bootstrap';
// import Wrapper from "../components/sectionWrapper.component";
import { Spinner } from "../components/spinner.component";
import { CreatePayementLink, CreatePayementLink2 } from "../common/auth";
import Swal from "sweetalert2";
import CommingSoon from "../components/commingSoon";
import { useHistory } from "react-router";

export function PaymentLinks() {
  const history = useHistory();

  const [data, handleInput] = useFormData();
  const [data1, handleInput1] = useFormData();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(true);
  const { Lang } = useLangContext();
  const Infos = { msg: link[Lang] };

  const [Links, setLinks] = useState([]);
  const [loadingLink, setLoadingLink] = useState(true);

  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [show1, setShow1] = useState(false);
  const [isActive1, setIsActive1] = useState(false);

  const [show2, setShow2] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  const [show3, setShow3] = useState(false);
  const [isActive3, setIsActive3] = useState(false);

  const [show4, setShow4] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const [myLink, setMyLink] = useState("");
  const [myLinkDetail, setMyLinkDetail] = useState({});
  const [isCopied, setIsCopied] = useState(false);

  const [type, setType] = useState([]);
  const [loadingType, setLoadingType] = useState(true);
  const [devise, setDevise] = useState([]);
  const [loadingDevise, setLoadingDevise] = useState(true);
  const [value, setValue] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  // console.log("la value eeee:", )

  const [isOpenMenus, setIsOpenMenus] = useState({}); // State to track open menus

  // Function to toggle menu for a specific row
  const toggleMenu = (index) => {
    setIsOpenMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Function to handle click on a menu item
  const handleMenuItemClick = (val) => {
    // console.log(`Clicked on menu item: ${menuItem}`);
    // console.log("Clicked val:", val);
    // Perform any action based on the clicked menu item
    history.push({
      pathname: "/paymentlinkdetails",
      state: { val: val },
    });
  };

  const handleShow3 = () => setShow3(true);

  const [isOpenMe, setIsOpenMe] = useState(false);

  const toggleMenuMe = () => {
    setIsOpenMe(!isOpenMe);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
  };

  const Type = () => {
    FetchData(Endpoints.Utilities.getPaymentLinkTypes).then((res) => {
      setType(res);
      setLoadingType(false);
    });
  };

  useEffect(() => {
    Type();
  }, []);

  const Devise = () => {
    FetchData(Endpoints.Utilities.getPaymentLinkDevices).then((res) => {
      setDevise(res);
      setLoadingDevise(false);
    });
  };

  useEffect(() => {
    Devise();
  }, []);

  const Link = () => {
    FetchData(Endpoints.Paymentlinks.getuserPaymentLinks).then((res) => {
      setLinks(res);
      setLoadingLink(false);
    });
  };

  const handleOpen = () => {
    setShow(true);
    setIsActive(!isActive);
  };

  const handleOpen1 = () => {
    setShow1(true);
    setIsActive1(!isActive1);
  };

  const handleOpen2 = () => {
    setShow2(true);
    setIsActive2(!isActive2);
  };

  const handleOpen3 = () => {
    setShow3(true);
    setIsActive3(!isActive3);
  };
  const handleOpen4 = () => {
    setShow4(true);
    setIsActive4(!isActive4);
  };
  const handleCclose = () => {
    setIsActive(!isActive);
    setShow(false);
  };
  const handleCclose1 = () => {
    setIsActive1(!isActive1);
    setShow1(false);
  };
  const handleCclose11 = (e) => {
    e.preventDefault();
    setIsActive1(!isActive1);
    setShow1(false);
  };
  const handleCclose2 = () => {
    setIsActive2(!isActive2);
    setShow2(false);
  };
  const handleCclose22 = (e) => {
    e.preventDefault();
    setIsActive2(!isActive2);
    setShow2(false);
  };
  const handleCclose3 = () => {
    setIsActive3(!isActive3);
    setShow3(false);
  };
  const handleCclose4 = () => {
    setIsActive4(!isActive3);
    setShow4(false);
  };

  // console.log("data form:", data);

  useEffect(() => {
    Link();
  }, []);

  const teste = () => {
    modal.current.classList.add("-translate-y-full");
  };

  const closeModal = () => {
    setIsOpen(true);
    //  modal.current.classList.remove("-translate-y-full")
  };

  const currentURL = useMemo(() => window.location.host);
  // const currentURL = useMemo(() =>  window.location.host ); console.log("Tokodd le lien",currentURL);

  const mot = currentURL.toString();
  let myLinkConcat = mot.concat("/", myLink);

  const handleCopy = () => {
    navigator.clipboard.writeText(myLinkConcat);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  // console.log("Tokodd le limoten",mot);

  const modal = useRef();
  const modalLink = useRef();
  const modalQR = useRef();
  const linkBox = useRef();
  const boxToPrint = useRef();

  function ShowLink(valueLink) {
    let nevalue = mot.concat("/", valueLink);
    // console.log('en tout casseee', nevalue)

    // nevalue = nevalue.replace("Usercompany","User%20company")

    modalLink.current.classList.remove("-translate-y-full");
    linkBox.current.innerHTML = nevalue;
  }
  function ShowQR() {
    modalQR.current.classList.remove("-translate-y-full");
  }
  const Data = useMemo(
    () => [
      {
        pagename: "QOS",
        type: "Single charge",
        productname: "Neolas Soap",
        quantity: "400 pcs",
        amount: "800 fcfa",
        createOn: "22-02-2021",
      },
      {
        pagename: "QOS",
        type: "Single charge",
        productname: "Neolas Soap",
        quantity: "400 pcs",
        amount: "800 fcfa",
        createOn: "22-02-2021",
      },
      {
        pagename: "QOS",
        type: "Single charge",
        productname: "Neolas Soap",
        quantity: "400 pcs",
        amount: "800 fcfa",
        createOn: "22-02-2021",
      },
    ],
    []
  );

  async function handleSubmitSinglePay(e) {
    e.preventDefault();
    setLoading(true);
    setIsLoading(true);
    const type = { type: "SINGLE_CHARGE" };
    const device = { device: "XOF" };
    const image_name = { image_name: "" };
    const image_url = { image_url: "XOF" };
    const product_qte = { product_qte: 1 };
    const page_time = { page_time: 0 };
    const product_name = { product_name: "" };

    const doop = {
      ...type,
      ...image_name,
      ...product_qte,
      ...product_name,
      ...image_url,
      ...device,
      ...page_time,
      ...data,
    };

    trackEvent('Payment link product created', 'One time payment link', `Payment link product created`);
    try {
      // console.log("paymentlink Data : ", doop);
      const { data: user, status } = await CreatePayementLink2(doop);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        // let message = user.responseMessage;
        // const Toast = Swal.mixin({
        //   toast: true,
        //   position: "top",
        //   timer: 5500,
        //   showConfirmButton: false,
        //   timerProgressBar: true,
        //   didOpen: (toast) => {
        //     toast.addEventListener("mouseenter", Swal.stopTimer);
        //     toast.addEventListener("mouseleave", Swal.resumeTimer);
        //   },
        // });

        // Toast.fire({
        //   icon: "success",
        //   title: message,
        // });
        // window.location.reload();
        // modalLink.current.classList.remove("-translate-y-full");
        setIsLoading(false);
        // closeModal()
        setMyLinkDetail(user.datas);
        setMyLink(user.datas.url);
        Link();
        // teste();
        handleCclose11(e);
        handleCclose();
        handleOpen4();
        // setTimeout(() => {
        //   handleCclose11(e);
        //   handleCclose();
        // }, 3000);
      } else if (user.responseStatus === false) {
        let message = user.responseMessage;
        // return setTimeout( () => {
        //   Swal.fire({
        //     position: 'center',
        //     icon: 'error',
        //     title: message,
        //     showConfirmButton: false,
        //   });
        // }, "100")

        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });

        setIsLoading(false);
      }
    } catch (error) {
      // console.log("erreur :", error);

      setIsLoading(false);

      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        timer: 5500,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Erreur serveur contactez support",
      });
    }
    setLoading(false);
    setIsLoading(false);
  }

  async function handleSubmitMultiplePay(e) {
    e.preventDefault();
    setLoading(true);
    setIsLoading(true);
    const type = { type: "MULTIPLE_CHARGE" };
    const device = { device: "XOF" };
    const image_name = { image_name: "" };
    const image_url = { image_url: "XOF" };
    const product_qte = { product_qte: 1 };
    const page_time = { page_time: 0 };
    const product_name = { product_name: "" };

    const doop = {
      ...type,
      ...image_name,
      ...product_qte,
      ...page_time,
      ...product_name,
      ...image_url,
      ...device,
      ...data,
    };
    trackEvent('Payment link product created', 'Multiple payment link', `Payment link product created`);
    try {
      // console.log("paymentlink Data : ", doop);
      const { data: user, status } = await CreatePayementLink2(doop);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        // window.location.reload();
        // modalLink.current.classList.remove("-translate-y-full");
        setIsLoading(false);
        // closeModal()
        setMyLinkDetail(user.datas);
        setMyLink(user.datas.url);

        handleCclose22(e);
        handleCclose();
        handleOpen4();
        Link();
      } else if (user.responseStatus === false) {
        let message = user.responseMessage;
        // return setTimeout( () => {
        //   Swal.fire({
        //     position: 'center',
        //     icon: 'error',
        //     title: message,
        //     showConfirmButton: false,
        //   });
        // }, "100")

        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: message,
        });
        setIsLoading(false);
      }
    } catch (error) {
      // console.log("erreur :", error);
      setLoading(false);
      setIsLoading(false);

      const Toast = Swal.mixin({
        toast: true,
        position: "top",
        timer: 5500,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Erreur serveur contactez support",
      });
    }
  }

  const valueeree = data1;

  function handleShowChoice() {
    if (valueeree?.LinkChoice === "Choice1") {
      handleOpen1();
    } else if (valueeree?.LinkChoice === "Choice2") {
      handleOpen2();
    } else if (valueeree?.LinkChoice === "Choice3") {
      handleOpen3();
    } else {
      handleCclose();
    }
  }

  function CustomToggle({ children, eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, () =>
      // console.log('totally custom!'),
      {
        // console.log("totally eventKey!", eventKey)
      }
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div className="mx-auto  lg:w-8/12 mt-8  text-center justify-center">
        <button type="button" className="w-full " onClick={decoratedOnClick}>
          <div className="flex w-full gap-2  text-qosorange justify-center align-center">
            {isCurrentEventKey ? (
              <div className="flex w-full gap-2 justify-center align-center">
                <Icon
                  icon="mdi:minus"
                  height={20}
                  style={{ color: "orange" }}
                />
                Hide advanced options
              </div>
            ) : (
              <div className="flex w-full gap-2 justify-center align-center">
                <Icon
                  icon="ri:add-line"
                  height={20}
                  style={{ color: "orange" }}
                />
                Show advanced options
              </div>
            )}
          </div>
        </button>
      </div>
    );
  }

  return (
    <div className="p-4">
      {/* <div className="mb-5 flex text-left gap-1">
        <p
          className="text-semibold "
          style={{ fontSize: 20, color: "#45464E" }}
        >
          {Infos.msg.Payment}
        </p>
      </div> */}
      <div className="flex-column text-left gap-1">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
          {Infos.msg.Links} (
          {`${loadingLink ? "" : Links.data.datas.items.length}`}){" "}
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
          {Infos.msg.Createan}
        </span>
      </div>

      <section
        id="modalLink"
        ref={modalLink}
        className="absolute  transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modalLink"
            ? modalLink.current.classList.add("-translate-y-full")
            : ""
        }
      >
        <div className="flex items-center transform font-semibold absolute left-1/2 top-1/2 rounded-md -translate-x-1/2 -translate-y-1/2 p-8 bg-white max-w-max mx-auto mt-0">
          <p
            ref={linkBox}
            className="inline-block max-w-md truncate text-msm bg-qosdark bg-opacity-10 rounded-md p-2"
          >
            {mot}{" "}
            https://web.dev/measure/?gclid=CjwKCAjwiY6MBhBqEiwARFSCPqebGNf1BiVe-XjYxW0E407o2zV0IVWw591m2gEJF6qPS29YwD5BHhoCIt8QAvD_BwE
          </p>
          {/* <a
            target="_blank"
            className="inline-block mx-2 p-1 px-2 text-mmd rounded-sm bg-qosblue text-white"
            href={linkBox}
          >
            Browse
          </a> */}
          <button
            onClick={() => {
              var r = document.createRange();
              r.selectNode(linkBox.current);
              window.getSelection().removeAllRanges();
              window.getSelection().addRange(r);
              document.execCommand("copy");
              window.getSelection().removeAllRanges();
              linkBox.current.style =
                "background:rgba(37,99,235,.8);color:rgba(255,255,255,.5)";
            }}
            className="inline-block font-semibold p-1 px-2 text-mmd rounded-sm bg-qosblue opacity-70 text-white"
          >
            {Infos.msg.Copy}
          </button>
        </div>
      </section>

      {/* Modal to show QR code */}
      <section
        id="modalQR"
        ref={modalQR}
        className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modalQR"
            ? modalQR.current.classList.add("-translate-y-full")
            : ""
        }
      >
        <div
          ref={boxToPrint}
          className="transform bg-white font-semibold absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-12 min-w-max w-96 mx-auto mt-0
        "
        >
          <div className="bg-white p-8">
            <h3 className="text-center text-mH3 font-medium text-opacity-100 mb-5 block">
              {" "}
              QOS-IC CENTER
            </h3>
            <QRCode
              className="mx-auto"
              value="https://qosic.com/blog"
              size="150"
            />
          </div>
          <ReactToPrint
            trigger={() => (
              <button className="bg-gradient-to-bl hideOnPrint from-qosred to-qosorange opacity-70 min-w-max mx-auto mt-8 block p-2 text-qosgray rounded-lg font-medium">
                Print the QR code
              </button>
            )}
            content={() => boxToPrint.current}
          />
        </div>
      </section>
      <div className="mt-10 max-w-full lg border bg-white p-4 rounded-md flex justify-between items-center mx-auto">
        <p className="font-semibold text-qosdark text-opacity-90">
          {/* {`${loadingLink ?  "loading...": Links.data.datas.items.length} ${Infos.msg.links}`}{" "} */}
        </p>
        <button
          // onClick={() => modal.current.classList.remove("-translate-y-full")}
          onClick={handleOpen}
          className="flex justify-around bg-qosorange  min-w-max w-44  p-2 text-white rounded-sm font-medium"
        >
          {Infos.msg.Button}
          <Icon
            icon="ri:add-line"
            height={24}
            style={{ color: "white", fontSize: "20px" }}
          />
        </button>
      </div>

      {/* payment link table  */}
      <div className="max-w-full h-screen  xl overflow-x-auto hideScroll border mt-8">
        <table id="customers" className="min-w-full text-center">
          <thead>
            <tr>
              <th
                style={styles.title}
                className="px-4 py-4 text-msm text-qosdark text-opacity-100 tracking-tighter leading-3 truncate text-center"
              >
                {Infos.msg.Page}
              </th>
              <th
                style={styles.title}
                className="px-4 py-3 text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate"
              >
                {Infos.msg.Typ.toLowerCase()}
              </th>
              <th
                style={styles.title}
                className="px-4 py-3 text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate"
              >
                {Infos.msg.Amount.toLowerCase()}
              </th>
              <th
                style={styles.title}
                className="px-4 py-3 text-msm text-qosdark text-opacity-100  tracking-tighter leading-3 truncate"
              >
                {Infos.msg.Create}
              </th>
              <th
                style={styles.title}
                className="px-4 py-3 text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate"
              >
                {Infos.msg.action.toLowerCase()}
              </th>
            </tr>
          </thead>
          <tbody className="text-center bg-white">
            {loadingLink
              ? "loading..."
              : Links.data.datas.items.map((val, index) => (
                  <>
                    {val?.deleteStatus === false ||
                    val?.deleteStatus === null ||
                    val?.deleteStatus === undefined ? (
                      <tr key={index}>
                        <td className="text-center px-6 py-4 whitespace-nowrap">
                          {val.page_name}
                        </td>
                        <td className="text-center px-6 py-4 whitespace-nowrap ">
                          {val.type === "SINGLE_CHARGE" ? "One-time payment" : "Multiple payment"}
                        </td>
                        <td className="text-center px-6 py-4 whitespace-nowrap">
                          {val.product_price}
                        </td>
                        <td className="text-center px-6 py-4 whitespace-nowrap">
                          {val.createAt}
                        </td>
                        <td className="text-center px-6 py-4 whitespace-nowrap">
                          <div className="relative inline-block text-left">
                            <button
                              onClick={() => toggleMenu(index)}
                              className="text-gray-300 hover:text-gray-500 focus:outline-none"
                            >
                              <Icon
                                icon={
                                  isOpenMenus[index]
                                    ? "iconamoon:close"
                                    : "icon-park-outline:more-one"
                                }
                                style={{ color: "black" }}
                                className="h-6 w-6"
                              />
                            </button>
                            {isOpenMenus[index] && (
                              <div className="absolute z-50 right-0 mt-2 w-52 bg-white rounded-md shadow-lg">
                                <div
                                  className="py-1"
                                  role="menu"
                                  aria-orientation="vertical"
                                  aria-labelledby="options-menu"
                                >
                                  <div className="flex h-11 items-center justify-between px-4 border-qosgraye border-t border-b-0">
                                    <a
                                      onClick={() => handleMenuItemClick(val)}
                                      className="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                      role="menuitem"
                                    >
                                      View page
                                    </a>
                                  </div>
                                  <div className="flex h-11 items-center justify-between px-4 border-qosgraye border-t border-b-0">
                                    <a
                                      href="#about"
                                      className="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                      role="menuitem"
                                    >
                                      Edit page
                                    </a>
                                  </div>
                                  <div className="flex h-11 items-center justify-between px-4 border-qosgraye border-t border-b-0">
                                    <button
                                      onClick={() => ShowLink(val.url)}
                                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                      Open link
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
          </tbody>
        </table>
      </div>

      {/* modal selection type de lien de paimenent */}
      <Modal
        show={show}
        onHide={handleCclose}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <div className="w-full px-4 justify-center items-center text-center">
          <p className="text-semibold ">New payment link</p>
        </div>

        <Modal.Body>
          <div className="">
            <div className="  w-full gap-2 ">
              <div className="w-full py-4 px-10 flex justify-center align-center items-center gap-2">
                <div className="w-8/12  justify-center items-center">
                  <p for="Choice1" className="">
                    {/* {Infos.header.Individual}  */}
                    One-time payment
                  </p>
                  <span className="text-qosText">
                    Create a simple page for your customers to pay you
                  </span>
                </div>
                <div className="w-4/12 h-30  flex  justify-end">
                  <div className="h-30 w-4/12">
                    <input
                      type="radio"
                      id="Choice1"
                      name="LinkChoice"
                      value="Choice1"
                      onChange={handleInput1}
                      style={{ background: "red" }}
                      className="bg-qosorange cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className=" border "></div>

              <div className="w-full py-4 px-10 flex justify-center align-center items-center gap-2">
                <div className="w-8/12  justify-center items-center">
                  <p for="Choice2" className="">
                    {/* {Infos.header.Individual}  */}
                    Multiple Payment
                  </p>
                  <span className="text-qosText">
                    Create a page for recurring payments or subscriptions
                  </span>
                </div>
                <div className="w-4/12 h-30  flex  justify-end">
                  <div className="h-30 w-4/12">
                    <input
                      type="radio"
                      id="Choice2"
                      name="LinkChoice"
                      value="Choice2"
                      onChange={handleInput1}
                      className="bg-qosorange cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className=" border "></div>

              <div className="w-full py-4 px-10 flex justify-center align-center items-center gap-2">
                <div className="w-8/12  justify-center items-center">
                  <p for="Choice3" className="">
                    {/* {Infos.header.Individual}  */}
                    Product Payment
                  </p>
                  <span className="text-qosText">
                    Create a page to sell one or more products from your store
                    inventory
                  </span>
                </div>
                <div className="w-4/12 h-30  flex  justify-end">
                  <div className="h-30 w-4/12">
                    <input
                      type="radio"
                      id="Choice3"
                      name="LinkChoice"
                      value="Choice3"
                      onChange={handleInput1}
                      className="bg-qosorange cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className=" border "></div>

              <div className="w-full md:px-20 mb-4">
                <button
                  style={styles.button}
                  disabled={isLoading}
                  onClick={handleShowChoice}
                  className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80  ${
                    isLoading ? "bg-coolGray-300" : "bg-qosorange"
                  }  p-3 btn-sm mx-auto mt-14`}
                >
                  {isLoading ? (
                    <Spinner className="border-qosorange h-4 w-4 mx-1" />
                  ) : (
                    `Create payment page`
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* modal one time payment */}
      <Modal
        show={show1}
        onHide={handleCclose1}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <div className="w-full px-4 justify-center items-center text-center">
          <p className="text-semibold ">One time payment page</p>
        </div>

        <Modal.Body>
          <div className="">
            <div className="  w-full gap-2 ">
              <form
                method="POST"
                onSubmit={handleSubmitSinglePay}
                className="w-full "
              >
                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Page name</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="page_name"
                    type="text"
                    required
                    placeholder="Enter name of your page"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Description</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="description"
                    type="text"
                    required
                    placeholder="Enter page description or extra instruction"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Amount</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="product_price"
                    type="number"
                    pattern="[0-9]{5}"
                    required
                    placeholder="Enter amount"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">SEO Image (Optional)</span>
                  <p className="text-left">
                    This image will show when the page is shared on social
                    media. We recommend a 1024 x 512 pixel JPG or PNG, under 1
                    MB in size.
                  </p>
                  <Form.Control
                    type="file"
                    accept="csv, xls"
                    size="lg"
                    onChange={handleFileChange}
                  />
                </div>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card.Header>
                      <CustomToggle eventKey="1"></CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <div className="mx-auto  lg:w-10/12 mt-8">
                        <Card.Body>
                          <div className="mx-auto   mt-4">
                            <span className="block">Use your custom link</span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="customer_link"
                              type="link"
                              // required
                              disabled
                              placeholder="Enter your URL"
                            />
                          </div>
                          <div className="mx-auto mt-4">
                            <span className="block">
                              Redirect after payment
                            </span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="redirect_link"
                              type="link"
                              // required
                              disabled
                              placeholder="https://redirected.com"
                            />
                          </div>
                          <div className="mx-auto   mt-4">
                            <span className="block">Success Message</span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="success_message"
                              type="text"
                              // required
                                
                              placeholder="Message to show after payment"
                            />
                          </div>
                          <div className="mx-auto   mt-4">
                            <span className="block">
                              Send Notifications To : If provided, this email
                              will get transaction notice
                            </span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="email_notification"
                              type="email"
                              // required
                              placeholder="Enter email address "
                            />
                          </div>
                        </Card.Body>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
                <div className="flex my-10  w-full mx-auto gap-4 lg:w-10/12">
                  <button
                    style={styles.button1}
                    onClick={handleCclose11}
                    className={` w-full xl:w-12/12 lg:w-12/12  border-sm rounded-sm border-qosorange  flex justify-center items-center  text-qosorange  p-2  mx-auto mt-14`}
                  >
                    Cancel
                  </button>

                  <button
                    style={styles.button}
                    disabled={isLoading}
                    type="submit"
                    // onClick={handleShow(data)}
                    className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80  ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  p-2 btn-sm mx-auto mt-14`}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      <div className="flex gap-2">
                        Create{" "}
                        <Icon
                          icon="mynaui:arrow-right"
                          height={20}
                          style={{ color: "white" }}
                        />
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal multiple payment  */}
      <Modal
        show={show2}
        onHide={handleCclose2}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <div className="w-full px-4 justify-center items-center text-center">
          <p className="text-semibold ">Multiple Payment </p>
        </div>

        <Modal.Body>
          <div className="">
            <div className="w-full gap-2 ">
              <form
                method="POST"
                onSubmit={handleSubmitMultiplePay}
                className="  w-full "
              >
                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Page name</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="page_name"
                    type="text"
                    required
                    placeholder="Enter name of your page"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Description</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="description"
                    type="text"
                    required
                    placeholder="Enter page description or extra instruction"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">Amount</span>
                  <input
                    style={styles.input}
                    onChange={handleInput}
                    className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    name="product_price"
                    type="number"
                    required
                    placeholder="Enter amount"
                  />
                </div>

                <div className="mx-auto  lg:w-10/12 mt-4">
                  <span className="block">SEO Image (Optional)</span>
                  <p className="text-left">
                    This image will show when the page is shared on social
                    media. We recommend a 1024 x 512 pixel JPG or PNG, under 1
                    MB in size.
                  </p>
                  <Form.Control
                    type="file"
                    accept="csv, xls"
                    size="lg"
                    onChange={handleFileChange}
                  />
                </div>

                <div>
                  <Accordion defaultActiveKey="0">
                    <Card.Header>
                      <CustomToggle eventKey="1"></CustomToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <div className="mx-auto  lg:w-10/12 mt-8">
                        <Card.Body>
                          <div className="mx-auto   mt-4">
                            <span className="block">Use your custom link</span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="customer_link"
                              type="link"
                              // required
                              disabled
                              placeholder="Enter your URL"
                            />
                          </div>
                          <div className="mx-auto mt-4">
                            <span className="block">
                              Redirect after payment
                            </span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="redirect_link"
                              type="link"
                              // required
                              disabled
                              placeholder="https://redirected.com"
                            />
                          </div>
                          <div className="mx-auto   mt-4">
                            <span className="block">Success Message</span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="success_message"
                              type="text"
                              // required
                              placeholder="Message to show after payment"
                            />
                          </div>
                          <div className="mx-auto   mt-4">
                            <span className="block">
                              Send Notifications To : If provided, this email
                              will get transaction notice
                            </span>
                            <input
                              style={styles.input}
                              onChange={handleInput}
                              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                              name="email_notification"
                              type="email"
                              // required
                              placeholder="Enter email address "
                            />
                          </div>
                        </Card.Body>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
                <div className="flex my-10  w-full mx-auto gap-4 lg:w-10/12">
                  <button
                    style={styles.button1}
                    onClick={handleCclose22}
                    className={` w-full xl:w-12/12 lg:w-12/12  border-sm rounded-sm border-qosorange  flex justify-center items-center  text-qosorange  p-2  mx-auto mt-14`}
                  >
                    Cancel
                  </button>

                  <button
                    style={styles.button}
                    disabled={isLoading}
                    // onClick={handleSubmitMultiplePay}
                    className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80  ${
                      isLoading ? "bg-coolGray-300" : "bg-qosorange"
                    }  p-2 btn-sm mx-auto mt-14`}
                  >
                    {isLoading ? (
                      <Spinner className="border-qosorange h-4 w-4 mx-1" />
                    ) : (
                      <div className="flex gap-2">
                        Create{" "}
                        <Icon
                          icon="mynaui:arrow-right"
                          height={20}
                          style={{ color: "white" }}
                        />
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* modal product payment */}
      <Modal
        show={show3}
        onHide={handleCclose3}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        {/* <div className="w-full px-4 justify-center items-center text-center">
          <p className="text-semibold ">Product Payment</p>
        </div> */}
        <CommingSoon />
      </Modal>

      {/* modal payment link successful created */}
      <Modal
        show={show4}
        onHide={handleCclose4}
        backdrop="static"
        keyboard={false}
        size="lg"
        dialogClassName="my-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="border-b-0"></Modal.Header>

        <div className="flex w-full  justify-center  text-center">
          <Icon
            icon="ic:baseline-check-circle"
            height={70}
            style={{ color: "#1faf38", fontSize: "20px", borderWidth: 0 }}
            // strokeWidth={0}
          />
        </div>

        <Modal.Body>
          <div className="">
            <div className="w-full gap-2 justify-center items-center md:mt-6">
              <div className="h-10 justify-center  text-center">
                <p className="text-semibold text-xl text-qosTab">
                  Page created
                </p>
              </div>

              <div className="w-full px-5 md:px-26 justify-center text-qosText items-center text-center">
                <p className="text-semibold text-qosText">
                  Your page has been created. Visit the link to make a demo
                  payment. To view all created pages, use the payment pages link
                  on the left side bar
                </p>
              </div>

              <div className="flex w-full justify-center items-center px-4 mt-4">
                <div className="w-full  ">
                  <input
                    type="text"
                    value={myLinkConcat}
                    readOnly
                    style={styles.input2}
                    className=" border  focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  />
                </div>

                <div className="w-3/12 bg-qosgraylinkButton ">
                  <button
                    // disabled
                    // onChange={handleInput}
                    onClick={handleCopy}
                    style={styles.input2}
                    className={`w-full border ${
                      isCopied ? "bg-qosgreen" : "bg-qosgraylinkButton"
                    }  focus:outline-none focus:ring-1 focus:ring-qosgreen focus:border-qosgreen`}
                  >
                    {isCopied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>

              <div className="w-full  mt-10 md:px-10 px-4 md:gap-4 mb-20">
                <button
                  style={styles.button2}
                  disabled={isLoading}
                  onClick={() => handleMenuItemClick(myLinkDetail)}
                  className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80 bg-qosorange  p-3 btn-sm mx-auto mt-4 md:mt-14`}
                >
                  <div className="flex gap-2  justify-center align-center">
                    <span>Visit your payment page</span>
                  </div>
                </button>

                <button
                  style={styles.button3}
                  disabled={isLoading}
                  onClick={handleCclose4}
                  className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-qosorange  p-2  mx-auto mt-4`}
                >
                  <p className="underline">Close</p>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const styles = {
  input: {
    borderRadius: 4,
  },
  button: {
    borderRadius: 4,
  },
  button1: {
    borderWidth: 1,
    borderRadius: 4,
  },
  title: {
    fontSize: 18,
  },
  input2: {
    // width:680,
    height: "52px",
    borderRadius: 4,
  },
  button2: {
    height: "45px",
    borderWidth: 1,
    borderRadius: 4,
  },
  button3: {
    height: "45px",
    // borderWidth: 1,
    // borderRadius: 4,
  },
};
