const initialState = {
  lastTransactions: [],
  transactionsByPeriod: [],
};

export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DASH_TRANSACTION":
      return { ...state, lastTransactions: action.payload };
    default:
      return state;
  }
};
