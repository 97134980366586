import { useEffect, useState } from "react";
import "./styles.css";
import { FetchData } from "../../common/services/httpServices";
import { Endpoints } from "../../common/utils/endpoints";
import { useLangContext } from "../../components/translateContext";
import overview from "../../components/translation/overview.json";

function UserAllLastTansaction(country) {
  const [Transac, setTransac] = useState([]);

  const [loading, setLoading] = useState(true);
  const { Lang } = useLangContext();
  const Infos = { msg: overview[Lang] };

  let value = country.country.toUpperCase();
  //  console.log("mon trucd", value)

  const inf = () => {
    FetchData(
      Endpoints.Transactions.getLastBycountry + "?country=" + value
    ).then((res) => {
      setTransac(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    inf();
  }, []);

  // let voila = loading ?  "tokoss" : Transac.datas.items.map.responsetime.split("T")[1]  ;
  // console.log("heure :" , voila)

  // let heure = voila.split(".")[0];

  // console.log("Vrai heure :" , heure)

  return (
    <div className="mt-5 text-mmd">
      <h4 className="pl-1">{Infos.msg.Last}</h4>
      {Transac.length !== 0 ? (
        <div class="timeline">
          <div class="outer">
            {loading
              ? "loading..."
              : Transac.datas.items.map((transaction, index) => (
                  <div key={index} class="card">
                    <div className="grid  md:flex justify-between align-center text-left ">

                    {/* <table >
                      <tr>
                        <th>{Infos.msg.Date}</th>
                        <th>{Infos.msg.Amount}</th>
                        <th>{Infos.msg.status}</th>
                        <th>{Infos.msg.operation}</th>
                        <th>{Infos.msg.Phone}</th>
                      </tr>
                      <tr>
                        <td>{transaction.responsetime === "null" ||
                          transaction.responsetime === null
                            ? "-"
                            : transaction.responsetime.split("T")[0] +
                              " at " +
                              transaction.responsetime
                                .split(".")[0]
                                .split("T")[1]}{" "}</td>
                        <td>{transaction.amount} XOF</td>
                        <td className={` ${transaction.responsemessage === "FAILED" || transaction.responsemessage === "" ? 'text-qosred' : 'text-qosgreen'}`} > {transaction.responsemessage === ""? "FAILED": transaction.responsemessage}</td>
                        <td>{transaction.operationtype}</td>
                        <td>{transaction.sendersmsisdn}</td>
                      </tr>
                  
                  
                    </table> */}
                      <h5 class="title">
                        {Infos.msg.Date}
                        <p class="paragraph">
                          {transaction.responsetime === "null" ||
                          transaction.responsetime === null
                            ? "-"
                            : transaction.responsetime.split("T")[0] +
                              " at " +
                              transaction.responsetime
                                .split(".")[0]
                                .split("T")[1]}{" "}
                        </p>
                      </h5>
                      {/* <p>{transaction.responsetime.split(".")[0].split("T")[1]} </p>
                      <p>{transaction.responsetime} </p> */}
                      <h5>
                        {Infos.msg.Amount}{" "}
                        <p class="paragraph">{transaction.amount} XOF</p>
                      </h5>
                      <h5>
                        {Infos.msg.status}{" "}
                        <p className={` ${transaction.responsemessage === "FAILED" || transaction.responsemessage === "" ? 'text-qosred' : 'text-qosgreen'}`}>{transaction.responsemessage === ""? "FAILED": transaction.responsemessage}</p>
                      </h5>
                      <h5>
                        {Infos.msg.operation}
                        <p class="paragraph">{transaction.operationtype}</p>
                      </h5>
                      <h5>
                        {Infos.msg.Phone}
                        <p class="paragraph"> {transaction.sendersmsisdn}</p>
                      </h5>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      ) : (
        <div className="flex mx-auto w-100 mt-10 h-100 justify-center align-center ">
          <p>No transactions data available </p>
        </div>
      )}
    </div>
  );
}

export default UserAllLastTansaction;
