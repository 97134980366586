import { useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import {
  FetchData,
  PutData,
  //  PostData
} from "../common/services/httpServices";
import { useLangContext } from "../components/translateContext";
import settings from "../components/translation/settings.json";
import { http } from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import { getUser } from "../common/utils/tools";
import { Credentials } from "../pages/credentials";
import Wrapper from "../components/sectionWrapper.component";
import { encryptStorage } from "../common/services/encryptData";
import Modal from "react-bootstrap/Modal";

import Documents from "../partials/dashboard/Documents";
import Viewer from "react-viewer/dist/index.js";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { TabledocumentUser } from "../components/tables/tabledocumentUser";
import LogoDocument from "../components/logoDocument";
import Swal from "sweetalert2";
import DocumentsBank from "../partials/dashboard/DocumentsBank";
import { Spinner } from "../components/spinner.component";
import { ConsoleView } from "react-device-detect";
import showPwdImg from "../pages/public/login/showPwdImg.svg";
import hidePwdImg from "../pages/public/login/hidePwdImg.svg";
import DiseableTwoFa from "../components/DiseableTwoFa";
import { TableMySubscription } from "../components/tables/tableMySubscription";
import { media } from "../common/libs/media";
import { WhitelistIp } from "../components/tables/whitelistIp";

export function Settings() {
  const [state, setstate] = useState(0);

  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  return (
    <section className="p-4 bg-qosbackgray">
      {/* <div className="mt-14 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
          {Infos.msg.Setting}
        </p>
      </div> */}
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
          {Infos.msg.Setting}
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
          {Infos.msg.Subti}
        </span>
      </div>
      <header className="mt-12">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-20 sm:translate-x-32 md:translate-x-44"
                : state === 2
                ? "translate-x-40 sm:translate-x-32 md:translate-x-96"
                : state === 3
                ? "translate-x-65 sm:translate-x-32 md:translate-x-104"
                : state === 4
                ? "translate-x-85 sm:translate-x-32 md:translate-x-188"
                : state === 5
                ? "translate-x-65 sm:translate-x-32 md:translate-x-188"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-24 sm:w-32 md:w-44  text-white h-full bg-qosorange  rounded-full p-2 md:px-4`}
          ></div>
          <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-small2  p-2 md:px-4`}
          >
            {Infos.msg.Account}
          </button>
          <button
            onClick={() => setstate(1)}
            className={`transition-all duration-500 ${
              state === 1 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-small2  p-2 md:px-4`}
          >
            {Infos.msg.Payout}
          </button>
          <button
            onClick={() => setstate(2)}
            className={`transition-all duration-500 ${
              state === 2 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-small2  p-2 md:px-4`}
          >
            {Infos.msg.Privacy}
          </button>
          <button
            onClick={() => setstate(3)}
            className={`transition-all duration-500 ${
              state === 3 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-small2  p-2 md:px-4`}
          >
            {Infos.msg.Ip}
          </button>
          <button
            onClick={() => setstate(4)}
            className={`transition-all duration-500 ${
              state === 4 && "text-white"
            } z-10 w-24 sm:w-32 md:w-44 font-semibold text-small2 md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.Subscription}
          </button>
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <AccountDetails />}
          {state === 1 && <Business />}
          {state === 2 && <Privacy />}
          {state === 3 && <IPWhitelist />}
          {state === 4 && <MySubscription />}
        </section>
      </div>
    </section>
  );
}

function AccountDetails() {
  const phone = useMemo(() => getUser("phone"), []);
  const email = useMemo(() => getUser("mail"), []);
  const first_name = useMemo(() => getUser("first_name"), []);
  const last_name = useMemo(() => getUser("last_name"), []);
  const country = useMemo(() => getUser("country"), []);
  const companyname = useMemo(() => getUser("company_name"), []);
  const activityDescription = useMemo(() => getUser("companyDescription"), []);
  const paymentMode = useMemo(() => getUser("paymentMode"), []);
  const userId = useMemo(() => getUser("userId"));
  const [Account, setAccount] = useFormData({
    phone,
    email,
    first_name,
    last_name,
    companyname,
    activityDescription,
    paymentMode,
  });

  const { qosLogoBlack } = media.Logos.qos;
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);

  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  const [Password, setPassword] = useFormData({});
  const [isLoading, setIsLoading] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [file, setFile] = useState(""); // storing the uploaded file
  // storing the recived file from backend
  const [data, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element

  let firstname = Account.first_name;
  let lastname = Account.last_name;

  const handleClose = () => {
    // setIsActive(!isActive);
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  //  let valuer = {Account, firstname:firstname , lastname:lastname};
  //  let valuer = Account.replace("first_name", "firstname")

  // console.log("mys Account de", Account);

  function UpdateUser(e) {
    e.preventDefault();

    PutData(
      Endpoints.User.updateUser,
      { ...Account, userId },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        // console.log("my res", res)
        if (
          res.data.responseMessage === "Your update has been done successfully"
        ) {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: message,
          });
        } else if (
          res.data.responseMessage != "Your update has been done successfully"
        ) {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function updatePassword(e) {
    // console.log("value de password :", Password)

    e.preventDefault();
    setIsLoading(true);

    PutData(
      Endpoints.User.updatePassword,
      { ...Password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        // console.log("my res", res)
        if (res.data.responseMessage === "Successful") {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: message,
          });
          setIsLoading(false);
          handleClose();
        } else if (res.data.responseMessage != "Successful") {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  const emptyFunc = "emptyFunc";

  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accessing file
    // console.log(file);
    setFile(file); // storing file
  };

  return (
    <div className="bg-white mt-5">
      {/**/}

      <section className=" gap-3  mb-14 sm:block justify-center w-full md:block  sm:rounded-lg ">
        <Wrapper className="mt-10 mb-10 ">
          <div className="bg-white   w-full  mx-auto px-4  my-15 py-14   md:w-4/6 lg:w-4/6 sm:w-full lg:px-2 md:px-2   ">
            <div className="h-20 flex justify-center text-center">
              <span style={{ fontSize: 30 }}>{Infos.msg.Profile}</span>
            </div>

            <form onSubmit={UpdateUser} className=" w-100">
              <div className="block">
                <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
                  <label className="mb-2 inline-block" htmlFor="amount">
                    {Infos.msg.First}:
                  </label>
                  <input
                    className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    id="firstname"
                    name="first_name"
                    value={Account.first_name}
                    onChange={setAccount}
                    type="text"
                  />
                </div>
                <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
                  <label className="mb-2 inline-block" htmlFor="amount">
                    {Infos.msg.Last}:
                  </label>
                  <input
                    className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    id="last_name"
                    name="last_name"
                    value={Account.last_name}
                    onChange={setAccount}
                    type="text"
                  />
                </div>
                <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
                  <label className="mb-2 inline-block" htmlFor="amount">
                    {Infos.msg.Email}:
                  </label>
                  <input
                    className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    id="email"
                    name="email"
                    value={Account.email}
                    onChange={setAccount}
                    type="text"
                    disabled
                  />
                </div>
                <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
                  <label className="mb-2 inline-block" htmlFor="amount">
                    {Infos.msg.Number}:
                  </label>
                  <input
                    className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    id="phone"
                    name="phone"
                    value={Account.phone}
                    onChange={setAccount}
                    type="text"
                    disabled
                  />
                </div>
              </div>

              <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 ">
                <button className="w-full bg-qosorange   mt-8 min-w-max w-44 mx-auto block p-2 text-white rounded-sm ">
                  <p className="py-1.5 text-white">{Infos.msg.Update}</p>
                </button>
              </div>
            </form>

            <div className="mx-auto w-full md:max-w-sm mt-1 mb-4 md:mb-0">
              {encryptStorage.getItem("qosUser")?.isUsing2Fa == false ||
              encryptStorage.getItem("qosUser")?.isUsing2Fa == null ? (
                ""
              ) : (
                <div className="w-1/2  flex justify-center pt-4 text-left">
                  <DiseableTwoFa className="" />
                </div>
              )}
            </div>

            <div className="mx-auto w-full md:max-w-sm mt-4 mb-8 md:mb-0">
              <button
                onClick={handleShow}
                style={{ borderWidth: 1, borderColor: "#F59E0B" }}
                className="w-full bg-white rounded-sm    mt-8 min-w-max w-44 mx-auto block p-2 "
              >
                <p className="py-1.5 text-qosorange">{Infos.msg.pass}</p>
              </button>
            </div>
          </div>
        </Wrapper>

        <div className="flex-inline justify-start  content-start justify-items-start w-full min-w-100 md:w-full lg:w-full">
          <div className="absolute ">
            {/* <Documents  Account={Account}  /> */}
            {/* <LogoDocument/>
                  
                  */}
          </div>

          {/* <div className="flex relative justify-start self-start content-start justify-items-start bg-qosorange ">
                  <button className="bg-qosblue  absolute left-0 top-0  self-start opacity-70 mt-8 min-w-max w-44 mx-auto block p-2 text-qosgray rounded-lg font-medium">
                    Upload file
                </button>
              </div>  */}
        </div>
      </section>

      {/*  <section className="grid grid-cols-2 gap-2 items-center bg-white my-8 p-4 md:max-w-screen-lg mx-auto">
      <form onSubmit={UpdateUser} className="">
        <div className="">
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
            <h4>Document</h4>
          </div>
          <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0">
          <div className="file-upload">
                <input type="file" ref={el} onChange={handleChange} />
                <div className="progessBar" style={{ width: progress }}>
                   {progress}
                </div>
                <button  className="upbutton">
                   Upload
                </button>
            <hr />
            
            {data.path && <img src={data.path} alt={data.name} />}
            </div>
          </div>
        </div>
       
      </form>
      
      
    </section> */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Title
          className="flex justify-end items-end align-end pt-3 pl-3 pb-3 pr-8"
          onClick={handleClose}
        >
          <button>X</button>
        </Modal.Title>
        <Modal.Body>
          <div>
            <div className="w-full   p-2">
              {/* <div className="p-1">
                <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
              </div> */}

              <div className="w-full text-center p-4">
                <span className="" style={{ fontSize: 20 }}>
                  {Infos.msg.pass}
                </span>
              </div>

              <form
                onSubmit={updatePassword}
                className="w-full bg-white p-1 my-4 mx-auto "
              >
                <div className="md:flex flex-wrap border-lg">
                  <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                    <label
                      className="mb-2 inline-block"
                      htmlFor="merchantNumber"
                    >
                      {Infos.msg.Current}
                    </label>
                    <input
                      id="oldPassword"
                      type={isRevealPwd ? "text" : "password"}
                      name="oldPassword"
                      onChange={setPassword}
                      required={true}
                      placeholder={"Enter " + Infos.msg.Current.toLowerCase()}
                      className=" py-4 border  focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                    />
                  </div>
                  <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                    <label
                      className="mb-2 inline-block"
                      htmlFor="merchantNumber"
                    >
                      {Infos.msg.New}
                    </label>
                    <div className="flex bg-white border rounded-sm">
                      <input
                        id="amount"
                        type={isRevealPwd ? "text" : "password"}
                        name="password"
                        onChange={setPassword}
                        required={true}
                        placeholder={"Enter " + Infos.msg.New.toLowerCase()}
                        className="border-0 bg-white rounded-none"
                        // className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                      />
                      <img
                        height={20}
                        width={20}
                        className="mx-4 bg-qoswhite"
                        title={isRevealPwd ? "Hide password" : "Show password"}
                        src={isRevealPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                  </div>
                  <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                    <label
                      className="mb-2 inline-block"
                      htmlFor="merchantNumber"
                    >
                      {Infos.msg.Confirm}
                    </label>
                    <div className="flex bg-white border rounded-sm">
                      <input
                        id="newPassword"
                        type={isRevealPwd ? "text" : "password"}
                        name="newPassword"
                        onChange={setPassword}
                        required={true}
                        placeholder="Confirm new password"
                        className="border-0 bg-white rounded-none"
                      />
                      <img
                        height={20}
                        width={20}
                        className="mx-4 bg-qoswhite"
                        title={isRevealPwd ? "Hide password" : "Show password"}
                        src={isRevealPwd ? showPwdImg : hidePwdImg}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                  </div>

                  <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                    <label
                      className="mb-2 inline-block"
                      htmlFor="merchantNumber"
                    >
                      {/* {Infos.msg.Confirm} : */}
                    </label>
                    {Password.password === Password.newPassword ? (
                      <button
                        disabled={isLoading}
                        className={`w-full h-12 flex justify-center items-center  rounded-sm text-qosgray  font-bold ${
                          isLoading ? "bg-coolGray-300" : "bg-qosorange"
                        }  btn-sm md:max-w-sm mx-auto mt-1`}
                      >
                        {isLoading ? (
                          <Spinner className="border-qosorange h-4 w-4 mx-1" />
                        ) : (
                          `${Infos.msg.pass}`
                        )}
                      </button>
                    ) : (
                      <p className="mb-2 rounded-lg border bg-red  mt-8  mx-auto block p-2.5 text-center text-white cursor-not-allowed  font-medium">
                        {Infos.msg.passUpdate}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

function Business() {
  return (
    <>
      <MerchantNumber />
    </>
  );
}

function GeneralBusiness() {
  const company_name = useMemo(() => getUser("company_name"), []);
  const activity_description = useMemo(
    () => getUser("activity_description"),
    []
  );
  const paymentMode = useMemo(() => getUser("paymentMode"), []);
  const phone = useMemo(() => getUser("phone"), []);
  const email = useMemo(() => getUser("mail"), []);
  const first_name = useMemo(() => getUser("first_name"), []);
  const last_name = useMemo(() => getUser("last_name"), []);
  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  const [Business, setBusiness] = useFormData({
    company_name,
    activity_description,
    paymentMode,
    phone,
    email,
    first_name,
    last_name,
  });

  const [paymentMethode, setPaymentMethode] = useState();
  const [sloading, setSloading] = useState(true);

  const paymode = () => {
    FetchData(Endpoints.Utilities.getPaymentMode).then((res) => {
      setPaymentMethode(res.data.datas);
      setSloading(false);
    });
  };

  useEffect(() => {
    paymode();
  }, []);

  function UpdateUser(e) {
    e.preventDefault();
    http
      .put(Endpoints.User.updateUser, {
        ...Business,
        userId: getUser("userId"),
      })
      .then((res) => console.log(res));
  }
  return (
    <form
      onSubmit={UpdateUser}
      className="max-w-sm bg-white h-fit p-4 md:mx-2 my-4 mx-auto sm:rounded-lg "
    >
      <div className="">
        <div className="mx-auto w-full px-2  ">
          <label className="block">{Infos.msg.Payment}:</label>

          <input
            value={sloading ? "loading" : paymentMethode.items[0]}
            name="merchantnumberCurrent"
            type="tel"
            required={true}
            disabled
          />
        </div>

        <div className="row mt-4 mb-4 mx-auto w-full ">
          <label className="block">{Infos.msg.Businessname}: </label>
          <h4 className="mt-3">{Business.company_name}</h4>
        </div>

        <div className="mt-2 mx-auto w-full px-2">
          <label className="block">{Infos.msg.Describe}:</label>
          <textarea
            name="activity_description"
            value={Business.activity_description}
            onChange={setBusiness}
          ></textarea>
        </div>
      </div>
      <button className="bg-qosorange bg-opacity-70 mt-2.5 min-w-max w-full mx-auto block p-2 text-black rounded-lg font-medium">
        {Infos.msg.Save}
      </button>
    </form>
  );
}

function MerchantNumber() {
  // const [Edit, setEdit] = useState(false);

  // const merchantNumber = useMemo(
  //   () => getUser(`${operator}merchantNumber`),
  //   [Edit]
  // );

  // const moov_merchant_number = useMemo(() => getUser("moov_merchant_number"), []);
  // const mtn_merchant_number = useMemo(() => getUser("mtn_merchant_number"), []);

  // const [MerchantNumber, setMerchantNumber] = useFormData({
  //   operator,
  // });
  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  const [merchandNum, setMerchandNum] = useState();
  const [mloading, setMloading] = useState(true);
  const [reg, setReg] = useState();
  const [MerchantNumber, setMerchantNumber] = useFormData({});
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const modal = useRef();
  const [show, setShow] = useState(false);

  const MonMerchandNum = () => {
    FetchData(Endpoints.User.getUserMerchantNumbers).then((res) => {
      setMerchandNum(res.data.datas.items);
      setMloading(false);
    });
  };

  const handleClose = () => {
    // setIsActive(!isActive);
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    MonMerchandNum();
  }, []);

  const username = encryptStorage.getItem("qosUser")?.email;
  const twofa = encryptStorage.getItem("qosUser")?.isUsing2Fa;



  // console.log("reg dehors  ", reg);

  function updateMerchantNumber(e) {
    e.preventDefault();
    setLoading(true);
 

    const operator = reg;

    if (twofa) {
      PutData(
        Endpoints.User.updateUserMerchantNumbers,
        JSON.stringify([{ ...MerchantNumber, operator }]),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.responseMessage == "Successful") {
            let message = res.data.responseMessage;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: message,
            });
          } else {
            let message = "error";
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: message,
            });
          }

          // setEdit(false);
          setLoading(false);
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: error,
          });
        });
      setLoading(false);
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Enable 2FA ",
      });
      setLoading(false);
    }
  }


  function handleEditToast  () {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
  
    Toast.fire({
      icon: "error",
      title: `${Infos.msg.enab}`,
    });
  }
 


  return (
    <div className="h-full w-full bg-white mt-5">
      <section className="bg-white gap-3  flex-inline mb-14  content-center w-full align-center sm:rounded-lg ">
        <div className="h-20  mt-10 flex justify-center text-center">
          <span style={{ fontSize: 30 }}>{Infos.msg.Payout}</span>
        </div>
        <Wrapper className="mt-2 mb-10  w-full  ">
          <div className="flex  lg:px-20  row justify-start">
            <div className="bg-white overflow-x-auto hideScroll sm:rounded-lg p-2">
              <table id="mee" className="no-vertical-lines ">
                <thead>
                  <tr className="text-center bg-qosgraylink text-qosTab">
                    <th className="px-4 py-3 text-mlg  text-opacity-100">
                      {Infos.msg.Operator}
                    </th>

                    <th className="px-4 py-3 text-mlg  text-opacity-100">
                      {Infos.msg.Accountde}
                    </th>
                    <th className="px-4 text-center py-3 text-mlg  text-opacity-100">
                      {Infos.msg.Actions}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mloading
                    ? "loading..."
                    : merchandNum.map((val, key) => {
                        return (
                          <tr className="text-center  bg-white" key={key}>
                            <td className=" bg-white px-4 py-2">
                              {" "}
                              {val?.operator}
                            </td>
                            <td className="  bg-white px-4 py-2">
                              {val?.merchantNumber === "" ||
                              val?.merchantNumber === null
                                ? `${Infos.msg.No}`
                                : val?.merchantNumber}
                            </td>

                            <td className=" bg-white px-4 py-2">
                              <div className="w-full flex justify-center">
                                {encryptStorage.getItem("qosUser")
                                  ?.isUsing2Fa == false ||
                                encryptStorage.getItem("qosUser")?.isUsing2Fa ==
                                  null ? (
                                  <button
                                    onClick={handleEditToast}
                                    className="  flex justify-center items-center  text-qosorange text-opacity-80 
                                "
                                  >
                                    {Infos.msg.Edit}
                                  </button>
                                ) : (
                                  <button
                                    // value={val?.merchantNumber}
                                    onClick={handleShow}
                                    className=" text-qosorange font-bold"
                                  >
                                    {Infos.msg.Edit}
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>

              {isOpen && (
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Title
                    className="flex justify-end items-end align-end pt-3 pl-3 pb-3 pr-8"
                    onClick={handleClose}
                  >
                    <button>X</button>
                  </Modal.Title>
                  <Modal.Body>
                    <div className="flex w-full  ">
                      <div className="w-full  bg-white p-2">
                        <div className="w-full mt-4 text-center p-4">
                          <span className="" style={{ fontSize: 20 }}>
                            {Infos.msg.PayInf}
                          </span>
                        </div>

                        <form
                          onSubmit={updateMerchantNumber}
                          className="w-full bg-white p-1 my-4 mx-auto "
                        >
                          <div className=" border-lg">
                            <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                              <label className="block">
                                {Infos.msg.Select}
                              </label>

                              <select
                                onChange={(e) => setReg(e.target.value)}
                                style={styles.input}
                                name="country"
                                autoComplete="country"
                              >
                                <option disabled selected hidden>
                                  {Infos.msg.Select}
                                </option>
                                {merchandNum?.map((item, index) => (
                                  <option key={index}>{item?.operator}</option>
                                ))}
                              </select>
                            </div>

                            <div className="mx-auto w-full md:max-w-sm mt-2 mb-8 md:mb-0 md:mr-4">
                              <label
                                className="mb-2 inline-block"
                                htmlFor="merchantNumber"
                              >
                                {Infos.msg.AccountN}:{" "}
                                <span className="text-qosorange">
                                  <span className="text-qosorange  font-black underline">
                                    {" "}
                                    229{" "}
                                  </span>{" "}
                                  000000
                                </span>
                              </label>
                              <div className="flex bg-white border rounded-sm">
                                <input
                                  id="merchantNumber"
                                  type="number"
                                  name="merchantNumber"
                                  onChange={setMerchantNumber}
                                  required={true}
                                  placeholder={Infos.msg.AccountN}
                                  className="border-0 bg-white rounded-none"
                                  // className=" py-4 border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                                />
                              </div>
                            </div>

                            <div className="mx-auto w-full md:max-w-sm mt-20 mb-8 md:mb-10 ">
                              <button
                                disabled={isLoading}
                                className={`w-full h-12 flex justify-center items-center  rounded-sm text-qosgray  font-bold ${
                                  isLoading ? "bg-coolGray-300" : "bg-qosorange"
                                }  btn-sm md:max-w-sm mx-auto mt-1`}
                              >
                                {isLoading ? (
                                  <Spinner className="border-qosorange h-4 w-4 mx-1" />
                                ) : (
                                  `${Infos.msg.Save}`
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              <style jsx>{`
                .no-vertical-lines td,
                .no-vertical-lines th {
                  border-left: none !important;
                  border-right: none !important;
                }
              `}</style>
            </div>
          </div>
        </Wrapper>
      </section>
    </div>
  );
}

function Privacy() {
  const [Password, setPassword] = useFormData({});
  const [isLoading, setIsLoading] = useState(false);

  const { Lang } = useLangContext();
  const Infos = { msg: settings[Lang] };

  function updatePassword(e) {
    // console.log("value de password :", Password)

    e.preventDefault();
    setIsLoading(true);

    PutData(
      Endpoints.User.updatePassword,
      { ...Password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        // console.log("my res", res)
        if (res.data.responseMessage === "Successful") {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: message,
          });
          setIsLoading(false);
        } else if (res.data.responseMessage != "Successful") {
          let message = res.data.responseMessage;
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: message,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <section className="bg-white">
      <Wrapper className="bg-white mt-5 mb-10 py-9 ">
        <div className="h-20 flex justify-center text-center">
          <span className="mt-1" style={{ fontSize: 30 }}>
            API credentials
          </span>
        </div>

        <div className="mt-1 ">{/* <Countries/> */}</div>
        <div className="">
          <Credentials className="bg-white " />
        </div>
      </Wrapper>
    </section>
  );
}

function DocumentList() {
  return (
    <section className="">
      <div className="">
        <Documents />
      </div>

      <div className="md:grid relative overflow-hidden mx-auto my-2">
        <TabledocumentUser />
      </div>
    </section>
  );
}

function MySubscription() {
  return (
    <section className="">
      <div className="md:grid bg-white mt-5 relative overflow-hidden mx-auto my-2">
        <TableMySubscription />
      </div>
    </section>
  );
}

function IPWhitelist() {
  return (
    <section className="">
      <div className="md:grid bg-white mt-5 relative overflow-hidden mx-auto my-2">
        <WhitelistIp />
      </div>
    </section>
  );
}

function Bank() {
  return (
    <section className="">
      <div className="">
        <DocumentsBank />
      </div>

      <div className="md:grid relative overflow-hidden mx-auto my-2"></div>
    </section>
  );
}

function DocumentLoad() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState("false");
  let images = [
    {
      src: "https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg",
      title: "image title 1",
    },
    {
      src: "https://images.unsplash.com/photo-1534628526458-a8de087b1123",
      title: "image title 2",
    },
  ];

  return (
    <section>
      <div>
        <Document
          file="https://s29.q4cdn.com/816090369/files/doc_downloads/test.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>

      <div className="mt-5 h-full  justify-center">
        <p>Mes documents</p>
        <button
          onClick={() => {
            setVisible(true);
          }}
        >
          show viewer
        </button>

        <div>
          {images.map((item, index) => {
            return (
              <div key={index.toString()} className="img-item">
                <img
                  alt="aaaaa"
                  src={item.src}
                  width="200px"
                  onClick={() => {
                    setVisible("true");
                    setActiveIndex(index);
                  }}
                />
              </div>
            );
          })}
        </div>
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          zoomSpeed={0.2}
          images={images}
          activeIndex={activeIndex}
          downloadable
        />
      </div>
    </section>
  );
}


const styles = {
  input:{
    height:50,
    borderRadius:4,
  },
}