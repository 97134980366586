import React, {  useState  } from "react";
import Wrapper from "./sectionWrapper.component";
import PhoneInput from "react-phone-input-2";




  


export default  function Paycomponentsimple()  {
  const [isLoading, setLoading] = useState(false);

//     const [info, setInfo] = useState([]);
//     const [selects, setSelects] = useState();
//     const [loading, setLoading] = useState(true);
//     const [units, setUnits] = useState([]);
//     const [loadingUnit, setLoadingUnit] = useState(true);
//     const [listOperators, setlistOperators] = useState([]);
//     const [loadingOperator, setLoadingOperator] = useState(true);
//     const [data, handleInput] = useFormData();
   


//   const inf = () => {
//     FetchData(
//         Endpoints.Offers.OffersCountriesList,
       
//       ).then((res) =>
//         {console.log("countries : ",res); setInfo(res);setLoading(false);ListOfOperator({target:{value : res.data.datas.items[0]}})}


//       );
//   }

//   useEffect(() => {
//       inf()
//   }, [] )


//   const Unit = () => {
//     FetchData(
//         Endpoints.Utilities.getCalculationUnits,
       
//       ).then((res) =>
//         {console.table("Unit : ",res); setUnits(res);setLoadingUnit(false)}


//       );
//   }
//   useEffect(() => {
//     Unit()
// }, [] )


// function ListOfOperator (pays) {
//   setLoadingOperator(true)
//   console.log('yoo')
//   console.log("pays",pays)
//   if (pays.target)
//   {
//     FetchData(
//       Endpoints.Utilities.getOperators+'?country='+pays.target.value,
     
//     ).then((res) =>
//       {console.log("operator : ",res); setlistOperators(res);setLoadingOperator(false);handleInput(pays)}


//     );

//   }
  
// }


// async function handleSubmit(e) {
//   e.preventDefault();
//   setLoading(true);
//   try {
//     console.log("user Data : ", data)
//     const { data: user, status } = await CreateOffer(data);
//     console.log("un user authData : ", user)
//     console.log("un user authSatus : ", status)
//     console.log("status de lol: " , user.responseStatus)
//     console.log("status de lol23: " , user.responseStatus)

//     if (user.responseStatus === true){
//        let message = user.responseMessage;
//         const Toast = Swal.mixin({
//         toast: true,
//         position: 'top',
//         showConfirmButton: false,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener('mouseenter', Swal.stopTimer)
//           toast.addEventListener('mouseleave', Swal.resumeTimer)
//         }
//       })
      
//       Toast.fire({
//         icon: 'success',
//         title: message
//       })}
    
    
//   } catch (error) {
//     console.log("erreur :", error)
//     return setTimeout( () => {
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         title: 'Vérifier  incorrectes',
//         showConfirmButton: false,
//         timer: 5500
//       });
//     }, "100") 
   
    

//   }
//   setLoading(false);
// }


//   const handleSelect = (e) => {
//     e.preventDefault();
//     setSelects(e.target.value)
//   }

   

    return (


      <Wrapper
      style={{
        background: "transparent",
        backgroundSize: "80% .12%",
      }}
    >
      <div className="w-12/12 max-w-md bg-coolGray-50 shadow-xl py-4 mx-auto my-20 rounded-xl">
        <h2
          className="text-center md:text-mH2"
          // dangerouslySetInnerHTML={{ __html: Infos.msg.Title }}
        >  QOS PAY</h2>
        <form 
        // onSubmit={handleSubmit} 
        
        className="px-4">


          <div className="mx-auto w-full md:max-w-sm mt-8">
          <select 
                    id="country" name="country" autoComplete="country" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                   
                   <option className="text-white m-1" >MTN</option> 
                   <option className="text-white m-1" >MOOV</option>
                    </select> 
          </div>

          <div className="mx-auto w-full md:max-w-sm mt-8">
         
            <PhoneInput
                country={"bj"}
                placeholder={"(229)"}
                onlyCountries={["bj", 
                // "tg", "ng", "bf", "bf", "ci", "cm", "ml", "ne", "sn"
              ]}
                localization={{bj:"Benin", 
                // tg:'Togo', ng:'Nigeria', bf:"Burkina", ci:"cote d'ivoire", cm:"Cameroun", ml:"Mali", ne:"Niger", sn:"Senegal"  
               }}
                autoFormat={false}
                containerClass="mt-2"
                inputClass="w-full min-w-full"
                inputProps={{ required: true, name: "phone" }}
                disableCountryCode={true}
                // onChange={(phone, country, value) => {
                //   handleInput({
                //     phone: phone,
                //     country: `${country.name}`.toUpperCase(),
                //     value: value,
                //   });
                // }}
              />
        
          </div>

         

          <div className="mx-auto w-full md:max-w-sm mt-8">
            <input 
               
              placeholder="Veuillez saisir le montant du dépot" 
               type="number"  name="base_fee" id="base_fee" autoComplete="base_fee" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" step="any" />
          </div>

         


          <div className="mx-auto w-full md:max-w-sm mt-8">
                <input
                placeholder="Commentaire" 
                type="text" name="name" id="name" autoComplete="name" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
          </div>



          <button
            disabled={isLoading}
            className={`w-8/12 h-12 flex justify-center items-center rounded-md text-qosgray text-opacity-80 font-bold ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-4`}
          >
            {/* {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              btnMsg.logInButton
            )} */}
            pay
          </button>
          {/* <p className="mt-4 text-center">
            You don't have an account{" "}
            <Link
              className="text-qosblue underline inline-block ml-2"
              to="/signup"
            >
              Create an account
            </Link>
          </p> */}
        </form>
      </div>
    </Wrapper>





    );
}

