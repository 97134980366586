import { GiConsoleController } from "react-icons/gi";

import { TaxFilesUploader } from "../pages/registerBusness";

function TaxDocument() {
  return (
    <div className="text-left">
      <div className="flex w-auto border-black ">
        <p className="text-qosdark">Upload copy of tax registration document or certificate</p>
      </div>
      <div className="flex w-auto ">
        {/* <IdFilesUploader selec={selec.selects} className="w-full" text="uploads your Id " /> */}
        {/* <RccmFilesUploader  className="w-full" text="Registration Document"/> */}
        <TaxFilesUploader
          className="w-full"
          text="Upload document"
        />
      </div>
    </div>
  );
}

export default TaxDocument;
