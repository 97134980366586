import { useEffect, useState } from "react";
import { media } from "../common/libs/media";
import { useLangContext } from "./translateContext";
import { Icon } from "@iconify/react";
const { fr, en } = media.Flags;
const Languages = {
  en: {
    lang: "English",
    flag: en,
    log: "EN",
  },
  fr: {
    lang: "Français",
    flag: fr,
    log: "FR",
  },
};

export default function DropdownCustom({ Short = true }) {
  const { Lang, setLang } = useLangContext();
  const [open, setOpen] = useState(false);
  // console.log("my lang:", Lang)

  useEffect(() => {
    setOpen(false);
  }, [Lang]);

  return (
    <div className="inline-block text-left mt-2 ml-4">
      {/* <p> {Languages[Lang].lang} </p> */}

      <button
        onClick={() => setOpen((prev) => !prev)}
        className="p-1 pb-0.5 min-w-max relative flex"
      >
        {/* <img
          src={Languages[Lang].flag}
          className="h-4 w-8 inline-block"
          alt="flag"
        /> */}

        <p
          className="inline-block mx-1 text-msm font-bold text-semibold "
          style={{ fontSize: 18 }}
        >
          {Languages[Lang].log}
        </p>
        {/* <span className="fi fi-rr-angle-small-down align-bottom inline-block text-msm ml-1"></span> */}
        <Icon
              icon="ion:chevron-down"
              height={20}
              style={{ color: "black",paddingLeft:3, fontSize: "20px", marginTop:-1 }}
            />
        {!Short && (
          <>
            <p className="inline-block mx-2 text-msm">{Languages[Lang].lang}</p>
            <span className="fi fi-rr-angle-small-down inline-block"></span>
          </>
        )}
      </button>
      <div
        className={`grid grid-flow-row shadow-lg origin-top transform transition-all duration-100 rounded-md ${
          !Short && "p-2 bg-qosdark"
        } gap-y-2 mt-2 absolute ${open ? "scale-y-100" : " scale-y-0"}`} 
      >
        <button
          onClick={() => setLang("en")}
          className={`${
            Lang === "en" && "hidden"
          } min-w-max bg-qosgray bg-opacity-10 rounded-lg pr-2`}
        >
          {/* <img
            src={Languages["en"].flag}
            className="h-4 w-8 inline-block"
            alt="flag"
          /> */}
          <p className="inline-block mx-2 text-msm"  style={{ fontSize: 18 }}>{Languages["en"].log}</p>
          {!Short && (
            <p className="inline-block w-6/12 mx-2 text-qosgray text-opacity-70 text-msm">
              {Languages["en"].lang}
            </p>
          )}
        </button>
        <button
          onClick={() => setLang("fr")}
          className={`${
            Lang === "fr" && "hidden"
          } min-w-max bg-qosgray bg-opacity-10 rounded-lg pr-2`}
        >
          {/* <img
            src={Languages["fr"].flag}
            className="h-4 w-8 inline-block"
            alt="flag"
          /> */}
          <p className="inline-block mx-2 text-msm text-bold"  style={{ fontSize: 18 }}>
            {Languages["fr"].log}
          </p>
          {!Short && (
            <p className="inline-block w-6/12 mx-2 text-qosgray text-opacity-70 text-msm">
              {Languages["fr"].lang}
            </p>
          )}
        </button>
      </div>
    </div>
  );
}

