import React, { useEffect, useState } from "react";
import Wrapper from "./sectionWrapper.component";
import { FetchData } from "../common/services/httpServices";
import { Link } from "react-router-dom";
import { Endpoints } from "../common/utils/endpoints";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import { trackEvent } from "../common/utils/analytics";
import { Icon } from "@iconify/react";
import { media } from "../common/libs/media";
const { qosLogoBlack } = media.Logos.qos;

export default function MailComfirm() {
  const [mail, setMail] = useState();


  const location = useLocation();
  // console.log(" koeiuuc: ",location?.search.split("=")[1].split(".")[0]);

  const code = location?.search.split("=")[1].split(".")[0];
  // console.log('location', location)

  const verification = () => {
    FetchData(Endpoints.User.verifyMailNew + "?code=" + code).then((res) => {
      setMail(res.data.responseMessage);
    });
    trackEvent('Email Verification', 'Email Verification', `Token sent`);
  };

  const handleButtonClick = () => {
    trackEvent('Email Verified Successfully', 'Email Verified Successfully', "Email Verified Successfully");
    // alert('Button clicked');
  };

  useEffect(() => {
    verification();
  }, []);

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  return (
    <Wrapper
      style={{
        background: "transparent",
        backgroundSize: "80% .12%",
      }}
    >
      <div className="w-12/12 max-w-md bg-white shadow-sm  px-4 py-4 mx-auto my-20 rounded-lg">
        <div className="p-1">
          <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
        </div>
         {/* { mail === "Your account has been successfully verified"?<h3 className="text-qosgreen" >{mail}</h3>:<h3 className="text-qosred" >{mail}</h3>  } */}
          {/* { mail === "Your account has been successfully verified"?  */}

       {  mail === "Your account has been successfully verified" ? (
       <>
       <div className="flex justify-center p-4">
          <Icon
            // icon="icon-park:mail-unpacking"
            icon="icon-park-solid:mail-unpacking"
            height={55}
            style={{
              color: "#1faf38",
              // marginLeft: "60px",
            }}
          />
          <Icon
            // icon="icon-park:mail-unpacking"
            icon="icon-park-solid:check-one"
            height={28}
            style={{
              color: "#1faf38",
              position: "relative",
              top: 35,
              right: 12,
            }}
          />
        </div>
        <div className=" m-1 py-4 text-center">
          <h4 className="text-center pb-2 md:text-mH2 ">Email verified</h4>
          <span className="pt-4 text-qosText">
            Thank you. Your email has been successfully verified
          </span>
        </div>

        <div className="w-full  justify-center text-center mt-4">
          <button onClick={handleButtonClick} className="w-full h-16 bg-qosorange  text-white border rounded-sm focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange">
            <Link to="/">Login to your account</Link>
          </button>
        </div> 
        </>) : (<> 
        
        <div className="flex justify-center mt-2 p-4">
          <Icon
            // icon="icon-park:mail-unpacking"
            icon="tabler:mail-x"
            height={55}
            style={{
              color: "red",
              // marginLeft: "60px",
            }}
          />
        
        </div>
        <div className="m-1 py-1 text-center">
        
          <h4 className="pt-2 text-red">
          {mail}
          </h4>
        </div>

      
        </>)}

        <div className="flex justify-center mt-4 mb-36">
          <Link to="/">
            <p className="underline">Close</p>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",
    borderRadius: 4,
  },
  button: {
    // width:680,
    // height:"28",
    borderRadius: 8,
  },
};
