import { useMemo } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import Table from "react-bootstrap/Table";
import "./table.css";

export function Tablecompo({
  dataColumns,
  content,
  paymentTable = false,
  showLink,
  showQR,
}) {
  const columns = useMemo(() => dataColumns, [dataColumns]);

  // const [filterInput, setFilterInput] = useState("");

  // Update the state when input changes
  // const handleFilterChange = (e) => {
  //   const value = e.target.value || undefined;
  //   const name = e.target.name || undefined;
  //   setFilter("name", value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value
  //   setFilterInput(value);
  // };

  const data = useMemo(() => content, [content]);
  const tableInstance = useTable({ columns, data }, useFilters, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // setFilter,
  } = tableInstance;
  return (
    <div>
      <div className="overflow-x-auto hideScroll sm:rounded-lg mt-10 ">
        <table
          //  id="customers"
          className="no-vertical-lines border"
          // className="min-w-full divide-y divide-qosorange"
          {...getTableProps()}
        >
          <thead className="text-left">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    scope="col"
                    className="px-4 py-3  text-left relative text-msm text-qosdark text-opacity-100 "
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("name")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="fi font-bold text-mlg fi-rr-angle-small-down block"></span>
                      ) : (
                        <span className="fi font-bold text-mlg fi-rr-angle-small-up block"></span>
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
                {paymentTable && (
                  <>
                    <th
                      scope="col"
                      className="px-4 py-2 text-left text-msm text-qosdark text-opacity-100 uppercase tracking-wide"
                    >
                      LINK
                    </th>
                    {/* <th
                      scope="col"
                      className="px-4 py-2 text-left text-msm text-qosdark text-opacity-100 uppercase tracking-wide"
                    >
                      QR CODE
                    </th> */}
                  </>
                )}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="divide-y divide-gray-200 text-center"
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="bg-white">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="px-6 py-4 whitespace-nowrap bg-white"
                        {...cell.getCellProps()}
                      >
                        <div className="text-mST font-medium text-qosdark">
                          {cell.render("Cell")}
                        </div>
                      </td>
                    );
                  })}
                  {paymentTable && (
                    <>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-mST bg-qosblue opacity-70 text-center bg-clip-text text-transparent font-medium text-qosdark">
                          <button
                            onClick={showLink}
                            className="shadow-sm rounded-md p-0.5 text-center"
                          >
                            <span className="fi fi-rr-eye"></span>
                          </button>
                        </div>
                      </td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-mST bg-qosblue opacity-70 text-center bg-clip-text text-transparent font-medium text-qosdark">
                          <button
                            onClick={showQR}
                            className="shadow-sm rounded p-0.5 text-center"
                          >
                            <span className="fi fi-rr-apps"></span>
                          </button>
                        </div>
                      </td> */}
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <style jsx>{`
          .no-vertical-lines td,
          .no-vertical-lines th {
            border-left: none !important;
            border-right: none !important;
          }
        `}</style>
      </div>
    </div>
  );
}
