import { Sidebar } from "../components/sidebar.component";
import { Header } from "../components/header.component";
import { useSideBarContext } from "../common/utils/context";
import { BreadCrumb } from "../components/breadcrumb.component";
export default function PublicLayout({ children }) {
  const { Active, setActive } = useSideBarContext();
  return (
    <main className="min-h-screen bg-white  relative flex overflow-hidden">
      <section className="max-h-screen w-full overflow-x-hidden overflow-y-auto pt-16">
        {children}
      </section>
    </main>
  );
}
