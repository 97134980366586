import {
  useEffect,
  // useMemo,
  useState,
} from "react";
import { PostData, FetchData } from "../../common/services/httpServices";
import { Endpoints } from "../../common/utils/endpoints";
import Swal from "sweetalert2";
import offer from "../translation/offer.json";
import { useLangContext } from "../translateContext";

import { Spinner } from "../spinner.component";

// Define a default UI for filtering

import "./Tableoffer.css";

function compareArrays(arr1, arr2) {
  return arr1.filter((item1) => {
    return !arr2.some((item2) => item1.name === item2.service_name);
  });
}

export function Tableoffers(props) {
  const [list, setList] = useState([]);
  const [subList, setSubList] = useState([]);
  // const [selects, setSelects] = useState();
  const [loading, setLoading] = useState(true);
  const [subloading, setSubLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [spin, setSpinner] = useState("");

  const { Lang } = useLangContext();
  const Infos = { msg: offer[Lang] };

  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);
  const [result, setResult] = useState([]);

  const List = () => {
    FetchData(Endpoints.Offers.offersList).then((res) => {
      setArray1(res.data.datas.items);
      setList(res);
      setLoading(false);
    });
  };

  const SubList = () => {
    FetchData(Endpoints.Subscriptions.UserIsSubscriptionsList).then((res) => {
      setArray2(res.data.datas.items);
      setSubList(res);
      setSubLoading(false);
    });
  };

  // FetchData(
  //   Endpoints.Subscriptions.UserIsSubscriptionsList,

  // ).then((res) =>
  //   { setSubList(res);setSubLoading(false)}

  // );
  // const array1 = loading ?  "loading...":  list.data.datas.items;
  // const array2 = subloading ?  "loading...":  subList.data.datas.items;

  // console.log("array1 : ",  array1  )
  // console.log("array2 : ",  array2  )

  // console.log("result : ",  result  )

  // FetchData(
  //   Endpoints.Offers.offersList,

  // ).then((res) =>
  //   {; setList(res);setLoading(false)}

  // );

  // function compareArrays() {

  //   const array1 = loading ?  "loading...":  list.data.datas.items;
  //   const array2 = subloading ?  "loading...":  subList.data.datas.items;
  //   return array1.filter(item1 => {
  //     return !array2.some(item2 => item1.name === item2.service_name);
  //   });
  // }

  // const obj2Response = await fetch('http://my-api.com/object2');
  // const array2 = subloading ?  "loading...":  subList.data.datas.items ;

  // const result = compareArrays();

  //  console.log("RESULT",result);

  useEffect(() => {
    List();
    SubList();
  }, []);

  useEffect(() => {
    setResult(compareArrays(array1, array2));
  }, [array1, array2]);

  // const data = [
  //     { name: "MTN BJ API", rate: 1.7, period: "-" , validady: "-", status: "FREE",},
  //     { name: "MOOV TOGO API", rate: 3000, period: 5, validady: 30},

  // //   ]

  //  console.log("New liste : ",  loading ?  "loading...":  list.data.datas.items  )
  //  console.log("New Subliste : ",  subloading ?  "loading...":  subList.data.datas.items  )

  //  console.log("New result : ", result  )

  let has_discount = false;
  let discount = 0;

  const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  async function SubscribeToOffer(SubscribeObject) {
    // console.log("New user Data sube : ", SubscribeObject )
    setIsLoading(true);

    SubscribeObject.discount = discount;
    SubscribeObject.has_discount = has_discount;

    let payload = JSON.stringify(SubscribeObject);
    // console.log("Payload : ", payload)

    try {
      const res = await PostData(
        Endpoints.Subscriptions.SubscribeToService,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("User subscription Res :",res);
      if (res.data.responseMessage === "Successful") {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (
        res.data.responseMessage ===
        "User has already subscribed to service in this country with same operator "
      ) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: message,
        });
      } else if (
        res.data.responseMessage === "User has already subscribed to service "
      ) {
        let message = res.data.responseMessage;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setIsLoading(false);
        Toast.fire({
          icon: "error",
          title: message,
        });
      }
      return res;
    } catch (e) {}
  }

  return (
    <div>
      <div className="overflow-x-auto hideScroll sm:rounded-lg p-2">
        {/* <div>
      {result.map(item => (
        <div>{item.name}</div>
      ))}
    </div> */}

        <table className="min-w-full divide-y divide-qosdark">
          <tr className="min-w-full ">
            <th className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left">
              {Infos.msg.Product}
            </th>
            <th className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left">
              {Infos.msg.Rate}
            </th>
            {/* <th className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left"> Evaluation Period (day)</th> */}
            <th className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left">
              {Infos.msg.Validity}
            </th>
            <th className="px-4 py-2 relative text-msm text-qosdark text-opacity-100 uppercase tracking-tighter leading-3 truncate text-left">
              {Infos.msg.Action}
            </th>
          </tr>
          {loading
            ? "loading..."
            : result.map((val, key) => {
                return (
                  <tr className="divide-y text-left w-full" key={key}>
                    <td className="px-6 text-left divide-qosorange py-4 whitespace-nowrap">
                      <div className="flex text-left divide-y divide-qosorange ">
                        {val.description}
                        {/* {val.name} */}
                        {/* { val.is_free === true ? <p style={styles}>FREE</p> : null} */}
                      </div>
                    </td>
                    <td className=" px-6 py-4 whitespace-nowrap">
                      {val.base_fee}{" "}
                      {val.calculation_unit === "PERCENTAGE"
                        ? "%"
                        : val.calculation_unit === "AMOUNT"
                        ? "XOF"
                        : null}
                    </td>
                    {/* <td  className="px-6 py-4 whitespace-nowrap">{val.evaluation_period}</td> */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      {val.validity_period}
                    </td>
                    <td className=" py-4 whitespace-nowrap text-left">
                      {/* <button className="bg-qosorange p-2 ml-4 rounded-md text-white"  >Subscribe</button> */}
                      <button
                        value={val.name}
                        onClick={(e) =>
                          SubscribeToOffer(
                            {
                              service_name: val.name,
                              calculation_unit: val.calculation_unit,
                            },
                            setSpinner(e.target.value)
                          )
                        }
                        disabled={isLoading}
                        className={`bg-qosorange p-2 ml-4 justify-center items-center rounded-md text-qosgray text-opacity-80 font-bold ${
                          isLoading ? "" : "bg-qosorange"
                        } bg-opacity-80 btn-sm md:max-w-sm mx-auto mt-4`}
                      >
                        {spin === val.name && isLoading === true ? (
                          <Spinner className="border-qosorange h-4 w-4 mx-1" />
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })}
        </table>
      </div>
    </div>
  );
}

const styles = {
  backgroundColor: "#0e730f",
  borderRadius: 3,
  padding: 2,
  marginLeft: 10,
  width: 60,
  borderColor: "#0e730f",
  alignContent: "center",
  borderWidth: 1,
  color: "white",
  textAlign: "center",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "12px",
};
