import { GiConsoleController } from "react-icons/gi";
import { IdFilesUploader } from "../pages/public/register/index";

function IdDocument(selec) {
  return (
    <div className="mt-2">
      <div className="flex w-auto border-black ">
        <p className="text-qosdark">Upload copy of your Identification Card</p>
      </div>
      <div className="flex w-auto">
        <IdFilesUploader selec={selec.selects} className="w-full" text="Upload document" />
      </div>
    </div>
  );
}

export default IdDocument;
