import Popover from "react-bootstrap/Popover";import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useLangContext } from "../components/translateContext";
import transfer from "../components/translation/transfer.json";
import {
  FetchData,
  //  PostData
} from "../common/services/httpServices";
import Table from "react-bootstrap/Table";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import { Icon } from "@iconify/react";



import "./table.css";

export function TopUpPage() {
  const [state, setstate] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  // console.log("state", state)

  return (
    <section className="p-4 ">
    {/* <div className="mt-12 mb-5 flex text-left gap-1">
        <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
          {Infos.msg.TopUp}
        </p>
      </div> */}
      <div className="flex-column text-left gap-2">
        <h4 style={{ color: "#45464E" }} className="pt-4 ">
        {Infos.msg.ChooseTop} (0)
        </h4>
        <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
        {Infos.msg.Seeal} 
        </span>
      </div> 
      
      <header className="mt-10">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-32 sm:w-32 md:w-44  text-white h-full bg-qosorange rounded-full p-2 md:px-4`}
          ></div>
          {/* <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-white"
            } z-10 w-32 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-12`}
          >
            {Infos.msg.Transfer}
          </button> */}
          {/* <button
          onClick={() => setstate(1)}
          className={`transition-all duration-500 ${
            state === 1 && "text-white"
          } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
        >
          TopUp
        </button> */}
        </nav>
      </header>
      <div>
        <section>
          {/* {state === 0 && <Transfers />} */}
          {state === 0 && <TopUpState />}
        </section>
      </div>
    </section>
  );
}



function TopUpState() {

  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  const [loading, setLoading] = useState(false)
  const [ transaction , SetTransaction ] = useState([])

  const TopuGetTransaction = () => {
    FetchData(Endpoints.Transfer.TopupTransaction).then((res) => {
      // console.log("Top Up tarnscation ==>", res)
      SetTransaction(res);
      setLoading(false);
    });
  };

  useEffect(()=>{
    TopuGetTransaction()
  },[])
 
  return (
    <div className=" relative overflow-hidden  mx-auto my-2">
  

      <div className="mt-10 max-w-full lg border bg-white p-4 rounded-md flex justify-between items-center mx-auto">
        <p className="font-semibold text-qosdark text-opacity-90">
          {/* {`${loadingLink ?  "loading...": Links.data.datas.items.length} ${Infos.msg.links}`}{" "} */}
        </p>

        <Link to="/topup" className="">
          <button
            // onClick={() => modal.current.classList.remove("-translate-y-full")}
            className="flex justify-between gap-2 bg-qosorange  min-w-max px-8  py-2 text-white rounded-lg font-medium"
          >
            {Infos.msg.TopUp}
            <Icon
              icon="ri:add-line"
              height={24}
              style={{ color: "white", fontSize: "20px" }}
            />
          </button>
          </Link>
    
      </div>

      <div className="w-full  mt-8 border">
        <table id="customers">
          <thead>
            <tr className="min-w-full text-center ">
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Channel
              </th>
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Amount
              </th>
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Account 
              </th>
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Date
              </th>
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Reference
              </th>
              <th style={styles.title} className="px-4 py-3  relative text-msm text-qosdark text-opacity-100 capitalize tracking-tighter leading-3 truncate ">
              Status
              </th>
            </tr>
          </thead>
          <tbody>
          {transaction?.data?.datas?.map(( items) =>{
            return(
              <tr className=" text-center w-full" key={items.id}>
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.channel}
              </td>
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.amount}
              </td>
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.account}
              </td> 
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.dateRequest}
              </td>
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.transactionId}
              </td> 
              <td className="px-6  text-center  divide-qosorange py-4 whitespace-nowrap">
                {items.status}
              </td>
              </tr>
            )
          } ).reverse()}
          </tbody>
        </table>
      </div>

      {/* <TransferComponent /> */}
    </div>
  );
}

const styles = {
  input: {
    borderRadius: 4,
  },
  button: {
    borderRadius: 4,
  },
  button1: {
    borderWidth: 1,
    borderRadius: 4,
  },
  title:{ 
    fontSize: 18 
  }
};