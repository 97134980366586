import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
// import { getUser } from "../common/utils/tools";
// import { Switch } from "../components/switch.component";
// import { getUserTransCredentials } from "../common/utils/tools";
// import { useMemo } from "react";
// import axios from "axios";
// import { black } from "tailwindcss/colors";
// import { isEmpty, isNull, isUndefined } from "lodash";
import { Spinner } from "../components/spinner.component";
// import { reduceHooks } from "react-table";
// import { Button } from 'react-bootstrap';
import { useLangContext } from "../components/translateContext";
import transfer from "../components/translation/transfer.json";
import {
  FetchData,
  //  PostData
} from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";
import Wrapper from "../components/sectionWrapper.component";

import TransferComponent from "../components/transfert.component";
import { Withdrawal, CardPayout } from "../common/auth";
import Swal from "sweetalert2";
import { PayoutStatements } from "./payoutStatements";

export function Transfer() {
  const [state, setstate] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  return (
    <section>
      <header className="">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-32 sm:w-32 md:w-44  text-white h-full bg-qosorange bg-opacity-40 rounded-full p-2 md:px-4`}
          ></div>
          {/* <button
            onClick={() => setstate(0)}
            className={`transition-all duration-500 ${
              state === 0 && "text-black"
            } z-10 w-32 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
          >
            {Infos.msg.Title}
          </button> */}
          {/* <button
          onClick={() => setstate(1)}
          className={`transition-all duration-500 ${
            state === 1 && "text-black"
          } z-10 w-24 sm:w-32 md:w-44 font-semibold text-msm md:text-mlg p-2 md:px-4`}
        >
          Transfer
        </button> */}
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <PayoutStatements />}
          {/* {state === 1 && <Transfers />} */}
        </section>
      </div>
    </section>
  );
}

export function Withdraw() {
  const { pathname } = useLocation();

  const [myAmount, setMyAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  // const [toggle, setToggle] = useState(!true);

  const [data, handleInput] = useFormData();
  // const [info, setInfo] = useState([]);

  const [Infocountries, setInfocountries] = useState([]);
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState([]);
  const [chosenPays, setchosenPays] = useState("Benin");

  const [number, setNumber] = useState([]);

  const [numberLoading, setNumberLoading] = useState(true);

  const [network, setNetwork] = useState("");
  const [val, setNewval] = useState("");

  const modal = useRef();
  const modalLink = useRef();

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  useEffect(() => {
    countries();
  }, []);

  function ListOfOperator(pays) {
    // console.log("pays",pays)
    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;

    if (pays.target) {
      FetchData(
        // Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        setchosenPays(lopetateur);
        // setchosenPays(pays.target.value);
        setlistOperators(res.data.datas.items);
      });
    }
  }

  function getAmout(operateur) {
    FetchData(
      Endpoints.User.getAvailableAmount +
        "?country=" +
        chosenPays +
        "&operator=" +
        operateur.target.value
    ).then((res) => {
      setMyAmount(res.data.datas.amount);
      setLoading(true);
    });
  }

  const [monNumero, setMonNumero] = useState("");

  function handleChange(event) {
    // console.log("voila toi nous maj",event.target.value);
    getAmout(event);
    setNetwork(event.target.value);
    setNewval(event.target.value);

    FetchData(Endpoints.User.getUserMerchantNumbers).then((res) => {
      setNumber(res);
      setNumberLoading(false);
      // console.log("moo po", network );

      if (res.data.datas.items.length !== 0) {
        // console.log("voila toi nous maj",event.target.value);
        for (let x in res.data.datas.items) {
          let elem = res.data.datas.items;
          if (network !== undefined) {
            if (
              event.target.value.toLowerCase() ===
              elem[x].operator.toLowerCase()
            ) {
              // console.log("voila toi nous", elem[x].operator.toLowerCase());
              setMonNumero(elem[x].merchantNumber);
            }
          }
        }
      }
    });
  }



  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    let newoperateur = { operator: network };
    let Newdata = {
      ...data,
      ...newoperateur,
    };

    try {
      // console.log("widraw Data : ", Newdata)
      // console.log("montant", Newdata.amount)

      const { data: user } = await Withdrawal(Newdata);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        let message = user.datas.responsemsg;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 20000);

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (user.responseStatus === false) {
        // console.log("rerere", user.datas.responsemsg)
        // console.log("error", user.responseMessage)

        // let message = user.datas.responsemsg || user.datas.responseMessage;
        if (
          user.datas.responsemsg === undefined ||
          user.datas.responsemsg === ""
        ) {
          let message = user.responseMessage;
          return setTimeout(() => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 20000);
            Toast.fire({
              icon: "error",
              title: message,
            });
          }, "100");
        } else {
          let message = user.datas.responsemsg;
          return setTimeout(() => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 20000);
            Toast.fire({
              icon: "error",
              title: message,
            });
          }, "100");
        }
      }
    } catch (error) {
      // console.log("erreur :", error)

      setTimeout(() => {
        setIsLoading(false);
      }, 10000);
      return setTimeout(() => {
     
        const Toast = Swal.fire({
          position: "top-end",
          timerProgressBar: true,
          showConfirmButton: false,
          timer: 5500,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "error",
          title: "Verify the information entered",
        });
      }, "100");
    }

    // setLoading(false);
  }

  async function cardSubmit(e) {
    e.preventDefault();
    setIsLoading(true);

    // let ope = {net :network};

    let Newdata = {
      ...data,
    };

    Newdata.mode =
      Newdata.mode === "MOOV 0.5%"
        ? "MOOV"
        : Newdata.mode === "MTN 0.5%"
        ? "MTN"
        : Newdata.mode === "BANK 0.0%"
        ? "Bank"
        : Newdata.amount;

    try {
      // console.log("Card Data : ", Newdata)
      const { data: user } = await CardPayout(Newdata);
      // console.log("un user authData : ", user)
      //  console.log("mon authSatus : ", status)
      // console.log("status lol: " , user.responseStatus)
      // console.log("status de lol23: " , user.responseMessage)

      if (user.responseStatus === true) {
        let message = user.datas.responsemsg;
        const Toast = Swal.mixin({
          toast: true,
          position: "top",
          timer: 5500,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 20000);

        Toast.fire({
          icon: "success",
          title: message,
        });
      } else if (user.responseStatus === false) {
        let message = user.datas.responsemsg;
        return setTimeout(() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 20000);
          Toast.fire({
            icon: "error",
            title: message,
          });
        }, "100");
      }
    } catch (error) {
      // console.log("erreur :", error)

      setTimeout(() => {
        setIsLoading(false);
      }, 20000);
      return setTimeout(() => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Verify the information entered",
          showConfirmButton: false,
        });
      }, "100");
    }
    // setLoading(false);
  }
  // max-w-screen-xl
  return (
    <div className="mt-4  relative overflow-hidden mx-auto ">
      <Wrapper className=" mb-4">
        <div className="bg-white w-full  mx-auto px-4  md:w-4/6 lg:w-4/6 sm:w-full lg:px-2 md:px-2   ">
          <div className="flex justify-end mx-auto  lg:w-12/12">
            <div>
              <select
                onChange={(e) => ListOfOperator(e)}
                className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
              >
                {/* <option value="" disabled selected hidden className="text-black">
                BENIN
              </option> */}
                {doloading
                  ? "loading.."
                  : Infocountries?.data?.datas?.items?.map((item) => {
                      // console.log("Infocountries Infocountries", item);
                      return (
                        <option
                          className="text-black m-1 w-10"
                          key={item.index}
                        >
                          {item === "TOGO"
                            ? "🇹🇬"
                            : item === "BENIN"
                            ? "🇧🇯"
                            : item === "IVORY_COST" || item === "IVORY_COAST"
                            ? "🇨🇮"
                            : ""}
                        </option>
                      );
                    })}
              </select>
            </div>
          </div>

          <div className="w-full pl-0 pt-4 mb-3  gap-1 flex  justify-start mx-auto  lg:w-12/12">
            {/* <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
              <img src={qosLogoWhite} alt="" className="h-full w-auto" />
            </span> */}
            <h3
              className="md:text-mH2  text-center font-sans"
              style={{ fontSize: 30 }}
            >
              {myAmount}
            </h3>
            <span className="text-qosText pt-2.5"> XOF</span>
          </div>

          <form onSubmit={handleSubmit} className="mt-2 w-full ">
            <div className="mx-auto  lg:w-12/12 mt-8">
              <div>
                <span className="block">{Infos.msg.Operator}</span>
                {doloading ? (
                  <select
                    onChange={handleInput}
                    id="operators"
                    name="operators"
                    autoComplete="operators"
                    // className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
                    style={styles.input}
                    className="h-18 py-1 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  >
                    <option className="text-black m-1">loading...</option>
                  </select>
                ) : (
                  <select
                    id="operators"
                    onChange={handleChange}
                    name="operators"
                    autoComplete="operators"
                    // className="h-8 text-black text-mmd px-4 md:w-40 min-w-max bg-qosorange  bg-opacity-70"
                    style={styles.input}
                    className="h-18 py-1 border text-black rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  >
                    <option
                      value=""
                      disabled
                      selected
                      hidden
                      className="h-8 text-black  px-4 md:w-40 min-w-max bg-qosorange"
                    >
                      {Infos.msg.Operator}
                    </option>
                    {listOperators.map((item) => (
                      <option className="text-black m-1" key={item.index}>
                        {item}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="mx-auto lg:w-12/12  mt-8 ">
              <span htmlFor="merchantNumberTrans" className="">
                {Infos.msg.Receiver}
              </span>
              <input
                style={styles.input}
                className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                onChange={handleInput}
                id="merchantNumberTrans"
                name="phoneNumber"
                type="tel"
                placeholder="-"
                required="true"
                defaultValue={
                  monNumero === "null" || monNumero === "CARD" ? "-" : monNumero
                }
                disabled={true}
              />
            </div>

            <div className="mx-auto lg:w-12/12  mt-8">
              <span htmlFor="amount">{Infos.msg.Amount}</span>
              <div className="relative">
                <input
                  style={styles.input}
                  className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
                  id="amount"
                  type="tel"
                  name="amount"
                  onChange={handleInput}
                  placeholder="Enter amount"
                  required="true"
                />
              </div>
            </div>

            <button
              style={styles.button}
              disabled={isLoading}
              className={` w-full xl:w-12/12 lg:w-12/12   flex justify-center items-center  text-white text-opacity-80  ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              } bg-opacity-80 p-3 btn-sm mx-auto mt-14`}
            >
              {isLoading ? (
                <Spinner className="border-qosorange h-4 w-4 mx-1" />
              ) : (
                `${Infos.msg.Button}`
              )}
            </button>
          </form>
        </div>
      </Wrapper>

      <section
        id="modal"
        ref={modal}
        className="absolute transform -translate-y-full  duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-full h-full left-0"
        onClick={(e) =>
          e.target.id === "modal"
            ? modal.current.classList.add("-translate-y-full")
            : ""
        }
      >
        <div className="max-w-screen-sm duration-500 bg-white rounded relative p-4 my-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="col-start-1 col-end-7 justify-center text-center">
            <div className=" p-5">
              <h4>For card payout send a request to our email </h4>

              <h3 className="mt-10">
                {" "}
                <a href="mailto:support@qosic.com" className="text-qosorange">
                  support@qosic.com
                </a>{" "}
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section
        id="modalLink"
        ref={modalLink}
        className="absolute transform -translate-y-full duration-500 top-0 z-50 bg-qosdark bg-opacity-10 w-screen h-screen left-0"
        onClick={(e) =>
          e.target.id === "modalLink"
            ? modalLink.current.classList.add("-translate-y-full")
            : ""
        }
      ></section>
    </div>
  );
}

function Transfers() {
  return (
    <div className="max-w-screen-xl relative overflow-hidden  mx-auto my-2">
      <TransferComponent />
    </div>
  );
}

const styles = {
  input: {
    // width:680,
    // height:"42px",

    borderRadius: 4,
  },
  button: {
    // width:680,
    height: "28",
    borderRadius: 8,
  },
};
