import {
  useEffect,
  useState,
  // useRef
} from "react";
// import { DashTable } from "../../../components/tables/dashtable.component";
// import { Switch } from "../../../components/switch.component";
// import { PostData } from "../../../common/services/httpServices";
// import DashboardCard14 from "../../../partials/dashboard/DashboardCard15";
// import DashboardCard16 from "../../../partials/dashboard/DashboardCard16";
// import axios from "axios";
// import { getUser } from "../../../common/utils/tools";
// import numeral from "numeral";
// import Countries from "../../../partials/dashboard/Countries";
// import { Spinner } from "../../../components/spinner.component";
import { FetchData } from "../../../common/services/httpServices";

import { Endpoints } from "../../../common/utils/endpoints";

import DashboardCard07 from "../../../partials/dashboard/DashboardCard09";
import DashboardCard04 from "../../../partials/dashboard/DashboardCard14";
import DashboardCardAll from "../../../partials/dashboard/DashboardCard17";
import numeral from "numeral";
import { Spinner } from "../../../components/spinner.component";

// import HistoryTransation from "../../../partials/dashboard/HistoryTransation";

import UserAllLastTansaction from "../../../partials/dashboard/UserAllLastTansaction";
import { useLangContext } from "../../../components/translateContext";
import overview from "../../../components/translation/overview.json";

const ci_operatorsdata = {};
const togoceldata = {};
const moovtgdata = {};
const mtndata = {};
const moovdata = {};
const carddata = {};
const typedata = {};

mtndata["inflow"] = "REQUESTPAYMENT";
mtndata["outflow"] = "MAKEDEPOSIT";
moovtgdata["inflow"] = "REQUESTPAYMENT";
moovtgdata["outflow"] = "MAKEDEPOSIT";
togoceldata["inflow"] = "REQUESTPAYMENT";
togoceldata["outflow"] = "MAKEDEPOSIT";
moovdata["inflow"] = "REQUESTPAYMENTMV";
moovdata["outflow"] = "MAKEDEPOSITMV";
carddata["inflow"] = "REQUESTPAYMENT";
carddata["outflow"] = "MAKEDEPOSIT";
ci_operatorsdata["inflow"] = "REQUESTPAYMENT";
ci_operatorsdata["outflow"] = "MAKEDEPOSIT";

typedata["ci_operators"] = ci_operatorsdata;
typedata["togocel"] = togoceldata;
typedata["moovtg"] = moovtgdata;
typedata["mtn"] = mtndata;
typedata["moov"] = moovdata;
typedata["card"] = carddata;

export function Dashboard() {
  let operateursVariable = [];
  const [Operator, setOperator] = useState("MTN");
  // const [Amount, setAmount] = useState({});

  // const [country, setCountry] = useState({});
  const [selects, setSelects] = useState();
  // const [ luck, setLuck ] = useState('la chance');
  // const [ loading, setLoading ] = useState(true);

  // const [montant, setMontant] = useState({});

  const [load, setload] = useState(true);
  const [newOperators, setnewOperators] = useState(operateursVariable);

  const [Infocountries, setInfocountries] = useState([]);
  const [doloading, setDoLoading] = useState(true);
  const [listOperators, setlistOperators] = useState([]);
  const [chosenPays, setchosenPays] = useState("BENIN");

  const [allInfo, setAllInfo] = useState({});
  const { Lang } = useLangContext();
  const Infos = { msg: overview[Lang] };

  const countries = () => {
    FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
      setInfocountries(res);
      setDoLoading(false);
      ListOfOperator({ target: { value: res.data.datas.items[0] } });
    });
  };

  const getAllData = () => {
    FetchData(Endpoints.Subscriptions.getAllInfo).then((res) => {
      // console.log('getAllData', res)
      setAllInfo(res.data.datas);
      // console.log('getAllData2', res.data.datas.items)
      setDoLoading(false);
    });
  };

  useEffect(() => {
    countries();
    getAllData();

    // console.log('contries')
  }, []);

  async function getAllAvailableAmount(pays, operateurs) {
    // console.log("OPEURATEUSSS :",operateurs)
    if (pays) {
      for (let i = 0; i < operateurs.length; i++) {
        // console.log("paysss",i)
        let type_of_req = typedata[operateurs[i].toLowerCase()]["inflow"];
        let res = await FetchData(
          Endpoints.User.getAvailableAmount +
            "?country=" +
            pays +
            "&operator=" +
            operateurs[i]
        );
        // console.log("paysss", res)
        let resp = await FetchData(
          Endpoints.Transactions.getTodayAmount +
            "?country=" +
            pays +
            "&operator=" +
            operateurs[i] +
            "&type=" +
            type_of_req
        );
        // console.log("paysss",resp)
        let temp = resp.data.datas.inflow;

        for (let v in temp) {
          if (temp[v].period === "today") {
            let count = temp[v].count;
            let payment = temp[v].amount;
            operateursVariable.push({
              operateur: operateurs[i],
              amount: res.data.datas.amount,
              coun: count,
              pay: payment,
            });
          }
        }
      }
      setload(false);

      setnewOperators(operateursVariable);
    }
  }

  // function getAvailableAmount (pays,operateur,index,nb_total) {
  //   // console.log('sssssss',operateur)
  //   let type_of_req = typedata[operateur.toLowerCase()]['inflow']

  //   // console.log("paysss",pays)
  //   if (pays)
  //   {
  //     FetchData(
  //       Endpoints.User.getAvailableAmount+'?country='+pays+"&operator="+operateur,

  //     ).then((res) =>
  //       {
  //         // console.log("operator YUYU : ",res);
  //         FetchData(
  //           Endpoints.Transactions.getTodayAmount+'?country='+pays+'&operator='+operateur+'&type='+type_of_req

  //           ).then((resp) =>
  //             {
  //             let temp = resp.data.datas.inflow;

  //             for (let v in temp){
  //               if (temp[v].period ==='today'){
  //                 let count = temp[v].count ;
  //                 let payment = temp[v].amount ;
  //                 operateursVariable.push(res.data.datas.amount);

  //               }
  //             }
  //             console.log('pp',res.data.datas)
  //             console.log('ppindex',index)

  //             if (index === nb_total)
  //             {
  //               setload(false)

  //               setnewOperators(operateursVariable)

  //               // console.log('next', newOperators)

  //             }

  //           });

  //         }

  //     );

  //   }

  // }

  function ListOfOperator(pays) {
    let lopetateur = `${
      pays.target.value === "🇹🇬"
        ? "TOGO"
        : pays.target.value === "🇧🇯"
        ? "BENIN"
        : pays.target.value === "🇨🇮"
        ? "IVORY_COST"
        : pays.target.value
    }`;
    if (pays.target) {
      FetchData(
        Endpoints.Subscriptions.getOperators + "?country=" + lopetateur
      ).then((res) => {
        // setchosenPays(pays.target.value);
        setchosenPays(lopetateur);
        getAllAvailableAmount(lopetateur, res.data.datas.items);
        setlistOperators(res.data.datas.items);
      });
    }
  }

  function handleChange(event) {
    // console.log("voila toi nous maj",event.target.value);
    // getAmout(event)
    setOperator(event.target.value);
  }

  // useEffect(() => {
  //     inf()
  //      console.log("verification country : ", Country )
  // }, [loading] );

  const handleSelect = (e) => {
    e.preventDefault();
    setSelects(e.target.value);
  };

  return (
    <section className="">
      <div className="p-4 text-left gap-1">
        {/* <Switch setOperator={setOperator} /> */}
        {/* <div className="mt-4 mb-5 flex text-left gap-1">
          <p className=" " style={{ fontSize: 20, color: "#45464E" }}>
            {Infos.msg.Overview}
          </p>
        </div> */}
        <div className="flex-column text-left gap-2 mb-4">
          <h4 style={{ color: "#45464E" }} className="pt-4 ">
            {Infos.msg.Dashboard}
          </h4>
          <span className="pt-4" style={{ fontSize: 14, color: "#B2B2B2" }}>
            {Infos.msg.See}
          </span>
        </div>

        <div className="rounded-lg mx-auto">
          <DashboardCardAll allInfo={allInfo} loading={load} />
        </div>

        <div className=" p-1 overflow-x-auto w-full mt-10 hideScroll ">
          <div className="grid bg-white border rounded ">
            <div className="flex justify-end">
              <div className="px-2 pt-3">
                <select
                  onChange={(e) => ListOfOperator(e)}
                  className="h-18  w-15 bg-white bg-opacity-70 text-black text-mmd "
                >
                  {doloading
                    ? "loading.."
                    : Infocountries?.data?.datas?.items?.map((item) => {
                        // console.log("Infocountries Infocountries", item);
                        return (
                          <option
                            className="text-white m-1 w-10"
                            key={item.index}
                          >
                            {item === "TOGO"
                              ? "🇹🇬"
                              : item === "BENIN"
                              ? "🇧🇯"
                              : item === "IVORY_COST" || item === "IVORY_COAST"
                              ? "🇨🇮"
                              : ""}
                          </option>
                        );
                      })}
                </select>
              </div>

              <div className="px-2 pt-3">
                {doloading ? (
                  <span
                    id="operators"
                    name="operators"
                    autoComplete="operators"
                    className="mt-1 block w-full  bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span className="text-black m-1">loading...</span>
                  </span>
                ) : (
                  <select
                    id="operators"
                    onChange={handleChange}
                    name="operators"
                    autoComplete="operators"
                    className="h-18  bg-white bg-opacity-70 text-black text-mmd px-4 md:w-40 min-w-max "
                  >
                    {listOperators.map((item) => (
                      <option key={item.index}>{item}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            <div className="w-100 overflow-x-auto hideScroll">
              <DashboardCard07
                country={chosenPays}
                Operators={listOperators}
                network={Operator}
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto hideScroll space-x-4  mx-auto">
          <UserAllLastTansaction country={chosenPays} />
        </div>
        {/* <Switch setOperator={setOperator} />  */}
      </div>
    </section>
  );
}

function Overview() {
  return (
    <section className="my-8">
      <div className="mb-2 max-w-screen-xl mx-auto">
        <div className="mb-2 max-w-screen-xl mx-auto">
          <div>
            <select className="h-8 text-mmd px-4 md:w-40 min-w-max bg-qosorange">
              <option value="" disabled selected hidden>
                No country
              </option>
            </select>
          </div>
        </div>
        <div className="space-x-4 my-2 grid md:grid-cols-1 gap-2 max-w-screen-xl mx-auto">
          <div className="grid md:grid-cols-1 gap-2 bg-white p-2 rounded-lg">
            <div className="p-1">
              <div className="flex justify-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
                <span> SUBSCRIBE TO AN OFFER FIRST </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// import {
//   useEffect,
//   useState,
//   // useRef
// } from "react";
// // import { DashTable } from "../../../components/tables/dashtable.component";
// // import { Switch } from "../../../components/switch.component";
// // import { PostData } from "../../../common/services/httpServices";
// // import DashboardCard14 from "../../../partials/dashboard/DashboardCard15";
// // import DashboardCard16 from "../../../partials/dashboard/DashboardCard16";
// // import axios from "axios";
// // import { getUser } from "../../../common/utils/tools";
// // import numeral from "numeral";
// // import Countries from "../../../partials/dashboard/Countries";
// // import { Spinner } from "../../../components/spinner.component";
// import { FetchData } from "../../../common/services/httpServices";

// import { Endpoints } from "../../../common/utils/endpoints";

// import DashboardCard07 from "../../../partials/dashboard/DashboardCard09";
// import DashboardCard04 from "../../../partials/dashboard/DashboardCard14";
// import numeral from "numeral";
// import { Spinner } from "../../../components/spinner.component";

// // import HistoryTransation from "../../../partials/dashboard/HistoryTransation";

// import UserAllLastTansaction from "../../../partials/dashboard/UserAllLastTansaction";

// const ci_operatorsdata = {};
// const togoceldata = {};
// const moovtgdata = {};
// const mtndata = {};
// const moovdata = {};
// const carddata = {};
// const typedata = {};

// mtndata["inflow"] = "REQUESTPAYMENT";
// mtndata["outflow"] = "MAKEDEPOSIT";
// moovtgdata["inflow"] = "REQUESTPAYMENT";
// moovtgdata["outflow"] = "MAKEDEPOSIT";
// togoceldata["inflow"] = "REQUESTPAYMENT";
// togoceldata["outflow"] = "MAKEDEPOSIT";
// moovdata["inflow"] = "REQUESTPAYMENTMV";
// moovdata["outflow"] = "MAKEDEPOSITMV";
// carddata["inflow"] = "REQUESTPAYMENT";
// carddata["outflow"] = "MAKEDEPOSIT";
// ci_operatorsdata["inflow"] = "REQUESTPAYMENT";
// ci_operatorsdata["outflow"] = "MAKEDEPOSIT";

// typedata["ci_operators"] = ci_operatorsdata;
// typedata["togocel"] = togoceldata;
// typedata["moovtg"] = moovtgdata;
// typedata["mtn"] = mtndata;
// typedata["moov"] = moovdata;
// typedata["card"] = carddata;

// export function Dashboard() {
//   let operateursVariable = [];
//   const [Operator, setOperator] = useState("MTN");
//   // const [Amount, setAmount] = useState({});

//   // const [country, setCountry] = useState({});
//   const [selects, setSelects] = useState();
//   // const [ luck, setLuck ] = useState('la chance');
//   // const [ loading, setLoading ] = useState(true);

//   // const [montant, setMontant] = useState({});

//   const [load, setload] = useState(true);
//   const [newOperators, setnewOperators] = useState(operateursVariable);

//   const [Infocountries, setInfocountries] = useState([]);
//   const [doloading, setDoLoading] = useState(true);
//   const [listOperators, setlistOperators] = useState([]);
//   const [chosenPays, setchosenPays] = useState("BENIN");

//   const countries = () => {
//     FetchData(Endpoints.Subscriptions.getCountries).then((res) => {
//       setInfocountries(res);
//       setDoLoading(false);
//       ListOfOperator({ target: { value: res.data.datas.items[0] } });
//     });
//   };

//   useEffect(() => {
//     countries();

//     // console.log('contries')
//   }, []);

//   async function getAllAvailableAmount(pays, operateurs) {
//     // console.log("OPEURATEUSSS :",operateurs)
//     if (pays) {
//       for (let i = 0; i < operateurs.length; i++) {
//         // console.log("paysss",i)
//         let type_of_req = typedata[operateurs[i].toLowerCase()]["inflow"];
//         let res = await FetchData(
//           Endpoints.User.getAvailableAmount +
//             "?country=" +
//             pays +
//             "&operator=" +
//             operateurs[i]
//         );
//         // console.log("paysss", res)
//         let resp = await FetchData(
//           Endpoints.Transactions.getTodayAmount +
//             "?country=" +
//             pays +
//             "&operator=" +
//             operateurs[i] +
//             "&type=" +
//             type_of_req
//         );
//         // console.log("paysss",resp)
//         let temp = resp.data.datas.inflow;

//         for (let v in temp) {
//           if (temp[v].period === "today") {
//             let count = temp[v].count;
//             let payment = temp[v].amount;
//             operateursVariable.push({
//               operateur: operateurs[i],
//               amount: res.data.datas.amount,
//               coun: count,
//               pay: payment,
//             });
//           }
//         }
//       }
//       setload(false);

//       setnewOperators(operateursVariable);
//     }
//   }

//   // function getAvailableAmount (pays,operateur,index,nb_total) {
//   //   // console.log('sssssss',operateur)
//   //   let type_of_req = typedata[operateur.toLowerCase()]['inflow']

//   //   // console.log("paysss",pays)
//   //   if (pays)
//   //   {
//   //     FetchData(
//   //       Endpoints.User.getAvailableAmount+'?country='+pays+"&operator="+operateur,

//   //     ).then((res) =>
//   //       {
//   //         // console.log("operator YUYU : ",res);
//   //         FetchData(
//   //           Endpoints.Transactions.getTodayAmount+'?country='+pays+'&operator='+operateur+'&type='+type_of_req

//   //           ).then((resp) =>
//   //             {
//   //             let temp = resp.data.datas.inflow;

//   //             for (let v in temp){
//   //               if (temp[v].period ==='today'){
//   //                 let count = temp[v].count ;
//   //                 let payment = temp[v].amount ;
//   //                 operateursVariable.push(res.data.datas.amount);

//   //               }
//   //             }
//   //             console.log('pp',res.data.datas)
//   //             console.log('ppindex',index)

//   //             if (index === nb_total)
//   //             {
//   //               setload(false)

//   //               setnewOperators(operateursVariable)

//   //               // console.log('next', newOperators)

//   //             }

//   //           });

//   //         }

//   //     );

//   //   }

//   // }

//   function ListOfOperator(pays) {
//     if (pays.target) {
//       FetchData(
//         Endpoints.Subscriptions.getOperators + "?country=" + pays.target.value
//       ).then((res) => {
//         setchosenPays(pays.target.value);
//         getAllAvailableAmount(pays.target.value, res.data.datas.items);
//         setlistOperators(res.data.datas.items);
//       });
//     }
//   }

//   function handleChange(event) {
//     // console.log("voila toi nous maj",event.target.value);
//     // getAmout(event)
//     setOperator(event.target.value);
//   }

//   // useEffect(() => {
//   //     inf()
//   //      console.log("verification country : ", Country )
//   // }, [loading] );

//   const handleSelect = (e) => {
//     e.preventDefault();
//     setSelects(e.target.value);
//   };

//   return (
//     <section className="my-8">
//       <div className="mb-2 max-w-screen-xl mx-auto">
//         <div className="mb-2 px-4 max-w-screen-xl mx-auto">
//           {/* <Switch setOperator={setOperator} /> */}

//           <div className="flex justify-end">
//             <div>
//               <select
//                 onChange={(e) => ListOfOperator(e)}
//                 className="h-8 text-mmd px-4 md:w-40 min-w-max bg-qosorange opacity-70"
//               >
//                 <option
//                   value=""
//                   disabled
//                   selected
//                   hidden
//                   className="text-white m-1"
//                 >
//                   BENIN
//                 </option>
//                 {doloading
//                   ? "BENIN"
//                   : Infocountries.data.datas.items.map((item) => (
//                       <option className="text-white m-1" key={item.index}>
//                         {item}
//                       </option>
//                     ))}
//               </select>
//             </div>

//             {/* <div>
//             { doloading ?
//                     <select
//                     onChange={handleInput}
//                      id="operators"
//                      name="operators"
//                      autoComplete="operators" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

//                    <option className="text-white m-1" >loading...</option>
//                     </select> : <select id="operators"
//                     onChange={handleChange}
//                     name="operators" autoComplete="operators" className="h-8 text-mmd px-4 md:w-40 min-w-max bg-qosorange  text-white bg-opacity-70">
//                    <option>select an operator</option>
//                     {listOperators.map(item => (
//                             <option key={item.index} >{item}</option>
//                             ))}
//                       </select>}

//             </div> */}
//           </div>
//         </div>

//         <div className="rounded-lg mx-auto">
//           {/* {newOperators.map((item,index) => (
//                       <div  className=" gap-1 w-full min-w-max mx-auto overflow-visible items-center px-2  border-opacity-10 border-qosdark ">
//                             {item.operateur}
//                       </div>
//                       ))
//             } */}
//           <DashboardCard04 newOperator={newOperators} loading={load} />
//         </div>

//         <div className="overflow-x-auto hideScroll rounded  p-4 space-x-4 my-2 grid md:grid-cols-1 gap-2 max-w-screen-xl mx-auto">
//           <div className="grid bg-white rounded ">
//             <div className="px-2 pt-3">
//               {doloading ? (
//                 <span
//                   // onChange={handleInput}
//                   id="operators"
//                   name="operators"
//                   autoComplete="operators"
//                   className="mt-1 block w-full  bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                 >
//                   <span className="text-black m-1">loading...</span>
//                 </span>
//               ) : (
//                 <select
//                   id="operators"
//                   onChange={handleChange}
//                   name="operators"
//                   autoComplete="operators"
//                   className="h-8 text-mmd w-32  md:w-40 min-w-max bg-qosorange  text-black bg-opacity-70"
//                 >
//                   {/* <option>select an operator</option> */}
//                   {listOperators.map((item) => (
//                     <option key={item.index}>{item}</option>
//                   ))}
//                 </select>
//               )}
//             </div>
//             <div className="overflow-x-auto hideScroll">
//               <DashboardCard07
//                 country={chosenPays}
//                 Operators={listOperators}
//                 network={Operator}
//               />
//             </div>
//           </div>
//         </div>

//         <div className="overflow-x-auto hideScroll space-x-4 my-2 grid md:grid-cols-1 gap-2 max-w-screen-xl mx-auto">
//           <UserAllLastTansaction country={chosenPays} />
//         </div>
//         {/* <Switch setOperator={setOperator} />  */}
//       </div>
//     </section>
//   );
// }

// function Overview() {
//   return (
//     <section className="my-8">
//       <div className="mb-2 max-w-screen-xl mx-auto">
//         <div className="mb-2 max-w-screen-xl mx-auto">
//           <div>
//             <select className="h-8 text-mmd px-4 md:w-40 min-w-max bg-qosorange">
//               <option value="" disabled selected hidden>
//                 No country
//               </option>
//             </select>
//           </div>
//         </div>
//         <div className="space-x-4 my-2 grid md:grid-cols-1 gap-2 max-w-screen-xl mx-auto">
//           <div className="grid md:grid-cols-1 gap-2 bg-white p-2 rounded-lg">
//             <div className="p-1">
//               <div className="flex justify-center font-bold border-b border-qosdark border-opacity-20 py-2 tracking-tighter text-mH3 text-qosdark text-opacity-90">
//                 <span> SUBSCRIBE TO AN OFFER FIRST </span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
