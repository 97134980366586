import { useState, useEffect } from "react";
import { FetchData, PostData } from "../common/services/httpServices";
import { VerifyUser, ActivateTwoAuth, DisableTwoAuth } from "../common/auth";
import { useFormData } from "../common/utils/hooks";
import { Endpoints } from "../common/utils/endpoints";
import { LogoutUser } from "../common/auth";
import { Spinner } from "./spinner.component";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";
import { media } from "../common/libs/media";
const { qosLogoBlack } = media.Logos.qos;
// import { FetchData, PostData } from "../common/services/httpServices";

function DiseableTwoFa() {
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);
  const [loading, setMLoading] = useState(true);
  const [user, setUser] = useState();
  // const [data, handleInput] = useFormData();

  const [isActive, setIsActive] = useState(false);
  const [isOn, setIsOn] = useState(false);

  const [show, setShow] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    setIsActive(!isActive);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const UserProfile = () => {
    FetchData(Endpoints?.User?.meNew).then((res) => {
      // console.log("resposnse :", res);
      setUser(res);
      setMLoading(false);
    });
  };

  useEffect(() => {
    UserProfile();
  }, []);

  const infoUser = loading ? "loading" : user?.data?.datas;
  // === "INACTIVE"
  //     ? "loading"
  //     : user?.data?.datas.state;
  // console.log(" infoUser:", infoUser.email);

  const handleToggle = () => {
    handleShow();
    setIsActive(!isActive);
  };

  function signout() {
    LogoutUser();
    history.replace("/");
  }
  // const handleClose = () => {
  //   setIsActive(!isActive);
  //   // console.log("rererer")
  // };

  const handleOpen = () => {
    setIsOn(!isOn);
    // console.log("handleOpen")
  };
  // disableTwoFactor

  // console.log("code data", data.code)

  const code = data?.code;

  // console.log(" variable code", code)

  const username = infoUser?.email;

  // console.log(" variable username", username)

  const datas = { username };

  function DisableTwoFa (e) {
    e.preventDefault();
    setLoading(true);
    PostData(
      Endpoints?.User?.disableTwoFactor + `${username}` + "/" + `${code}`
    ).then( (res) => {
      // console.log("resd disable", res);
      // setUser(res);
      // setMLoading(false);
      if (res?.data?.body?.responseCode === "00") {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        // history.push("/");

        Swal.fire({
          // let message = datas.responseCode;
          position: "top-end",
          // title: 'Successful!',
          text: res?.data?.body?.responseMessage,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
        setLoading(false);
        setTimeout(() => {
          handleClose();
          signout();
        }, 4000);
      } else {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          // title: 'Error!',
          text: "Invalide Code",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
          toast: true,
        });
      }
    })
    .catch((error) => console.log("error",error));
  };

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setLoading(true)

  //   console.log("data disable :", datas)
  //   // try {
  //     // data.isRememberMe = true;

  //       PostData(
  //           Endpoints.Endpoints.User.disableTwoFactor+'infoUser.email',

  //         ).then((res) =>
  //          {console.log("resd disable", res)}

  // );

  // const { data: user, status } = await DisableTwoAuth(datas);
  // console.log("status status : ", status)

  // console.log("datas.dat  : ", data)
  // console.log("user user: ", user.responseCode)
  // console.log("encript data token :", encryptStorage.getItem("qosToken"))
  // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
  // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
  // console.log("encript data  :", encryptStorage.getItem("qosUser"))

  // if (user.responseMessage === "Successful" ) {
  //   // console.log("encript data token :", encryptStorage.getItem("qosToken"))
  //   // history.push("/");

  //   Swal.fire({
  //     // let message = datas.responseCode;
  //     position: 'top-end',
  //     // title: 'Successful!',
  //     text: user.responseMessage,
  //     icon: 'success',
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: true,
  //     didOpen: (toast) => {
  //       toast.addEventListener('mouseenter', Swal.stopTimer)
  //       toast.addEventListener('mouseleave', Swal.resumeTimer)
  //     },
  //     toast:true,
  //   })
  // setLoading(false)

  // }else{

  //   setLoading(false)
  //   Swal.fire({
  //       position: 'top-end',
  //       // title: 'Error!',
  //       text: 'Please use real generated code number',
  //       icon: 'error',
  //       showConfirmButton: false,
  //       timer: 3000,
  //       timerProgressBar: true,
  //       didOpen: (toast) => {
  //         toast.addEventListener('mouseenter', Swal.stopTimer)
  //         toast.addEventListener('mouseleave', Swal.resumeTimer)
  //       },
  //       toast:true,
  //     })

  // }

  // } catch (error) {
  //   // alert("error");
  //   Swal.fire({
  //     position: 'top-end',
  //     // title: error,
  //     text: "Please use real generated code number",
  //     icon: 'error',
  //     showConfirmButton: false,
  //     timer: 3000,
  //     timerProgressBar: true,
  //     didOpen: (toast) => {
  //       toast.addEventListener('mouseenter', Swal.stopTimer)
  //       toast.addEventListener('mouseleave', Swal.resumeTimer)
  //     },
  //     toast:true,
  //   })
  // }
  //   setLoading(false)
  // }

  return (
    <div className="">
      <label className="flex  justify-center    items-center cursor-pointer">
        <div className="flex w-full items-center gap-2  pl-6 ">
         <div className="pt-2">
            <h5 className="text-red truncate md:truncate-non">Disable 2FA authentication</h5>
          </div>
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              className="pt-1"
              checked={isActive}
              onChange={handleToggle}
            />
          </Form>
         
        </div>
      </label>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <div className="w-full mt-4">
        <div className="p-1">
          <img src={qosLogoBlack} alt="" className="h-8 w-fit" />
        </div>

        <div className=" text-center  p-2 m-3 mt-4">
          <p className=" text-dark " style={{ fontSize: 18 }}>
          Deactivate 2FA
          </p>
        </div>

        <div className="p-2 ">
          <p className=" text-qosText">
          Open your authentication app and generates confirmation 
          code, enter it here
          </p>
        
        </div>

        <form
          id="stepForm"
          onSubmit={DisableTwoFa}
          className="overflow-x-hidden hideScroll text-center p-1 w-full"
        >
          <div className="mt-10">
            {/* <label className="block">Code </label> */}
            <input
              style={styles.input}
              className="py-4  h-14 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              name="code"
              value={data.code}
              onChange={handleInput}
              required
              placeholder="Enter confirmation code"
            />
          </div>

          <div className="font-bold w-full text-white  h-32   flex justify-around items-center mt-10  mb-10">
            <button
              style={styles.input}
              id="next"
              disabled={isLoading}
              type="submit"
              className={`w-full h-14 flex justify-center items-center  rounded-sm text-white font-bold ${
                isLoading ? "bg-coolGray-300" : "bg-qosorange"
              }  mt-1`}
              variant="warning"
              size="lg"
            >
              {isLoading ? (
                <Spinner className="h-4 w-4 mx-2 border-qosorange border-opacity-50" />
              ) : (
                <span className="text-white">Confirm</span>
              )}
            </button>
            {/* <Button
             style={styles.button}
               onClick={handleShow2}
               className="h-14  w-full   rounded-md font-bold bg-qosorange"
             >
              <span>Next</span>
            </Button> */}
          </div>
        </form>
      </div>

        </Modal.Body>
      </Modal>
    </div>
  );
}
const styles = {
  input:{
    // width:680,
    // height:"42px",
    borderRadius:4,
  },
  button:{
    // width:680,
    height:"28",
    borderRadius:8,
  }
}

export default DiseableTwoFa;
