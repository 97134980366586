import { GiConsoleController } from "react-icons/gi";
import { IfuFilesUploader } from "../pages/individualBusness";
import { IdFilesUploader } from "../pages/public/register/index";
import { IdBFilesUploader, IdFilesUploaderB } from "../pages/registerBusness";

function IFuDocument() {
  return (
    <div className="mt-5">
      <div className="flex text-left w-auto border-black ">
        <p className="text-qosdark">Upload copy of your IFU </p>
      </div>
      <div className="flex w-auto">
        {/* <IdFilesUploader selec={selec.selects} className="w-full" text="uploads your Id " /> */}
        {/* <IdDoc */}
        {/* <IdFilesUploaderB/> */}
        {/* <IdBFilesUploader className="w-full" text="uploads your IFU "/> */}
        <IfuFilesUploader className="w-full" text="Uploads your IFU "/>
      </div>
    </div>
  );
}

export default IFuDocument;
