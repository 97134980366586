import Wrapper from "../../../components/sectionWrapper.component";
import Messages from "./translations/index.json";
import InputsText from "../../../translations/input.json";
import { useLangContext } from "../../../components/translateContext";
import buttonText from "../../../translations/button.json";
import { useFormData } from "../../../common/utils/hooks";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link } from "react-router-dom";
import { media } from "../../../common/libs/media";
import axios from "axios";
import { VerifyUser } from "../../../common/auth";
import { useHistory } from "react-router";
import { encryptStorage } from "../../../common/services/encryptData";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/spinner.component";

import Swal from "sweetalert2";
import showPwdImg from './showPwdImg.svg';
import hidePwdImg from './hidePwdImg.svg';
export default function LoginPage() {
  const { qosLogoWhite } = media.Logos.qos;
  const { Lang } = useLangContext();
  const history = useHistory();
  const Infos = { msg: Messages[Lang], input: InputsText[Lang] };
  const btnMsg = buttonText[Lang];
  const [data, handleInput] = useFormData();
  const [isLoading, setLoading] = useState(false);


  const [ip , setIp] = useState('');
  const [city , setCity] = useState('');
  const [country , setCountry] = useState('');
  
 


  const getUrIp = () => {
    // const ip = await axios.get('https://ipapi.co/json/');
    // console.log("mon ip:", ip)
     
  fetch('https://ipapi.co/json/')
  .then(function(response) {
    response.json().then(jsonData => {
  
      setIp(jsonData.ip);
    });
  })
  .catch(function(error) {
    // console.log("errue",error)
  });
  
    

  }

  const getUrpays = async()=> {
    // const ip = await axios.get('https://ipapi.co/json/');
    fetch('https://ipapi.co/json/')
    .then(function(response) {
      response.json().then(jsonData => {
        setCountry(jsonData.country_name);
      });
    })
    .catch(function(error) {
      // console.log("errue",error)
    });
    // setCountry(ip.data.country_name);

  }

  const getUrCity = async()=> {
    fetch('https://ipapi.co/json/')
    .then(function(response) {
      response.json().then(jsonData => {
        setCity(jsonData.city);
      });
    })
    .catch(function(error) {
      // console.log("errue",error)
    });
    // const ip = await axios.get('https://ipapi.co/json/');
    //  setCity(ip.data.city);

  }

  // useEffect(() => {
    // getUrIp();
    // getUrpays();
    // getUrCity();
    // localStorage.setItem('items', JSON.stringify(ip));
    // localStorage.setItem('cite', JSON.stringify(city));
    // localStorage.setItem('pays', JSON.stringify(country));
    // if (encryptStorage.getItem("qosToken")) {
    //   history.push("/dashboard");
    // }
  // }, [history, ip, country, city]);

 

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true)
    try {
      data.isRememberMe = true;
      
      const { data: user, status } = await VerifyUser(data);
      // console.log("login data : ", data)
      // console.log("login status : ", status)
      
      // console.log("user :", user)
      // console.log("encript data appVersion :", encryptStorage.getItem("qosUser")?.appVersion)
      // console.log("encript data status :", encryptStorage.getItem("qosUser")?.state)
      // console.log("encript qosUser  :", encryptStorage.getItem("qosUser"))
      // console.log("encript email  :", encryptStorage.getItem("qosUser")?.email)

      if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="1.0" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === false ) {
        // console.log("encript data token :", encryptStorage.getItem("qosToken"))
        history.push("/dashboard");
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="1.0" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === true){
        history.push("/authenticator");
        
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="1.0" && encryptStorage.getItem("qosUser")?.state ==="ACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === false   ){
        history.push("/dashboard");
        
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="1.0" && encryptStorage.getItem("qosUser")?.state ==="ACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === false || encryptStorage.getItem("qosUser")?.isUsing2Fa === null  ){
        history.push("/dashboard");
        
      }else if ( encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="2.0" && encryptStorage.getItem("qosUser")?.state ==="INACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === false ){
        history.push("/getstarted");
        
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="2.0" && encryptStorage.getItem("qosUser")?.state ==="INACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa ===  true){
        history.push("/authenticator");
        
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="2.0" && encryptStorage.getItem("qosUser")?.state ==="ACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === false ){
        history.push("/dashboard");
        
      }else if (encryptStorage.getItem("qosToken") && encryptStorage.getItem("qosUser")?.appVersion ==="2.0" && encryptStorage.getItem("qosUser")?.state ==="ACTIVE" &&  encryptStorage.getItem("qosUser")?.isUsing2Fa === true ){
        history.push("/authenticator");
        
      }else if ( user.responseMessage === "Your account is not activated. Please check your registration mail "){
        setLoading(false)
        Swal.fire({
            position: 'top-end',
            title: '',
            text: user.responseMessage,
            icon: 'warning',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
            toast:true,
          })
          setTimeout(() => {
            history.push("/resendemailverify", { detail: `${data.username}` });
            
          }, 3000);
        
      }else if ( user.responseMessage === "No value present"){
        setLoading(false)
        Swal.fire({
            position: 'top-end',
            title: 'No value present',
            text: "User profile not found",
            icon: 'warning',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
            toast:true,
          })
        
      }else if (!encryptStorage.getItem("qosToken")){
        setLoading(false)
        Swal.fire({
            position: 'top-end',
            title: 'Error!',
            text: 'Bad credentials check your e-mail & password',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
            toast:true,
          })
        
      }else{
        alert("error");
      }
      
    } catch (error) {
      alert("error");
    }
    setLoading(false)
  }

  const [pwd, setPwd] = useState('');
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  return (
    <Wrapper className="" >
      <div className="bg-white  border-t shadow-sm border-qosgrain  w-full  mx-auto px-4  my-15 py-14   md:w-3/6 lg:w-3/6 sm:w-full lg:px-2 md:px-2   ">
        <div className="w-full p-4  flex bg-white justify-center items-center ">
          <span className="text-gray-600 inline-block h-9 dark:text-gray-300 text-mH3 md:text-mH2 font-black">
            <img src={qosLogoWhite} alt="" className="h-full w-auto" />
          </span>
        </div>
        <form onSubmit={handleSubmit} className="  w-full ">
          <div className="mx-auto  lg:w-8/12 mt-8">
            <span className="block">{Infos.input.email.label}</span>
            <input
              style={styles.input}
              onChange={handleInput}
              className=" py-4 border rounded-lg focus:outline-none focus:ring-1 focus:ring-qosorange focus:border-qosorange"
              value={data.username}
              name="username"
              type="email"
              required
              placeholder={Infos.input.email.placeholder}
            />
          </div>
          <div className="mx-auto lg:w-8/12  mt-8">
            <div className="flex items-center justify-between">
              <span className="block">{Infos.input.password.label}</span>{" "}
            </div>

            <div
              style={styles.input}
              className="flex bg-white border my-1 border-qosgraye gap-1 rounded-lg justify-around "
            >
              <input
                style={styles.input}
                onChange={handleInput}
                className="bg-transparent border-none rounded"
                value={data.password}
                name="password"
                color="red"
                type={isRevealPwd ? "text" : "password"}
                required
                placeholder="Enter your password"
              />

              <img
                height={20}
                width={20}
                className="mx-2 bg-white"
                title={isRevealPwd ? "Hide password" : "Show password"}
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
            <div className="flex  justify-end  ">
            <Link
                // className="text-qosorange underline "
                to="/PasswordReset"
              >
                <p className="text-qosorange underline ml-1">
                {Infos.msg.passwordForgot}
                </p>
                
              </Link>

            </div>

            <PasswordStrengthBar
              className="hidden"
              scoreWordStyle={{ textTransform: "uppercase", fontSize: "12px" }}
              password={data.password}
            />
          </div>

          <button
            disabled={isLoading}
            style={styles.button}
            className={` w-full xl:w-8/12 lg:w-8/12   flex justify-center items-center  text-white text-opacity-80  ${
              isLoading ? "bg-coolGray-300" : "bg-qosorange"
            }  p-3 btn-sm mx-auto mt-14`}
          >
            {isLoading ? (
              <Spinner className="border-qosorange h-4 w-4 mx-1" />
            ) : (
              <div className="flex gap-2  justify-center align-center">
                <span>{btnMsg.logInButton}</span>
                <span className="fi-rr-arrow-right mt-1"></span>
              </div>
            )}
          </button>

          <div className="inline-block mb-10  mt-4 text-center w-full justify-between align-center lg:mx-auto lg:w-8/12  lg:flex lg:text-center ">
            <p className="text-center flex  pr-2">
            {Infos.msg.dont}
              <Link
                // className="text-qosorange underline "
                to="/signup"
              >
                <p className="text-qosorange underline ml-1">
                {Infos.msg.create}
                </p>
                
              </Link>
            </p>

            {/* <p>
              <Link
                className="text-qosblack underline  "
                to="/PasswordReset"
              >
                <p>Forgot Password?</p>
              </Link>
            </p> */}
          </div>
        </form>
      </div>
    </Wrapper>
  );
}

const styles = {
  input:{
    // width:680,
    // height:"42px",
    borderRadius:4,
  },
  button:{
    // width:680,
    height:"28",
    borderRadius:8,
  }
}