import { encryptStorage } from "../services/encryptData";
import { PostData, PutData} from "../services/httpServices";
import { Endpoints } from "../utils/endpoints";



export async function VerifyUser(credentials = {}) {
  try {
    // console.log("credantial : " ,credentials)
    encryptStorage.clear();
    const res = await PostData(Endpoints.User.signinNew, credentials);
    // console.log("verify data :", res )
    // console.log("ve token :", res.data.datas.token )
  

    if (res.status === 200 && res.data.responseStatus === true ) {
      // console.log("my dataaa  :", data )
      encryptStorage.setItem("qosToken", res.data.datas.token);
      encryptStorage.setItem("qosUser", res.data.datas.user);
      encryptStorage.setItem("qosUserLog", res.data.datas);
      // console.log("encrypte qosUser :", encryptStorage.setItem("qosUser", data.datas.user) )
      
        //getTransfertCredentialsFromServer();
    }
    return res;
  } catch (e) {
    // console.log(e, "fab log");
  }
}

export async function ResentVerify(credentials) {
  try {
    // console.log("credantial Resend: " ,credentials)
  
    const res = await PostData(Endpoints.User.resentVerification, credentials);
    // console.log("verify Resend data :", res )
    // console.log("ve token :", res.data.datas.token )
    return res;
  } catch (e) {
    // console.log(e, "fab log");
  }
}

export async function CreateUser(userData) {
  delete userData.value;
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.User.signup, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function CreateNew(userData) {
  delete userData.value;
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.User.signupNewNew, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function AddAccount(userData) {
  delete userData.value;
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.User.AddNewAccount, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function AddNewProfile(userData) {
  delete userData.value;
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.User.AddProfile, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function SubscribeTo(userData) {
  delete userData.value;
  // console.log("New user individuale : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.Subscriptions.SubscribeToService, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Individual :",res);
    return res;
  } catch (e) {}
}

export async function Registered(userData) {
  delete userData.value;
  // console.log("New user individuale : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.Subscriptions.SubscribeToService, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Individual :",res);
    return res;
  } catch (e) {}
}



export async function ActivateTwoAuth(userData) {
  delete userData.value;
  // console.log("New user individuale : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload ActivateTwoAuth: ", payload)
  
  try {
    const res = await PostData(Endpoints.User.verifyTwoFactor, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("ActivateTwoAuth :",res);
    return res;
  } catch (e) {}
}


export async function TopUp(userData) {
  delete userData.value;
  // console.log("New user individuale : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("userData: ", payload)
  
  try {
    const res = await PostData(Endpoints.Transfer.TopUp, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("ActivateTwoAuth :",res);
    return res;
  } catch (e) {}
}
export async function ValidateTwoAuth(userData) {
  delete userData.value;


  let payload = JSON.stringify(userData)  ;

  try {
    const res = await PostData(Endpoints.User.validateTwoFactor + `${userData.username}` + "/" + `${userData.code}`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {}
}

export async function AddwhitelisteApi(userData) {
  delete userData.value;
  // console.log("validate two auth: ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload AAddwhitelisteApi: ", payload)
  // Endpoints.User.disableTwoFactor + `${username}` + "/" + `${code}`
  try {
    const res = await PutData(Endpoints.UsersProfile.whitelisteApi + '?clientId=' + `${userData.clientId}` + "&ipAdresse=" + `${userData.ipAdresse}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("AddwhitelisteApi res :",res);
    return res;
  } catch (e) {}
}

export async function CallbackUrl(userData) {
  delete userData.value;
  // console.log("New withdrawData Data : ", withdrawData)

  // userData.amount = parseInt(userData.amount)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(userData)  ;
  console.log("Payload CallbackUrl : ", payload)
  
  try {
    const res = await PostData(Endpoints.UsersProfile.AddcallBack, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res withdrawData :",res);
    return res;
  } catch (e) {}
}

export async function DeleteApi(userData) {
  delete userData.value;
 
 
  try {
    const res = await PostData(Endpoints.UsersProfile.whitelisteApiDelete + '?clientId=' + `${userData.deleteIp.e}` + "&ipAdresse=" + `${userData.deleteIp.ip}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("whitelisteApiDelete res :",res);
    return res;
  } catch (e) {}
}


export async function DisableTwoAuth(userData) {
  delete userData.value;
  // console.log("New user individuale : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload ActivateTwoAuth: ", payload)
  
  try {
    const res = await PostData(Endpoints.User.disableTwoFactor, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("ActivateTwoAuth :",res);
    return res;
  } catch (e) {}
}

export async function Generate(userData) {
  delete userData.value;
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.Rapport.generateRapport, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function CreateOffer(userData) {
  delete userData.value;
 

  userData.validity_period = parseInt(userData.validity_period)
  userData.base_fee = parseInt(userData.base_fee)
  userData.evaluation_period = parseInt(userData.evaluation_period)
  userData.is_free = (userData.is_free === 'true');
  userData.has_evaluation_period = (userData.has_evaluation_period === 'true');
  userData.operators = [userData.operators] 
  // console.log("New user Data : ", userData)

  let payload = JSON.stringify(userData)  ;
  // console.log("Payload : ", payload)
  
  try {
    const res = await PostData(Endpoints.Offers.saveAnOffer, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res :",res);
    return res;
  } catch (e) {}
}

export async function CreatePayementLink(paymentData) {
  delete paymentData.value;
  // console.log("New paymentlink Data : ", paymentData)

  paymentData.product_qte = parseInt(paymentData.product_qte)
  paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(paymentData)  ;
  // console.log("Payload paymenlink : ", payload)
  
  try {
    const res = await PostData(Endpoints.Paymentlinks.createUserPaymentLinks, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res paymentLink :",res);
    return res;
  } catch (e) {}
}

export async function CreatePayementLink2(paymentData) {
  delete paymentData.value;
  // console.log("New paymentlink Data : ", paymentData)

  paymentData.product_qte = parseInt(paymentData.product_qte)
  paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(paymentData)  ;
  // console.log("Payload paymenlink : ", payload)
  
  try {
    const res = await PostData(Endpoints.Paymentlinks.CreateUserpaymentLinks, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("CreateUserpaymentLinks paymentLink :",res);
    return res;
  } catch (e) {}
}

export async function DeletePamentLink2(paymentData) {
  delete paymentData.value;
  // console.log("New paymentlink Data : ", paymentData)

  // paymentData.product_qte = parseInt(paymentData.product_qte)
  // paymentData.product_price = parseInt(paymentData.product_price)

  // let payload = JSON.stringify(paymentData)  ;
  // console.log("Payload paymenlink  delete: ", payload)
  
  try {
    const res = await PostData(Endpoints.Paymentlinks.paymentLinkDelete+'?paymentUrl='+paymentData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("paymentLinkDelete :",res);
    return res;
  } catch (e) {}
}

export async function Withdrawal(withdrawData) {
  delete withdrawData.value;
  // console.log("New withdrawData Data : ", withdrawData)

  withdrawData.amount = parseInt(withdrawData.amount)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(withdrawData)  ;
  // console.log("Payload withdrawData : ", payload)
  
  try {
    const res = await PostData(Endpoints.Transfer.WithdrawMoney, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res withdrawData :",res);
    return res;
  } catch (e) {}
}


export async function CheckStatusValue(valueStatue) {
  delete valueStatue.value;


  // payload.amount = parseInt(payload.amount)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(valueStatue)  ;
  console.log("Payload jsonstringify : ", payload)
  
  try {
    const res = await PostData(Endpoints.Transactions.getOperationStatus, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("getOperationStatus :",res);
    return res;
  } catch (e) {}
}


export async function CardPayout(CardPayout) {
  delete CardPayout.value;
  // console.log("New CardPayout Data : ", CardPayout)

  CardPayout.amount = parseInt(CardPayout.amount)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(CardPayout)  ;
  // console.log("Payload CardPayout : ", payload)
  
  try {
    const res = await PostData(Endpoints.Transfer.PayoutCard, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res CardPayout :",res);
    return res;
  } catch (e) {}
}

export async function SinglePay(SinglePay, verificationCode) {
  delete SinglePay.value;
  // console.log("New SinglePay Data : ", SinglePay)

  SinglePay.amount = parseInt(SinglePay.amount)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(SinglePay)  ;
  // console.log("Payload TransferMomo : ", payload)
  
  try {
    const res = await PostData(Endpoints.Transfer.TransferMomo+'?code='+verificationCode, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone Res SinglePay :",res);
    return res;
  } catch (e) {}
}



export async function Payfromlink(paymentData) {
  delete paymentData.value;
  // console.log("New for pay paymentlink Data : ", paymentData)

  paymentData.product_qte = parseInt(paymentData.product_qte)
  paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(paymentData)  ;
  // console.log("Payload pay by paymenlink : ", payload)
  
  try {
    const res = await PostData(Endpoints.Paymentlinks.payByPaymentLinks, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone payé by paymentLink :",res);
    return res;
  } catch (e) {}
}


export async function ResetPassword(password) {
  delete password.value;
  // console.log("New for pay paymentlink Data : ", paymentData)

  // paymentData.product_qte = parseInt(paymentData.product_qte)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(password)  ;
  // console.log("password by password : ", payload)
  
  try {
    const res = await PutData(Endpoints.User.resetUserPassword, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("Fabrone payé by paymentLink :",res);
    return res;
  } catch (e) {}
}


export async function PasswordReset(reset, code) {
  delete reset.value;
  // console.log("New code : ", code)

  // paymentData.product_qte = parseInt(paymentData.product_qte)
  // paymentData.product_price = parseInt(paymentData.product_price)

  let payload = JSON.stringify(reset)  ;
  // console.log("reset by password : ", payload)
  
  try {
    const res = await PostData(Endpoints.User.verificationForPassword+'?code='+code, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log("ceode frfr :",res);
    return res;
  } catch (e) {}
}

export const checkAuthentication = () => {
  let userLoginStatus =
    encryptStorage.getItem("qosToken") &&
    // encryptStorage.getItem("qosUser") &&
    true;
  if (userLoginStatus !== null && ["true", true].includes(userLoginStatus)) {
    return true;
  }
  return null;
};

export const LogoutUser = () => {
  if (checkAuthentication()) {
    encryptStorage.clear();
  }
};

export const checkAuthorization = (url) => {
  let accessibleMenus = encryptStorage.getItem("accessibleMenus");
  let authorized = false;
  if (!accessibleMenus && accessibleMenus.length > 0) {
    let checkerArr = accessibleMenus.filter(
      (obj) => obj.menuController === url
    );
    if (checkerArr && checkerArr.length > 0) {
      authorized = true;
    }
  }
  return authorized;
};
