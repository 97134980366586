import React, { useEffect, useState, useRef } from "react";

import { useLangContext } from "../components/translateContext";
import transfer from "../components/translation/transfer.json";

import { Icon } from "@iconify/react";
import TopUpComponent from "../components/topUp.component";
import { Link } from "react-router-dom";

export function TopUp() {
  const [state, setstate] = useState(0);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  return (
    <section>
      <header className="">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-32 sm:w-32 md:w-44  text-white h-full bg-qosorange bg-opacity-40 rounded-full p-2 md:px-4`}
          ></div>
        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <Transfers />}
          {/* {state === 1 && <Transfers />} */}
        </section>
      </div>
    </section>
  );
}

function Transfers() {
  return (
    <section>
      <div className="mt-10 pl-10 flex text-left gap-1">
        <Link to="/TopUpPage" className="">
          <p
            className="pt-2 flex justify-center items-center gap-2 text-semibold cursor-pointer"
            style={{ fontSize: 15, color: "#45464E" }}
          >
            <Icon
              icon="bx:arrow-back"
              height={24}
              style={{ color: "black", fontSize: "20px" }}
            />
            Go back
          </p>
        </Link>
      </div>

      <div>
        <TopUpComponent />
        {/* <CommingSoon/> */}
      </div>
    </section>
  );
}
