// import { Sidebar } from "../components/sidebar.paycomponent";
// import { Header } from "../components/header.paycomponent";
import { backgroundImage } from "tailwindcss/defaultTheme";
import { useSideBarContext } from "../common/utils/context";
// import { BreadCrumb } from "../components/breadcrumb.component";
export default function PayLayout({ children }) {
  const { Active, setActive } = useSideBarContext();
  return (
    <main className="bg-white min-h-screen relative flex overflow-hidden" 
    style={{  
     
    // background: "url('https://checkout.qosic.net/assets/img/mobile-money-transfer-illustration.png')",
    // backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    right: 0,
    left: 0,
    position:"absolute",
    justifyContent: "center",
    alignContent: "center",
    alignItems: 'center',
    
    
  }}
    
    >
  
      {/* <Sidebar /> */}
      
   
      <section
        className={`${
          Active ? "w-full" : "w-full"
        } max-h-screen overflow-x-hidden overflow-y-auto pt-16`}
      >
        {/* <Header /> */}
      
        <section
          className={`px-0 py-2 ${
            Active ? "mx-0" : "mx-auto"
          } overflow-hidden`}
        >
      
          {/* koie */}
          {children}
          {/* okaa */}
        </section>
      </section> 
    </main>
  );
}
