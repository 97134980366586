import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
// import { getUser } from "../common/utils/tools";
// import { Switch } from "../components/switch.component";
// import { getUserTransCredentials } from "../common/utils/tools";
// import { useMemo } from "react";
// import axios from "axios";
// import { black } from "tailwindcss/colors";
// import { isEmpty, isNull, isUndefined } from "lodash";
import { Spinner } from "../components/spinner.component";
// import { reduceHooks } from "react-table";
// import { Button } from 'react-bootstrap';
import { useLangContext } from "../components/translateContext";
import transfer from "../components/translation/transfer.json";
import {
  FetchData,
  //  PostData
} from "../common/services/httpServices";
import { Endpoints } from "../common/utils/endpoints";
import { useFormData } from "../common/utils/hooks";

import TransferComponent from "../components/transfert.component";
import { Withdrawal, CardPayout } from "../common/auth";
import Swal from "sweetalert2";
import SingletransfertComponent from "../components/singletransfert.component";
import BulkComponent from "../components/bulk.component";
import CommingSoon from "../components/commingSoon";

export function BulkTransfer() {
  
  const [state, setstate] = useState(0);
  const [isLoading, setLoading] = useState(false);
  // const btnMsg = Button;
  const { Lang } = useLangContext();
  const Infos = { msg: transfer[Lang] };

  return (
    <section>
      <header className="">
        <nav className="bg-white overflow-hidden rounded-full flex justify-between max-w-max relative">
          <div
            className={`z-0 absolute mb-1 translate-x-0 transform transition-all duration-500 ${
              state === 0
                ? "translate-x-0"
                : state === 1
                ? "translate-x-24 sm:translate-x-32 md:translate-x-44"
                : `translate-x-24 sm:translate-x-32 md:translate-x-44 ml-24 sm:ml-32 md:ml-44`
            } w-32 sm:w-32 md:w-44  text-white h-full bg-qosorange bg-opacity-40 rounded-full p-2 md:px-4`}
          ></div>

        </nav>
      </header>
      <div>
        <section>
          {state === 0 && <Transfers />}
          {/* {state === 1 && <Transfers />} */}
        </section>
      </div>
    </section>
  );
}



function Transfers() {
  return (
    <section>
      <div className="mt-10 pl-14 flex text-left gap-1">
        <p
          className="pt-2 text-semibold "
          style={{ fontSize: 20, color: "#45464E" }}
        >
          Transfer
        </p>
      </div>

      <div>
       <BulkComponent/>
       {/* <CommingSoon/> */}
      </div>
    </section>
  );
}